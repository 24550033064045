import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import corporateImage from "../assets/Images/CorporateRentals1.webp";
import corporateImage2 from "../assets/Images/CorporateRentals2.webp";
import corporateImage3 from "../assets/Images/CorporateRentals3.webp";
import corporate1 from "../assets/Images/corporate1.png";
import corporate2 from "../assets/Images/corporate2.png";
import corporate3 from "../assets/Images/corporate3.png";
import checkbox from "../assets/Images/checkbox1.png";
import DiscoverContainer from "../Components/DiscoverContainer";
import CarouselContainer from "../Components/CarouselContainer";
import emailWhiteIcon from "../assets/svg/emailWhiteIcon.svg";
import phoneWhiteIcon from "../assets/svg/phoneWhiteIcon.svg";
import SpinnerLoader from "../Components/SpinnerLoader";
import { setCurrentPage } from "../Store/pageSlice";
import axios from "axios";
import { BASE_URL } from "../api";
import { toast } from "react-toastify";
import Recaptcha from "../Components/Recaptcha";
const CorporateRentals = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const [initialLoading, setInitialLoading] = useState(true);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    email: "",
    message: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("corporateRentals"));
  }, [dispatch]);

  // This effect runs once when the component is mounted (page load)
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoading(false);
    }, 2000);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const validateForm = () => {
    let newErrors = {};

    // Validate first name
    if (!formData.firstName) {
      newErrors.firstName = "First name is required.";
    }

    // Validate last name
    if (!formData.lastName) {
      newErrors.lastName = "Last name is required.";
    }

    // Validate job title
    if (!formData.jobTitle) {
      newErrors.jobTitle = "Job title is required.";
    }

    // Validate company name
    if (!formData.companyName) {
      newErrors.companyName = "Company name is required.";
    }

    // Validate email using regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid Gmail address.";
    }

    // Validate message length
    if (formData.message.length < 50) {
      newErrors.message = "Message must be at least 50 characters long.";
    }

    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Optional: You can validate on input change as well if needed
    // validateForm();
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    const value = formData[name];

    let error = "";
    if (!value) {
      error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
    } else if (
      name === "email" &&
      !/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(value)
    ) {
      error = "Please enter a valid gmail address.";
    } else if (name === "message" && value.length < 50) {
      error = "Message must be at least 50 characters long.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    
    if (Object.values(errors).every((error) => error === "")) {
      setInitialLoading(true)
      try {
        const response = await axios.post(
          `${BASE_URL}common/createCorporate/open`,
          formData
        );
        if (response.status === 200) {
          toast.success("Form submitted successfully!");
          setFormData({
            firstName: "",
            lastName: "",
            jobTitle: "",
            companyName: "",
            email: "",
            message: "",
          })
        } else {
          toast.error("Failed to submit form: ");
        }
        setInitialLoading(false)
      } catch (error) {
        toast.error("Error submitting form: ");
        setInitialLoading(false)
      }
    } else {
      toast.error("Form has validation errors:");
      setInitialLoading(false)
    }
  };

  const [captchaVerified, setCaptchaVerified] = useState(false);
  // Handle captcha response
  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  return (
    <div
      className={`${
        isDarkMode ? "bg-dark text-white" : "bg-light text-dark"
      } fontWeight400`}
    >
      {initialLoading && (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      <div className="container-fluid d-flex flex-column px-0">
        <div className="">
          {/* Image with responsive height for mobile */}
          <img
            className="object-fit-cover w-100 d-none d-md-block height100vh"
            src={corporateImage}
            alt="Corporate Rentals"
          />
          {/* For mobile screen, the image height is reduced */}
          <img
            className="object-fit-cover w-100 d-md-none"
            src={corporateImage}
            style={{ height: "50vh" }} // Adjust this to 50-60% as needed
            alt="Corporate Rentals"
          />

          {/* Overlay text */}
          <div className="position-absolute  top-30-sm top-50 start-50 translate-middle text-center text-white">
            <div className="responsive-landing-header">
              <h1 className="mb-3 fontWeight400">
                Discover Sustainable Corporate Travel with Joulez
              </h1>
              <div className="desc fs-6 mt-2 fontWeight400">
                {" "}
                {/* Adjust the font size for mobile */}
                Empower Your Business with Electric Vehicle Rentals
              </div>
            </div>
          </div>
        </div>

        <div className="bg3B3936">
          <div className="container d-flex flex-column flex-lg-row align-items-center text-white p-4">
            <div className="col-lg-6 order-1 order-lg-2">
              <img
                src={corporateImage2}
                alt="Corporate Rentals 2"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 px-4 text-center text-lg-start ">
              <h2 className="fontWeight400">
                A Unique Program Tailored to Your Business
              </h2>
              <p className="lead fontWeight400">
                As a member, you get access to amazing benefits such as rewards,
                discounts, and more every time you or your team rents with
                Joulez.
              </p>
              <button type="button" className="btn bg725CE1 fontWeight400"
                onClick={() => document.getElementById('contactUsSection').scrollIntoView({ behavior: 'smooth' })}>
                Contact Us
              </button>
            </div>
          </div>
        </div>

        <div className="bg23 py-4">
          <div className="container text-center ">
            <h3 className="mb-4 text-white fontWeight400 fs-5">
              Why Choose Joulez for Your Corporate Needs?
            </h3>
            <div className="row">
              <div className="col-12 col-md-4 mb-4">
                <img
                  src={corporate2}
                  alt="Eco-Friendly Innovation"
                  className="img-fluid"
                />
                <h5 className="mt-2 fontWeight400">Eco-Friendly Innovation</h5>
                <p className="fontWeight400 bgInfo">
                  Reduce your carbon footprint and showcase your commitment to
                  sustainability.
                </p>
              </div>
              <div className="col-12 col-md-4 mb-4">
                <img
                  src={corporate1}
                  alt="Cost-Effective Solutions"
                  className="img-fluid"
                />
                <h5 className="mt-2 fontWeight400">Cost-Effective Solutions</h5>
                <p className="fontWeight400 bgInfo">
                  Enjoy significant savings on fuel costs with our
                  top-of-the-line electric vehicles.
                </p>
              </div>
              <div className="col-12 col-md-4 mb-4">
                <img
                  src={corporate3}
                  alt="Seamless Booking Experience"
                  className="img-fluid"
                />
                <h5 className="mt-2 fontWeight400">
                  Seamless Booking Experience
                </h5>
                <p className="fontWeight400 bgInfo">
                  Our user-friendly platform ensures a hassle-free reservation
                  process.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg3B3936">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-1">
              <img
                src={corporateImage3}
                alt="Corporate Rentals 3"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 order-2 order-lg-2 text-center text-lg-start mt-4 mt-lg-0 fontWeight400">
              <h2 className="fontWeight400 fontAntarctica">
                Exclusive Corporate Rental Perks with Joulez:
              </h2>
              <ul className="list-unstyled">
                <li className="d-flex align-items-center mb-3">
                  <img
                    src={checkbox}
                    alt="Special Discounts"
                    className="me-2"
                    width="30"
                    height="30"
                  />
                  <div>
                    <strong className="fontWeight400 fw-500">
                      Special Discounts
                    </strong>
                    <p className="mb-0 bgInfo">
                      Enjoy up to 10% off on base rates, making your corporate
                      travel more affordable.
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <img
                    src={checkbox}
                    alt="Reward Days"
                    className="me-2"
                    width="30"
                    height="30"
                  />
                  <div>
                    <strong className="fontWeight400">Reward Days</strong>
                    <p className="mb-0 bgInfo">
                      Earn a Reward Day after your first rental and for every 20
                      rental days.
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <img
                    src={checkbox}
                    alt="Business Member Site"
                    className="me-2"
                    width="30"
                    height="30"
                  />
                  <div>
                    <strong className="fontWeight400">
                      Business Member Site
                    </strong>
                    <p className="mb-0 bgInfo">
                      Access the Joulez for Business portal for effortless
                      tracking of rentals and rewards.
                    </p>
                  </div>
                </li>
              </ul>
              <button type="button" className="btn bg725CE1 fontWeight400"
                onClick={() => document.getElementById('contactUsSection').scrollIntoView({ behavior: 'smooth' })}>
                Contact Us
              </button>
            </div>
          </div>
        </div>

        </div>
        
      </div>
      <div className=" d-flex justify-content-center w-100 mt-0">
        <DiscoverContainer marginTop="mt-0" />
      </div>
      <div className="text-white">
        <CarouselContainer />
      </div>
      <div   id="contactUsSection" className="d-flex bg3B3936 flex-column  contactForm justify-content-center align-items-center">
        <div className="text-white fontAtacama fs-3 my-3">Contact Us</div>
        <div className="input-group d-flex flex-column fontWeight400">
          <div className="d-flex flex-row">
            <div className="d-flex flex-column w-100">
              <input
                name="firstName"
                placeholder="First Name"
                type="text"
                value={formData.firstName}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                className="form-control supportFormFocus"
                style={{ marginRight: "4px" }}
              />

              {errors.firstName && (
                <div className="text-danger">{errors.firstName}</div>
              )}
            </div>

            <div className="d-flex flex-column w-100">
              <input
                name="lastName"
                placeholder="Last Name"
                type="text"
                value={formData.lastName}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                className="form-control supportFormFocus"
                style={{ marginLeft: "1px" }}
              />
              {errors.lastName && (
                <div className="text-danger">{errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="d-flex mt-2 flex-row">
            <div className="d-flex flex-column w-100">
              <input
                name="jobTitle"
                placeholder="Job Title"
                type="text"
                value={formData.jobTitle}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                className="form-control supportFormFocus"
                style={{ marginRight: "4px" }}
              />
              {errors.jobTitle && (
                <div className="text-danger">{errors.jobTitle}</div>
              )}
            </div>
            <div className="d-flex flex-column w-100">
              <input
                name="companyName"
                placeholder="Company Name"
                type="text"
                value={formData.companyName}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                className="form-control supportFormFocus"
                style={{ marginLeft: "1px" }}
              />
              {errors.companyName && (
                <div className="text-danger">{errors.companyName}</div>
              )}
            </div>
          </div>
          <div className="fontWeight400 ">
            <input
              name="email"
              placeholder="Email"
              type="text"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur} // Add onBlur here
              className="form-control supportFormFocus my-2 fontWeight400"
            />
            {errors.email && <div className="text-danger">{errors.email}</div>}
            <div className="form-floating my-2">
              <textarea
                name="message"
                className="form-control supportFormFocus textareaHeight"
                placeholder=""
                value={formData.message}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                id="floatingTextarea"
              ></textarea>
               {!formData?.message && <label htmlFor="floatingTextarea" className="opacity">Message</label>}
             
            </div>
            {errors.message && (
              <div className="text-danger">{errors.message}</div>
            )}
            <div className="py-2">
              <Recaptcha onCaptchaChange={handleCaptchaChange} />
            </div>
            <button
              onClick={handleSubmit}
              className={`w-100 my-2 p-2 bgCE1 borderRadius12 text-white outlineNone borderE1
                ${ 
                  !captchaVerified ||
                  formData?.companyName === "" ||
                  formData?.email === "" ||
                  formData?.firstName === "" ||
                  formData?.lastName === "" ||
                  formData?.jobTitle === "" ||
                  formData?.message === "" ||
                  errors?.companyName !== "" ||
                  errors?.email !== "" ||
                  errors?.firstName !== "" ||
                  errors?.lastName !== "" ||
                  errors?.jobTitle !== "" ||
                  errors?.message !== ""
                    ? "disabled-class"
                    : ""
                }
                `}
            >
              Submit
            </button>
            <div className="my-2 text-center fontAntarctica mt-3">
              <div className="text-white fontAntarctica fontSize25 my-3">Contact Us</div>
              <div className="fontSize16 my-2 d-flex justify-content-center p-2 borderRadius12">
                <div className="bg191916 border3B3936 supportPageWidth p-2 borderRadius12 d-flex justify-content-start">
                  <div className="support_Image_div">
                    <img src={emailWhiteIcon} alt="EmailIcon" />
                  </div>
                  <a
                    href="mailto:support@drivejoulez.com"
                    className="text-white m-1 mx-2 cursorPointer text-decoration-none opacity"
                  >
                    support@drivejoulez.com
                  </a>
                </div>
              </div>
              <div className="fontSize16 my-2 d-flex justify-content-center p-2 borderRadius12">
                <div className="bg191916 border3B3936 supportPageWidth p-2 borderRadius12 d-flex justify-content-start">
                  <div className="support_Image_div">
                    <img src={phoneWhiteIcon} alt="EmailIcon" />
                  </div>
                  <a
                    href="tel:+14452568539"
                    className="text-white m-1 mx-2 text-decoration-none opacity"
                  >
                    +1 (445) 2JOULEZ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateRentals;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Custom hook to calculate the number of days between pickup and drop dates
const useDateCount = () => {
  const [dayCount, setDayCount] = useState(1); // Default value is 1 day

  // Retrieve dates and timings from Redux store
  const currentDates = useSelector(
    (store) => store?.currentDatesAndTimings?.pickupAndDropDates
  );
  const currentTimings = useSelector(
    (store) => store?.currentDatesAndTimings?.pickupAndDropTimings
  );

  const calculateDaysCount = () => {
    // Ensure both pickup and drop dates & times exist
    if (!currentDates?.pickupDate || !currentTimings?.pickupTime ||
        !currentDates?.dropDate || !currentTimings?.dropTime) {
      console.error("Invalid date or time data in Redux store.");
      return;
    }

    const pickupDateTimeString = `${currentDates?.pickupDate} ${currentTimings?.pickupTime}`;
    const dropDateTimeString = `${currentDates?.dropDate} ${currentTimings?.dropTime}`;
    
    const pickupDateTime = new Date(pickupDateTimeString);
    const dropDateTime = new Date(dropDateTimeString);

    // Check if Date objects are valid
    if (isNaN(pickupDateTime.getTime()) || isNaN(dropDateTime.getTime())) {
      console.error("Invalid Date encountered. Please check the input.");
      return;
    }

    // Calculate the difference in milliseconds
    const timeDifference = dropDateTime - pickupDateTime;

    // Convert the time difference from milliseconds to days
    let daysCount = timeDifference / (1000 * 3600 * 24);

    // Round up if the days are greater than a whole number
    if (daysCount > 1) {
      daysCount = Math.ceil(daysCount);
    } else if (daysCount === 1 || daysCount < 1) {
      // If exactly 1 day, count as 1
      daysCount = 1;
    }

    setDayCount(daysCount);
  };

  // Call the calculateDaysCount whenever the relevant data changes
  useEffect(() => {
    calculateDaysCount();
    //eslint-disable-next-line
  }, [currentDates, currentTimings]); // Recalculate when dates or times change in Redux store

  return dayCount; // Return the calculated day count
};

export default useDateCount;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { useFetchLocations } from "../../Hooks/useFetchLocations";
import LocationsList from "./LocationsList";
import { useDispatch, useSelector } from "react-redux";
import { addDropLocation, addPickupAndDropId } from "../../Store/locationSlice";
import { toggleShowModalDropLocation, toggleShowModalPickupLocation } from "../../Store/modalStatusSlice";
import { BASE_URL } from "../../api/index";
import useKeyBoradNavigation from "../../Hooks/useKeyboardNavigation";
import closeIcon from "../../assets/svg/closeIcon.svg";
import whiteCloseIcon from "../../assets/svg/whiteCloseIcon.svg";
import { setDisableSearchButton } from "../../Store/buttonSlice";
import axios from "axios";
import ModalContainer from "../ModalContainer";
import { Spinner } from "react-bootstrap";
import useScrollY from "../../Hooks/useScrollY";

const DropOffLocation = ({
  toggle = () => {},
  show,
  onSelect = () => {},
  showInputContainer,
  activeInput,
  setActiveInput = () => {},
  locationOptions,
  filterOptions,
  setFilterOptions,
  loading,
  error,
  pickupGarageServiceCount,
  restrictDropOffLocations,
  setRestrictDropOffLocations,
  handleGetServiceCount
}) => {
  
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const pickAndDropLocation = useSelector((store) => store?.currentLocations);
  const modalLocationStatus = useSelector((store) => store.modalStatus);
  const firstInputRef = useRef();
  const autocompleteRef = useRef(null); // Create a ref for Autocomplete component
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]); // State for autocomplete suggestions
  const [suggestionLoader, setSuggestionLoader] = useState(false)
  const [optionValue, setOptionValue] = useState(pickAndDropLocation?.drop);

  const scrollY  = useScrollY()
  // const { locationOptions, filterOptions, setFilterOptions, loading, error } =
  //   useFetchLocations(`${BASE_URL}location/open`);
  const dispatch = useDispatch();
  const [garageServiceCount, setGarageServiceCount] = useState("")

  // const toggleLocationDisplay = () => {
  //   toggle();
  // };

  const [disableDropOff, setDisableDropOff] = useState(true)
  
  
  useEffect(() => {
   const serviceCount =  localStorage.getItem("serviceCount")
  if(pickupGarageServiceCount > 0 || serviceCount > 0){
    setDisableDropOff(false)
  } else {
    setDisableDropOff(true)
  }
  }, [pickupGarageServiceCount, pickAndDropLocation?.pickup])

  const toggleLocationDisplay = () => {
    if (window?.innerWidth < 992) {
      dispatch(toggleShowModalDropLocation(true));
    } else {
      toggle();
    }
  };

  if (modalLocationStatus?.showModalDropLocation) {
    firstInputRef?.current?.focus();
  }

  useEffect(() => {
    // Only attempt to focus if the modal has just been made visible
    if (
      modalLocationStatus?.showModalDropLocation 
    ) {
      setActiveInput("drop");
    }
  }, [modalLocationStatus, setActiveInput]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000 && show) {
        // If screen is smaller than 1000px and option container is shown, switch to modal
        dispatch(toggleShowModalDropLocation(true));
        toggle(); // Hide the option container
      } else if (
        window.innerWidth >= 1000 &&
        modalLocationStatus?.showModalDropLocation
      ) {
        // If screen is resized back to 1000px or more, close the modal
        dispatch(toggleShowModalDropLocation(false));
        toggle();
      }
    };
    //this is to render the updated option value in optionContainer in desktop screen
    setOptionValue(pickAndDropLocation?.drop);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [show, modalLocationStatus?.showModalDropLocation, toggle]);

  useEffect(() => {
    if (activeInput === "drop" && firstInputRef?.current) {
      firstInputRef?.current?.focus();
    } //this is to render the updated option value in optionContainer in mobile screen
    setOptionValue(pickAndDropLocation?.drop);
  }, [activeInput, pickAndDropLocation?.drop]);

  const handleChange = (e) => {
    dispatch(setDisableSearchButton(true));
    dispatch(addDropLocation(e.target.value));
    localStorage.setItem("localCurrentDropLocation", JSON.stringify(e.target.value))
    const newValue = e.target.value.replace(/[\s,]+/g, "");
    setOptionValue(newValue);
    const res = locationOptions.filter((opt) => {
      const cleanedOption = opt.displayAddress
        .replace(/[\s,]+/g, "")
        .toLowerCase();
      return cleanedOption.includes(newValue.toLowerCase());
    });

    //here we are not saving the locations beacuse we should not show in the UI
    // setFilterOptions(res);
  };

  const handleChangeLocation = (
    location,
    dropId,
    currentDropoffLatitude,
    currentDropoffLongitude,
    garageLocationOptionSelected
  ) => {

    if(currentDropoffLatitude !== null ||  currentDropoffLongitude !== null){
      getNearByGarages(currentDropoffLatitude, currentDropoffLongitude, garageLocationOptionSelected ? dropId : "")
    } else {
      if(window?.innerWidth > 992){
        onSelect();
      }
      dispatch(toggleShowModalDropLocation(false))
    }
  
    setOptionValue(location);
    dispatch(addDropLocation(location));
    localStorage.setItem("localCurrentDropLocation", JSON.stringify(location))
    dispatch(
      addPickupAndDropId({
        currentDropoffLatitude,
        currentDropoffLongitude,
      })
    );
    dispatch(setDisableSearchButton(false));
    // setActiveInput("");
    // if(window.innerWidth > 1000){
    //   onSelect();//Ensure onSelect is called on big devices to show the location option container
    //  }
    // setFilterOptions(locationOptions);
  };

  const getNearByGarages = async (currentLatitude, currentLongitude, dropId) => {
    setSuggestionLoader(true)
    const payload = {
      city: "",
      country: "",
      latitude: currentLatitude,
      longitude: currentLongitude,
      pickupIds: [Number(pickAndDropLocation?.pickupIdAndDropId?.pickupId), dropId],
      state: "",
    };
    try {
      const result = await axios.post(`${BASE_URL}location/open`, payload);
      //setFilterOptions(result?.data?.data?.locations);
      setGarageServiceCount(result?.data?.data?.serviceableGaragesCount)
      localStorage.setItem("newFilteredDropGoogleLocations", JSON.stringify(result?.data?.data?.locations))
      if (result?.data?.data?.serviceableGaragesCount > 0) {
        // setRestrictDropOffLocations(false)
        handleGetServiceCount(result?.data?.data?.serviceableGaragesCount , false)
        dispatch(toggleShowModalDropLocation(false));
        if(window?.innerWidth > 992){
          onSelect();
        }
        // setActiveInput("");
        dispatch(addPickupAndDropId({dropId: result?.data?.data?.locations[0]?.id}))
      } else {
        dispatch(setDisableSearchButton(true));
        setAutocompleteSuggestions([])
      }
      setSuggestionLoader(false)
    } catch (error) {
      setSuggestionLoader(false)
    }
  };

  const newFilteredDropGoogleLocations = JSON.parse(localStorage.getItem("newFilteredDropGoogleLocations"))
  // console.log(newFilteredPickupGoogleLocations);

  // Handles the place changed event from Google Places Autocomplete
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current?.getPlace(); // Correctly access the place

    const currentLatitude = place?.geometry?.location?.lat();
    const currentLongitude = place?.geometry?.location?.lng();

    if (place && place.formatted_address) {
      handleChangeLocation(place.formatted_address, place.place_id, currentLatitude, currentLongitude);
    }
  };

  //new code location api
  const fetchPredictions = useCallback(
    (input = pickAndDropLocation?.pickup) => {
      if (!window.google || !window.google.maps) return;
  
      const autocompleteService = new window.google.maps.places.AutocompleteService();
  
      autocompleteService.getPlacePredictions(
        { input, componentRestrictions: { country: "us" } },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            // Only return descriptions and place_id for predictions
            const results = predictions.map((prediction) => ({
              description: prediction?.description,
              placeId: prediction?.place_id,
            }));
            
            setAutocompleteSuggestions(results);
          } else {
            setAutocompleteSuggestions([]);
          }
        }
      );
    },
    [pickAndDropLocation?.pickup]
  );
  
  // Handle user selection of a prediction (when they click on a suggestion)
  const handlePlaceSelect = (placeId) => {
    
    
    if (!window.google || !window.google.maps) return;
  
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  
    // Fetch the detailed info (lat, lng, placeId) when the user selects a place
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
        const selectedPlace = {
          description: place.name, // or you can use another property
          placeId: place.place_id,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
  
        handleChangeLocation(
          place.name, 
          place.place_id,
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        )
        
        // Do something with the selected place details (e.g., store it in state)
        // setSelectedPlace(selectedPlace);
      }
    });
  };

  // useEffect(() => {
  //   fetchPredictions(); //this is to render the location list of initial input value
  // }, [pickAndDropLocation?.drop, fetchPredictions]);

  useEffect(() => {
    if (!show) {
      firstInputRef?.current?.blur();
    } //it's to remove the focusing on drop input field
  }, [show]);

  const handleInputChange = (e) => {
    handleChange(e);
    fetchPredictions(e.target.value);
    if (!show && window.innerWidth > 1000) {
      toggle(); //it opens the locations dropdown when the user changes the input value in desktop screen
    }
  };

  const { handleKeyDown, highlightedIndex } = useKeyBoradNavigation(
    filterOptions,
    handleChangeLocation
  ); //used for navigate the options through up and down arrows

  const handleClear = () => {
     dispatch(addDropLocation(""));
     localStorage.removeItem("localCurrentDropLocation")
    dispatch(addPickupAndDropId({ dropId: "" }));
    dispatch(setDisableSearchButton(true));
    // setFilterOptions(locationOptions)
    firstInputRef?.current?.focus()
    //console.log("hi hello ");
    // localStorage.removeItem("newFilteredDropGoogleLocations")
  };

  if (loading) return <div className="mb-0 text-center p-4">
    <Spinner />
  </div>;
  if (error) return <div>Error loading locations!</div>;

  return (
    <div className={`text-white ${scrollY > 500 ? "col-lg-2" : "col-lg-3"} col-sm-12 col-12`}>
    
        {/* <div className="w-100 fontAntarctica fontSize13">
           <p className="mb-1 fontColorC7 fontSize14">Where</p>
          <div
            className={`inputModalOptionBox ${
              activeInput === "drop" ? "borderTrue" : ""
            }`}
          >
            <p className="my-1 fontAntarctica fontSize9">Drop</p>
            <Autocomplete
              onPlaceChanged={handlePlaceChanged}
              onLoad={(autocomplete) =>
                (autocompleteRef.current = autocomplete)
              } // Assign autocomplete instance to ref
            >
              <div className="d-flex justify-content-center align-items-center">
                <input
                  type="text"
                  placeholder="Same as Pickup"
                  value={pickAndDropLocation?.drop}
                  className={`${
                    isDarkMode ? "fontcolorFFF" : "fontColor916"
                  } inputOptionBox text-truncate`}
                  onChange={handleInputChange}
                  // onFocus={() => setActiveInput("drop")}
                  onClick={() => setActiveInput("drop")}
                  ref={firstInputRef}
                  onKeyDown={handleKeyDown}
                />
                {activeInput === "drop" ? (
                  <div className="px-2">
                    <img
                      onClick={handleClear}
                      src={closeIcon}
                      alt="closeIcon"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Autocomplete>
          </div>
          {activeInput === "drop" ? (
            <div className="main-option-container w-100">
              <LocationsList
                show={show}
                filterOptions={filterOptions}
                handleChangeLocation={handleChangeLocation}
                optionValue={optionValue}
                highlightedIndex={highlightedIndex}
                autocompleteSuggestions={autocompleteSuggestions}
                suggestionLoader={suggestionLoader}
                handlePlaceSelect={handlePlaceSelect}
              />
            </div>
          ) : (
            ""
          )}
        </div> */}
    
        <div
          className={`fontAntarctica fontSize13 border-bottom-grey commonBgInput p-2  mb-2 mb-lg-0 `}
          // onClick={toggleLocationDisplay}
        >
          <p className={`mb-1 textAlignLeft  ${isDarkMode ? "fontColor212529 fontWeight700" : "fontcolor936"}`}>
           Drop Off
          </p>
          <Autocomplete
          className={`${disableDropOff ? "disabled-class" : ""}`}
            onPlaceChanged={handlePlaceChanged}
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)} // Assign autocomplete instance to ref
          >
            <div className="d-flex justify-content-between align-items-center">
              <input
                type="text"
                placeholder="Same as Pick Up"
                value={pickAndDropLocation?.drop}
                className={`${
                  isDarkMode ? "fontColor212529" : "fontColor916"
                } inputOptionBox text-truncate`}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onClick={toggleLocationDisplay}
                ref={firstInputRef}
                readOnly={window.innerWidth < 992}
              />
              {show ? (
                <div className="d-none d-lg-flex px-2">
                  <img onClick={handleClear} src={closeIcon} alt="closeIcon" />
                </div>
              ) : (
                ""
              )}
            </div>
          </Autocomplete>
        </div>

        {(window.innerWidth < 992 ||
      modalLocationStatus?.showModalDropLocation) &&
      <ModalContainer
      isVisible={modalLocationStatus?.showModalDropLocation}
      onClose={() => {
        dispatch(toggleShowModalDropLocation(false));
        dispatch(toggleShowModalPickupLocation(false));
      }}
      modalTopHeading="Where"
      >
         <div className="w-100 fontAntarctica fontSize13 p-2">
          <div
            className={`inputModalOptionBox ${
              activeInput === "drop" || modalLocationStatus?.showModalDropLocation ? "borderTrue" : ""
            }`}
          >
            <p className="my-1 fontAntarctica fontSize9">Drop</p>
            <Autocomplete
              onPlaceChanged={handlePlaceChanged}
              onLoad={(autocomplete) =>
                (autocompleteRef.current = autocomplete)
              } // Assign autocomplete instance to ref
            >
              <div className="d-flex justify-content-center align-items-center">
                <input
                  type="text"
                  placeholder="Same as Pickup"
                  value={pickAndDropLocation?.drop}
                  className={`${
                    isDarkMode ? "fontcolorFFF" : "fontColor916"
                  } inputOptionBox text-truncate`}
                  onChange={handleInputChange}
                  // onFocus={() => setActiveInput("drop")}
                  onClick={() => setActiveInput("drop")}
                  ref={firstInputRef}
                  onKeyDown={handleKeyDown}
                />
                  <div className="px-2">
                    <img
                      onClick={handleClear}
                      src={whiteCloseIcon}
                      alt="whiteCloseIcon"
                    />
                  </div>
              </div>
            </Autocomplete>
          </div>
         
            <div className="main-option-container w-100">
              <LocationsList
                show={show}
                filterOptions={newFilteredDropGoogleLocations ? newFilteredDropGoogleLocations : filterOptions}
                handleChangeLocation={handleChangeLocation}
                optionValue={optionValue}
                highlightedIndex={highlightedIndex}
                autocompleteSuggestions={autocompleteSuggestions}
                suggestionLoader={suggestionLoader}
                handlePlaceSelect={handlePlaceSelect}
                mainTitle="Garage Drop Off"
                garageServiceCount={garageServiceCount}
                restrictDropOffLocations={restrictDropOffLocations}
              />
            </div>
        </div>
      </ModalContainer>
      }
   
      {show && window.innerWidth > 1000 && (
        <div className="main-option-container">
          <LocationsList
            show={show}
            filterOptions={newFilteredDropGoogleLocations ? newFilteredDropGoogleLocations : filterOptions}
            handleChangeLocation={handleChangeLocation}
            optionValue={optionValue}
            highlightedIndex={highlightedIndex}
            autocompleteSuggestions={autocompleteSuggestions}
            suggestionLoader={suggestionLoader}
            handlePlaceSelect={handlePlaceSelect}
             mainTitle="Garage Drop Off"
             garageServiceCount={garageServiceCount}
             restrictDropOffLocations={restrictDropOffLocations}
          />
        </div>
      )}
    </div>
  );
};

export default DropOffLocation;

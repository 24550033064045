import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  format,
  isSameDay,
  isAfter,
  isBefore,
  getMonth,
  getYear,
  differenceInDays,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { addPickupAndDropDates } from "../../Store/dateAndTimeSlice";
import { addEmrPickupAndDropDates } from "../../Store/emrTimeAndDateSlice";

const CalendarSelector = ({
  myTripPage,
  bookingStatus = null,
  onGetDateCount,
}) => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
  const [isStartSelected, setIsStartSelected] = useState(false);
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const dispatch = useDispatch();
  const currentDates = useSelector(
    (store) => store?.currentDatesAndTimings?.pickupAndDropDates
  );
  // const currentTimings = useSelector(
  //   (store) => store?.currentDatesAndTimings?.pickupAndDropTimings
  // );

  // console.log(currentDates, currentTimings);
  

  //const [daysCount, setDaysCount] = useState(0); // State to store days count
  // Helper function to calculate the number of days between two dates
  // const calculateDaysCount = (startDate, endDate) => {
  //   console.log(currentDates?.pickupDate, currentTimings?.pickupTime, currentDates?.dropDate, currentTimings?.dropTime);
    
  //   onGetDateCount(differenceInDays(endDate, startDate));
  //   // return differenceInDays(endDate, startDate); // Returns the difference in days
  // };

  const currentEMRDates = useSelector(
    (store) => store?.emrDatesAndTimings?.pickupAndDropDates
  );

  const handleDateClick = (date) => {
    if (!isStartSelected || !selectedDates[0]) {
      setSelectedDates([date]);
      const tomorrowDate = new Date(date);
      if (bookingStatus === "On-Going" || bookingStatus === "Completed") {
        tomorrowDate.setDate(tomorrowDate.getDate());
      } else {
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      }

      const pickupDate = format(date, "MMM dd, yyyy");
      const dropDate = format(tomorrowDate, "MMM dd, yyyy");

      //firstly we are checking the component is from myTrip page or not
      if (myTripPage) {
        if (bookingStatus === "On-Going" || bookingStatus === "Completed") {
          dispatch(
            addEmrPickupAndDropDates({
              pickupDate: currentEMRDates?.pickupDate,
              dropDate,
            })
          );
        } else {
          //if it is my trip page we are dispatching the dates to to emrDateAndtime store
          dispatch(addEmrPickupAndDropDates({ pickupDate, dropDate }));
        }
      } else {
        dispatch(addPickupAndDropDates({ pickupDate, dropDate }));
      }
      setIsStartSelected(true);
    } else {
      // Check if the newly clicked date should be the start or end date
      if (isAfter(selectedDates[0], date)) {
        // If the existing start date is after the new date, switch them
        setSelectedDates([date, selectedDates[0]]);
        const pickupDate = format(date, "MMM dd, yyyy");
        const dropDate = format(selectedDates[0], "MMM dd, yyyy");

        if (myTripPage) {
          if (bookingStatus === "On-Going" || bookingStatus === "Completed") {
            dispatch(
              addEmrPickupAndDropDates({
                pickupDate: currentEMRDates?.pickupDate,
                dropDate,
              })
            );
          } else {
            dispatch(addEmrPickupAndDropDates({ pickupDate, dropDate }));
          }
        } else {
          dispatch(addPickupAndDropDates({ pickupDate, dropDate }));
        }
      } else {
        // Otherwise, set the new date as the end date
        setSelectedDates([selectedDates[0], date]);
        const pickupDate = format(selectedDates[0], "MMM dd, yyyy");
        const dropDate = format(date, "MMM dd, yyyy");
        if (myTripPage) {
          if (bookingStatus === "On-Going" || bookingStatus === "Completed") {
            dispatch(
              addEmrPickupAndDropDates({
                pickupDate: currentEMRDates?.pickupDate,
                dropDate,
              })
            );
          } else {
            dispatch(addEmrPickupAndDropDates({ pickupDate, dropDate }));
          }
        } else {
          dispatch(addPickupAndDropDates({ pickupDate, dropDate }));
        }
      }
      setIsStartSelected(false); // Allow new range selection
    }
  };

  // const handleDateClick = (date) => {
  //   if (bookingStatus === "On-Going") {
  //     // If the pickup date is already set, don't allow the user to change it
  //     if (selectedDates[0]) {
  //       // Only allow changing the drop date
  //       if (!isSameDay(date, selectedDates[0])) {
  //         // Update the drop date, keeping the pickup date the same
  //         setSelectedDates([selectedDates[0], date]);
  //         const pickupDate = format(selectedDates[0], "MMM dd, yyyy");
  //         const dropDate = format(date, "MMM dd, yyyy");

  //         // Update the day count for the new range
  //         setDaysCount(calculateDaysCount(selectedDates[0], date));

  //         // Dispatch actions based on myTripPage
  //         if (myTripPage) {
  //           dispatch(addEmrPickupAndDropDates({ pickupDate, dropDate }));
  //         } else {
  //           dispatch(addPickupAndDropDates({ pickupDate, dropDate }));
  //         }
  //       }
  //     }

  //     console.log(currentEMRDates?.pickupDate, currentEMRDates?.dropDate);

  //   }
  //    else {
  //     // When bookingStatus is NOT "On-Going", both pickup and drop dates are editable
  //     if (!isStartSelected || !selectedDates[0]) {
  //       setSelectedDates([date]);
  //       const tomorrowDate = new Date(date);
  //       tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  //       const pickupDate = format(date, "MMM dd, yyyy");
  //       const dropDate = format(tomorrowDate, "MMM dd, yyyy");

  //       // Calculate days count for the range
  //       setDaysCount(calculateDaysCount(date, tomorrowDate));

  //       // Dispatch actions based on myTripPage
  //       if (myTripPage) {
  //         dispatch(addEmrPickupAndDropDates({ pickupDate, dropDate }));
  //       } else {
  //         dispatch(addPickupAndDropDates({ pickupDate, dropDate }));
  //       }
  //       setIsStartSelected(true);
  //     } else {
  //       // Handle date selection for both pickup and drop dates
  //       if (isAfter(selectedDates[0], date)) {
  //         setSelectedDates([date, selectedDates[0]]);
  //         const pickupDate = format(date, "MMM dd, yyyy");
  //         const dropDate = format(selectedDates[0], "MMM dd, yyyy");
  //         setDaysCount(calculateDaysCount(date, selectedDates[0])); // Update day count when dates are swapped

  //         // Dispatch actions based on myTripPage
  //         if (myTripPage) {
  //           dispatch(addEmrPickupAndDropDates({ pickupDate, dropDate }));
  //         } else {
  //           dispatch(addPickupAndDropDates({ pickupDate, dropDate }));
  //         }
  //       } else {
  //         setSelectedDates([selectedDates[0], date]);
  //         const pickupDate = format(selectedDates[0], "MMM dd, yyyy");
  //         const dropDate = format(date, "MMM dd, yyyy");
  //         setDaysCount(calculateDaysCount(selectedDates[0], date)); // Update day count for selected range

  //         // Dispatch actions based on myTripPage
  //         if (myTripPage) {
  //           dispatch(addEmrPickupAndDropDates({ pickupDate, dropDate }));
  //         } else {
  //           dispatch(addPickupAndDropDates({ pickupDate, dropDate }));
  //         }
  //       }
  //       setIsStartSelected(false); // Allow new range selection
  //     }
  //   }
  // };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const activeMonth = getMonth(activeStartDate);
      const activeYear = getYear(activeStartDate);
      const dateMonth = getMonth(date);
      const dateYear = getYear(date);

      // Check if the date is in the same month and year as the active start date
      if (dateMonth !== activeMonth || dateYear !== activeYear) {
        return "hidden_date";
      }

      // Check if the date is within the selected pickup and drop dates range
      if (myTripPage) {
        if (
          currentEMRDates?.pickupDate &&
          currentEMRDates?.dropDate &&
          isAfter(date, currentEMRDates?.pickupDate) &&
          isBefore(date, currentEMRDates?.dropDate)
        ) {
          return "between-date";
        }
      } else {
        if (
          currentDates?.pickupDate &&
          currentDates?.dropDate &&
          isAfter(date, currentDates?.pickupDate) &&
          isBefore(date, currentDates?.dropDate)
        ) {
          return "between-date";
        }
      }

      //  //firstly we are checking the component is from myTrip page or not
      // Check if the date is the pickup or drop date
      if (myTripPage) {
        if (
          currentEMRDates?.pickupDate &&
          isSameDay(date, currentEMRDates?.pickupDate)
        ) {
          return "selected-date";
        }
        if (
          currentEMRDates?.dropDate &&
          isSameDay(date, currentEMRDates?.dropDate)
        ) {
          return "selected-date";
        }
      } else {
        if (
          currentDates?.pickupDate &&
          isSameDay(date, currentDates?.pickupDate)
        ) {
          return "selected-date";
        }
        if (currentDates?.dropDate && isSameDay(date, currentDates?.dropDate)) {
          return "selected-date";
        }
      }
    }
  };

  // const estNowPlusTwoHours = () => {
  //   const now = new Date();
  //   const estDate = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));
  //   estDate.setHours(estDate.getHours() + 2);
  //   return estDate;
  // };

  const locationTimeData = JSON.parse(
    localStorage.getItem("allLocationsTimeZones")
  );
  const currentDate = locationTimeData?.timeSlots?.currentDate;

  // Convert the currentDate string to a Date object
  const date = new Date(currentDate + "T00:00:00"); // Ensuring midnight time for the date

  // Format the date to match "Wed Nov 27 2024 00:00:00 GMT-0500 (Eastern Standard Time)"
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    timeZone: "America/New_York", // Set the timezone to Eastern Standard Time
  };

  const formattedDate = date.toLocaleString("en-US", options); // This is for display only
  // console.log(formattedDate); // For debugging/logging

  // For minDate, use the Date object directly
  const minDate = date; // Use the Date object here for minDate

  return (
    <div>
      <Calendar
        onClickDay={handleDateClick}
        value={selectedDates}
        minDate={minDate}
        className={`react-calendar ${
          isDarkMode ? "darkMode" : "lightGreyMode"
        }`}
        tileClassName={tileClassName}
        onActiveStartDateChange={({ activeStartDate }) =>
          setActiveStartDate(activeStartDate)
        }
        showDoubleView={false}
      />
    </div>
  );
};

export default CalendarSelector;

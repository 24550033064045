// import { useSelector } from "react-redux";
// import { content3 } from "../data/content";
// import { Fragment } from "react";

// const JoulezWorkContainer = () => {
//   const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
//   const { title, steps } = content3;
//   return (
//     <div
//       className={`${
//         isDarkMode ? "darkGreyMode" : "lightGreyMode"
//       } col-12 p-5  d-flex justify-content-sm-start  justify-content-md-center  justify-content-lg-center align-items-center`}
//     >
//       <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-3 text-center text-lg-center ">
//         <h1 className="fontAntarctica joulezWorkContainerTitle">{title}</h1>
//         <div>
//           {steps.map((step, index) => (
//             <Fragment key={index}>
//               <div className="fontAntarctica joulezWorkContainerFontSize">{step}</div>
//               {index < steps.length - 1 && <hr className="horizontalLine" />}
//             </Fragment>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JoulezWorkContainer;

import { useSelector } from "react-redux";
import { content3 } from "../data/content";
import { Fragment } from "react";
import howJoulezWorksImage from "../assets/svg/howJoulezWorks.svg";

const JoulezWorkContainer = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const { title, steps } = content3;
  return (
    <div className={`${isDarkMode ? "darkGreyMode" : "lightGreyMode"} py-0 py-lg-4`}>
      <div className="col-12 d-flex flex-column flex-lg-row justify-content-center px-4 px-lg-0 pt-md-5 pb-5">
        <div className="d-flex justify-content-center justify-content-lg-end mb-1 mb-lg-0">
          <img
            className="howJoulezWorksImage"
            src={howJoulezWorksImage}
            alt="howJoulezWorksImage"
          />
        </div>
        <div className="col-12 col-lg-5 mx-0 mx-lg-4">
          <h1 className="fontAntarctica joulezWorkContainerTitle">{title}</h1>
          <div className="fontAntarctica joulezWorkContainerFontSize fontcolorFFF {
">
            {steps.map((step, index) => (
              <Fragment key={index}>
                <div>
                  {step}
                </div>
                {index < steps.length - 1 && <hr className="horizontalLine" />}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoulezWorkContainer;

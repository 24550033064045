
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = ({onCaptchaChange }) => {
    //console.log(process.env.REACT_APP_CAPTCHA_KEY);
  return (
    <ReCAPTCHA
      sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
      //sitekey='6LcTGcQjAAAAAGFJkfvS2cIhlSdF2YVu2ra-RMR-'
      onChange={onCaptchaChange} // Pass the onChange callback as a prop
    />
  );
};

export default Recaptcha;

import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../Store/pageSlice";
import useMainSearch from "../Hooks/useMainSearch";
import TimeSelector from "../Components/SearchComponent/TimeSelector";
import greyInfoIcon from "../assets/svg/greyInfoIcon.svg";
import notchIcon from "../assets/svg/notchIcon.svg";
import {
  toggleShowModalBookingDetails,
  toggleShowModalCalendar,
  toggleShowModalDriverLicenseDetails,
} from "../Store/modalStatusSlice";
import Tesla_icon from "../assets/svg/Tesla_icon.svg";
import AllWheelDrive from "../assets/svg/Wheel-Icon.svg";
import nexarCamera from "../assets/svg/Nexer-icon.svg";
import siriusBookingIcon from "../assets/svg/siriusBookingIcon.svg";
import ModalContainer from "../Components/ModalContainer";
import ExtrasAndRentalDetails from "../Components/ExtrasAndRentalDetails";
import { BASE_URL } from "../api/index";
import {
  parse,
  format,
  differenceInMilliseconds,
  endOfYear,
  setYear,
} from "date-fns";
import { useNavigate, useOutletContext } from "react-router-dom";
import ShowPricingDetails from "../Components/ShowPricingDetails";
import {
  setOpenLoginDesktopModal,
  setOpenProceedBookingDesktopModal,
} from "../Store/buttonSlice";
// import { toast, ToastContainer } from "react-toastify";
import AddressAutocomplete from "../Components/AddressAutocomplete";
import FileUploader from "../Components/FileUploader";
import { toast } from "react-toastify";
import DesktopCustomModal from "../Components/DesktopCustomModal";
import CommonCalendar from "../Components/CommonCalendar";
import PhoneInput from "react-phone-input-2";
import SpinnerLoader from "../Components/SpinnerLoader";
import DuplicateImage from "../assets/Images/Duplicate.png";
import {
  addPickupAndDropDates,
  addPickupAndDropTimings,
} from "../Store/dateAndTimeSlice";
import moment from "moment/moment";
import DateOfBirthPicker from "../Components/DateOfBirthPicker";
const Booking = () => {
  const { isLoggedIn } = useOutletContext();

  const isDarkMode = useSelector((store) => store?.currentModeStatus?.darkMode);
  const currentLocations = useSelector((store) => store?.currentLocations);
  const currentDates = useSelector((store) => store?.currentDatesAndTimings);
  const modalBookingStatus = useSelector(
    (store) => store?.modalStatus?.showModalBookingDetails
  );
  const modalDriverLicenseStatus = useSelector(
    (store) => store?.modalStatus?.showModalDriverLicenseDetails
  );

  const modalCalendarStatus = useSelector(
    (store) => store?.modalStatus?.showModalCalendar
  );

  const isProceedBooking = useSelector(
    (store) => store?.currentButton?.bookingScreenNextButton
  );

  // console.log(isProceedBooking);
  

  const userToken = useSelector(
    (store) => store?.userDetailsSlice?.userDetails?.token
  );

  const currentBookingScreenLoginAndSignUp = useSelector(
    (state) => state?.currentButton
  );

  const locationTimeData =
    JSON.parse(localStorage.getItem("allLocationsTimeZones")) || [];

  const bookingInputs = JSON.parse(localStorage.getItem("bookingInputs"));
  const isRentalCoverageModal =  localStorage.getItem("isRentalCoverageModal")

  const [hideZindex, setHideZindex] = useState(false);
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const carDetails = JSON.parse(localStorage.getItem("selectedCar"));
  const [showCarSpecs, setShowCarSpecs] = useState(true);
  const [showComponent, setShowComponent] = useState(true); // it is used hide the timeSelector component
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [loadingUntilResponse, setLoadingUntilResponse] = useState(true);
  const { containerRef, showModal, handleToggle } = useMainSearch();
  const dispatch = useDispatch();
  const [estimatedResultData, setEstimatedResultData] = useState({});
  // const [carDetailsResponse, setCarDetailsResponse] = useState("");
  // const [carDetailsResponseError, setCarDetailsResponseError] = useState("");

  const [activeModal, setActiveModal] = useState(null);
  const openModal = (modalId) => setActiveModal(modalId);
  const closeModal = () => {
    setActiveModal("");
  };

  const [socialEmail, setSocialEmail] = useState(false);
  const [socialPhone, setSocialPhone] = useState(false);
  const [socialDob, setSocialDob] = useState(false);
  const [socialUserCont, setSocialUserCont] = useState(false);
  const [socialFlName, setSocialFlName] = useState(false);

  const convertedPickupDate = format(
    parse(
      currentDates?.pickupAndDropDates?.pickupDate,
      "MMMM d, yyyy",
      new Date()
    ),
    "yyyy-MM-dd"
  );

  const convertedDropDate = format(
    parse(
      currentDates?.pickupAndDropDates?.dropDate,
      "MMMM d, yyyy",
      new Date()
    ),
    "yyyy-MM-dd"
  );

  const convertTo24HourFormat = (time12hr) => {
    const parsedTime = parse(time12hr, "hh:mm a", new Date());
    if (isNaN(parsedTime)) {
      return "";
    }

    return format(parsedTime, "HH:mm:ss");
  };

  const formatPickupTIme = convertTo24HourFormat(
    currentDates?.pickupAndDropTimings?.pickupTime
  );
  const formatDropTIme = convertTo24HourFormat(
    currentDates?.pickupAndDropTimings?.dropTime
  );
  
  const currentUserDetails = JSON.parse(
    sessionStorage.getItem("CurrentUserDetails")
  );
  
  const {
    perDayPrice,
    maxMiles,
    teslaAppAccess,
    siriusXM,
    isNexarCameraInclude,
    milesPerDay,
    totalMiles,
    name,
    vehicleModel,
    modelYear,
    seats,
    color,
    vehicleType,
    wheelDrive,
    description,
    nexarCameraInclude,
    officialRangeMiles,
    carImage,
    id,
    locationId
  } = carDetails || {};

  const filterSectionState = {
    ageGroup: currentUserDetails?.ageGroup ? currentUserDetails?.ageGroup : 3,
    autoPilotIds: [],
    brand: [name],
    pickupDate: bookingInputs?.pickupDateTime,
    dropoffDate: bookingInputs?.dropOffDateTime,
    locationId: locationId,
    vehicleModel: [vehicleModel],
    vehicleType: [vehicleType],
    seats: [seats],
    colors: [color],
    ranges: [],
    minPrice: "",
    maxPrice: "",
  };

  const [filterData, setFilterData] = useState({ ...filterSectionState });
  const [carListLoading, setCarListLoading] = useState("");

  const [isCarAvailable, setIsCarAvailable] = useState(false);

  const handlePostData = async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}car/availableCarsV4/open`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.data?.data?.cars?.length > 0) {
        setIsCarAvailable(true);
      } else {
        setIsCarAvailable(false);
      }
      setCarListLoading(false); // Set loading to false after data is fetched
      // console.log(res?.data?.message);

      if (
        res?.data?.message ===
        "No cars available. Please try a different Pickup and Drop-off Location or dates for vehicle availability."
      ) {
        openModal("CarNotAvailable");
      }
    } catch (error) {
      // console.log(error?.response?.data?.message); // Set error message
      setCarListLoading(false); // Set loading to false in case of an error
      setIsCarAvailable(false);
    }
  };

  useEffect(() => {
    if (
      currentDates?.pickupAndDropDates?.pickupDate ||
      currentDates?.pickupAndDropDates?.dropDate ||
      currentDates?.pickupAndDropTimings?.pickupTime ||
      currentDates?.pickupAndDropTimings?.dropTime
    ) {
      if (filterData?.pickupDate && filterData?.dropoffDate && locationId) {
        handlePostData(filterData);
      }
    }
    //eslint-disable-next-line
  }, [filterData?.pickupDate, filterData?.dropoffDate]);

  useEffect(() => {
    if (currentDates) {
      setFilterData((prev) => ({
        ...prev,
        pickupDate: convertedPickupDate + " " + formatPickupTIme,
        dropoffDate: convertedDropDate + " " + formatDropTIme,
      }));
    }
  }, [
    convertedPickupDate,
    convertedDropDate,
    currentDates,
    formatPickupTIme,
    formatDropTIme,
  ]);

  const getCarId = JSON.parse(localStorage.getItem("selectedCar"));

  const convertedPickupDateTime = `${format(
    parse(
      currentDates?.pickupAndDropDates?.pickupDate,
      "MMMM d, yyyy",
      new Date()
    ),
    "yyyy-MM-dd"
  )} ${format(
    parse(
      currentDates?.pickupAndDropTimings?.pickupTime,
      "hh:mm a",
      new Date()
    ),
    "HH:mm:ss"
  )}`;

  const convertedDropDateTime = `${format(
    parse(
      currentDates?.pickupAndDropDates?.dropDate,
      "MMMM d, yyyy",
      new Date()
    ),
    "yyyy-MM-dd"
  )} ${format(
    parse(currentDates?.pickupAndDropTimings?.dropTime, "hh:mm a", new Date()),
    "HH:mm:ss"
  )}`;

  useEffect(() => {
    const calculateDays = () => {
      if (convertedPickupDateTime && convertedDropDateTime) {
        const diffMilliseconds = differenceInMilliseconds(
          new Date(convertedDropDateTime),
          new Date(convertedPickupDateTime)
        );
        const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24); // Convert to days

        setEstimatedPriceDetails((prev) => ({
          ...prev,
          pickupDateTime: convertedPickupDateTime,
          dropOffDateTime: convertedDropDateTime,
          noOfDays: Number(Math.ceil(diffDays)), // Round to 2 decimal places
          emailFlag: false,
          // couponCodeResponse: null,
        }));
      }
    };
    calculateDays();
  }, [convertedPickupDateTime, convertedDropDateTime]);

  // const localStorageExtras = JSON.parse(
  //   localStorage.getItem("estimatedPriceDetails")
  // );

  const estimatedSectionData = {
    ageGroup: currentUserDetails?.ageGroup ? currentUserDetails?.ageGroup : 3,
    carId: getCarId?.id,
    carProtectionPlanId: Number(getCarId?.id + "4"),
    dropOffDateTime: convertedDropDateTime,
    dropOffLocation: currentLocations?.drop,
    locationId: currentLocations?.pickupIdAndDropId?.pickupId,
    dropoffLocationId: currentLocations?.pickupIdAndDropId?.dropId,
    currentPickupLatitude: currentLocations?.pickupIdAndDropId?.pickupLatitude,
    currentPickupLongitude:
      currentLocations?.pickupIdAndDropId?.pickupLongitude,
    currentDropoffLatitude: currentLocations?.pickupIdAndDropId?.dropLatitude,
    currentDropoffLongitude: currentLocations?.pickupIdAndDropId?.dropLongitude,
    estimatedPrice: {
      ageFee: 0,
      baseRate: 0,
      cityTax: 0,
      discount: 0,
      protectCharge: 0,
      stateTax: 0,
      subTotal: 0,
      total: 0,
      isBasePriceChanged: false,
      carLevelBasePice: 0,
      carCalendarLevelBasePice: 0,
      cityTaxPercent: 0,
      stateTaxPercent: 0,
      countyLocalTaxSiPercent: 0,
      countyLocalTaxSi: 0,
      countyDistrictTaxSp: 0,
      countyDistrictTaxSpPercent: 0,
      tourismTax: 0,
      tourismTaxPercent: 0,
      useTax: 0,
      useTaxPercent: 0,
      metropolitanTax: 0,
      metropolitanTaxPercent: 0,
      eventExtrasCharges: [],
      artMetroPierAndExpoAuth: 0,
      artMetroPierAndExpoAuthTaxPercent: 0,
      artTaxLocalPortion: 0,
      artTaxLocalPortionTaxPercent: 0,
      baseRatePerDay: 0,
      rentalCoverageCharge: 0,
      additionalCoverageCharge: 0,
    },
    noOfDays: "",
    pickupDateTime: convertedPickupDateTime,
    pickupLocation: currentLocations?.pickup,
    couponCode: "",
    walkThrough: "{}",
    protectionPlanType: 0,
    referenceId: "",
    extraIds: [],
    totalMiles: "0 Miles",
    additionalMilesFee: 0,
    unlimitedMiles: false,
    emailFlag: false,
    insuranceFlag: false,
    additionalCoverageIds: [],
    rentalCoverageId: [],
    rentalKey: [],
    walkThroughFlag: false
  };

  const [estimatedPriceDetails, setEstimatedPriceDetails] = useState({
    ...estimatedSectionData,
  });

  useEffect(() => {
    if (bookingInputs !== null) {
      setEstimatedPriceDetails((prev) => ({
        ...prev,
        ...bookingInputs,
        // couponCodeResponse: null,
      }));

      // Format the date using date-fns
      const formattedPickupDate = format(
        new Date(bookingInputs.pickupDateTime),
        "MMM dd, yyyy"
      );
      const formattedDropDate = format(
        new Date(bookingInputs.dropOffDateTime),
        "MMM dd, yyyy"
      );

      const bookingPickupTime = moment(bookingInputs.pickupDateTime).format(
        "hh:mm A"
      );
      const bookingDropTime = moment(bookingInputs.dropOffDateTime).format(
        "hh:mm A"
      );

      //console.log(bookingPickupTime, bookingDropTime);

      // Example for setting pickup and drop dates in your Redux store or state
      dispatch(
        addPickupAndDropDates({
          pickupDate: formattedPickupDate,
          dropDate: formattedDropDate,
        })
      );
      dispatch(
        addPickupAndDropTimings({
          pickupTime: bookingPickupTime,
          dropTime: bookingDropTime,
        })
      );
    }
  }, []);

  //on small screen it send the name as booking so we can remove the footer
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        dispatch(setCurrentPage("booking"));
      } else {
        dispatch(setCurrentPage(""));
        dispatch(toggleShowModalBookingDetails(false));
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  //get the protectionPlans data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${BASE_URL}protectionPlans/${carDetails?.id}/open`
        );
        setData(result?.data?.data);
      } catch (error) {}
    };
    fetchData();
  }, [carDetails?.id]);

  const token = sessionStorage.getItem("AccessToken");

  const navigate = useNavigate();

  useEffect(() => {
    const handleGetEstimatedPrice = async () => {
      setLoadingUntilResponse(true);
      try {
        const result = await axios.post(
          token &&
            currentUserDetails &&
            currentUserDetails?.dob &&
            currentUserDetails?.dob !== undefined &&
            currentUserDetails?.dob !== null
            ? `${BASE_URL}booking/estimatedPrice`
            : `${BASE_URL}booking/estimatedPrice/open`,
          estimatedPriceDetails,
          {
            headers: {
              "Content-Type": "application/json",
              ...(token &&
              currentUserDetails &&
              currentUserDetails.dob &&
              currentUserDetails.dob !== undefined &&
              currentUserDetails.dob !== null
                ? { Authorization: `Bearer ${token}` }
                : {}),
            },
          }
        );
        if (result?.data?.data?.couponCodeResponse != null && estimatedPriceDetails?.couponCodeResponse === null ) {
          if (
            result?.data?.data?.couponCodeResponse === "Coupon Not Applicable" 
          ) {
            toast.error(result?.data?.data?.couponCodeResponse);
          } else {
            toast.success(result?.data?.data?.couponCodeResponse);
          }
        }
        setLoadingUntilResponse(false);
        setEstimatedResultData(result?.data);
        if (estimatedPriceDetails?.emailFlag) {
          //need to pass to ExtrasAndDetails component to open I understand modal
          if (window.innerWidth < 992) {
            dispatch(toggleShowModalBookingDetails(true));
          }
          //dispatch(setOpenProceedBookingDesktopModal(true));
        }
        localStorage.setItem(
          "bookingInputs",
          JSON.stringify(result?.data?.data)
        );
      } catch (error) {
        setTimeout(() => {
          if (
            error?.response?.data?.message ===
              "This car is not applicable to your age-group" ||
            error?.response?.data?.message ===
              "Car is not available in the given location : "
          ) {
            navigate("/cars-list");
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error?.response?.data?.message);
            setLoadingUntilResponse(false);
          }
        }, 2000);
        //setLoading(false);
      }
    };

    // Fetch estimated price only if noOfDays is not null
    if (estimatedPriceDetails?.noOfDays !== "") {
      handleGetEstimatedPrice();
    }
    //eslint-disable-next-line
  }, [estimatedPriceDetails, token, isLoggedIn, navigate, isCarAvailable]);

  // useEffect(() => {
  //   const fetchCarDetails = async () => {
  //     try {
  //       const response = await axios.get(`${BASE_URL}car/getById/${id}/open`);
  //       setCarDetailsResponse(response.data?.data); // Assuming response.data contains the result
  //     } catch (error) {
  //       setCarDetailsResponseError(error);
  //     }
  //   };

  //   fetchCarDetails();
  // }, [id]);

  // console.log(carDetailsResponse);

  // if (error) return <p>{error}</p>;
  const { estimatedPrice, pricingDetails } = estimatedResultData?.data || {};

  const handleZIndex = (value) => {
    setHideZindex(value);
  };

  //this states all are related to driving license for mobile screen
  const [showConditions, setShowConditions] = useState(false);
  const [licenseDetailsCheckbox, setLicenseDetailsCheckbox] = useState(false);
  const [mailingAddressCheckbox, setMailingAddressCheckbox] = useState(false);
  const [addressDetails, setAddressDetails] = useState({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    countryCode: "",
    secondAddressline: "",
  }); //this state is using for AddressAutocomplete component
  const [drivingLicenseDocument, setDrivingLicenseDocument] = useState({
    frontPage: "",
    backPage: "",
    drivingLicenseNumber: "",
    expiryDate: "",
  });

  const [drivingLicenseErrors, setDrivingLicenseErrors] = useState({
    drivingLicenseNumber: "",
  });

  const [errors, setErrors] = useState({
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const [drivingLicenseLoader, setDrivingLicenseLoader] = useState(false)
  //this api is related to mobile screen
  const postDrivingLicenseData = async () => {
    // Create FormData instance
    const formData = new FormData();

    // Append addressDetails data to FormData (replace with actual fields)
    formData.append("licenseFrontSide", drivingLicenseDocument?.frontPage);
    formData.append("licenseBacksideSide", drivingLicenseDocument?.backPage);
    formData.append(
      "licenseNumber",
      drivingLicenseDocument?.drivingLicenseNumber
    );
    formData.append("expiration", drivingLicenseDocument?.expiryDate);
    formData.append("issuingCountryCode", addressDetails.countryCode);
    formData.append("issuingState", addressDetails.state);
    formData.append("issuingCityZipCode", addressDetails.zipCode);
    formData.append("addressLine1", addressDetails.address);
    formData.append("addressLine2", addressDetails.secondAddressline);
    formData.append("country", addressDetails.country);
    formData.append("city", addressDetails.city);
    formData.append("isMailingAddress", mailingAddressCheckbox);

    try {
      setDrivingLicenseLoader(true)
      // Send the POST request with FormData and token header
      const response = await axios.post(
        `${BASE_URL}licensedetail/createLicenseDetail`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );
      // toast.success(" Driver License details updated Successfully.");
      dispatch(toggleShowModalDriverLicenseDetails(false));
      // Handle the response if needed
      setDrivingLicenseLoader(false)
      toast.success("License Uploaded Successfully");
      return response.data;
    } catch (error) {
      console.error(
        "Error posting driving license data:",
        error?.response?.data?.message
      );
      setDrivingLicenseLoader(false)
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChangeUpdateUserDetails = (e) => {
    const { id, value } = e.target;

    // Optionally validate in real-time on change as well
    if (touched[id]) {
      const error = validateField(id, value);
      setUserErrors((prevState) => ({ ...prevState, [id]: error }));
    }

    setEditUserDetails((prevState) => {
      // Check if the flag (id) already exists in the state object
      // if (!prevState.hasOwnProperty(id)) {
      // }
      return {
        ...prevState,
        [id]: value, // Add or update the flag with the new value
      };
    });
  };

  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    primaryContact: false,
    dob: false,
  });

  const validateField = (id, value) => {
    let error = ""; // Default to no error

    const emailRegex = /^[^ @]+@[^ @]+\.[^ @]+$/;
    const phoneRegex = /^[0-9]{1}[0-9]{9}$/;

    switch (id) {
      case "firstName":
        if (!value.trim()) {
          error = "First name is required.";
        } else if (value.trim().length < 2) {
          error = "First name must be at least 2 characters long.";
        }
        break;

      case "lastName":
        if (!value.trim()) {
          error = "Last name is required.";
        } else if (value.trim().length < 2) {
          error = "Last name must be at least 2 characters long.";
        }
        break;

      case "dob":
        if (!value.trim()) {
          error = "Dob is required.";
        }
        break;

      case "email":
        if (value && !emailRegex.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;
      case "primaryContact":
        if (!value || !phoneRegex.test(value)) {
          error = "Please enter valid Phone Number.";
        }
        break;

      // Additional validation can go here for other fields like phoneNumber, etc.

      default:
        break;
    }

    return error;
  };

  const [userErrors, setUserErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    primaryContact: "",
    email: "",
    dob: "",
  });

  const [editUserDetails, setEditUserDetails] = useState({
    email: currentUserDetails?.email,
    id: currentUserDetails?.id,
    dob: currentUserDetails?.dob,
    firstName: currentUserDetails?.firstName,
    lastName: currentUserDetails?.lastName || "",
    phoneNumberCountryCode: currentUserDetails?.phoneNumberCountryCode,
    primaryContact: currentUserDetails?.primaryContact,
    countryCode: currentUserDetails?.countryCode,
  });

  const [updateUserLoading, setUpdateUserLoading] = useState(false);

  const [showCalendarForSignUp, setShowCalendarForSignUp] = useState(false);
  const handleDateSelect = (selectedDate) => {
    setDrivingLicenseDocument((prevValues) => ({
      ...prevValues,
      expiryDate: format(
        parse(selectedDate, "MM/dd/yyyy", new Date()),
        "yyyy-MM-dd"
      ),
    }));

    // setEditUserDetails({
    //   ...editUserDetails,
    //   dob: format(parse(selectedDate, "MM/dd/yyyy", new Date()), "yyyy-MM-dd"),
    // }); // Update DOB with the selected date
    setUserErrors({ ...userErrors, dob: "" });
    setShowCalendarForSignUp(false);
  };
  const handleDobUpdate = (selectedDate) => {
    //console.log(selectedDate)
    setEditUserDetails({
      ...editUserDetails,
      dob: selectedDate,
    }); // Update DOB with the selected date
    setUserErrors({ ...userErrors, dob: "" });
    setShowCalendarForSignUp(false);
  };


  const handlePhoneChange = (value, country) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue?.length < 3) {
      setUserErrors({
        ...userErrors,
        primaryContact: "Please enter valid number",
      });
    } else {
      setUserErrors({ ...userErrors, primaryContact: "" });
    }

    if (numericValue !== "") {
      setEditUserDetails((prev) => ({
        ...prev,
        primaryContact: numericValue,
        phoneNumberCountryCode: country?.countryCode?.toUpperCase(),
        countryCode: country?.dialCode,
      }));
    }
  };

  const handleSubmitUpdateUserDetails = async () => {
    // Clear any previous errors before validating again
    setUpdateUserLoading(true);
    setUserErrors({
      firstName: "",
      middleName: "",
      lastName: "",
      primaryContact: "",
      email: "",
      dob: "",
    });

    let hasError = false;
    const newErrors = { ...userErrors };

    // Validate firstName (at least 2 characters)
    if (
      !editUserDetails.firstName ||
      editUserDetails.firstName.trim().length < 2
    ) {
      newErrors.firstName =
        "Please provide a valid first name (at least 2 characters)";
      hasError = true;
    }

    // Validate lastName (at least 2 characters)
    if (
      !editUserDetails.lastName ||
      editUserDetails.lastName.trim().length < 2
    ) {
      newErrors.lastName =
        "Please provide a valid last name (at least 2 characters)";
      hasError = true;
    }

    // If there are validation errors, update the state and exit
    if (hasError) {
      setUserErrors(newErrors);
      return; // Prevent the API call if there are validation errors
    }
    try {
      const response = await axios.post(
        `${BASE_URL}user/updateContact`,
        {
          ...editUserDetails,
          dob:format(editUserDetails?.dob, 'yyyy-MM-dd'),
          primaryContact: editUserDetails?.primaryContact?.slice(
            editUserDetails?.countryCode?.length
          ),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
            Accept: "application/json", // Include Accept header
          },
        }
      );
      // Handle response here if needed, e.g., show a success message
      sessionStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(response?.data?.data)
      );
      closeModal("UpdateUserDetails");
      toast.success("Profile details updated successfully");
      if (response?.status === 200) {
        getUserDetails();
        // setting Estimated price to update Pricing Details after user details updated and will open I understand modal
        setEstimatedPriceDetails((prev) => ({
          ...prev,
          emailFlag: true,
          // couponCodeResponse: null,
        }));
      }
      setUpdateUserLoading(false);
    } catch (error) {
      // Handle error here, e.g., show an error message
      console.error("Error updating user details:", error);
      toast.error(error?.response?.data?.message);
      setUpdateUserLoading(false);
    }
  };

  //it is used to get the current user details
  const getUserDetails = async () => {
    const token = sessionStorage.getItem("AccessToken");
    try {
      const userResponse = await axios.get(`${BASE_URL}user/currentUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      sessionStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(userResponse?.data?.data)
      );
    } catch (error) {}
  };
  return (
    <div className={`min-vh-100 py-5 ${isDarkMode ? "darkMode" : "bgFEFDFD"}`}>
      {/* <ToastContainer /> */}
      {/* {error && (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {error}
        </div>
      )} */}
      {(loadingUntilResponse || drivingLicenseLoader )&& (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      <>
        <div>
          <div className="d-flex justify-content-center smallMt">
            <div className="px-3 m-2 carDetailsContainer  bookingPaddingBottom">
              <div className="py-5 px-2 pb-0 d-flex  margin30 justify-content-between fontAntarctica ">
                <div>
                  <h5 className="fontSize22 ">
                    {name} {vehicleModel}
                  </h5>
                </div>
                <div>
                  <h5 className="fontSize17 fontColor5BD ">{vehicleType}</h5>
                </div>
              </div>
              <div>
                {/* this is to render the duplicate car image on the ui if the image is not available */}
                <div className="d-flex justify-content-center align-items-center ">
                  {/* <img
                    src={carDetails?.carImage }}
                    alt="duplicateCarImage"
                  /> */}
                  {carDetails?.carImage ?
                    ( <img
                     src={carDetails?.carImage }
                     alt="duplicateCarImage"
                     className="duplicateCarImageHeight"
                   />) :
                   ( <img
                    src={ DuplicateImage }
                    alt="duplicateCarImage"
                    className="height135 mt-2"
                  />)
                  }
                </div>
                {/* {carImages?.length === 0 && (
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src="https://dev.drivejoulez.com/assets/image/car-default%20black.svg"
                        alt="duplicateCarImage"
                      />
                    </div>
                  )} */}
                {/* this is to render the  car image on the ui if the image is available */}
                {/* {carImages?.map((carImage, index) => (
                    <div key={carImage.id}>
                      <div
                        id="bookingCarouselIndicators"
                        className="carousel slide"
                      >
                        <div className="carousel-inner">
                          <img
                            className={`carousel-item ${
                              index === 0 ? "active" : ""
                            } BookingCarImage`}
                            src={carImage?.imagePath}
                            alt="carImages"
                          />
                        </div>
                        <button
                          className="carousel-control-prev"
                          type="button"
                          data-bs-target="#bookingCarouselIndicators"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next"
                          type="button"
                          data-bs-target="#bookingCarouselIndicators"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  ))} */}
                {/* <div className="book-carousel-indicators d-flex justify-content-center">
                    {carImages?.map((_, i) => (
                      <button
                        key={i}
                        type="button"
                        data-bs-target="#bookingCarouselIndicators"
                        data-bs-slide-to={i}
                        className={`${i === 0 ? "active" : ""}`}
                        aria-current="true"
                        aria-label={`Slide ${i}`}
                      ></button>
                    ))}
                  </div> */}
              </div>
              <div className="p-2 d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="fontSize13 fontAntarctica mb-0">Car Specs</h4>
                </div>
                {/* <div>
                  <p
                    onClick={() => setShowCarSpecs(!showCarSpecs)}
                    className="fontSize14 fontAntarctica fontColorCE1 mb-0"
                    style={{ cursor: "pointer" }}
                  >
                    {showCarSpecs ? "View less" : "View More"}
                  </p>
                </div> */}
              </div>
              <div className="d-flex justify-content-between justify-content-lg-start align-items-center flex-wrap">
                {/* <div className="carSpecsBox d-flex flex-column justify-content-center align-items-center">
                  <p className="fontSize13 mb-1">
                    $ {perDayPrice}{" "}
                    <span className="fontSize9 fontColor5BD">Per day</span>{" "}
                  </p>
                  <p className="mb-0 fontSize9 fontWeight400 fontColor5BD">
                    Price
                  </p>
                </div> */}
                <div className="carSpecsBox d-flex flex-column justify-content-center align-items-center">
                  <p className="fontSize13 mb-1">{maxMiles} mi</p>
                  <p className="mb-0 fontSize9 fontWeight400 fontColor5BD">
                    Range
                  </p>
                </div>
                <div className="carSpecsBox d-flex flex-column justify-content-center align-items-center">
                  <p className="fontSize13 mb-1">{modelYear}</p>
                  <p className="mb-0 fontSize9 fontWeight400 fontColor5BD">
                    Year
                  </p>
                </div>
                <div
                  className={`carSpecsBox ${
                    showCarSpecs ? "d-flex" : "d-none"
                  } d-md-flex flex-column justify-content-center align-items-center`}
                >
                  <p className="fontSize13 mb-1">{seats}</p>
                  <p className="mb-0 fontSize9 fontWeight400 fontColor5BD">
                    Seating
                  </p>
                </div>
                <div
                  className={`carSpecsBox ${
                    showCarSpecs ? "d-flex" : "d-none"
                  } d-md-flex flex-column justify-content-center align-items-center text-center`}
                >
                  <p className="fontSize13 mb-1">{color}</p>
                  <p className="mb-0 fontSize9 fontWeight400 fontColor5BD">
                    Color
                  </p>
                </div>
                {showCarSpecs && (
                  <>
                    {teslaAppAccess && (
                      <div className="carSpecsBox d-flex flex-column justify-content-center align-items-center">
                        <div className="mb-1">
                          <img src={Tesla_icon} alt="Tesla_icon" />
                        </div>
                        <div className="fontSize9 fontWeight400 fontColor5BD">
                          Tesla App Access
                        </div>
                      </div>
                    )}
                    {siriusXM && (
                      <div className="carSpecsBox d-flex flex-column justify-content-center align-items-center">
                        <div className="mb-1">
                          <img
                            src={siriusBookingIcon}
                            alt="siriusBookingIcon"
                          />
                        </div>
                        <div className="fontSize9 fontWeight400 fontColor5BD ">
                          Sirius XM
                        </div>
                      </div>
                    )}
                    {isNexarCameraInclude && (
                      <div className="carSpecsBox d-flex flex-column justify-content-center align-items-center">
                        <div className="mb-1">
                          <img src={nexarCamera} alt="nexarCamera" />
                        </div>
                        <div className="fontSize9 fontColor5BD fontWeight400">
                          Nexar Camera
                        </div>
                      </div>
                    )}
                    {/* {maxMiles && (
                      <div className="carSpecsBox d-flex flex-column justify-content-center align-items-center">
                        <div className="fontSize13 mb-1">
                          {estimatedResultData?.data?.totalMiles}
                        </div>
                        <div className="fontSize9 fontWeight400 fontColor5BD">
                          Included
                        </div>
                      </div>
                    )} */}
                    {wheelDrive && (
                      <div className="carSpecsBox d-flex flex-column justify-content-center align-items-center">
                        <div className="mb-1">
                          <img src={AllWheelDrive} alt="siriusBookingIcon" />
                        </div>
                        <div className="fontSize9 fontWeight400 fontColor5BD">
                          {wheelDrive}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {description && (
                <div className="p-3 mt-2 fontAntarctica bgF08 rounded rounded-4">
                  <h4 className="fontSize14">Description</h4>
                  <p className="fontSize13 fontColor5BD">{description}</p>
                </div>
              )}
              <div className="mt-2 d-flex flex-column flex-md-row justify-content-between">
                <div className="m-1 fontAntarctica bgF08 border3B3936 bookPickupDropBox d-flex flex-column justify-content-between">
                  <div className="p-3">
                    <h4 className="fontSize14 mb-2">Pick Up</h4>
                    {/* <p className="mb-1 fontSize13">Joulez Queens Station,</p> */}
                    <p className="fontSize13 opacity80 cursor">
                      {/* {currentLocations?.pickup} */}
                      {JSON.parse(
                        localStorage.getItem("localCurrentPickupLocation")
                      )}
                    </p>
                  </div>
                  <div className="bookingPickupheight45px bg23 rounded rounded-bottom-4 d-none d-md-flex cursor justify-content-around align-items-center">
                    {showComponent ? (
                      <div ref={containerRef}>
                        {/* here we sent bookingUIPage="true" for changing the ui screen  */}
                        <TimeSelector
                          toggle={() => {
                            handleToggle("calendarAndTime");
                          }}
                          show={showModal.calendarAndTime}
                          bookingUIPage={true}
                          locationTimeData={locationTimeData}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setShowComponent(!showComponent);
                          //here we call handleToggle("calendarAndTime") to open the timeSelector component
                          handleToggle("calendarAndTime");
                        }}
                        className="width200px d-flex justify-content-between align-items-center"
                      >
                        <p className="mb-0 fontSize13 cursor">
                          {currentDates?.pickupAndDropDates?.pickupDate}
                        </p>
                        <div className="text-white hrLineSearch"></div>
                        <p className="mb-0 fontSize13 cursor">
                          {currentDates?.pickupAndDropTimings?.pickupTime}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="m-1 fontAntarctica bgF08 border3B3936 bookPickupDropBox d-flex flex-column justify-content-between">
                  <div className="p-3">
                    <h4 className="fontSize14 mb-2">Drop Off</h4>
                    {/* <p className="mb-1 fontSize13">Custom,</p> */}
                    <p className="fontSize13 opacity80  cursor">
                      {/* {currentLocations?.drop} */}
                      {JSON.parse(
                        localStorage.getItem("localCurrentDropLocation")
                      )}
                    </p>
                  </div>
                  <div className="bookingPickupheight45px bg23 rounded rounded-bottom-4 d-none d-md-flex justify-content-around align-items-center">
                    {showComponent ? (
                      <div
                        onClick={() => {
                          setShowComponent(!showComponent);
                          handleToggle("calendarAndTime");
                        }}
                        className="width200px d-flex justify-content-between align-items-center"
                      >
                        <p className="mb-0 fontSize13 cursor">
                          {currentDates?.pickupAndDropDates?.dropDate}
                        </p>
                        <div className="text-white hrLineSearch "></div>
                        <p className="mb-0 fontSize13 cursor">
                          {currentDates?.pickupAndDropTimings?.dropTime}
                        </p>
                      </div>
                    ) : (
                      <div ref={containerRef}>
                        {/* here we sent dropUI="true" for changing the ui screen  */}
                        <TimeSelector
                          toggle={() => {
                            handleToggle("calendarAndTime");
                          }}
                          show={showModal.calendarAndTime}
                          bookingUIPage={true}
                          dropUI={true}
                          locationTimeData={locationTimeData}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {modalBookingStatus && window.innerWidth < 1000 ? (
              <ModalContainer
                isVisible={modalBookingStatus}
                hideZindex={hideZindex}
                onClose={
                  modalDriverLicenseStatus
                    ? () => {
                        dispatch(toggleShowModalDriverLicenseDetails(false));
                        dispatch(toggleShowModalBookingDetails(false));
                      }
                    : () => dispatch(toggleShowModalBookingDetails(false))
                }
                // bookingInputs?.rentalCoverageId?.includes(4) || isProceedBooking ? "100%" : "80%"
                height={modalDriverLicenseStatus ? "100%" : isRentalCoverageModal === "true" ? "100%" : "80%"}
                zindex={1000}
                modalTopHeading={
                  modalDriverLicenseStatus
                    ? "Add Driver License"
                    : "Booking Details"
                }
              >
                {modalDriverLicenseStatus ? (
                  <div className="p-2">
                    <div className="m-1 cursor">
                      <FileUploader
                        onFileChange={(file) => {
                          setDrivingLicenseDocument((prevValues) => ({
                            ...prevValues,
                            frontPage: file,
                          }));
                        }}
                        handleDeleteFile={() => {
                          setDrivingLicenseDocument((prevValues) => ({
                            ...prevValues,
                            frontPage: "",
                          }));
                        }}
                        label="Front Page"
                        fileTextMessage="Drag & drop some files here, or click to select files"
                      />
                    </div>
                    <div className="m-1 cursor">
                      <FileUploader
                        onFileChange={(file) => {
                          setDrivingLicenseDocument((prevValues) => ({
                            ...prevValues,
                            backPage: file,
                          }));
                        }}
                        handleDeleteFile={() => {
                          setDrivingLicenseDocument((prevValues) => ({
                            ...prevValues,
                            backPage: "",
                          }));
                        }}
                        label="Back Page"
                        fileTextMessage="Drag & drop some files here, or click to select files"
                      />
                    </div>

                    <div className="mb-3">
                      <input
                        id="DrivingLicenseNumber"
                        placeholder="Enter Driver License Number"
                        type="text"
                        className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
                        value={drivingLicenseDocument?.drivingLicenseNumber}
                        // onChange={(e) => {
                        //   setDrivingLicenseDocument((prevValues) => ({
                        //     ...prevValues,
                        //     drivingLicenseNumber: e.target.value,
                        //   }));
                        // }}
                        onChange={(e) => {
                          setDrivingLicenseDocument((prevValues) => ({
                            ...prevValues,
                            drivingLicenseNumber: e.target.value,
                          }));
                          const regex = /^[a-zA-Z0-9]{1,15}$/; // Only allow alphabets and numbers
                          if (!regex.test(e.target.value)) {
                            setDrivingLicenseErrors((prev) => ({
                              ...prev,
                              drivingLicenseNumber:
                                "Invalid Driving License format",
                            }));
                          } else {
                            setDrivingLicenseErrors((prev) => ({
                              ...prev,
                              drivingLicenseNumber: "",
                            })); // Clear error if valid
                          }
                        }}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!value) {
                            setDrivingLicenseErrors((prev) => ({
                              ...prev,
                              drivingLicenseNumber:
                                "Invalid Driving License format",
                            }));
                          }
                        }}
                      />
                      {drivingLicenseErrors?.drivingLicenseNumber && (
                        <div className="text-danger mt-2">
                          {drivingLicenseErrors?.drivingLicenseNumber}
                        </div>
                      )}
                    </div>
                    {/* <div className="mb-3 position-relative">
                      <input
                        min={new Date().toISOString().split("T")[0]}
                        id="expiryDate"
                        placeholder="Enter Expiry Date (dd/mm/yyyy)"
                        type="date"
                        className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
                        value={drivingLicenseDocument?.expiryDate}
                        onChange={(e) => {
                          setDrivingLicenseDocument((prevValues) => ({
                            ...prevValues,
                            expiryDate: e.target.value,
                          }));
                        }}
                      />
                    </div> */}

                    <div className="mb-3 position-relative">
                      <CommonCalendar
                        onDateSelect={handleDateSelect}
                        handleBlur={() => {}}
                        defaultViewDate={new Date()}
                        minDate={new Date()}
                        maxDate=""
                        placeholder="Enter Expiry Date"
                        value={drivingLicenseDocument?.expiryDate}
                      />
                    </div>

                    <div className="">
                      <AddressAutocomplete
                        addressDetails={addressDetails}
                        setAddressDetails={setAddressDetails}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <input
                        type="checkbox"
                        id="mailingAddress"
                        className="me-2"
                        checked={mailingAddressCheckbox}
                        onChange={() =>
                          setMailingAddressCheckbox(!mailingAddressCheckbox)
                        }
                      />
                      <label htmlFor="mailingAddress" className="text-white">
                        Use this address as my Mailing Address
                      </label>
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      <input
                        type="checkbox"
                        id="licenseDetails"
                        className="me-2"
                        checked={licenseDetailsCheckbox}
                        onChange={() =>
                          setLicenseDetailsCheckbox(!licenseDetailsCheckbox)
                        }
                      />
                      {/* <label htmlFor="licenseDetails" className="text-white">
                        We will use your license details to perform a driver and
                        criminal background check. For details{" "}
                        <span
                          onMouseEnter={() => setShowConditions(true)} // Show content on hover
                          onMouseLeave={() => setShowConditions(false)} // Hide content when mouse leaves
                        >
                          Read More
                        </span>
                      </label> */}

                      <div
                        // onMouseEnter={() => setShowConditions(true)} // Show content on hover
                        // onMouseLeave={() => setShowConditions(false)} // Hide content when mouse leaves
                        // onClick={() => setShowConditions(!showConditions)}
                      >
                        <label htmlFor="licenseDetails" className="text-white">
                          We will use your license details to perform a driver
                          and criminal background check. For details{" "}
                          <span
                          onMouseEnter={() => setShowConditions(true)} // Show content on hover
                          onMouseLeave={() => setShowConditions(false)} // Hide content when mouse leaves
                          onClick={() => setShowConditions(!showConditions)}
                          className="cursorPointer fontColorCE1">
                            Read More
                          </span>
                        </label>
                        {showConditions && (
                          <div className="col-md-12">
                            <div className="row">
                              <div
                                className="col-md-12  bg23 p-2 px-4 borderRadius8"
                                style={{
                                  height: "300px",
                                  width: "380px",
                                  overflow: "auto",
                                  position: "absolute",
                                  top: "53%",
                                  left: "1%",
                                  border: "1px solid grey",
                                }}
                              >
                                <p>
                                  Joulez may need to request a consumer report,
                                  drivers record report, or other background
                                  check in order to approve your rental
                                  application. By checking the box, you
                                  authorize Joulez and its designated agents and
                                  representatives to conduct a comprehensive
                                  review of your records. By checking the box,
                                  you indicate that you understand the scope of
                                  the consumer report may include, but is not
                                  limited to: criminal history, including
                                  records from any criminal justice agency in
                                  any or all federal, state, or county
                                  jurisdictions; motor vehicle records,
                                  including traffic citations and registration;
                                  and any other public record that might be
                                  relevant to determining your risk as a vehicle
                                  renter.
                                </p>
                                <p>
                                  If you are a California resident, you have the
                                  right to receive the report Joulez reviews. By
                                  sending us an e-mail at
                                  reservations@drivejoulez.com, you can request
                                  to receive a copy of the report Joulez
                                  reviews. Upon receipt of the report, Joulez
                                  will provide a copy of the report to you
                                  within three business days to the e-mail
                                  address you have associated with this rental.
                                </p>
                                <p>
                                  By withholding your permission, no records
                                  will be reviewed and your rental reservation
                                  will not be processed further.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      className={`signInButton bgCE1 text-white w-100 ${
                        drivingLicenseErrors?.drivingLicenseNumber === "" &&
                        errors?.country === "" &&
                        errors?.address === "" &&
                        errors?.city === "" &&
                        errors?.state === "" &&
                        errors?.zipCode === "" &&
                        licenseDetailsCheckbox &&
                        addressDetails?.address !== "" &&
                        addressDetails?.city !== "" &&
                        addressDetails?.country !== "" &&
                        addressDetails?.countryCode !== "" &&
                        addressDetails?.state !== "" &&
                        addressDetails?.zipCode !== "" &&
                        drivingLicenseDocument?.frontPage !== "" &&
                        drivingLicenseDocument?.backPage !== "" &&
                        drivingLicenseDocument?.drivingLicenseNumber !== "" &&
                        drivingLicenseDocument?.expiryDate !== ""
                          ? ""
                          : "disabled-class"
                      } `}
                      onClick={postDrivingLicenseData}
                    >
                      Proceed
                    </button>
                  </div>
                ) : (
                  <ExtrasAndRentalDetails
                    data={data}
                    modalBookingStatus={modalBookingStatus}
                    estimatedPriceDetails={estimatedPriceDetails}
                    setEstimatedPriceDetails={setEstimatedPriceDetails}
                    estimatedResultData={estimatedResultData}
                    handleZIndex={handleZIndex}
                    loadingUntilResponse={loadingUntilResponse}
                  />
                )}
              </ModalContainer>
            ) : (
              <ExtrasAndRentalDetails
                data={data}
                modalBookingStatus={modalBookingStatus}
                estimatedPriceDetails={estimatedPriceDetails}
                setEstimatedPriceDetails={setEstimatedPriceDetails}
                estimatedResultData={estimatedResultData}
                loadingUntilResponse={loadingUntilResponse}
              />
            )}
          </div>
          <div
            className={`viewContainer ${
              isProceedBooking ||
              hideZindex ||
              currentBookingScreenLoginAndSignUp?.bookingScreenSignUpButton ||
              currentBookingScreenLoginAndSignUp?.bookingScreenLoginButton ||
              modalDriverLicenseStatus
                ? ""
                : "z-index1100"
            }   w-100 `}
          >
            <div className="p-1 text-white bg191916 text-center w-100 borderTopRadius">
              <div
                onClick={() => {
                  dispatch(toggleShowModalBookingDetails(true));
                }}
              >
                {modalBookingStatus || modalCalendarStatus ? (
                  ""
                ) : (
                  <div>
                    <div>
                      <img
                        className="notchImage"
                        src={notchIcon}
                        alt="Notch Icon"
                      />
                    </div>
                    <div className="fontSize17 fontAntarctica">
                      Booking Details
                    </div>
                  </div>
                )}
              </div>
              {modalCalendarStatus ? (
                ""
              ) : (
                <div>
                  {showPriceDetails && (
                    <ShowPricingDetails estimatedPrice={estimatedPrice} pricingDetails={pricingDetails} />
                  )}
                  <div  className="p-1 d-flex justify-content-between align-items-center">
                    <div onClick={() => setShowPriceDetails(!showPriceDetails)}>
                      <div className="d-flex">
                        <div className="m-1">Total Cost</div>
                        <div
                          className="m-1"
                        >
                          <img src={greyInfoIcon} alt="greyInfoIcon" />
                        </div>
                      </div>
                      <div className="fontSize17 fontAntarctica">
                        ${" "}
                        {estimatedResultData?.data?.estimatedPrice?.total?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </div>
                    </div>
                    <div>
                      {/* <button
                        onClick={() => {
                          dispatch(toggleShowModalBookingDetails(true));
                          if (userToken === "") {
                             dispatch(setOpenLoginDesktopModal(true));
                          } 
                          else {
                            dispatch(setOpenProceedBookingDesktopModal(true));
                          }
                        }}
                        className="bgCE1 width200px p-2 border-0 text-white rounded rounded-3"
                      >
                        Proceed
                      </button> */}
                      <button
                        onClick={() => {
                          dispatch(setOpenProceedBookingDesktopModal(true));
                          localStorage.setItem("isRentalCoverageModal", true)
                          // dispatch(toggleShowModalBookingDetails(true));
                          if (userToken === "") {
                            dispatch(setOpenLoginDesktopModal(true));
                            dispatch(toggleShowModalBookingDetails(false))
                          } else {
                            if (
                              currentUserDetails?.email?.includes(
                                "privaterelay.appleid.com"
                              ) ||
                              currentUserDetails?.email === null ||
                              currentUserDetails?.email.length === 0 ||
                              currentUserDetails?.primaryContact ===
                                undefined ||
                              currentUserDetails?.primaryContact === null ||
                              currentUserDetails?.primaryContact.length === 0 ||
                              currentUserDetails?.dob === undefined ||
                              currentUserDetails?.dob === null ||
                              currentUserDetails?.dob?.length === 0 ||
                              currentUserDetails?.firstName === null ||
                              currentUserDetails?.firstName === undefined ||
                              currentUserDetails?.firstName?.length === 0 ||
                              currentUserDetails?.lastName === null ||
                              currentUserDetails?.lastName === undefined ||
                              currentUserDetails?.lastName?.length === 0 ||
                              currentUserDetails?.dob === ""
                            ) {
                              if (
                                currentUserDetails?.email.includes(
                                  "privaterelay.appleid.com"
                                ) ||
                                currentUserDetails?.email === null
                              ) {
                                setSocialEmail(true);
                              } else {
                                setSocialEmail(false);
                                //this.socailUserContForm.get('email')?.disable();
                              }
                              if (
                                currentUserDetails?.primaryContact ===
                                  undefined ||
                                currentUserDetails?.primaryContact === null ||
                                currentUserDetails?.primaryContact?.length === 0
                              ) {
                                setSocialPhone(true);
                              } else {
                                setSocialPhone(false);
                                // socailUserContForm.get('phoneNumber')?.disable();
                                // socailUserContForm.get('countryCode')?.disable();
                              }
                              if (
                                currentUserDetails?.dob === undefined ||
                                currentUserDetails?.dob === null ||
                                currentUserDetails?.dob?.length === 0 ||
                                currentUserDetails?.dob === ""
                              ) {
                                setSocialDob(true);
                              } else {
                                setSocialDob(false);
                                // socailUserContForm.get('dob')?.disable();
                              }
                              if (
                                currentUserDetails?.firstName === undefined ||
                                currentUserDetails?.firstName === null ||
                                currentUserDetails?.firstName?.length === 0 ||
                                currentUserDetails?.lastName === undefined ||
                                currentUserDetails?.lastName === null ||
                                currentUserDetails?.lastName?.length === 0
                              ) {
                                setSocialFlName(true);
                              } else {
                                setSocialFlName(false);
                                // socailUserContForm.get('firstName')?.disable();
                                // socailUserContForm.get('lastName')?.disable();
                              }
                              // socialUserCont = true;
                              openModal("UpdateUserDetails");
                              dispatch(toggleShowModalBookingDetails(false))
                            } else {
                              // openModal("ProceedBooking");

                              setEstimatedPriceDetails((prev) => ({
                                ...prev,
                                emailFlag: true,
                                // couponCodeResponse: null,
                              }));
                            }
                          }
                        }}
                        className="bgCE1 width200px p-2 border-0 text-white rounded rounded-3"
                      >
                        {loadingUntilResponse ? <SpinnerLoader /> : "Proceed"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
      <DesktopCustomModal
        isModalOpen={activeModal === "CarNotAvailable"}
        closeModal={() => closeModal("CarNotAvailable")}
        closeOnOutsideClick={true}
        modalId="AddNewCard"
        modalTitle={
          <div className="fontColorCE1 p-3">{`Car is not Available from ${currentDates?.pickupAndDropDates?.pickupDate} to ${currentDates?.pickupAndDropDates?.dropDate}, Are you sure you want
                to proceed to show other available cars.`}</div>
        }
        modalButton=""
        modalCenter={true}
        modalBackgroudColor="bg23"
      >
        <div
          onClick={() => {
            closeModal("CarNotAvailable");
          }}
          className="d-flex justify-content-between w-100 mt-2"
        >
          <button
            onClick={() => {
              closeModal("CarNotAvailable")
              dispatch(toggleShowModalCalendar(false))
            }}
            className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 opacity-75 text-white"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              dispatch(toggleShowModalCalendar(false))
              navigate("/cars-list");
            }}
            className={`accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white`}
          >
            Proceed
          </button>
        </div>
      </DesktopCustomModal>
      <DesktopCustomModal
        isModalOpen={activeModal?.includes("UpdateUserDetails")}
        closeModal={() => closeModal("UpdateUserDetails")}
        closeOnOutsideClick={true}
        modalId="UpdateUserDetails"
        modalTitle={<div className="fontColorCE1 p-3">Update User Details</div>}
        modalButton=""
        modalCenter={true}
        modalBackgroudColor="bg23"
        modalClassHeight="requiredCalendarHeightModal"
      >
        <div className="px-2 input-group w-100">
          <div className="w-100">
            {socialFlName && (
              <div className="mb-3">
                <input
                  id="firstName"
                  placeholder="First Name"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  onChange={handleChangeUpdateUserDetails}
                  onBlur={(e) => {
                    setTouched((prevState) => ({
                      ...prevState,
                      firstName: true,
                    }));
                    const error = validateField(e.target.id, e.target.value);
                    setUserErrors((prevErrors) => ({
                      ...prevErrors,
                      firstName: error,
                    }));
                  }}
                  value={editUserDetails?.firstName}
                />
                {userErrors.firstName && (
                  <div className="text-danger">{userErrors.firstName}</div>
                )}
              </div>
            )}

            {socialFlName && (
              <div className="mb-3">
                <input
                  id="lastName"
                  placeholder="Last Name"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  onChange={handleChangeUpdateUserDetails}
                  onBlur={(e) => {
                    setTouched((prevState) => ({
                      ...prevState,
                      lastName: true,
                    }));
                    const error = validateField(e.target.id, e.target.value);
                    setUserErrors((prevErrors) => ({
                      ...prevErrors,
                      lastName: error,
                    }));
                  }}
                  value={editUserDetails?.lastName}
                />
                {userErrors.lastName && (
                  <div className="text-danger">{userErrors.lastName}</div>
                )}
              </div>
            )}
            {socialDob && (
              <div className="mb-3">



                  <div>
                  <DateOfBirthPicker
                      maxDate={
                        new Date(
                          2006,
                          new Date().getMonth(),
                          Math.min(new Date().getDate(), 31)
                        )
                      }
                      handleBlur={(e) => {
                        setTouched((prevState) => ({ ...prevState, dob: true }));
                        const error = validateField(e.target.id, e.target.value);
                        setUserErrors((prevErrors) => ({
                          ...prevErrors,
                          dob: error,
                        }));
                      }}
                      value={editUserDetails?.dob}
                      placeholder="Date Of Birth mm/dd/yyyy"
                      onDateSelect={handleDobUpdate}
                    />
                  </div>




                
                {/* <div>
                  <CommonCalendar
                    maxDate={
                      new Date(
                        2006,
                        new Date().getMonth(),
                        Math.min(new Date().getDate(), 31)
                      )
                    }
                    handleBlur={(e) => {
                      setTouched((prevState) => ({ ...prevState, dob: true }));
                      const error = validateField(e.target.id, e.target.value);
                      setUserErrors((prevErrors) => ({
                        ...prevErrors,
                        dob: error,
                      }));
                    }}
                    value={editUserDetails?.dob}
                    placeholder="Date Of Birth"
                    onDateSelect={handleDateSelect}
                  />
                </div> */}
              </div>
            )}
            {socialPhone && (
              <div className="mb-3 borderRadius8 p-1">
                <PhoneInput
                  value={editUserDetails?.primaryContact}
                  country={"us"}
                  placeholder="Phone Number"
                  onChange={handlePhoneChange}
                />
                <div className="fontColor4031">
                  {" "}
                  {userErrors?.primaryContact && userErrors?.primaryContact}
                </div>
              </div>
            )}
            {socialEmail && (
              <div className="mb-3">
                <input
                  id="email"
                  placeholder="Email"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  onChange={handleChangeUpdateUserDetails}
                  onBlur={(e) => {
                    setTouched((prevState) => ({ ...prevState, email: true }));
                    const error = validateField(e.target.id, e.target.value);
                    setUserErrors((prevErrors) => ({
                      ...prevErrors,
                      lastName: error,
                    }));
                  }}
                  value={editUserDetails?.email}
                  disabled
                />
              </div>
            )}
            <div className="d-flex justify-content-between">
              <button
                onClick={handleSubmitUpdateUserDetails}
                className={`${
                  userErrors?.primaryContact ||
                  userErrors?.firstName ||
                  userErrors?.lastName ||
                  userErrors?.email ||
                  userErrors?.dob ||
                  editUserDetails?.email?.length === 0 ||
                  editUserDetails?.firstName?.length === 0 ||
                  editUserDetails?.lastName?.length === 0 ||
                  editUserDetails?.dob?.length === 0 ||
                  editUserDetails?.primaryContact?.length === 0
                    ? "disabled-class"
                    : ""
                } w-100 mt-2 mx-1 px-3 py-2 text-white bgCE1 borderRadius8 border-0 fontSize13`}
              >
                {updateUserLoading ? <SpinnerLoader /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </DesktopCustomModal>
    </div>
  );
};

export default Booking;

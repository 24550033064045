import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import colorLocateIcon from "../assets/svg/colorLocateIcon.svg";
import InfoIcon from "../assets/svg/InfoIcon.svg";
import greenTickIcon from "../assets/svg/greenTickIcon.svg";
import clockIcon from "../assets/svg/clockIcon.svg";
import cancelIcon from "../assets/svg/cancelIcon.svg";
import tripCancelIcon from "../assets/svg/tripCancelIcon.svg";
import greyInfoIcon from "../assets/svg/greyInfoIcon.svg";
import insuranceLogo from "../assets/svg/insuranceLogo.svg";
import creditCardLogo from "../assets/svg/creditCardLogo.svg";
import insuranceDocIcon from "../assets/svg/insuranceDocumentIcon.svg";
import axleSuccessLogo from "../assets/svg/AxleSuccessLogo.svg";
import latestPdfIcon from "../assets/svg/latestPdfIcon.svg";
import viewTransactionsIcon from "../assets/svg/viewTransactions.svg";
import axios from "axios";
import { BASE_URL } from "../api";
import MyTripShimmer from "../Components/MyTripShimmer";
import DesktopCustomModal from "../Components/DesktopCustomModal";
import DateTimeSlotContainer from "../Components/SearchComponent/DateTimeSlotContainer";
import moment from "moment/moment";
import {addEmrPickupAndDropDates,addEmrPickupAndDropTimings,} from "../Store/emrTimeAndDateSlice";
import { differenceInDays, format, parse } from "date-fns";
import SpinnerLoader from "../Components/SpinnerLoader";
import Overlay from "../Components/Overlay";
import FileUploader from "../Components/FileUploader";
import Checkout from "../Components/Checkout";
import AddressAutocomplete from "../Components/AddressAutocomplete";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import images from "../assets/Images";
import CommonCalendar from "../Components/CommonCalendar";
import axleLogo from "../assets/svg/axle.svg";
import { setCurrentPage } from "../Store/pageSlice";
import DateOfBirthPicker from "../Components/DateOfBirthPicker";
const MyTrips = () => {
  const isAxleNeed = process.env.REACT_APP_AXLE;
  const dispatch = useDispatch();
  const currentEmrDatesAndTimings = useSelector(
    (store) => store?.emrDatesAndTimings
  );

  const navigate = useNavigate();
  const [teslaChargeInfo, setTeslaChargeInfo] = useState("")
  const [teslaSuperChargeLoader, setTeslaSuperChargeLoader] = useState(false)
  const [commentFromEvent, setCommentFromEvent] = useState("")
  const [pickupTimeSlotData, setPickupTimeSlotData] = useState([]);
  const [dropTimeSlotData, setdropTimeSlotData] = useState([]);
  const [pickupBg, setPickupBg] = useState(null);
  const [dropBg, SetDropBg] = useState(null);
  const isDarkMode = useSelector((store) => store?.currentModeStatus?.darkMode);
  const [otherBookingID, setOtherBookingID] = useState("");
  const [pickupTimeIndex, setPickupTimeIndex] = useState("");
  const [saveAllAddress, setSaveAllAddress] = useState(""); //save Address
  const [specificAddress, setSpecificAddress] = useState(""); //save Address
  const [bookingHistory, setBookingHistory] = useState([]);
  const [bookingHistoryError, setBookingHistoryError] = useState("");
  const [bookingHistoryFilteredData, setBookingHistoryFilteredData] = useState([]);
  const [otherBookingHistory, setOtherBookingHistory] = useState([]);
  const [otherBookingHistoryError, setOtherBookingHistoryError] = useState("");
  const [otherBookingHistoryFilteredData, setOtherBookingHistoryFilteredData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const [activeBookingID, setActiveBookingID] = useState("");
  const [bookingCheckRentalDoc, setBookingCheckRentalDoc] = useState(false);
  const [EmrTimings, setEmrTimings] = useState("");
  const [EmrTimingsError, setEmrTimingsError] = useState("");
  const [EmrTimingsLoading, setEmrTimingsLoading] = useState(false);
  const [showCalendarAndtime, setShowCalendarAndtime] = useState(true);
 const [emrData, setEmrData] = useState("");
 const [emrDataLoading, setEmrDataLoading] = useState(false);
 const [bookingStatus, setBookingStatus] = useState("");
 const [individualEmrID, setIndividualEmrID] = useState("");
 const [specificModificationRequest, setSpecificModificationRequest] =useState("");
 const [specificModificationError, setSpecificModificationError] =useState("");
 const [filterBookingDetailsWithID, setFilterBookingDetailsWithID] =useState("");
const [rentalPaymentResponse, setRentalPaymentResponse] = useState("");
const [rentalPaymentError, setRentalPaymentError] = useState("");
const [rentalPaymentResponseLoading, setRentalPaymentResponseLoading] =useState(false);
const [cardDetails, setCardDetails] = useState("");
const [cardDError, setCardError] = useState("");
  //insurance protection plans
const [insurancePlanData, setInsurancePlanData] = useState("");
const [insuranceLoading, setInsuranceLoading] = useState(false);
const [insuranceError, setInsuranceError] = useState("");
const [rentalID, setRentalID] = useState({rentalCoverageId: [],rentalKey: [],additionalCoverageIds: [],});
const [isId1Disabled, setIsId1Disabled] = useState(false);
  const [hideZindex, setHideZindex] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isInsuranceClicked, setIsInsuranceClicked] = useState(false);
  const [isCreditCardClicked, setIsCreditCardClicked] = useState(false);
  const [isSelectCoverage, setIsSelectCoverage] = useState(false);
  const [isSelectedCreditCard, setIsSelectedCreditCard] = useState(false);
  const [insuranceInputValues, setInsuranceInputValues] = useState({
    documentType: "I",
    policyNumber: "",
    issueState: "",
    provider: "",
    expiryDate: "",
    documentName: "",
  });
  const [updateTextToDate, setUpdateTextToDate] = useState(false)
  const [userInsuranceDocs, setUserInsuranceDocs] = useState([]);
  const [userInsuranceError, setUserInsuranceError] = useState("");
  // const [userCreditDetails, setUserCreditDetails] = useState([]);
  const [userCreditError, setUserCreditError] = useState("");
  const [selectedDocId, setSelectedDocId] = useState("");
  const [selectedCreditId, setSelectedCreditId] = useState("");
  const [licenseDetails, setLicenseDetails] = useState("");
  const [licenseError, setLicenseError] = useState("");
  // const [linkLicenseError, setLinkLicenseError] = useState("");
  const [showDeleteLicense, setShowDeleteLicense] = useState(false);
  const [storeStateNames, setStoreStateNames] = useState([]);
  const [deleteLicenseLoader, setDeleteLicenseLoader] = useState(false);
  const [showConditions, setShowConditions] = useState(false);
  const [licenseDetailsCheckbox, setLicenseDetailsCheckbox] = useState(false);
  const [mailingAddressCheckbox, setMailingAddressCheckbox] = useState(false);
  const [addressDetails, setAddressDetails] = useState({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    countryCode: "",
    secondAddressline: "",
  }); //this state is using for AddressAutocomplete component
  const [drivingLicenseDocument, setDrivingLicenseDocument] = useState({
    frontPage: "",
    backPage: "",
    drivingLicenseNumber: "",
    expiryDate: "",
  });
  const [drivingLicenseErrors, setDrivingLicenseErrors] = useState({
    drivingLicenseNumber: "",
  });
  const [errors, setErrors] = useState({
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
  });
  const [emrTripsLoading, setEmrTripsLoading] = useState(false);
  const [addMailingAddressLoading, setAddMailingAddressLoading] =
    useState(false);
    const [myTripsLoading, setmyTripsLoading] = useState(false);
  // const [activeModal, setActiveModal] = useState("");
  // const openModal = (modalId) => {
  //   setActiveModal(modalId);
  //   // handleToggle("calendarAndTime");
  // };
  // const closeModal = () => {
  //   setActiveModal("");
  //   setEmrData("");
  //   setCardDetails("");
  //   setShowCalendarAndtime(true);
  // };
  const [activeModal, setActiveModal] = useState([]);

  const openModal = (modalId, allowMultiple = false) => {
    // If multiple modals are allowed, append to the array
    if (allowMultiple) {
      setActiveModal((prevModals) => [...prevModals, modalId]);
    } else {
      // If multiple modals are not allowed, only keep the new modal
      setActiveModal([modalId]);
    }
  };

  const closeModal = (modalId, selectedDocument = false) => {
    setSpecificAddress("");
    if (modalId === "") {
      setActiveModal("");
      setEmrData("");
      setCardDetails("");
      setShowCalendarAndtime(true);
    } else {
      setActiveModal(
        (prevModals) => prevModals?.filter((id) => id !== modalId) // Remove the specific modal from the array
      );
    }

    if (modalId === "RentalOptions") {
      setRentalID((prev) => ({
        ...prev,
        rentalCoverageId: [],
        rentalKey: [],
        additionalCoverageIds: [],
      }));
      localStorage.removeItem("rentalCoverageChanges")
      // localStorage.removeItem("bookingIdAxle")
      setIsId1Disabled(false);
      setIsNext(false);
    }

    // Only remove ID 4 if closing the Rental Coverage Modal
    if (modalId === "RentalCoverageModal") {
      setIsCreditCardClicked(false);
      setIsInsuranceClicked(false);
      setIsSelectCoverage(false);
      setIsSelectedCreditCard(false);
      if (!selectedDocument) {
        setSelectedDocId("");
      }
      setSelectedCreditId("");

      if (!selectedDocument) {
        const storedDetails = getLocalStorageData(); // Load previous data from localStorage
        if (rentalID?.rentalCoverageId[0] === 1) {
          
          // setRentalID((prev) => ({
          //   ...prev,
          //   rentalCoverageId: prev?.rentalCoverageId.filter((id) => id !== 4),
          //   rentalKey: prev?.rentalKey.filter((id) => id !== "own_liability"),
          // }));

          setRentalID((prev) => {
            // Filter out specific IDs for rentalCoverageId and rentalKey
            const filteredDetails = {
              ...prev,
              rentalCoverageId: prev?.rentalCoverageId.filter((id) => id !== 4),
              rentalKey: prev?.rentalKey.filter((id) => id !== "own_liability"),
            };
          
            // Create updated details by merging stored details and other changes
            const updatedDetails = {
              
              ...storedDetails, // Merge stored data (preserve other selections)
              ...filteredDetails,
            };
          
            // Save updated details to localStorage
            
            localStorage.setItem("rentalCoverageChanges", JSON.stringify(updatedDetails));
          
            return updatedDetails; // Return the updated details to update the state
          });
          

        } else {
          // setRentalID((prev) => ({
          //   ...prev,
          //   rentalCoverageId: prev?.rentalCoverageId.filter(
          //     (id) => id !== 4 && id !== 1
          //   ),
          //   rentalKey: prev?.rentalKey.filter(
          //     (id) => id !== "own_liability" && id !== "minimum_liability"
          //   ),
          // }));



          setRentalID((prev) => {
            // Filter out specific IDs for rentalCoverageId and rentalKey
            const filteredDetails = {
              ...prev,
              rentalCoverageId: prev?.rentalCoverageId.filter(
                (id) => id !== 4 && id !== 1
              ),
              rentalKey: prev?.rentalKey.filter(
                (id) => id !== "own_liability" && id !== "minimum_liability"
              ),
            };
          
            // Create updated details by merging stored details and other changes
            const updatedDetails = {
             
              ...storedDetails, // Merge stored data (preserve other selections)
              ...filteredDetails,
            };
          
            // Save updated details to localStorage
            localStorage.setItem("rentalCoverageChanges", JSON.stringify(updatedDetails));
          
            return updatedDetails; // Return the updated details to update the state
          });
        }

       
      }
    }
  };

  const token = sessionStorage.getItem("AccessToken");

  const getBookingHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}booking/v1/getHistory`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setBookingHistory(response?.data?.data); // Make sure to use response.data
      setBookingHistoryFilteredData(response?.data?.data);
      const  bookingId  =JSON.parse(localStorage.getItem("bookingIdAxle"));
      setActiveBookingID(bookingId ? bookingId :response?.data?.data[0]?.id);
      // setActiveBookingID(response?.data?.data[0]?.id);
      setLoading(false);
      getOtherBookings();
    } catch (error) {
      setBookingHistoryError(
        `Error fetching booking history: ${error?.response?.data?.message}`
      );
      setLoading(false);
    }
  };

  const getOtherBookings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}booking/getOtherBookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newResponseData = response?.data?.data;
      setOtherBookingHistory(response?.data?.data);
      setOtherBookingHistoryFilteredData(newResponseData);
      setLoading(false);
    } catch (error) {
      setOtherBookingHistoryError(
        `Error fetching booking history: ${error?.response?.data?.message}`
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const myTripActiveTap = localStorage.getItem("myTripActiveTap")
  useEffect(() => {
    getBookingHistory();
    // if(myTripActiveTap){
    //   reviewingTab(myTripActiveTap)
    // }
    // getOtherBookings();
    // eslint-disable-next-line
  }, []);

  const reviewingTab = (statusMessage) => {
    setActiveTab(statusMessage);
    // localStorage.setItem("myTripActiveTap",statusMessage);
    setLoading(true);
    if (bookingHistory.length === 0) return []; // Return early if there's no data

    const res = bookingHistory.filter(
      (details) => details?.statusMessage === statusMessage
    );
    setBookingHistoryFilteredData(res);

    const res1 = otherBookingHistory.filter(
      (details) => details?.statusMessage === statusMessage
    );
    setOtherBookingHistoryFilteredData(res1);

    if (statusMessage === "All") {
      setBookingHistoryFilteredData(bookingHistory);
      setOtherBookingHistoryError(otherBookingHistory);
    }

    if (res.length === 0) {
      setMessage(`Currently there are no ${statusMessage} bookings`);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const fetchEmrTimings = async (id) => {
    setEmrTimingsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}booking/emr/timeSlots/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEmrTimings(response?.data?.data);
      setEmrTimingsLoading(false);
    } catch (error) {
      setEmrTimingsError(
        `Error fetching booking history: ${error?.response?.data?.message}`
      );
      setEmrTimingsLoading(false);
    }
  };

  // Format options for displaying date
  const options = { year: "numeric", month: "short", day: "numeric" };

  // Get the current day formatted
  const currentDay = new Date().toLocaleDateString("en-US", options);

  //it will set the Emr time Slots
  useEffect(() => {
    if (
      currentDay ===
        currentEmrDatesAndTimings?.pickupAndDropDates?.pickupDate &&
      currentDay === currentEmrDatesAndTimings?.pickupAndDropDates?.dropDate
    ) {
      setPickupTimeSlotData(EmrTimings?.timeSlots?.currentDateTimeSlots);
      const updatedtimeSlots =
        EmrTimings?.timeSlots?.currentDateTimeSlots.slice(1);
      setdropTimeSlotData(updatedtimeSlots);
    } else if (
      currentDay ===
        currentEmrDatesAndTimings?.pickupAndDropDates?.pickupDate &&
      currentDay !== currentEmrDatesAndTimings?.pickupAndDropDates?.dropDate
    ) {
      setPickupTimeSlotData(EmrTimings?.timeSlots?.currentDateTimeSlots);
      setdropTimeSlotData(EmrTimings?.timeSlots?.generalTimeSlots);
    } else if (
      currentDay !==
        currentEmrDatesAndTimings?.pickupAndDropDates?.pickupDate &&
      currentDay !== currentEmrDatesAndTimings?.pickupAndDropDates?.dropDate
    ) {
      setPickupTimeSlotData(EmrTimings?.timeSlots?.generalTimeSlots);
      setdropTimeSlotData(EmrTimings?.timeSlots?.generalTimeSlots);
    } else if (
      (bookingStatus === "On-Going" || bookingStatus === "Completed") &&
      currentDay === currentEmrDatesAndTimings?.pickupAndDropDates?.dropDate
    ) {
      setdropTimeSlotData(EmrTimings?.timeSlots?.currentDateTimeSlots);
    }
  }, [currentEmrDatesAndTimings, currentDay, EmrTimings, bookingStatus]);

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    // Format to "YYYY-MM-DD HH:mm:ss"
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:00`;
    return formattedDate;
  };

  // Assuming currentEmrDatesAndTimings is defined and has the expected structure
  const pickupDateString = `${currentEmrDatesAndTimings?.pickupAndDropDates?.pickupDate} ${currentEmrDatesAndTimings?.pickupAndDropTimings?.pickupTime}`;
  const dropDateString = `${currentEmrDatesAndTimings?.pickupAndDropDates?.dropDate} ${currentEmrDatesAndTimings?.pickupAndDropTimings?.dropTime}`;

  const convertedPickupDate = convertDate(pickupDateString);
  const convertedDropDate = convertDate(dropDateString);

  const fetchEstimatedEmrPricing = async (approveValue, cardID = "", documentModal, isRequiredDocId=true) => {
    setEmrDataLoading(true);
    if(approveValue){
      setEmrTripsLoading(true)
    }
    const payload = {
      bookingId: activeBookingID,
      comments: commentFromEvent,
      endDateTime: convertedDropDate,
      eventId: 0,
      startDateTime: convertedPickupDate,
      isApproved: approveValue ? true : false,
      paymentMethod: cardID,
      emrDocumentId: isRequiredDocId ? selectedDocId?.id : ""
    };

    if (
      activeBookingID !== "" &&
      convertedPickupDate !== "" &&
      convertedDropDate !== ""
    ) {
      try {
        const response = await axios.post(
          `${BASE_URL}booking/eventModificationRequestInfoAndCreateV2`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Handle the response here
        setEmrData(response?.data?.data);
        setEmrDataLoading(false);

        if (
          approveValue &&
          (response?.data?.data?.statusMessage === "EMR_PENDING_PAYMENT" ||
            response?.data?.data?.statusMessage === "ZOHO_SIGN_PENDING")
        ) {
          setEmrTripsLoading(false)
          openModal("PaymentDone");
          getBookingHistory();
        }

        if(documentModal){
          setTimeout(() => {
            openModal("TripModify")
            setShowCalendarAndtime(false)
          }, 2000)
        }
        // setShowCalendarAndtime(false);
      } catch (error) {
        // Handle the error here
        toast.error(error?.response?.data?.message);
        setShowCalendarAndtime(true);
        setEmrDataLoading(false);
        setEmrTripsLoading(false)
        setCardDetails("")
        setEmrData("")
      }
    }
  };

  const { existingBookingInfo, newBookingInfo, eventModificationRequest } =
    emrData;

    const [cardDetailsSpinner, setCardDetailsSpinner] = useState(false)

  const fetchCardDetails = async () => {
    try {
      setCardDetailsSpinner(true)
      const response = await axios.get(
        `${BASE_URL}savedcard/selectedCard/${activeBookingID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        setCardDetails(response?.data?.data);
      } else {
        setCardDetails("");
        // toast.error("Unable to load card details");
      }
      setCardDetailsSpinner(false)
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setCardDetailsSpinner(false)
      setCardError(
        `Error fetching booking history: ${error?.response?.data?.message}`
      );
    }
  };

  const fetchSpecificModificationRequest = async (id) => {
    const formData = new FormData();
    formData.append("emrId", id);

    try {
      const response = await axios.post(
        `${BASE_URL}booking/eventModificationRequestInfoViewOnly`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSpecificModificationRequest(response?.data?.data);
    } catch (error) {
      setSpecificModificationError(
        `Error fetching booking history: ${error?.response?.data?.message}`
      );
    }
  };

  //filter booking ID
  const filteredBookingId = (historyDetails) => {
    if (historyDetails[historyDetails?.length - 1]?.status === 21) {
      setIndividualEmrID(historyDetails[historyDetails?.length - 1]?.id);
    }
    setFilterBookingDetailsWithID(historyDetails);
  };

  //this is a switch wher it hide or shows the modify button
  const isEMRAllowed = (history) => {
    switch (history?.statusMessage) {
      case "On-Going":
      case "Upcoming":
        return true;
      case "Completed":
        const currentDate = new Date();
        const diffDropoffDate = moment(
          moment(history.dropOffDateTime).format("YYYY-MM-DD HH:mm:ss")
        ).toDate();
        const diff =
          (currentDate.getTime() - diffDropoffDate.getTime()) /
          1000 /
          (60 * 60);
        return diff < 6;
      default:
        return false;
    }
  };

  //fetch the rental options from API
  const fetchData = async () => {
    setInsuranceLoading(true);
    try {
      const result = await axios.get(`${BASE_URL}protectionPlans/12/open`);
      setInsurancePlanData(result?.data?.data);
      setInsuranceLoading(false);
    } catch (error) {
      setInsuranceError(
        "Failed to fetch protection plan data in booking screen"
      );
      setInsuranceLoading(false);
    }
  };

  // const handleClickRental = (rentalItem) => {
  //   if(rentalID.includes(rentalItem?.id)){
  //   const filteredRental = rentalID.filter((ele) => ele !== rentalItem?.id)
  //   setRentalID(filteredRental)
  //   } else {
  //     setRentalID(prev => [...prev, rentalItem?.id])
  //   }
  // }

  
  useEffect(() => {
    dispatch(setCurrentPage("booking-history"));
  }, [dispatch]);

  useEffect(() => {
    //this is used to remove isRefreshed once we route from cancel-booking to booking-history
    sessionStorage.removeItem("isRefreshed"); // Remove flag after redirection
  }, []);

  useEffect(
    () => {
      if (token) {
        getUserInsuranceDocs();
        // getUserCreditDocs();
        getStates();
        getLicenseDetails();
        getAddressAll();
      }
    },
    //eslint-disable-next-line
    []
  );

  const getAddressAll = async () => {
    try {
      const response = await axios.get(`${BASE_URL}address/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSaveAllAddress(response?.data?.data);
    } catch (error) {
      toast.error(
        `Error fetching in cards due to ${error?.response?.data?.message}`
      );
    }
  };

  const getLicenseDetails = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}licensedetail/getCurrentUserLicenseDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLicenseDetails(response?.data);
    } catch (error) {
      setLicenseError(
        `Error fetching in License due to ${error?.response?.data?.message}`
      );
    }
  };

  const getStates = async () => {
    const resultStatesList = await axios.get(
      `${BASE_URL}location/getStateList/open`
    );
    setStoreStateNames(resultStatesList?.data?.data?.countries);
  };

  //this is to delete the current file in fileUploader reusable document
  const handleDeleteFile = () => {
    setInsuranceInputValues((prev) => ({
      ...prev,
      documentName: "",
    }));
  };
  
  const getUserInsuranceDocs = async () => {
    try {
      setmyTripsLoading(true);
      const userResponse = await axios.get(
        `${BASE_URL}userDocument/currentUser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserInsuranceDocs(userResponse?.data?.data);
      setmyTripsLoading(false);
    } catch (error) {
      setUserInsuranceError("Error in getting Insurance docs");
      setmyTripsLoading(false);
    }
  };

  const handleSubmitInsuranceDetails = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("documentType", insuranceInputValues?.documentType);
    formData.append("documentId", insuranceInputValues?.policyNumber);
    formData.append("issueState", insuranceInputValues?.issueState);
    formData.append("provider", insuranceInputValues?.provider);
    formData.append(
      "expiration",
      format(insuranceInputValues?.expiryDate, "yyyy-MM-dd HH:mm:ss")
    );
    formData.append("documentName", insuranceInputValues?.documentName); // The binary file
    setInsuranceInputValues((prev) => ({
      ...prev,
      documentType: "I",
      policyNumber: "",
      issueState: "",
      provider: "",
      expiryDate: "",
      documentName: "",
    }));
    try {
      const response = await axios.post(
        `${BASE_URL}userDocument/createProtectionPlan`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        toast.success(
          response?.data?.message ||
            "Protection Plan document uploaded successfully"
        );
        getUserInsuranceDocs(); // to get the user details
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Unable to process your request"
      );
    }
  };

  const handleChangeInsuranceDetails = (e) => {
    setSelectedDocId("");
    const { id, value } = e.target;
    setInsuranceInputValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleChangeInsuranceDateDetails = (selectedDate) => {
    setSelectedDocId("");
    setInsuranceInputValues((prev) => ({
      ...prev,
      expiryDate: selectedDate,
    }));
  };
  

  const filterSelectedInsuranceDoc = userInsuranceDocs?.find(
    (details) => details?.id === selectedDocId?.id
  );

  // const filterSelectedCreditDoc = userCreditDetails?.find(
  //   (details) => details?.id === selectedCreditId
  // );

  const staticValue = { id: 1, rentalDocKey: "minimum_liability" };

  const handleZIndex = (value) => {
    setHideZindex(value);
  };
// Function to get the state from localStorage or set initial state
const getLocalStorageData = () => {
  const savedData = localStorage.getItem("rentalCoverageChanges");
  return savedData ? JSON.parse(savedData) : {}; // If no data in LS, return empty object
};

useEffect(() => {
  const storedDetails = getLocalStorageData();
  const bookingId =JSON.parse(localStorage.getItem("bookingIdAxle"));
   setActiveBookingID(bookingId)
  setRentalID((prev) => ({
    ...prev,
    ...storedDetails
  }))
}, [])


  const handleDetailsSelect = (type, value) => {
    //console.log(type,value)
    const { id, rentalDocKey } = value;
    if (type === "rentalCoverageId") {
      if (value?.id === 2 || value?.id === 3) {
        setIsId1Disabled(true);
      } else if (value?.id === 4) {
        if (!rentalID?.rentalCoverageId?.includes(4)) {
          openModal("RentalCoverageModal", true);
          setIsId1Disabled(false);
        }
        handleZIndex(true);
      }
    }

    setRentalID((prev) => {
      const storedDetails = getLocalStorageData(); // Load previous data from localStorage
      let newSelection = storedDetails[type] ? [...storedDetails[type]] : [];
      let rentalKeySelection = storedDetails?.rentalKey || []; // Preserve existing rentalKey selection

      //console.log(storedDetails)
      // let newSelection;
      // let rentalKeySelection;

      if (type === "rentalCoverageId") {
        if (prev[type]?.includes(1) && id === 1) {
          newSelection = prev[type]?.filter((itemID) => itemID !== 1);
          rentalKeySelection = prev?.["rentalKey"]?.filter(
            (rentalValue) => rentalValue !== "minimum_liability"
          );
        } else {
          if (id === 2 || id === 3) {
            newSelection = [id];
            rentalKeySelection = [rentalDocKey];
          } else if (id === 4) {
            let IsResultId1 = rentalID?.rentalCoverageId?.includes(1);
            if (IsResultId1) {
              newSelection = [...prev[type], id];
              rentalKeySelection = [
                ...(prev?.["rentalKey"] || []),
                rentalDocKey,
              ];
            } else {
              newSelection = [id];
              rentalKeySelection = [rentalDocKey];
            }
          } else if (id === 1) {
            let IsResultId4 = rentalID?.rentalCoverageId?.includes(4);
            if (IsResultId4) {
              newSelection = [...prev[type], id];
              rentalKeySelection = [
                ...(prev?.["rentalKey"] || []),
                rentalDocKey,
              ];
            } else {
              newSelection = [id];
              rentalKeySelection = [rentalDocKey];
            }
          } else {
            newSelection = [id];
            rentalKeySelection = [rentalDocKey];
          }
        }
      } else if (type === "additionalCoverageIds") {
        newSelection = prev[type]?.includes(value?.id)
          ? prev[type]?.filter((itemID) => itemID !== id)
          : [...prev[type], id];
      }

       // Return the updated state, merging new selections
       const updatedDetails = {
        ...prev,
        ...storedDetails, // Merge stored data (preserve other selections)
        [type]: newSelection, // Update the type you are modifying
        rentalKey: rentalKeySelection, // Preserve rentalKey or update it
       
      };
      localStorage.setItem("rentalCoverageChanges", JSON.stringify(updatedDetails));
      return {
        ...prev,
        [type]: newSelection,
        rentalKey: rentalKeySelection,
      };
    });
  };

  const linkProtectionPlan = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("bookingId", activeBookingID);
    formData.append(
      "protectionPlanType",
      selectedDocId?.documentType === "I" ? 1 : 2
    );
    formData.append(
      "referenceId",
      selectedDocId?.documentType === "I"
        ? filterSelectedInsuranceDoc?.id
        : cardDetails?.id
    );

    try {
      const response = await axios.post(
        `${BASE_URL}userDocument/linkProtectionPlanToBooking`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      closeModal("RentalCoverageModal", true);
       getBookingHistory();
       localStorage.removeItem("openModalAfterAxle");
      // if (bookingCheckRentalDoc) {
      //   linkCoverage();
      //   // getBookingHistory();
      // }
      setLoading(false);
      toast.success("Rental Coverage added successfully");
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message)
    }
  };

  // const linkCoverage = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}protectionPlans/rentalDocument/${activeBookingID}`,
  //       {
  //         responseType: "json",
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     getBookingHistory();
  //   } catch (error) {
  //     setLoading(false);
  //     // toast.error(error?.response?.data?.message)
  //   }
  // };

  const insuranceAndAdditionalCoveragePayment = async () => {
    setRentalPaymentResponseLoading(true);
    const payload = {
      additionalIds: rentalID?.additionalCoverageIds,
      bookingId: activeBookingID,
      paymentMethod: cardDetails?.id,
      rentalId: rentalID?.rentalCoverageId,
      rentalKey: rentalID?.rentalKey,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}protectionPlans/rentalPayment/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRentalPaymentResponse(response?.data?.data);
      getBookingHistory();
      localStorage.removeItem("openModalAfterAxle");
      setTimeout(() => {
        closeModal("RentalOptions");
      }, 1000);
      setRentalPaymentResponseLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setRentalPaymentError(
        `Error fetching booking history: ${error?.response?.data?.message}`
      );
      setRentalPaymentResponseLoading(false);
    }
  };

  const postDrivingLicenseData = async () => {
    // Create FormData instance
    const formData = new FormData();

    // Append addressDetails data to FormData (replace with actual fields)
    formData.append("licenseFrontSide", drivingLicenseDocument?.frontPage);
    formData.append("licenseBacksideSide", drivingLicenseDocument?.backPage);
    formData.append(
      "licenseNumber",
      drivingLicenseDocument?.drivingLicenseNumber
    );
    formData.append("expiration", drivingLicenseDocument?.expiryDate);
    formData.append("issuingCountryCode", addressDetails.countryCode);
    formData.append("issuingState", addressDetails.state);
    formData.append("issuingCityZipCode", addressDetails.zipCode);
    formData.append("addressLine1", addressDetails.address);
    formData.append("addressLine2", addressDetails.secondAddressline);
    formData.append("country", addressDetails.country);
    formData.append("city", addressDetails.city);
    formData.append("isMailingAddress", mailingAddressCheckbox);

    try {
      // Send the POST request with FormData and token header
      const response = await axios.post(
        `${BASE_URL}licensedetail/createLicenseDetail`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );
      // toast.success(" Driver License details updated Successfully.");
      closeModal("DrivingLicenseModal");
      // Handle the response if needed
      getLicenseDetails();
      getBookingHistory();
      return response.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Unable to add driving license"
      );
    }
  };

  const handleLinkLicense = async (bookingId) => {
    const formData = new FormData();
    formData.append("bookingId", bookingId);
    formData.append("licenseId", licenseDetails?.data?.id);
    try {
      const response = await axios.post(
        `${BASE_URL}licensedetail/linkLicenseToBooking`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getBookingHistory();
      setShowDeleteLicense(false);
      toast.success(response.message || "Driver License linked successfully");
    } catch (error) {
      if (error?.response?.data?.message === "License expired.") {
        // this.showdelLicenseOptions = true; // Set showAddLicenseOptions to true
        // this.showdelLicenseOptions1 = true;
        setShowDeleteLicense(true);
        toast.error("Driver License Expired, please add a new License.");
      } else {
        toast.error(error?.response?.data?.message || "An error occurred");
      }

      // setLinkLicenseError(
      //   `Error fetching booking history: ${error?.response?.data?.message}`
      // );
    }
  };
  //To Download receipt
  const pdfByBookingId = async (bookingId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}booking/retrieveTransactionReceipt`,
        {
          params: { bookingId },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob", // Important for downloading files
        }
      );

      // Create a new Blob for the PDF
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "ViewReceipt.pdf"; // Set file name
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    }
  };

  

  const handleProceedMailingAddress = async (AddressPayload, changePayload) => {
    setAddMailingAddressLoading(true);
    //here based on the changePayload boolean value we are changing the payload accordingly
    let newPayload;
    if (changePayload) {
      const { address, city, state, zipCode, country, secondAddressline } =
        AddressPayload;

      newPayload = {
        addressLine1: address,
        addressLine2: secondAddressline,
        city: city,
        state: state,
        country: country,
        zip: zipCode,
        isMailingAddress: true,
      };
    } else {
      //  newPayload =
      //  AddressPayload
      newPayload = {
        availableAddress: {
          ...AddressPayload,
          isMailingAddress: true,
        },
      };
    }

    try {
      const response = await axios.post(
        `${BASE_URL}licensedetail/linkAddressToLicense`,
        newPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeModal("SelectMailingAddress");
      closeModal("AddMailingAddress");
      getBookingHistory();
      setAddMailingAddressLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setAddMailingAddressLoading(false);
    }
  };

  //to know the status of the booking related to otherBookingFilterData
  const getTripStatus = (date1, date2, timeZone) => {
    let date = new Date(
      new Date()?.toLocaleString("en-US", {
        timeZone,
      })
    );

    let d1 = new Date(
      new Date(date1)?.toLocaleString("en-US", {
        timeZone,
      })
    );

    let d2 = new Date(
      new Date(date2)?.toLocaleString("en-US", {
        timeZone,
      })
    );

    if (d1.getTime() > date.getTime()) {
      return "Upcoming";
    } else if (d2.getTime() < date.getTime()) {
      return "Completed";
    } else {
      return "On-Going";
    }
  };

  useEffect(() => {
    if (otherBookingHistory?.length > 0) {
      setOtherBookingHistory((prev) =>
        prev.map((item) => ({
          ...item,
          statusMessage: getTripStatus(
            item.startDateTime,
            item.endDateTime,
            item.timeZone
          ), // replace "hello" with actual status
        }))
      );
    }
  }, [otherBookingHistory?.length]);

  const deleteLicense = async (licenseId) => {
    setDeleteLicenseLoader(true);
    try {
      await axios.delete(`${BASE_URL}licensedetail/${licenseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("License Deleted Successfully");
      closeModal("DeleteDrivingLicense");
      setShowDeleteLicense(false);
      getLicenseDetails();
      setDeleteLicenseLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setShowDeleteLicense(true);
      setDeleteLicenseLoader(false);
    }
  };

  const handleDateSelect = (selectedDate) => {
    setDrivingLicenseDocument((prevValues) => ({
      ...prevValues,
      expiryDate: format(
        parse(selectedDate, "MM/dd/yyyy", new Date()),
        "yyyy-MM-dd"
      ),
    }));
  };
 
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const axleStatus = queryParams.get("status");
  const [axleDetails, setAxleDetails] = useState("");
  const [axleError, setAxleError] = useState("");
  useEffect(() => {
    const bookingId =JSON.parse(localStorage.getItem("bookingIdAxle"));
    const openModalAfterAxle = JSON.parse(localStorage.getItem("openModalAfterAxle"))
    if (axleStatus === "complete" || axleStatus === "error") {
      if(axleStatus === "complete"){
      getAxleDetails();
      }
      clearQueryParams();
      
      setActiveBookingID(bookingId)
    if(openModalAfterAxle)
   {
  openModal("RentalOptions", true);
  fetchData();
  openModal("RentalCoverageModal", true);
  }else{
  openModal("RentalCoverageModal", true);
  }
      

      setIsInsuranceClicked(true);
    } else {
      if (axleStatus === "exit") {
        clearQueryParams();
        setActiveBookingID(bookingId)
        if(openModalAfterAxle)
          {
            openModal("RentalOptions", true);
            fetchData();
            openModal("RentalCoverageModal", true);
          }else{
            openModal("RentalCoverageModal", true);
          }

        setIsInsuranceClicked(true);
      }
      // else{
      //   localStorage.removeItem("rentalCoverageChanges")
      // }
    }
    // eslint-disable-next-line
  }, [axleStatus]);

  const clearQueryParams = () => {
    // Remove all specific query parameters
    searchParams.delete("status");
    searchParams.delete("authCode");
    searchParams.delete("client");
    searchParams.delete("result");
    // Update the URL with the new query parameters (which will be empty)
    setSearchParams(searchParams); // This will trigger a URL update
  };

  const getAxleLink = async () => {
    setmyTripsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}axle/generateIgnitionToken?returnUrl=booking-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //const parsedData = JSON.parse(response?.data?.data);
      //window.location.href = parsedData.data.ignitionUri;
      const ignitionUri = response?.data?.data?.ignitionUri;
      if(ignitionUri){
        window.location.href = ignitionUri;
      }
      // localStorage.setItem("", true);
      setAxleDetails(response?.data);
      setmyTripsLoading(false);
    } catch (error) {
      setmyTripsLoading(false);
      setAxleError(
        `Error fetching in License due to ${error?.response?.data?.message}`
      );
    }
  };

  const getAxleDetails = async () => {
    // setmyTripsLoading(true);
    try {
      setmyTripsLoading(true);
      const response = await axios.get(
        `${BASE_URL}axle/getIgnitionResponse${location?.search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.message || "Insurance details added successfully");
      getUserInsuranceDocs();
      // setmyTripsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unable to process");
      setmyTripsLoading(false);
    }
  };

  const getTeslaSuperCharges = async (bookID) => {
   try {
    setTeslaSuperChargeLoader(true)
    const response = await axios.get(`${BASE_URL}booking/chargingDetailsByBookingId/${bookID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
     )
     setTeslaChargeInfo(response?.data?.data?.chargingDetails)
     setTeslaSuperChargeLoader(false)
   } catch (error) {
    toast.error(error?.response?.data?.message || "Unable to Get Tesla Charge Info")
    setTeslaSuperChargeLoader(false)
   }
   openModal("TeslaSuperCharge")
  }

  return (
    <div
      className={`min-vh-100 myTripspaddingLeftAndRight  ${
        isDarkMode ? "darkMode" : "bgFEFDFD"
      }`}
    >
      {(myTripsLoading  || emrTripsLoading || teslaSuperChargeLoader)&& (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      {/* <ToastContainer theme="dark" /> */}
      {/* {otherBookingHistory?.length === 0 && bookingHistory?.length === 0 ? (
        <div className="text-white text-center fontAntarctica fontSzie17">
          {bookingHistoryError}
          You have no Trips booked.
        </div>
      ) : ( */}
      <div className="d-flex justify-content-center align-items-center">
        <div className="w-100">
          {(otherBookingHistory?.length !== 0 ||
            bookingHistory?.length !== 0) && (
            <div className="text-center fontSize30 fontAtacama mb-3">
              My Trips
            </div>
          )}
          {(otherBookingHistory?.length !== 0 ||
            bookingHistory?.length !== 0) && (
            <div className="fontSize17 fontAntarctica w-100 d-flex justify-content-center mb-4">
              <div
                className={`${
                  loading && "disabled-class"
                } d-flex justify-content-between px-2 overflow-auto w-auto`}
              >
                <div
                  className={`mx-2 p-1 cursorPointer ${
                    activeTab === "All" ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    if (activeTab !== "All") {
                      reviewingTab("All");
                    }
                  }}
                >
                  All
                </div>
                <div
                  className={`mx-2 p-1 text-nowrap cursorPointer ${
                    activeTab === "Review Pending" ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    if (activeTab !== "Review Pending") {
                      reviewingTab("Review Pending");
                    }
                  }}
                >
                  Review Pending
                </div>
                <div
                  className={`mx-2 p-1 cursorPointer ${
                    activeTab === "On-Going" ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    if (activeTab !== "On-Going") {
                      reviewingTab("On-Going");
                    }
                  }}
                >
                  Ongoing
                </div>
                <div
                  className={`mx-2 p-1 cursorPointer ${
                    activeTab === "Upcoming" ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    if (activeTab !== "Upcoming") {
                      reviewingTab("Upcoming");
                    }
                  }}
                >
                  Upcoming
                </div>
                <div
                  className={`mx-2 p-1 cursorPointer ${
                    activeTab === "Completed" ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    if (activeTab !== "Completed") {
                      reviewingTab("Completed");
                    }
                  }}
                >
                  Completed
                </div>
              </div>
            </div>
          )}
          {loading ? (
            <div>
              <MyTripShimmer />
            </div>
          ) : (
            <div>
              {otherBookingHistory?.length === 0 &&
                bookingHistory?.length === 0 && (
                  <div className="text-white text-center fontAntarctica fontSzie17">
                    You have no Trips booked.
                  </div>
                )}
            </div>
          )}
          {bookingHistoryFilteredData.length === 0 ? (
            <div className="text-center fontAtacama">{message}</div>
          ) : (
            bookingHistoryFilteredData?.map((historyDetails, index) => {
              return (
                <div
                  key={historyDetails?.id || index}
                  onClick={() => {
                    filteredBookingId(
                      historyDetails?.eventModificationRequest
                    );
                    if (activeBookingID === historyDetails?.id) {
                      // setActiveBookingID("");
                    } else {
                    //  const  bookingId  =JSON.parse(localStorage.getItem("bookingIdAxle"));
                    localStorage.setItem("bookingIdAxle",historyDetails?.id)
                     setActiveBookingID(historyDetails?.id);
                    }
                    setBookingStatus(historyDetails?.statusMessage); //it is used to store the booking status
                  }}
                >
                  {activeBookingID === historyDetails?.id ? (
                    <div className="borderC780 bg191916 borderRadius12 mb-4 col-12 fontAntarctica fontWeight400">
                      <div className="d-flex flex-column flex-lg-row justify-content-between">
                        <div className="col-12 col-lg-6">
                          <div className="p-3">
                            <div>
                              <img
                                src={
                                  historyDetails?.carImage ||
                                  "https://s3.amazonaws.com/joulez-dev/3fc2c8c1-ca83-4801-9bbf-50ae0d59492c/fc842b92-d7f1-4ad8-a1b8-3f853c0872e1_file1"
                                }
                                alt="duplicateCarImage"
                                className="borderRadius4 width50 mx-auto d-block"
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center py-2">
                              <div className="d-flex align-items-center">
                                <div className="fontSize17">
                                  {historyDetails?.carName}
                                </div>
                                <div className="mx-2 fontSize17">
                                  {historyDetails?.carModel}
                                </div>
                                <div className="mx-2 fontSize13 opacity70">
                                  {historyDetails?.carType}
                                </div>
                              </div>
                              <div className="fontSize13 opacity70">
                                Booked for {historyDetails?.noOfDays}{" "}
                                {historyDetails?.noOfDays === 1 ||
                                historyDetails?.noOfDays === -1
                                  ? "Day"
                                  : "Days"}
                              </div>
                            </div>
                            <div className="mb-2">
                              <div className=" bg3B3936 p-3 borderRadius8 mb-2">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <div className="fontSize14">Pickup</div>
                                  <div className="fontSize13 fontWeight400">
                                    {moment(
                                      historyDetails?.pickupDateTime
                                    ).format("MMM DD, YYYY hh:mm A")}
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex">
                                    <img
                                      src={colorLocateIcon}
                                      alt="colorLocateIcon"
                                      className="colorLocateIcon"
                                    />
                                    <div className="mx-2 fontSize13 fontWeight400 opacity80">
                                      {historyDetails?.pickupLocation}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="bg3B3936 p-3 borderRadius8 mb-2">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <div className="fontSize14">Drop Off</div>
                                  <div className="fontSize13 fontWeight400">
                                    {moment(
                                      historyDetails?.dropOffDateTime
                                    ).format("MMM DD, YYYY hh:mm A")}
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex">
                                    <img
                                      src={colorLocateIcon}
                                      alt="colorLocateIcon"
                                      className="colorLocateIcon"
                                    />
                                    <div className="mx-2 fontSize13 fontWeight400 opacity80">
                                      {historyDetails?.dropOffLocation}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="p-3">
                            <div className="border3B3936 borderRadius8 mb-3">
                              <div className="d-flex justify-content-between align-items-center p-3">
                                <div className="fontSize14">
                                  {historyDetails?.bookingRefId}
                                </div>
                                <div
                                  className={`px-3 py-1
                               ${
                                 historyDetails?.statusMessage === "Upcoming"
                                   ? "bgCE1"
                                   : historyDetails?.statusMessage ===
                                     "Review Pending"
                                   ? "bg4ACC"
                                   : historyDetails?.statusMessage ===
                                     "On-Going"
                                   ? "bg653CC"
                                   : historyDetails?.statusMessage ===
                                     "Completed"
                                   ? "bgD0C7 text-dark"
                                   : historyDetails?.statusMessage ===
                                     "Cancelled"
                                   ? "bgB5757"
                                   : historyDetails?.statusMessage ===
                                     "Rejected"
                                   ? "bgB5757"
                                   : ""
                               }  borderRadius4 fontSize13`}
                                >
                                  {historyDetails?.statusMessage}
                                </div>
                              </div>
                            </div>
                            <div className="mb-3 fontSize14 bg3680 p-2 borderRadius8 fontAntarctica">
                              {historyDetails?.status === 13 && (
                                <div className="d-inline-block p-2">
                                  Your booking is currently pending, awaiting
                                  for necessary documents for verification.
                                  Please provide the below documents.
                                </div>
                              )}
                              {historyDetails?.status === 8 && (
                                <div>Your booking is cancelled.</div>
                              )}
                              {historyDetails?.status === 12 && (
                                <div>Your booking is rejected.</div>
                              )}
                              {historyDetails?.statusMessage === "Completed" && (
                                <div>Your booking is completed.</div>
                              )}
                              {historyDetails?.statusMessage === "On-Going" && (
                                <div>Your booking is On-Going.</div>
                              )}
                              {historyDetails?.statusMessage === "Upcoming" && (
                                <div>Your booking is Upcoming.</div>
                              )}
                            </div>
                            <div className="mb-3">
                              {historyDetails?.status === 8 ||
                              historyDetails?.status === 15 ||
                              historyDetails?.status === 12 ||
                              historyDetails?.status === 17 ? (
                                ""
                              ) : (
                                <div
                                  className={`${
                                    historyDetails?.licenseId
                                      ? "bg3680"
                                      : "bgF2994A33"
                                  } d-flex justify-content-between align-items-center p-3 borderRadius12 mb-3`}
                                >
                                  <div className="fontSize14">
                                    Driver License
                                  </div>
                                  <div className={`fontSize13 `}>
                                    {historyDetails?.licenseId &&
                                    licenseDetails?.data ? (
                                      "Verified"
                                    ) : !historyDetails?.licenseId &&
                                      licenseDetails?.data ? (
                                      <>
                                        <span
                                          className="cursorPointer 
                                        text-decoration-underline"
                                          onClick={() =>
                                            handleLinkLicense(
                                              historyDetails?.id
                                            )
                                          }
                                        >
                                          Link Now
                                        </span>{" "}
                                        &nbsp;&nbsp;
                                        {showDeleteLicense && (
                                          <span
                                            className="cursorPointer text-decoration-underline"
                                            onClick={() => {
                                              openModal("DeleteDrivingLicense");
                                            }}
                                          >
                                            Delete License
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <span
                                        className="cursorPointer text-decoration-underline"
                                        onClick={() =>
                                          openModal("DrivingLicenseModal")
                                        }
                                      >
                                        Upload Now
                                      </span>
                                    )}{" "}
                                    <span>
                                      <img
                                        className="mx-2"
                                        src={
                                          historyDetails?.licenseId &&
                                          licenseDetails?.data
                                            ? greenTickIcon
                                            : InfoIcon
                                        }
                                        alt="InfoIcon"
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                              {historyDetails?.status === 8 ||
                              historyDetails?.status === 15 ||
                              historyDetails?.status === 12 ||
                              historyDetails?.status === 17 ||
                              !historyDetails?.licenseId ? (
                                ""
                              ) : (
                                <div
                                  className={`${
                                    historyDetails?.isMailingAddressLinked
                                      ? "bg3680"
                                      : "bgF2994A33"
                                  }  d-flex justify-content-between align-items-center p-3 borderRadius12 mb-3`}
                                >
                                  <div className="fontSize14">
                                    Mailing Address
                                  </div>
                                  {historyDetails?.isMailingAddressLinked ? (
                                    <div className="fontSize13">
                                      Verified
                                      <img
                                        className="mx-2"
                                        src={greenTickIcon}
                                        alt="greenTickIcon"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        openModal("SelectMailingAddress")
                                      }
                                      className="fontSize13 text-decoration-underline cursorPointer"
                                    >
                                      Add Address{" "}
                                      <span>
                                        <img
                                          className="mx-2"
                                          src={InfoIcon}
                                          alt="InfoIcon"
                                        />
                                      </span>
                                    </div>
                                  )}
                                </div>
                                // historyDetails?.licenseId && <div
                                //   className={`${
                                //     historyDetails?.isMailingAddressLinked
                                //       ? "bg3680"
                                //       : "bgF2994A33"
                                //   }  d-flex justify-content-between align-items-center p-3 borderRadius12 mb-3`}
                                // >
                                //   <div className="fontSize14">
                                //     Mailing Address
                                //   </div>
                                //   {historyDetails?.isMailingAddressLinked ? (
                                //     <div className="fontSize13">
                                //       Verified
                                //       <img
                                //         className="mx-1"
                                //         src={greenTickIcon}
                                //         alt="greenTickIcon"
                                //       />
                                //     </div>
                                //   ) : (
                                //     <div
                                //       onClick={() =>
                                //         openModal("AddMailingAddress")
                                //       }
                                //       className="fontSize13 text-decoration-underline"
                                //     >
                                //       Add Address{" "}
                                //       <span>
                                //         <img
                                //           className="mx-2"
                                //           src={InfoIcon}
                                //           alt="InfoIcon"
                                //         />
                                //       </span>
                                //     </div>
                                //   )}
                                // </div>
                              )}

                              {/*Delete Driving license Modal */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes(
                                  "DeleteDrivingLicense"
                                )}
                                closeModal={() =>
                                  closeModal("DeleteDrivingLicense")
                                }
                                closeOnOutsideClick={true}
                                modalCenter={true}
                                modalId="DeleteDrivingLicense"
                                modalButton=""
                                modalTitle={
                                  <div className="p-3 text-center fontSize14">
                                    Are you sure do you want to delete Driver
                                    License details?
                                  </div>
                                }
                              >
                                <div className="d-flex justify-content-between w-100">
                                  <button
                                    onClick={() =>
                                      closeModal("DeleteDrivingLicense")
                                    }
                                    className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgB5757 opacity-75 text-white"
                                  >
                                    Cancel
                                  </button>

                                  <button
                                    onClick={() =>
                                      deleteLicense(licenseDetails?.data?.id)
                                    }
                                    className="accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white"
                                  >
                                    {deleteLicenseLoader ? (
                                      <SpinnerLoader />
                                    ) : (
                                      "Confirm"
                                    )}
                                  </button>
                                </div>
                              </DesktopCustomModal>
                              {/*Select Mailing Address Modal */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes(
                                  "SelectMailingAddress"
                                )}
                                closeModal={() =>
                                  closeModal("SelectMailingAddress")
                                }
                                closeOnOutsideClick={true}
                                modalCenter={true}
                                modalId="SelectMailingAddress"
                                isCloseIconRequired={true}
                                modalClassHeight="addMailingaddressHeightModal"
                                modalButton=""
                                modalTitle={
                                  <div className="p-3 text-center text-white fontSize14">
                                    Mailing Address
                                  </div>
                                }
                              >
                                <div className="d-flex flex-wrap p-2">
                                  {saveAllAddress?.length > 0 &&
                                    saveAllAddress?.map((details) => {
                                      return (
                                        <div
                                          key={details?.id}
                                          onClick={() =>
                                            setSpecificAddress(details)
                                          }
                                          className={`${
                                            specificAddress?.id === details?.id
                                              ? "borderE1"
                                              : ""
                                          } width48 border3B3936 p-2 m-1 rounded rounded-3 cursorPointer`}
                                        >
                                          <div className="p-2 fontSize13 fontAntarctica">
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                <label>
                                                  {details?.addressLabel}
                                                </label>
                                              </div>
                                              <div>
                                                <input
                                                  onChange={() => {}}
                                                  type="radio"
                                                  checked={
                                                    specificAddress?.id ===
                                                    details?.id
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="mt-3 opacity">
                                              <div>{details?.flatNo}</div>
                                              <div>
                                                {details?.city} {details?.state}
                                              </div>
                                              <div>{details?.country}</div>
                                              <div>{details?.zip}</div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  <div className="d-flex justify-content-between w-100 mt-2">
                                    <button
                                      onClick={() => {
                                        closeModal("SelectMailingAddress");
                                        openModal("AddMailingAddress");
                                      }}
                                      className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 opacity-75 text-white"
                                    >
                                      Add New Address
                                    </button>

                                    <button
                                      onClick={() => {
                                        handleProceedMailingAddress(
                                          specificAddress,
                                          false
                                        );
                                      }}
                                      className={`${
                                        specificAddress === "" &&
                                        "disabled-class"
                                      } accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white`}
                                    >
                                      {addMailingAddressLoading ? (
                                        <SpinnerLoader />
                                      ) : (
                                        "Proceed"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </DesktopCustomModal>
                              {/*Add Mail address Modal */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes(
                                  "AddMailingAddress"
                                )}
                                closeModal={() =>
                                  closeModal("AddMailingAddress")
                                }
                                closeOnOutsideClick={true}
                                modalCenter={true}
                                modalId="AddMailingAddress"
                                isCloseIconRequired={true}
                                modalButton=""
                                modalTitle={
                                  <div className="p-3 text-center text-white fontSize14">
                                    Add New Address
                                  </div>
                                }
                              >
                                <div className="p-2">
                                  <AddressAutocomplete
                                  errors={errors}
                                  setErrors={setErrors}
                                    addressDetails={addressDetails}
                                    setAddressDetails={setAddressDetails}
                                  />
                                  <div className="d-flex justify-content-between w-100 mt-2">
                                    <button
                                      onClick={() => {
                                        openModal("SelectMailingAddress");
                                      }}
                                      className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 opacity-75 text-white"
                                    >
                                      Select from Saved Addresses
                                    </button>

                                    <button
                                      onClick={() => {
                                        handleProceedMailingAddress(
                                          addressDetails,
                                          true
                                        );
                                      }}
                                      className={`accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white`}
                                    >
                                      Add Address and Proceed
                                    </button>
                                  </div>
                                </div>
                              </DesktopCustomModal>
                              {/* if rental coverage is aleardy added in the booking we will show this one  */}
                              {historyDetails?.status === 8 ||
                              historyDetails?.status === 15 ||
                              historyDetails?.status === 12 ||
                              historyDetails?.status === 17 ? (
                                ""
                              ) : (
                                <div
                                  className={`${
                                    historyDetails?.selectedPlans?.length > 0 &&
                                    !historyDetails?.checkRentalDoc
                                      ? "bg3680"
                                      : "bgF2994A33"
                                  }  d-flex justify-content-between align-items-center p-3 borderRadius12 mb-3`}
                                >
                                  <div className="fontSize14">
                                    Rental Coverage
                                  </div>
                                  <div
                                    className={`${
                                      historyDetails?.selectedPlans?.length > 0
                                        ? ""
                                        : "text-decoration-underline"
                                    } fontSize13 d-flex align-items-center`}
                                  >
                                    {historyDetails?.selectedPlans?.length ===
                                      0 && (
                                      <div
                                        onClick={() => {
                                          openModal("RentalOptions");
                                          fetchData();
                                          fetchCardDetails();
                                          localStorage.setItem("openModalAfterAxle", JSON.stringify(true));
                                          
                                        }}
                                        className="d-flex cursorPointer"
                                      >
                                        <div className="d-none d-lg-block">
                                          Select Rental
                                        </div>{" "}
                                        <div className="mx-1">Coverage</div>{" "}
                                        {/* <div>
                                          <img
                                            className="mx-2"
                                            src={
                                              historyDetails?.selectedPlans
                                                ?.length &&
                                              !historyDetails?.checkRentalDoc
                                                ? greenTickIcon
                                                : InfoIcon
                                            }
                                            alt="InfoIcon"
                                          />
                                        </div> */}
                                      </div>
                                    )}
                                    <div className="d-flex flex-column">
                                      {historyDetails?.selectedPlans?.length >
                                        0 && (
                                        <div className="d-flex mb-1">
                                          <div>
                                            {historyDetails?.selectedPlans?.map(
                                              (details) => (
                                                <div key={details?.planId}>
                                                  {details?.planName}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      {historyDetails?.checkRentalDoc && (
                                        <div
                                          onClick={() => {
                                            // openModal("RentalOptions");
                                            fetchCardDetails();
                                            openModal(
                                              "RentalCoverageModal",
                                              true
                                            );
                                            localStorage.setItem("openModalAfterAxle",JSON.stringify(false));
                                            setBookingCheckRentalDoc(
                                              historyDetails?.checkRentalDoc
                                            );
          
                                          }}
                                          className="d-flex cursorPointer text-decoration-underline"
                                        >
                                          <div className="">
                                            Add Rental Coverage 
                                          </div>
                                          {/* <div>
                                            <img
                                              className="mx-2"
                                              src={
                                                historyDetails?.selectedPlans
                                                  ?.length > 0 &&
                                                !historyDetails?.checkRentalDoc
                                                  ? greenTickIcon
                                                  : InfoIcon
                                              }
                                              alt="InfoIcon"
                                            />
                                          </div> */}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <img
                                        className="mx-2"
                                        src={
                                          historyDetails?.selectedPlans
                                            ?.length &&
                                          !historyDetails?.checkRentalDoc
                                            ? greenTickIcon
                                            : InfoIcon
                                        }
                                        alt="InfoIcon"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* {historyDetails?.teslaCar &&
                              <div
                                  className={`bg3680 d-flex justify-content-between align-items-center p-3 borderRadius12 mb-3`}
                                >
                                  <div className="fontSize14">
                                    Tesla Supercharges
                                  </div>
                                  <div
                                    className={`fontSize13 d-flex align-items-center`}
                                    onClick={() => {
                                      getTeslaSuperCharges(historyDetails?.id)
                                      // openModal("TeslaSuperCharge")
                                    }}
                                  >
                                      <div
                                        className="d-flex cursorPointer"
                                      >
                                        <div className="text-decoration-underline">
                                          View Transactions
                                        </div>
                                      </div>
                                    <div>
                                      <img
                                        className="mx-2"
                                        src={viewTransactionsIcon}
                                        alt="InfoIcon"
                                      />
                                    </div>
                                  </div>
                                </div>
                                  } */}
                                 {/* When you click TeslaSuperCharge the transcations modal will be opened */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes("TeslaSuperCharge")}
                                closeModal={() => closeModal("TeslaSuperCharge")}
                                closeOnOutsideClick={false}
                                modalId="TeslaSuperCharge"
                                modalTitle={
                                  <div className="fontColorCE1 p-3">
                                  View Tesla Supercharges for {historyDetails?.bookingRefId}
                                  </div>
                                }
                                modalButton=""
                                modalCenter={true}
                                isCloseIconRequired={true}
                                modalClassHeight="modalSuperChargeHeight"
                                modalClassWidth="modalSuperChargeWidth"
                              >
                                <div className="mb-3">
                               {teslaChargeInfo?.length > 0 ?  
                               teslaChargeInfo?.map((data) => {
                                return(
                                  <div className="superCharge-details-card" key={data?.sessionId}>
                                <div className="fontAntarctica">
                                  <div className="row">
                                    <div className="col-md-5 timeline-container">
                                      <div className="timeline-line"></div>
                                      <div className="timeline-point top"></div>
                                      <div className="px-4 timelineTransactionTop">
                                        <span className="fontSize13">{data?.chargeStartDateTime}</span>
                                      </div>
                                      <div className="timeline-point bottom"></div>
                                      <div className="px-4 timelineTransactionBottom">
                                        <span className="fontSize13">{data?.chargeStopDateTime}</span>
                                      </div>
                                    </div>
                                    <div className="col-md-7 p-3">
                                      <div className="d-flex flex-column mb-2">
                                        <div className="fontSize13 fontWeight300">Total Amount: </div>
                                        <div className="fontSize17"> $ {data?.chargingFees[0]?.totalDue}</div>
                                      </div>
                                      <div className="mb-3">
                                        <span className="fontSize13 fontWeight300">Charging location</span>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                        <div className="fontSize17">{data?.siteLocationName}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                )
                               }) :
                               <div className="d-flex justify-content-center align-items-center">
                                <div className="fontAntarctica fontSize17">There are no charges updated yet.</div>
                               </div>
                               }
                               </div>
                               {teslaChargeInfo?.length > 0 && <div className="d-flex justify-content-between bg3B3936 p-2 br8">
                                  <div>Total Payable</div>
                                   <div>$ Total Amount</div>
                               </div>}
                              </DesktopCustomModal>
                              {/* this is a rental coverage option shown in the modal */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes(
                                  "RentalOptions"
                                )}
                                closeModal={() => closeModal("RentalOptions")}
                                closeOnOutsideClick={false}
                                modalId="RentalOptions"
                                modalTitle={
                                  <div className="fontColorCE1 px-4">
                                    Rental Coverage
                                  </div>
                                }
                                modalButton=""
                                modalCenter={true}
                                isCloseIconRequired={true}
                                modalClassWidth="myTripPageModalRentalCoverageOptions"
                              >
                                <div>
                                  {insuranceLoading ? (
                                    <div>
                                      <SpinnerLoader />
                                    </div>
                                  ) : insuranceError ? (
                                    <div>{insuranceError}</div>
                                  ) : isNext ? (
                                    
                                    cardDetailsSpinner ? (
                                      <div>
                                        <SpinnerLoader />
                                      </div>
                                    ) :
                                     (<div className="">
                                      <div
                                        key={cardDetails?.id}
                                        className={`borderE1 p-4 lineheight21 mb-3 border3B3936 borderRadius8 d-flex justify-content-between align-items-center`}
                                      >
                                        <div className=" fontSize13 fontWeight400 fontAntarctica ">
                                          <div className="mb-1 fontColor5BD text-nowrap">
                                            Card Number
                                            <span className="text-white mx-2 text-nowrap">
                                              XXXX XXXX XXXX{" "}
                                              {cardDetails?.cardNumber}
                                            </span>
                                          </div>
                                          <div className="mb-1 fontColor5BD">
                                            Card Holder Name{" "}
                                            <span className="text-white mx-2">
                                              {cardDetails?.name}
                                            </span>
                                          </div>
                                          <div className="mb-1 fontColor5BD">
                                            Expiry Date{" "}
                                            <span className="text-white mx-2">
                                              {cardDetails?.expiryDate}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="py-2">
                                          <div className="d-flex justify-content-end p-1 mb-4">
                                            <input
                                              type="radio"
                                              checked={true}
                                              onChange={() =>
                                                setSelectedCreditId(
                                                  cardDetails?.id
                                                )
                                              }
                                            />
                                          </div>
                                          {/* <div className="bgE933 p-4 borderRadius8"> */}
                                          {/* {cardDetails?.cardIssuedBy} */}
                                          <img
                                            className="mx-1 height35px"
                                            src={
                                              images[
                                                cardDetails?.cardIssuedBy
                                              ] || images.defaultCard
                                            }
                                            alt="images"
                                          />
                                          {/* </div> */}
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-end">
                                        <button
                                          onClick={() => setIsNext(false)}
                                          className="mx-1 bgCE1 text-white border-0 px-3 py-1 borderRadius8 outlineNone"
                                        >
                                          Prev
                                        </button>
                                        <button
                                          onClick={() =>
                                            closeModal("RentalOptions")
                                          }
                                          className="mx-1 bgCE1 text-white border-0 px-3 py-1 borderRadius8 outlineNone"
                                        >
                                          Close
                                        </button>
                                        <button
                                          onClick={() => {
                                            insuranceAndAdditionalCoveragePayment();
                                            // toast(rentalPaymentResponse);
                                          }}
                                          className="mx-1 bgCE1 text-white border-0 px-3 py-1 borderRadius8 outlineNone"
                                        >
                                          {rentalPaymentResponseLoading ? (
                                            <SpinnerLoader />
                                          ) : (
                                            "Accept"
                                          )}
                                        </button>
                                      </div>
                                    </div>)
                                        

           
                                  ) : (
                                    <div>
                                      <div className="mb-2 d-flex justify-content-between align-items-stretch align-items-center flex-wrap">
                                        {insurancePlanData?.rentalCoverage?.map(
                                          (item) => {
                                            return (
                                              <div
                                                onClick={() => {
                                                  handleDetailsSelect(
                                                    "rentalCoverageId",
                                                    item
                                                  );
                                                }}
                                                key={item?.id}
                                                className={`myTripRentalCoverageBoxWidth border3B3936 p-2 m-1 rounded rounded-3 cursorPointer
                                            ${
                                              rentalID?.rentalCoverageId?.includes(
                                                item?.id
                                              )
                                                ? "borderE1"
                                                : ""
                                            } ${
                                                  item?.id === 1 &&
                                                  isId1Disabled
                                                    ? "disableRentalCard1"
                                                    : ""
                                                } 
                                            `}
                                              >
                                                <div className="fontSize13 fontAntarctica d-flex justify-content-between align-items-center">
                                                  <div className="p-2  gap2 d-flex justify-content-between">
                                                    {item?.id === 1 ? (
                                                      <input
                                                        onChange={() => {}} //we put this just to ignore the console errors
                                                        type="checkbox"
                                                        id={`checkbox-${item?.id}`}
                                                        checked={
                                                          rentalID?.rentalCoverageId?.includes(
                                                            item?.id
                                                          )
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        type="radio"
                                                        id={`radio-${item?.id}`}
                                                        name="coverages"
                                                        value={item?.title}
                                                        onChange={() => {}} //we put this just to ignore the console errors
                                                        checked={
                                                          rentalID?.rentalCoverageId?.includes(
                                                            item?.id
                                                          )
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                    )}{" "}
                                                    <label>{item?.title}</label>
                                                  </div>
                                                  {item?.title ===
                                                    "Standard Damage Coverage" && (
                                                    <span className="rentalPopular2">
                                                      Most popular
                                                    </span>
                                                  )}
                                                  <div>
                                                    {item?.description && (
                                                      <Overlay
                                                        dataBsTitle={
                                                          item?.description ||
                                                          ""
                                                        }
                                                        direction="top"
                                                      >
                                                        <img
                                                          src={greyInfoIcon}
                                                          alt="greyInfoIcon"
                                                        />
                                                      </Overlay>
                                                    )}
                                                  </div>
                                                </div>
                                                {item?.description
                                                  .split("\r\n")
                                                  .map((point, index) => (
                                                    <ul
                                                      key={index}
                                                      className="mb-1 px-4"
                                                    >
                                                      <li className="fontSize10 fontAntarctica opacity ">
                                                        {point}
                                                      </li>
                                                    </ul>
                                                  ))}
                                                {item?.id === 1 && (
                                                  <span className="fontSize10 fontAntarctica  fontColor994A">
                                                    CAUTION: Does not cover
                                                    damage to the rented
                                                    vehicle.
                                                  </span>
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        {insurancePlanData?.additionalCoverage?.map(
                                          (item) => {
                                            return (
                                              <div
                                                onClick={() => {
                                                  handleDetailsSelect(
                                                    "additionalCoverageIds",
                                                    item
                                                  );
                                                }}
                                                key={item?.id}
                                                className={`w-100 border3B3936 p-2 m-1 rounded rounded-3  ${
                                                  rentalID?.additionalCoverageIds?.includes(
                                                    item?.id
                                                  )
                                                    ? "borderE1"
                                                    : ""
                                                }`}
                                              >
                                                <div className="fontSize13 fontAntarctica d-flex justify-content-between align-items-center">
                                                  <div className="p-2 gap2 d-flex justify-content-between">
                                                    <input
                                                      onChange={() => {}}
                                                      type="checkbox"
                                                      id={item?.title}
                                                      name="coverages"
                                                      value={item?.title}
                                                      checked={
                                                        rentalID?.additionalCoverageIds?.includes(
                                                          item?.id
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                    />{" "}
                                                    <label>{item?.title}</label>
                                                  </div>
                                                  <div>
                                                    {item?.description && (
                                                      <Overlay
                                                        dataBsTitle={
                                                          item?.description ||
                                                          ""
                                                        }
                                                        direction="top"
                                                      >
                                                        <img
                                                          src={greyInfoIcon}
                                                          alt="greyInfoIcon"
                                                        />
                                                      </Overlay>
                                                    )}
                                                  </div>
                                                </div>
                                                {item?.description
                                                  .split("\r\n")
                                                  .map((point, index) => (
                                                    <ul
                                                      key={index}
                                                      className="mb-1 px-4"
                                                    >
                                                      <li className="fontSize9 fontAntarctica  opacity">
                                                        {point}
                                                      </li>
                                                    </ul>
                                                  ))}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                      <div
                                        className={`${
                                          rentalID?.rentalCoverageId?.length ===
                                          0
                                            ? "disabled-class"
                                            : ""
                                        } d-flex justify-content-end w-100 p-2`}
                                      >
                                        {/* <button
                                            onClick={() => {
                                              if (
                                                rentalID?.rentalCoverageId
                                                  ?.length > 0
                                              ) {
                                                setIsNext(true);
                                              }
                                            }}
                                            className="bgCE1 text-white border-0 px-3 py-1 borderRadius8 outlineNone"
                                          >
                                           {selectedDocId && !rentalID?.rentalCoverageId?.includes(1) && !rentalID?.rentalCoverageId?.includes(2) && !rentalID?.rentalCoverageId?.includes(3) ? "Accept" : "Next"}
                                          </button> */}
                                        {selectedDocId &&
                                        !rentalID?.rentalCoverageId?.includes(
                                          1
                                        ) &&
                                        !rentalID?.rentalCoverageId?.includes(
                                          2
                                        ) &&
                                        !rentalID?.rentalCoverageId?.includes(
                                          3
                                        ) &&
                                        rentalID?.additionalCoverageIds
                                          ?.length === 0 ? (
                                          <button
                                            onClick={() => {
                                              if (
                                                rentalID?.rentalCoverageId
                                                  ?.length > 0
                                              ) {
                                                setIsNext(true);
                                              }
                                            }}
                                            className="bgCE1 text-white border-0 px-3 py-1 borderRadius8 outlineNone"
                                          >
                                            Accept
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() => {
                                              if (
                                                rentalID?.rentalCoverageId
                                                  ?.length > 0
                                              ) {
                                                setIsNext(true);
                                                fetchCardDetails()
                                              }
                                            }}
                                            className="bgCE1 text-white border-0 px-3 py-1 borderRadius8 outlineNone"
                                          >
                                            Next
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </DesktopCustomModal>
                              {/* here DesktopModal is rendered here to open when user clicks on I have my own option */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes(
                                  "RentalCoverageModal"
                                )}
                                closeModal={() => {
                                  closeModal("RentalCoverageModal");
                                  handleZIndex(false);
                                }} // Pass the modalId}
                                modalId="RentalCoverageModal"
                                modalClassHeight="modalHeightOwnCoverage"
                                modalTitle={
                                  isCreditCardClicked ? (
                                    <div className="mt-2 p-2">
                                      I have my own Credit Card Coverage
                                    </div>
                                  ) : isInsuranceClicked ? (
                                    <div className="mt-2 p-2">
                                      I have my own Insurance coverage
                                    </div>
                                  ) : (
                                    <div className="mt-2 p-2">
                                      I have my own coverage
                                    </div>
                                  )
                                }
                                modalButton=""
                                closeOnOutsideClick={false}
                                isCloseIconRequired={true}
                                isHRLineRequired={true}
                                modalContent={
                                  isInsuranceClicked || isCreditCardClicked ? (
                                    <div className="d-flex w-100">
                                      <button
                                        className="bg3B3936 outlineNone fontcolorFFF w-100 border-0 p-2 borderRadius8"
                                        onClick={() => {
                                          setSelectedCreditId("");
                                          setSelectedDocId("");
                                          if (isSelectCoverage) {
                                            setIsCreditCardClicked(false);
                                            setIsInsuranceClicked(true);
                                            setIsSelectCoverage(false);
                                          } else if (isInsuranceClicked) {
                                            setIsInsuranceClicked(false);
                                          }

                                          if (isCreditCardClicked) {
                                            setIsCreditCardClicked(false);
                                          }

                                          if (isSelectedCreditCard) {
                                            setIsSelectedCreditCard(false);
                                            setIsInsuranceClicked(false);
                                            setIsCreditCardClicked(true);
                                          }
                                        }}
                                      >
                                        Go Back
                                      </button>
                                      {isCreditCardClicked ? (
                                        isSelectedCreditCard ? (
                                          <button
                                            onClick={() => {
                                              // closeModal("");
                                              // setIsCreditCardClicked(false);
                                              // setIsSelectedCreditCard(false);
                                              setSelectedCreditId("");
                                              setIsId1Disabled(true);
                                              handleZIndex(false);
                                              linkProtectionPlan();
                                              // toast("Rental Coverage added successfully")
                                            }}
                                            className="bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8"
                                          >
                                            Continue Card
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() => {
                                              if (selectedCreditId !== "") {
                                                setIsSelectedCreditCard(true);
                                                if (
                                                  !rentalID?.rentalCoverageId?.includes(
                                                    1
                                                  )
                                                ) {
                                                  handleDetailsSelect(
                                                    "rentalCoverageId",
                                                    staticValue
                                                  );
                                                }
                                              }
                                            }}
                                            className={`${
                                              selectedCreditId
                                                ? ""
                                                : "disabled-class"
                                            } bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8`}
                                          >
                                            Select Coverage
                                          </button>
                                        )
                                      ) : isSelectCoverage ? (
                                        <button
                                          onClick={() => {
                                            // closeModal("");
                                            linkProtectionPlan();
                                            // setIsInsuranceClicked(false);
                                            // setIsSelectCoverage(false);
                                            // setSelectedDocId("");
                                            handleZIndex(false);
                                          }}
                                          className="bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8"
                                        >
                                          Continue Coverage
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            if (selectedDocId?.id !== "") {
                                              setIsSelectCoverage(true);
                                            }
                                          }}
                                          className={`${
                                            selectedDocId
                                              ? ""
                                              : "disabled-class"
                                          } bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8`}
                                        >
                                          Select Coverage
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="d-flex w-100">
                                      <button
                                        onClick={() => {
                                          closeModal("RentalCoverageModal");
                                          setSelectedDocId("");
                                          setSelectedCreditId("");
                                          setIsSelectedCreditCard(false);
                                          handleZIndex(false);
                                          // if (
                                          //   rentalID?.rentalCoverageId?.includes(
                                          //     1
                                          //   )
                                          // ) {
                                          //   handleDetailsSelect(
                                          //     "rentalCoverageId",
                                          //     staticValue
                                          //   );
                                          // }
                                        }}
                                        className="bg3B3936 outlineNone fontcolorFFF w-100 border-0 p-2 borderRadius8"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        onClick={() =>
                                          setIsInsuranceClicked(true)
                                        }
                                        className="bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8"
                                      >
                                        Continue
                                      </button>
                                    </div>
                                  )
                                }
                              >
                                <div>
                                  {(isInsuranceClicked ||
                                    isCreditCardClicked) &&
                                  !isSelectCoverage &&
                                  !isSelectedCreditCard ? (
                                    <div>
                                      <div className="d-flex justify-content-between align-items-center fontAntarctica bg3B3936 borderRadius8">
                                        <button
                                          onClick={() => {
                                            setIsCreditCardClicked(false);
                                            setIsInsuranceClicked(true);
                                          }}
                                          className={`${
                                            isInsuranceClicked &&
                                            isCreditCardClicked === false
                                              ? "bgCE1"
                                              : "bg3B3936"
                                          } fontcolorFFF fontSize13 border-0 p-2 m-1 w-100 borderRadius8`}
                                        >
                                          Insurance
                                        </button>
                                        <button
                                          onClick={() => {
                                            setIsCreditCardClicked(true);
                                            setIsInsuranceClicked(false);
                                            fetchCardDetails()
                                          }}
                                          className={`${
                                            isCreditCardClicked
                                              ? "bgCE1"
                                              : "bg3B3936"
                                          } fontcolorFFF fontSize13 border-0 p-2 m-1 w-100 borderRadius8`}
                                        >
                                          Credit Card
                                        </button>
                                      </div>
                                      {isCreditCardClicked ? (
                                        <div>
                                          <div className="mt-2">
                                            <div>
                                              If you're using credit card
                                              coverage, just make sure to use
                                              the same card for payments.
                                            </div>
                                            <div className="py-2">
                                              Credit Cards
                                            </div>
                                            {cardDetailsSpinner ? <SpinnerLoader /> : 
                                              <div>
                                              <div>
                                                <div>
                                                  <div
                                                    onClick={() => {
                                                      setSelectedCreditId(
                                                        cardDetails?.id
                                                      );
                                                    }}
                                                    key={cardDetails?.id}
                                                    className={`${
                                                      selectedCreditId ===
                                                      cardDetails?.id
                                                        ? "borderE1"
                                                        : ""
                                                    } p-3 mb-3 border3B3936 borderRadius8 d-flex justify-content-between align-items-center`}
                                                  >
                                                    <div className=" fontSize13 lineheight21 fontWeight400 fontAntarctica ">
                                                      <div className="mb-1 fontColor5BD">
                                                        Card Number
                                                        <span className="text-white mx-2">
                                                          XXXX XXXX XXXX{" "}
                                                          {
                                                            cardDetails?.cardNumber
                                                          }
                                                        </span>
                                                      </div>
                                                      <div className="mb-1 fontColor5BD">
                                                        Card Holder Name{" "}
                                                        <span className="text-white mx-2">
                                                          {cardDetails?.name}
                                                        </span>
                                                      </div>
                                                      <div className="mb-1 fontColor5BD">
                                                        Expiry Date{" "}
                                                        <span className="text-white mx-2">
                                                          {
                                                            cardDetails?.expiryDate
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="py-2">
                                                      <div className="d-flex justify-content-end mb-4 p-1">
                                                        <input
                                                          type="radio"
                                                          checked={
                                                            selectedCreditId ===
                                                            cardDetails?.id
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={() =>
                                                            setSelectedCreditId(
                                                              cardDetails?.id
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      {/* <div className="bgE933 p-4 borderRadius8"> */}
                                                      {/* {
                                                            cardDetails?.cardIssuedBy
                                                          } */}
                                                      <img
                                                        className="mx-1 height35px"
                                                        src={
                                                          images[
                                                            cardDetails
                                                              ?.cardIssuedBy
                                                          ] ||
                                                          images.defaultCard
                                                        }
                                                        alt="images"
                                                      />
                                                      {/* </div> */}
                                                    </div>
                                                  </div>
                                                  {/* <button
                                                      onClick={() => {
                                                        openModal(
                                                          "AddNewCard",
                                                          true
                                                        );
                                                        setSelectedCreditId("");
                                                      }}
                                                      className="mt-2 px-3 py-2 text-white bg3B3936 borderRadius8 border-0 fontSize13"
                                                    >
                                                      Add New Card
                                                    </button> */}
                                                </div>
                                                <div>{cardDError}</div>
                                              </div>
                                            </div>}
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div>
                                            <div className="py-2 fontAntarctica fontSize17">
                                              Insurances
                                            </div>
                                            <div>
                                              {userInsuranceDocs?.map(
                                                (details) => {
                                                  const isSelected =
                                                    selectedDocId?.id ===
                                                    details?.id;
                                                  return (
                                                    <div
                                                      onClick={() => {
                                                        setSelectedDocId(
                                                          details
                                                        );
                                                        setInsuranceInputValues(
                                                          (prev) => ({
                                                            ...prev,
                                                            documentType: "I",
                                                            policyNumber: "",
                                                            issueState: "",
                                                            provider: "",
                                                            expiryDate: "",
                                                            documentName: "",
                                                          })
                                                        );
                                                      }}
                                                      key={details?.id}
                                                      className={`p-3 cursorPointer lineheight21 border3B3936 borderRadius8 d-flex justify-content-between align-items-center mb-3 ${
                                                        isSelected
                                                          ? "borderE1"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div className="fontSize13 p-2 fontWeight400 fontAntarctica">
                                                        <div className="mb-1 d-flex  align-items-center">
                                                          <p className="mb-0 opacity">
                                                            Policy Number
                                                          </p>
                                                          <span className="mx-1">
                                                            {
                                                              details?.documentId
                                                            }
                                                          </span>
                                                          {details?.isAxleVerified && <span>
                                                          <img src={axleSuccessLogo} alt="axleSuccessLogo" />
                                                           </span>}
                                                        </div>
                                                        <div className="mb-1 d-flex  align-items-center">
                                                          <p className="mb-0 opacity">
                                                            Issue State
                                                          </p>
                                                          <span className="mx-1">
                                                            {
                                                              details?.issueState
                                                            }
                                                          </span>
                                                        </div>
                                                        <div className="mb-1 d-flex  align-items-center">
                                                          <p className="mb-0 opacity">
                                                            Provider
                                                          </p>
                                                          <span className="mx-1">
                                                            {details?.provider}
                                                          </span>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                          <p className="mb-0 opacity">
                                                            Exp. Date
                                                          </p>
                                                          <span className="mx-1">
                                                            {
                                                              details?.docExpDate
                                                            }
                                                          </span>
                                                        </div>
                                                      </div>

                                                      {/* <div className=" fontSize13 fontWeight400 fontAntarctica "> */}
                                                      {/* <div className="mb-1">
                                                            Policy Number
                                                            <span className="mx-1 text-white">
                                                              {
                                                                details?.documentId
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="mb-1 fontColor5BD">
                                                            Issue State
                                                            <span className="mx-1 text-white">
                                                              {
                                                                details?.issueState
                                                              }
                                                            </span>
                                                          </div> */}
                                                      {/* <div className="mb-1">
                                                            Provider
                                                            <span className="mx-1 text-white">
                                                              {
                                                                details?.provider
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="fontColor5BD">
                                                            Exp. Date
                                                            <span className="mx-1 text-white">
                                                              {
                                                                details?.docExpDate
                                                              }
                                                            </span>
                                                          </div> */}
                                                      {/* </div> */}
                                                      <div>
                                                        <div className="p-1 borderRadius8">
                                                          <div className="d-flex justify-content-end py-2">
                                                            <input
                                                              type="radio"
                                                              name="insurance-doc"
                                                              checked={
                                                                isSelected
                                                              }
                                                              onChange={() =>
                                                                setSelectedDocId(
                                                                  details
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <img
                                                            className="insuranceImagePreview br2"
                                                            src={
                                                              details?.fileType ===
                                                                "application/octet-stream" ||
                                                              details?.fileType ===
                                                                "application/pdf"
                                                                ? latestPdfIcon
                                                                : details?.documentName
                                                            }
                                                            alt="insuranceDocIcon"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                            <div>{userInsuranceError}</div>
                                          </div>
                                          <div className="mt-2">
                                            <FileUploader
                                              label={
                                                <div className="fontAntarctica fontSize17">
                                                  Update New Coverage
                                                </div>
                                              }
                                              fileTextMessage={
                                                <div className="borderRadius8">
                                                  <img
                                                    src={insuranceDocIcon}
                                                    alt="insuranceDocIcon"
                                                  />
                                                </div>
                                              }
                                              removePreviewImage={
                                                insuranceInputValues?.documentName ===
                                                ""
                                                  ? true
                                                  : false
                                              }
                                              onFileChange={(file) => {
                                                setSelectedDocId("");
                                                setInsuranceInputValues(
                                                  (prevValues) => ({
                                                    ...prevValues,
                                                    documentName: file, // Store the compressed file
                                                  })
                                                );
                                              }}
                                              handleDeleteFile={
                                                handleDeleteFile
                                              }
                                            />
                                          </div>
                                          <div className="pt-3 input-group w-100">
                                            <form
                                              className="w-100"
                                              onSubmit={
                                                handleSubmitInsuranceDetails
                                              }
                                            >
                                              <div className="mb-3">
                                                <input
                                                  id="policyNumber"
                                                  placeholder="Policy Number"
                                                  type="text"
                                                  className="form-control supportFormFocus p-2 mb-3"
                                                  onChange={
                                                    handleChangeInsuranceDetails
                                                  }
                                                  value={
                                                    insuranceInputValues?.policyNumber
                                                  }
                                                />
                                                <select
                                                  id="issueState"
                                                  onChange={
                                                    handleChangeInsuranceDetails
                                                  }
                                                  className="form-control supportFormFocus p-2 mb-3"
                                                  value={
                                                    insuranceInputValues?.issueState
                                                  }
                                                >
                                                  <option value="">
                                                    Issue State
                                                  </option>
                                                  {storeStateNames[0]?.states?.map(
                                                    (item) => (
                                                      <option
                                                        key={item?.name}
                                                        value={item?.stateCode}
                                                      >
                                                        {item?.name}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                <input
                                                  id="provider"
                                                  placeholder="Provider"
                                                  type="text"
                                                  className="form-control supportFormFocus p-2 mb-3"
                                                  onChange={
                                                    handleChangeInsuranceDetails
                                                  }
                                                  value={
                                                    insuranceInputValues?.provider
                                                  }
                                                />
                                                {/* <input
                                                  id="expiryDate"
                                                  placeholder="Expiry Date"
                                                  type="date"
                                                  onFocus={() => setUpdateTextToDate(true)}
                                                  min={
                                                    new Date().toISOString().split("T")[0]
                                                  }
                                                  className="form-control supportFormFocus p-2 text-white"
                                                  onChange={
                                                    handleChangeInsuranceDetails
                                                  }
                                                  value={
                                                    insuranceInputValues?.expiryDate
                                                  }
                                                /> */}
                                                <DateOfBirthPicker
                                                id="expiryDate"
                                                minDate={
                                                    new Date()
                                                  }
                                                  value={
                                                    insuranceInputValues?.expiryDate
                                                  }
                                                  placeholder="Policy Expiration Date"
                                                  onDateSelect={handleChangeInsuranceDateDetails}
                                                />
                                              </div>
                                              <button
                                                disabled={
                                                  !(
                                                    insuranceInputValues?.documentName &&
                                                    insuranceInputValues?.policyNumber &&
                                                    insuranceInputValues?.issueState &&
                                                    insuranceInputValues?.provider &&
                                                    insuranceInputValues?.expiryDate
                                                  )
                                                }
                                                type="submit"
                                                className={`${
                                                  insuranceInputValues?.documentName !==
                                                    "" &&
                                                  insuranceInputValues?.policyNumber !==
                                                    "" &&
                                                  insuranceInputValues?.issueState !==
                                                    "" &&
                                                  insuranceInputValues?.provider !==
                                                    "" &&
                                                  insuranceInputValues?.expiryDate !==
                                                    ""
                                                    ? ""
                                                    : "opacity disabled"
                                                } w-100 mt-2 px-3 py-2 text-white fw-normal bg3B3936 borderRadius8 border-0 fontSize13`}
                                              >
                                                Upload
                                              </button>
                                            </form>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="pb-3 mt-3 margin110">
                                        Please provide your Insurance details
                                      </div>
                                      {isSelectedCreditCard ? (
                                        ""
                                      ) : (
                                        <div>
                                          {isAxleNeed === "true" ?
                                          <div
                                            // onClick={() =>
                                            //   setIsInsuranceClicked(true)
                                            // }
                                           
                                            onClick={() => getAxleLink()}
                                            className={`${
                                              isSelectCoverage
                                                ? "bgInsuranceSelected"
                                                : ""
                                            } d-flex justify-content-start align-items-center bg3B3936 p-3 borderRadius8 cursorPointer mb-1`}
                                          >
                                            <div>
                                              {/* <img
                                              className="mx-1"
                                              src={insuranceLogo}
                                              alt="insuranceLogo"
                                            /> */}
                                              <img
                                                className="mx-1"
                                                src={axleLogo}
                                                alt="axleLogo"
                                              />
                                            </div>
                                            <div className="InsuranceAndCreditPaddingLeft fontAntarctica d-flex justify-content-between align-items-center w-100">
                                              <div>
                                                <div className="fontSize17">
                                                  {/* Insurance Coverage */}
                                                  Axle Insurance Verification
                                                </div>
                                                <div className="fontSize13 fontColor5BD mt-1">
                                                  {/* Make sure the insurance provides
                                                rental coverage */}
                                                  Go Ahead with Axle to get
                                                  verified quickly.
                                                </div>
                                              </div>
                                              {isSelectCoverage ? (
                                                <div>
                                                  <img
                                                    src={greenTickIcon}
                                                    alt="greenTickIcon"
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                          :
                                          <div
                                          onClick={() =>
                                            setIsInsuranceClicked(true)
                                          }
                                            className={`${
                                              isSelectCoverage
                                                ? "bgInsuranceSelected"
                                                : ""
                                            } d-flex justify-content-start align-items-center bg3B3936 p-3 borderRadius8 cursorPointer mb-1`}
                                          >
                                            <div>
                                            <img
                                              className="mx-1"
                                              src={insuranceLogo}
                                              alt="insuranceLogo"
                                            /> 
                                            </div>
                                            <div className="InsuranceAndCreditPaddingLeft fontAntarctica d-flex justify-content-between align-items-center w-100">
                                              <div>
                                                <div className="fontSize17">
                                                  Insurance Coverage
                                                </div>
                                                <div className="fontSize13 fontColor5BD mt-1">
                                                  Make sure the insurance provides rental
                                                coverage
                                                </div>
                                              </div>
                                              {isSelectCoverage ? (
                                                <div>
                                                  <img
                                                    src={greenTickIcon}
                                                    alt="greenTickIcon"
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                  </div>
                                         </div>
                                          }
                                          {isAxleNeed === "true" && !isSelectCoverage && <div className="p-2 d-flex align-items-center fontAntarctica flex-wrap">
                                            <p className="opacity fontSize14 mb-0 me-1">
                                              If your Insurance provider is not
                                              listed in Axle please
                                            </p>
                                            <span
                                              className="cursorPointer fontSize14 fontColorCE1 text-decoration-none"
                                              onClick={() =>
                                                setIsInsuranceClicked(true)
                                              }
                                            >
                                              click here
                                            </span>
                                          </div>}
                                        </div>
                                      )}
                                      {isSelectCoverage && (
                                        <div>
                                          <div
                                            className={`p-2 cursorPointer border3B3936 borderRadius8 d-flex justify-content-between align-items-center mb-3 borderE1`}
                                          >
                                            <div className=" fontSize13 fontWeight400 fontAntarctica ">
                                              <div className="mb-1">
                                               <span className="opacity">Policy Number</span>
                                                <span className="mx-1">
                                                  {
                                                    filterSelectedInsuranceDoc?.documentId
                                                  }
                                                </span>
                                              </div>
                                              <div className="mb-1">
                                              <span className="opacity">Issue State</span>
                                                <span className="mx-1">
                                                  {
                                                    filterSelectedInsuranceDoc?.issueState
                                                  }
                                                </span>
                                              </div>
                                              <div className="mb-1">
                                              <span className="opacity">Provider</span>
                                                <span className="mx-1">
                                                  {
                                                    filterSelectedInsuranceDoc?.provider
                                                  }
                                                </span>
                                              </div>
                                              <div>
                                              <span className="opacity">Exp. Date</span>
                                                <span className="mx-1">
                                                  {
                                                    filterSelectedInsuranceDoc?.docExpDate
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                            <div>
                                              <div className="p-1 borderRadius8">
                                                <div className="d-flex justify-content-end py-2">
                                                  <input
                                                    type="radio"
                                                    name="insurance-doc"
                                                    checked={true}
                                                    onChange={() =>
                                                      setSelectedDocId(
                                                        filterSelectedInsuranceDoc?.id
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <img
                                                  className="insuranceImagePreview"
                                                  src={
                                                    filterSelectedInsuranceDoc?.fileType ===
                                                      "application/octet-stream" ||
                                                    filterSelectedInsuranceDoc?.fileType ===
                                                      "application/pdf"
                                                      ? latestPdfIcon
                                                      : filterSelectedInsuranceDoc?.documentName
                                                  }
                                                  alt="insuranceDocIcon"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {isSelectCoverage ||
                                      isSelectedCreditCard ? (
                                        ""
                                      ) : (
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="borderDash"></div>
                                          <div className="mx-3 fontColor5BD fontAntarctica">
                                            OR
                                          </div>
                                          <div className="borderDash"></div>
                                        </div>
                                      )}
                                      {isSelectCoverage ? (
                                        ""
                                      ) : (
                                        <div
                                          onClick={() =>{
                                            setIsCreditCardClicked(true)
                                            fetchCardDetails()
                                          }
                                          }
                                          className={`${
                                            isSelectedCreditCard
                                              ? "bgInsuranceSelected"
                                              : ""
                                          } d-flex justify-content-start align-items-center bg3B3936 p-3 borderRadius8 cursorPointer`}
                                        >
                                          <div>
                                            <img
                                              className="mx-1"
                                              src={creditCardLogo}
                                              alt="creditCardLogo"
                                            />
                                          </div>
                                          <div className="InsuranceAndCreditPaddingLeft fontAntarctica">
                                            <div className="fontSize17">
                                              Credit Card Coverage
                                            </div>
                                            <div className="fontSize13 mt-1 fontColor5BD">
                                              Don't forget to check if the
                                              credit card covers rental
                                              coverage.
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {isSelectedCreditCard && (
                                        <div>
                                          <div
                                            className={`borderE1 p-3 my-3 border3B3936 borderRadius8 d-flex justify-content-between align-items-center`}
                                          >
                                            <div className=" fontSize13 fontWeight400 fontAntarctica ">
                                              <div className="mb-1 fontColor5BD">
                                                Card Number
                                                <span className="text-white mx-2">
                                                  XXXX XXXX XXXX{" "}
                                                  {cardDetails?.cardNumber}
                                                </span>
                                              </div>
                                              <div className="mb-1 fontColor5BD">
                                                Card Holder Name{" "}
                                                <span className="text-white mx-2">
                                                  {cardDetails?.name}
                                                </span>
                                              </div>
                                              <div className="mb-1 fontColor5BD">
                                                Expiry Date{" "}
                                                <span className="text-white mx-2">
                                                  {cardDetails?.expiryDate}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="">
                                              <div className="d-flex justify-content-end  mb-3">
                                                <input
                                                  type="radio"
                                                  checked={
                                                    selectedCreditId ===
                                                    cardDetails?.id
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() =>
                                                    setSelectedCreditId(
                                                      cardDetails?.id
                                                    )
                                                  }
                                                />
                                              </div>
                                              {/* <div className="bgE933 p-4 borderRadius8"> */}
                                              {/* {cardDetails?.cardIssuedBy} */}
                                              <img
                                                className="mx-1 height35px"
                                                src={
                                                  images[
                                                    cardDetails?.cardIssuedBy
                                                  ] || images.defaultCard
                                                }
                                                alt="images"
                                              />
                                              {/* </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {isSelectCoverage ? "" : <hr />}
                                      <div className="fontAntarctica">
                                        <div className="mb-1 fontSize17 fontcolorFFF">
                                          Please keep in mind
                                        </div>
                                        <ul className="fontSize13 fontColor5BD">
                                          <li>
                                            Having liability coverage is
                                            required by law.
                                          </li>
                                          <li>
                                            If your card/insurance doesn't cover
                                            it, just add the minimum liability.
                                          </li>
                                          <li>
                                            If you cause any damage or injuries
                                            to others, that's on you to handle
                                            through your insurance.
                                          </li>
                                          <li className="px-1">
                                            Same goes for the rental car itself
                                            - any dings, dents, or theft issues
                                            are your responsibility.
                                          </li>
                                          <li>
                                            We are no longer accepting rental
                                            coverage purchased through{" "}
                                            &nbsp;&nbsp;
                                            <a
                                              href="https://rentalcover.com"
                                              rel="noopener noreferrer"
                                              target="_blank"
                                            >
                                              <u className="fontColorCE1">
                                                RentalCover.com
                                              </u>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                      {/* <div>
                                          <div
                                            onClick={() =>
                                              isSelectedCreditCard
                                                ? ""
                                                : handleDetailsSelect(
                                                    "rentalCoverageId",
                                                    staticValue
                                                  )
                                            }
                                            className={`${
                                              rentalID?.rentalCoverageId?.includes(
                                                1
                                              )
                                                ? "borderE1"
                                                : ""
                                            }  fontAntarctica border3B3936 borderRadius8 p-2`}
                                          >
                                            <input
                                              type="radio"
                                              onChange={() => {}}
                                              checked={
                                                rentalID?.rentalCoverageId?.includes(
                                                  1
                                                )
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label className="mx-2">
                                              Minimum Liability Coverage
                                            </label>
                                            <ul className="fontSize13 px-3">
                                              <li className="py-1">
                                                Covers damage to third-party
                                                property or persons.
                                              </li>
                                              <li className="py-1">
                                                Meets minimum liability
                                                insurance requirements.
                                              </li>
                                              <li className="fontColor335">
                                                Does not cover damage to the
                                                vehicle.
                                              </li>
                                            </ul>
                                            <div className="fontSize13 fontColor335 bg71A p-2 borderRadius8">
                                              Warning: If your card/insurance
                                              doesn't offer liability coverage,
                                              We won't be able to confirm your
                                              booking without a liability
                                              coverage.
                                            </div>
                                          </div>
                                        </div> */}
                                    </div>
                                  )}
                                </div>
                              </DesktopCustomModal>
                              {/* here DesktopModal is rendered here to open when user clicks on Add New Card in the above DesktopModal */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes("AddNewCard")}
                                closeModal={() => closeModal("AddNewCard")}
                                closeOnOutsideClick={true}
                                modalId="AddNewCard"
                                modalTitle={
                                  <div className="fontColorCE1 p-3">
                                    Add New Card
                                  </div>
                                }
                                modalButton=""
                                modalCenter={true}
                                modalBackgroudColor="transparent"
                              >
                                <Checkout
                                  closeModal={() => {
                                    closeModal("AddNewCard");
                                    // getUserCreditDocs();
                                  }}
                                />
                              </DesktopCustomModal>
                              <div className="border3B3936 d-flex justify-content-between align-items-center p-3 py-3 borderRadius8 mb-2">
                                <div className="fontSize14">Total Price</div>
                                <div className="fontSize17 px-2">
                                  $ {historyDetails?.grandTotal}
                                </div>
                              </div>
                            </div>
                            {/* //driving license modal */}
                            <DesktopCustomModal
                              isModalOpen={activeModal.includes(
                                "DrivingLicenseModal"
                              )}
                              closeModal={() =>
                                closeModal("DrivingLicenseModal")
                              }
                              closeOnOutsideClick={false}
                              isCloseIconRequired={true}
                              modalId="driverLicenseModal"
                              modalClassHeight="modalHeightDrivingLicense"
                              modalTitle={
                                <div className="d-flex justify-content-center align-items-center py-3">
                                  Add Driver License
                                </div>
                              }
                              // modalButton={
                              //   licenseDetails?.data === null ?  ( <div
                              //     onClick={() => {
                              //       openModal("DrivingLicenseModal");
                              //       // getStateListAll();
                              //     }}
                              //   >
                              //     Upload Now
                              //   </div> ) : (
                              //     "Verified"
                              //   )
                              // }
                            >
                              <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
                                <div className="m-1 cursor w-100">
                                  <FileUploader
                                    onFileChange={(file) => {
                                      setDrivingLicenseDocument(
                                        (prevValues) => ({
                                          ...prevValues,
                                          frontPage: file,
                                        })
                                      );
                                    }}
                                    handleDeleteFile={() => {
                                      setDrivingLicenseDocument(
                                        (prevValues) => ({
                                          ...prevValues,
                                          frontPage: "",
                                        })
                                      );
                                    }}
                                    label="Front Page"
                                    fileTextMessage="Drag & drop some files here, or click to select files"
                                  />
                                </div>
                                <div className="m-1 cursor w-100">
                                  <FileUploader
                                    onFileChange={(file) => {
                                      setDrivingLicenseDocument(
                                        (prevValues) => ({
                                          ...prevValues,
                                          backPage: file,
                                        })
                                      );
                                    }}
                                    handleDeleteFile={() => {
                                      setDrivingLicenseDocument(
                                        (prevValues) => ({
                                          ...prevValues,
                                          backPage: "",
                                        })
                                      );
                                    }}
                                    label="Back Page"
                                    fileTextMessage="Drag & drop some files here, or click to select files"
                                  />
                                </div>
                              </div>
                              <div>
                                <form>
                                  <div className="mb-3">
                                    <input
                                      id="DrivingLicenseNumber"
                                      placeholder="Enter Driver License Number"
                                      type="text"
                                      className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
                                      value={
                                        drivingLicenseDocument?.drivingLicenseNumber
                                      }
                                      onChange={(e) => {
                                        setDrivingLicenseDocument(
                                          (prevValues) => ({
                                            ...prevValues,
                                            drivingLicenseNumber:
                                              e.target.value,
                                          })
                                        );
                                        const regex = /^[a-zA-Z0-9]{1,15}$/; // Only allow alphabets and numbers
                                        if (!regex.test(e.target.value)) {
                                          setDrivingLicenseErrors((prev) => ({
                                            ...prev,
                                            drivingLicenseNumber:
                                              "Invalid Driving License format",
                                          }));
                                        } else {
                                          setDrivingLicenseErrors((prev) => ({
                                            ...prev,
                                            drivingLicenseNumber: "",
                                          })); // Clear error if valid
                                        }
                                      }}
                                      onBlur={(e) => {
                                        const value = e.target.value;
                                        if (!value) {
                                          setDrivingLicenseErrors((prev) => ({
                                            ...prev,
                                            drivingLicenseNumber:
                                              "Invalid Driving License format",
                                          }));
                                        }
                                      }}
                                    />
                                    {drivingLicenseErrors?.drivingLicenseNumber && (
                                      <div className="text-danger mt-2">
                                        {
                                          drivingLicenseErrors?.drivingLicenseNumber
                                        }
                                      </div>
                                    )}
                                  </div>

                                  {/* <div className="mb-3 position-relative">
                                    <input
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                      id="expiryDate"
                                      placeholder="Enter Expiry Date (dd/mm/yyyy)"
                                      type="date"
                                      className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white custom-date-input"
                                      value={drivingLicenseDocument?.expiryDate}
                                      onChange={(e) => {
                                        setDrivingLicenseDocument(
                                          (prevValues) => ({
                                            ...prevValues,
                                            expiryDate: e.target.value,
                                          })
                                        );
                                      }}
                                    />
                                  </div> */}

                                  <div className="mb-3 position-relative">
                                    <CommonCalendar
                                      onDateSelect={handleDateSelect}
                                      handleBlur={() => {}}
                                      defaultViewDate={new Date()}
                                      minDate={new Date()}
                                      maxDate=""
                                      placeholder="Enter Expiry Date"
                                      value={drivingLicenseDocument?.expiryDate}
                                    />
                                  </div>

                                  <AddressAutocomplete
                                    errors={errors}
                                    setErrors={setErrors}
                                    addressDetails={addressDetails}
                                    setAddressDetails={setAddressDetails}
                                  />

                                  <div className="d-flex align-items-center mb-3">
                                    <input
                                      type="checkbox"
                                      id="mailingAddress"
                                      className="me-2"
                                      checked={mailingAddressCheckbox}
                                      onChange={() =>
                                        setMailingAddressCheckbox(
                                          !mailingAddressCheckbox
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor="mailingAddress"
                                      className="text-white"
                                    >
                                      Use this address as my Mailing Address
                                    </label>
                                  </div>

                                  <div className="d-flex align-items-center mb-3">
                                    <input
                                      type="checkbox"
                                      id="licenseDetails"
                                      className="me-2"
                                      checked={licenseDetailsCheckbox}
                                      onChange={() =>
                                        setLicenseDetailsCheckbox(
                                          !licenseDetailsCheckbox
                                        )
                                      }
                                    />
                                    <div
                                      onMouseEnter={() =>
                                        setShowConditions(true)
                                      } // Show content on hover
                                      onMouseLeave={() =>
                                        setShowConditions(false)
                                      } // Hide content when mouse leaves
                                    >
                                      <label
                                        htmlFor="licenseDetails"
                                        className="text-white"
                                      >
                                        We will use your license details to
                                        perform a driver and criminal background
                                        check. For details{" "}
                                        <span className="cursorPointer fontColorCE1">
                                          Read More
                                        </span>
                                      </label>
                                      {showConditions && (
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div
                                              className="col-md-12 bg23 fontSize14 text-white p-2 px-4 borderRadius8"
                                              style={{
                                                height: "300px",
                                                width: "400px",
                                                overflow: "auto",
                                                position: "absolute",
                                                top: window.innerWidth > 992 ? "69%" : "86%",
                                                left: "1%",
                                                border: "1px solid grey",
                                              }}
                                            >
                                              <p>
                                                Joulez may need to request a
                                                consumer report, drivers record
                                                report, or other background
                                                check in order to approve your
                                                rental application. By checking
                                                the box, you authorize Joulez
                                                and its designated agents and
                                                representatives to conduct a
                                                comprehensive review of your
                                                records. By checking the box,
                                                you indicate that you understand
                                                the scope of the consumer report
                                                may include, but is not limited
                                                to: criminal history, including
                                                records from any criminal
                                                justice agency in any or all
                                                federal, state, or county
                                                jurisdictions; motor vehicle
                                                records, including traffic
                                                citations and registration; and
                                                any other public record that
                                                might be relevant to determining
                                                your risk as a vehicle renter.
                                              </p>
                                              <p>
                                                If you are a California
                                                resident, you have the right to
                                                receive the report Joulez
                                                reviews. By sending us an e-mail
                                                at reservations@drivejoulez.com,
                                                you can request to receive a
                                                copy of the report Joulez
                                                reviews. Upon receipt of the
                                                report, Joulez will provide a
                                                copy of the report to you within
                                                three business days to the
                                                e-mail address you have
                                                associated with this rental.
                                              </p>
                                              <p>
                                                By withholding your permission,
                                                no records will be reviewed and
                                                your rental reservation will not
                                                be processed further.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className={`signInButton bgCE1 text-white w-100 ${
                                      drivingLicenseErrors?.drivingLicenseNumber ===
                                        "" &&
                                      errors?.country === "" &&
                                      errors?.address === "" &&
                                      errors?.city === "" &&
                                      errors?.state === "" &&
                                      errors?.zipCode === "" &&
                                      licenseDetailsCheckbox &&
                                      addressDetails?.address !== "" &&
                                      addressDetails?.city !== "" &&
                                      addressDetails?.country !== "" &&
                                      addressDetails?.countryCode !== "" &&
                                      addressDetails?.state !== "" &&
                                      addressDetails?.zipCode !== "" &&
                                      drivingLicenseDocument?.frontPage !==
                                        "" &&
                                      drivingLicenseDocument?.backPage !== "" &&
                                      drivingLicenseDocument?.drivingLicenseNumber !==
                                        "" &&
                                      drivingLicenseDocument?.expiryDate !== ""
                                        ? ""
                                        : "disabled-class"
                                    }`}
                                    onClick={postDrivingLicenseData}
                                  >
                                    Proceed
                                  </button>
                                </form>
                              </div>
                            </DesktopCustomModal>
                            {/* //drivingLicense */}
                            <div className="d-flex  flex-column flex-lg-row justify-content-end">
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes("TripModify")}
                                closeModal={() => {
                                  setSelectedDocId("")
                                  setEmrData("")
                                  setCardDetails("")
                                  setShowCalendarAndtime(true)
                                  fetchEmrTimings(historyDetails?.id)
                                  closeModal("TripModify")}}
                                closeOnOutsideClick={false}
                                modalId="tripModification"
                                modalCenter={true}
                                modalClassHeight="myTripModalHeight"
                                modalClassWidth="myTripModalWidth"
                                isCloseIconRequired={true}
                                modalTitle={
                                  <div className="d-flex justify-content-center align-items-center px-4 pt-2 fontSize17 fontAntarctica fontWeight400">
                                    Request for trip extension
                                  </div>
                                }
                                modalButton=""
                              >
                                <div className="d-flex p-2 flex-column h-100">
                                  {historyDetails?.nextCarBlockedDate && <div className="px-3 pb-1 fontAntarctica historyDetailsNextCarBlockClass">This vehicle trip can be extended till {historyDetails?.nextCarBlockedDate}</div>}
                                  <div className="d-flex flex-column">
                                    {cardDetails === "" && (
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div
                                          onClick={() => {
                                            if (emrData !== "") {
                                              setShowCalendarAndtime(
                                                !showCalendarAndtime
                                              );
                                            }
                                            setEmrData("");
                                          }}
                                          className="fontSize13 fontAntarctica border733 p-3 borderRadius12 w-100"
                                        >
                                          <div className="opacity mb-1">
                                            Pick up & Drop off
                                          </div>
                                          <div>
                                            {
                                              currentEmrDatesAndTimings
                                                ?.pickupAndDropDates?.pickupDate
                                            }{" "}
                                            {
                                              currentEmrDatesAndTimings
                                                ?.pickupAndDropTimings
                                                ?.pickupTime
                                            }{" "}
                                            -{" "}
                                            {
                                              currentEmrDatesAndTimings
                                                ?.pickupAndDropDates?.dropDate
                                            }{" "}
                                            {
                                              currentEmrDatesAndTimings
                                                ?.pickupAndDropTimings?.dropTime
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {showCalendarAndtime ? (
                                      EmrTimingsLoading ? (
                                        <SpinnerLoader />
                                      ) : EmrTimingsError ? (
                                        <div>{EmrTimingsError}</div>
                                      ) : (
                                        <div className="d-flex justify-content-center">
                                          <DateTimeSlotContainer
                                            pickupBg={pickupBg}
                                            setPickupBg={setPickupBg}
                                            dropBg={dropBg}
                                            SetDropBg={SetDropBg}
                                            pickupTimeSlotData={
                                              pickupTimeSlotData
                                            }
                                            dropTimeSlotData={dropTimeSlotData}
                                            myTripPage={true}
                                            bookingStatus={bookingStatus}
                                            setPickupTimeIndex={
                                              setPickupTimeIndex
                                            }
                                          />
                                        </div>
                                      )
                                    ) : cardDetails === "" ? (
                                      emrDataLoading ? (
                                        <SpinnerLoader />
                                      ) : (
                                        <div className="pt-4 fontSize13 fontAntarctica fontWeight400">
                                          <table className="w-100">
                                            <thead>
                                              <tr className="text-center fw-bold">
                                                <th className="text-start"></th>
                                                <th className="fw-normal opacity">
                                                  Changes
                                                </th>
                                                <th className="text-end fw-normal opacity">
                                                  Difference
                                                </th>
                                              </tr>
                                            </thead>
                                            <tr>
                                              <td colSpan="3">
                                                <hr className="my-2" />
                                              </td>
                                            </tr>
                                            <tbody>
                                              <tr
                                                className="my-2"
                                                style={{
                                                  height: "10px",
                                                }}
                                              >
                                                <td
                                                  colSpan="2"
                                                  className="border-0"
                                                ></td>
                                              </tr>
                                              <tr>
                                                <td className="requestTripExtensionwidth175 opacity">
                                                  Pick up date
                                                </td>
                                                <td className="text-center">
                                                  <div>
                                                    {existingBookingInfo?.pickupDateTime ===
                                                    newBookingInfo?.pickupDateTime ? (
                                                      <div>
                                                        {
                                                          moment(
                                                            existingBookingInfo?.pickupDateTime
                                                          ).format(
                                                            "MMM DD, YYYY hh:mm A"
                                                          )
                                                          // existingBookingInfo?.pickupDateTime
                                                        }
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <div className="strikethrough opacity70 text-nowrap">
                                                          {
                                                            moment(
                                                              existingBookingInfo?.pickupDateTime
                                                            ).format(
                                                              "MMM DD, YYYY hh:mm A"
                                                            )
                                                            // existingBookingInfo?.pickupDateTime
                                                          }
                                                        </div>
                                                        <div className="text-nowrap">
                                                          {
                                                            moment(
                                                              newBookingInfo?.pickupDateTime
                                                            ).format(
                                                              "MMM DD, YYYY hh:mm A"
                                                            )
                                                            // newBookingInfo?.pickupDateTime
                                                          }
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </td>
                                                {/* <td className="text-end">
                                                    -
                                                  </td> */}
                                              </tr>
                                              <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="opacity">
                                                  Drop off date
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo?.dropOffDateTime ===
                                                  newBookingInfo?.dropOffDateTime ? (
                                                    <div>
                                                      {
                                                        moment(
                                                          existingBookingInfo?.dropOffDateTime
                                                        ).format(
                                                          "MMM DD, YYYY hh:mm A"
                                                        )

                                                        // existingBookingInfo?.dropOffDateTime
                                                      }
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <div className="strikethrough opacity70 text-nowrap">
                                                        {
                                                          moment(
                                                            existingBookingInfo?.dropOffDateTime
                                                          ).format(
                                                            "MMM DD, YYYY hh:mm A"
                                                          )
                                                          // existingBookingInfo?.dropOffDateTime
                                                        }
                                                      </div>
                                                      <div className="text-nowrap">
                                                        {
                                                          moment(
                                                            newBookingInfo?.dropOffDateTime
                                                          ).format(
                                                            "MMM DD, YYYY hh:mm A"
                                                          )
                                                          // newBookingInfo?.dropOffDateTime
                                                        }
                                                      </div>
                                                    </div>
                                                  )}
                                                </td>
                                                {/* <td className="text-end">
                                                    -
                                                  </td> */}
                                              </tr>
                                              <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="opacity">
                                                  No. of Days
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo?.noOfDays ===
                                                  newBookingInfo?.noOfDays ? (
                                                    <div>
                                                      {
                                                        existingBookingInfo?.noOfDays
                                                      }
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <span className="strikethrough opacity70">
                                                        {
                                                          existingBookingInfo?.noOfDays
                                                        }
                                                      </span>
                                                      <span className="mx-2">
                                                        {
                                                          newBookingInfo?.noOfDays
                                                        }
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {
                                                    eventModificationRequest?.noOfdays
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="opacity">
                                                  Base Rate
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.actualPrice ===
                                                  newBookingInfo?.estimatedPrice
                                                    ?.baseRate ? (
                                                    <div>
                                                      ${" "}
                                                      {existingBookingInfo?.paymentTransaction?.actualPrice?.toFixed(
                                                        2
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <span>$</span>
                                                      <span className="strikethrough opacity70 mx-2">
                                                        {existingBookingInfo?.paymentTransaction?.actualPrice?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                      <span>
                                                        {newBookingInfo?.estimatedPrice?.baseRate?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="text-end text-nowrap">
                                                  {/* ${" "}
                                                  {eventModificationRequest?.baseRate.toFixed(
                                                    2
                                                  )} */}
                                                  {eventModificationRequest?.baseRate <
                                                  0
                                                    ? `- $ ${Math.abs(
                                                        eventModificationRequest?.baseRate
                                                      ).toFixed(2)}`
                                                    : `$ ${eventModificationRequest?.baseRate.toFixed(
                                                        2
                                                      )}`}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>
                                              {existingBookingInfo
                                                ?.paymentTransaction?.ageFee !==
                                                0 && (
                                                <tr>
                                                  <td className="opacity">
                                                    Age Fee
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.ageFee ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.ageFee ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.ageFee?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.ageFee?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.ageFee?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end text-nowrap">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.ageFee.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.ageFee <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.ageFee
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.ageFee.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}
                                              {existingBookingInfo
                                                ?.paymentTransaction?.ageFee !==
                                                0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}

                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.stateTax !== 0 && (
                                                <tr>
                                                  <td className="opacity">
                                                    State Tax{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.stateTaxPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.stateTax ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.stateTax ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.stateTax?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.stateTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.stateTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end text-nowrap">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.stateTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.stateTax <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.stateTax
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.stateTax.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}
                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.stateTax !== 0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}

                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.tourismTax !== 0 && (
                                                <tr>
                                                  <td className="opacity">
                                                  Tourism Tax{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.tourismTaxPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.tourismTax ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.tourismTax ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.tourismTax?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.tourismTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.tourismTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end text-nowrap">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.tourismTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.tourismTax <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.tourismTax
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.tourismTax.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}
                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.tourismTax !== 0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}

                                             {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.countyLocalTaxSi !== 0 && (
                                                <tr>
                                                  <td className="opacity">
                                                  County Local Tax SI{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.countyLocalTaxSiPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.countyLocalTaxSi ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.countyLocalTaxSi ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.countyLocalTaxSi?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.countyLocalTaxSi?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.countyLocalTaxSi?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end text-nowrap">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.tourismTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.countyLocalTaxSi <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.countyLocalTaxSi
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.countyLocalTaxSi.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}

                                             {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.countyLocalTaxSi !== 0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}

                                            {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.countyDistrictTaxSp !== 0 && (
                                                <tr>
                                                  <td className="opacity">
                                                  County District Tax SP Tax{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.countyDistrictTaxSpPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.countyDistrictTaxSp ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.countyDistrictTaxSp ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.countyDistrictTaxSp?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.countyDistrictTaxSp?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.countyDistrictTaxSp?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end text-nowrap">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.tourismTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.countyDistrictTaxSp <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.countyDistrictTaxSp
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.countyDistrictTaxSp.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}
                                          {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.countyDistrictTaxSp !== 0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}

                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.artTaxLocalPortion !== 0 && (
                                                <tr>
                                                  <td className="opacity">
                                                  ART Tax-Local Portion Tax{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.artTaxLocalPortionTaxPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.artTaxLocalPortion ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.artTaxLocalPortion ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.artTaxLocalPortion?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.artTaxLocalPortion?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.artTaxLocalPortion?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end text-nowrap">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.tourismTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.artTaxLocalPortion <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.artTaxLocalPortion
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.artTaxLocalPortion.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}

                                          {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.artTaxLocalPortion !== 0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}

                                           {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.artMetroPierAndExpoAuth !== 0 && (
                                                <tr>
                                                  <td className="opacity">
                                                  ART Metro Pier And Expo Auth Tax{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.artMetroPierAndExpoAuthTaxPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.artMetroPierAndExpoAuth ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.artMetroPierAndExpoAuth ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.artMetroPierAndExpoAuth?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.artMetroPierAndExpoAuth?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.artMetroPierAndExpoAuth?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end text-nowrap">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.tourismTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.artMetroPierAndExpoAuth <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.artMetroPierAndExpoAuth
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.artMetroPierAndExpoAuth.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}

                                            {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.artMetroPierAndExpoAuth !== 0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}

                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.cityTax !== 0 && (
                                                <tr>
                                                  <td className="opacity">
                                                    City Tax{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.cityTaxPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.cityTax ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.cityTax ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.cityTax?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.cityTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.cityTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.cityTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.cityTax <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.cityTax
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.cityTax.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}
                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.cityTax !== 0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}
                                              {existingBookingInfo
                                                ?.paymentTransaction?.useTax !==
                                                0 && (
                                                <tr>
                                                  <td className="opacity">
                                                    Use Tax{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.useTaxPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.useTax ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.useTax ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.useTax?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.useTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.useTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.useTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.useTax <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.useTax
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.useTax.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}
                                              {existingBookingInfo
                                                ?.paymentTransaction?.useTax !==
                                                0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}
                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.metropolitanTax !== 0 && (
                                                <tr>
                                                  <td className="opacity">
                                                    Metropolitan Tax{" "}
                                                    {
                                                      existingBookingInfo
                                                        ?.paymentTransaction
                                                        ?.metropolitanTaxPercent
                                                    }
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {existingBookingInfo
                                                      ?.paymentTransaction
                                                      ?.metropolitanTax ===
                                                    newBookingInfo
                                                      ?.estimatedPrice
                                                      ?.metropolitanTax ? (
                                                      <div>
                                                        ${" "}
                                                        {existingBookingInfo?.paymentTransaction?.metropolitanTax?.toFixed(
                                                          2
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>$</span>
                                                        <span className="strikethrough opacity70 mx-2">
                                                          {existingBookingInfo?.paymentTransaction?.metropolitanTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                        <span>
                                                          {newBookingInfo?.estimatedPrice?.metropolitanTax?.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="text-end">
                                                    {/* ${" "}
                                                    {eventModificationRequest?.metropolitanTax.toFixed(
                                                      2
                                                    )} */}
                                                    {eventModificationRequest?.metropolitanTax <
                                                    0
                                                      ? `- $ ${Math.abs(
                                                          eventModificationRequest?.metropolitanTax
                                                        ).toFixed(2)}`
                                                      : `$ ${eventModificationRequest?.metropolitanTax.toFixed(
                                                          2
                                                        )}`}
                                                  </td>
                                                </tr>
                                              )}
                                              {existingBookingInfo
                                                ?.paymentTransaction
                                                ?.metropolitanTax !== 0 && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <hr className="my-2" />
                                                  </td>
                                                </tr>
                                              )}

                                         {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.rentalCoverageCharge !== 0 && <tr>
                                                <td className="opacity">
                                                  Rental Coverage Charges
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.rentalCoverageCharge ===
                                                  newBookingInfo?.estimatedPrice
                                                    ?.rentalCoverageCharge ? (
                                                    <div>
                                                      ${" "}
                                                      {existingBookingInfo?.paymentTransaction?.rentalCoverageCharge?.toFixed(
                                                        2
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <span>$</span>
                                                      <span className="strikethrough opacity70 mx-2">
                                                        {existingBookingInfo?.paymentTransaction?.rentalCoverageCharge?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                      <span>
                                                        {newBookingInfo?.estimatedPrice?.rentalCoverageCharge?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {/* ${" "}
                                                  {eventModificationRequest?.rentalCoverageCharge?.toFixed(
                                                    2
                                                  )} */}
                                                  {eventModificationRequest?.rentalCoverageCharge <
                                                  0
                                                    ? `- $ ${Math.abs(
                                                        eventModificationRequest?.rentalCoverageCharge
                                                      ).toFixed(2)}`
                                                    : `$ ${eventModificationRequest?.rentalCoverageCharge.toFixed(
                                                        2
                                                      )}`}
                                                </td>
                                              </tr>
            }
                                              {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.rentalCoverageCharge !== 0 && <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>

            }
                                              
                                              {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.additionalTripCharges !== 0&& <tr>
                                                <td className="opacity">
                                                  Additional Charges
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.additionalTripCharges ===
                                                  newBookingInfo?.estimatedPrice
                                                    ?.additionalTripCharges ? (
                                                    <div>
                                                      ${" "}
                                                      {existingBookingInfo?.paymentTransaction?.additionalTripCharges?.toFixed(
                                                        2
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <span>$</span>
                                                      <span className="strikethrough opacity70 mx-2">
                                                        {existingBookingInfo?.paymentTransaction?.additionalTripCharges?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                      <span>
                                                        {newBookingInfo?.estimatedPrice?.additionalTripCharges?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {/* ${" "}
                                                  {eventModificationRequest?.additionalTripCharges?.toFixed(
                                                    2
                                                  )} */}
                                                  {eventModificationRequest?.additionalTripCharges <
                                                  0
                                                    ? `- $ ${Math.abs(
                                                        eventModificationRequest?.additionalTripCharges
                                                      ).toFixed(2)}`
                                                    : `$ ${eventModificationRequest?.additionalTripCharges.toFixed(
                                                        2
                                                      )}`}
                                                </td>
                                              </tr>
            }
                                                {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.additionalTripCharges !== 0&& <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>}


                                              {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.preTripCharges !== 0&& <tr>
                                                <td className="opacity">
                                                PreTrip  Charges
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.preTripCharges ===
                                                  newBookingInfo?.estimatedPrice
                                                    ?.preTripCharges ? (
                                                    <div>
                                                      ${" "}
                                                      {existingBookingInfo?.paymentTransaction?.preTripCharges?.toFixed(
                                                        2
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <span>$</span>
                                                      <span className="strikethrough opacity70 mx-2">
                                                        {existingBookingInfo?.paymentTransaction?.preTripCharges?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                      <span>
                                                        {newBookingInfo?.estimatedPrice?.preTripCharges?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {/* ${" "}
                                                  {eventModificationRequest?.additionalTripCharges?.toFixed(
                                                    2
                                                  )} */}
                                                  {eventModificationRequest?.preTripCharges <
                                                  0
                                                    ? `- $ ${Math.abs(
                                                        eventModificationRequest?.preTripCharges
                                                      ).toFixed(2)}`
                                                    : `$ ${eventModificationRequest?.preTripCharges.toFixed(
                                                        2
                                                      )}`}
                                                </td>
                                              </tr>
            }
                                                {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.preTripCharges !== 0&& <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>}




                                              {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.discount !== 0&& <tr>
                                                <td className="opacity">
                                                Discount  Applied
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.discount ===
                                                  newBookingInfo?.estimatedPrice
                                                    ?.discount ? (
                                                    <div>
                                                      ${" "}
                                                      {existingBookingInfo?.paymentTransaction?.discount?.toFixed(
                                                        2
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <span>$</span>
                                                      <span className="strikethrough opacity70 mx-2">
                                                        {existingBookingInfo?.paymentTransaction?.discount?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                      <span>
                                                        {newBookingInfo?.estimatedPrice?.discount?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {/* ${" "}
                                                  {eventModificationRequest?.additionalTripCharges?.toFixed(
                                                    2
                                                  )} */}
                                                  {eventModificationRequest?.discount <
                                                  0
                                                    ? `- $ ${Math.abs(
                                                        eventModificationRequest?.discount
                                                      ).toFixed(2)}`
                                                    : `$ ${eventModificationRequest?.discount.toFixed(
                                                        2
                                                      )}`}
                                                </td>
                                              </tr>
            }
                                                {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.discount !== 0&& <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>}



                               {existingBookingInfo?.paymentTransaction?.additionalCoverageCharge !== 0 && 
                                              <tr>
                                                <td className="opacity">
                                                  Additional Coverage Charges
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.additionalCoverageCharge ===
                                                  newBookingInfo?.estimatedPrice
                                                    ?.additionalCoverageCharge ? (
                                                    <div>
                                                      ${" "}
                                                      {existingBookingInfo?.paymentTransaction?.additionalCoverageCharge?.toFixed(
                                                        2
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <span>$</span>
                                                      <span className="strikethrough opacity70 mx-2">
                                                        {existingBookingInfo?.paymentTransaction?.additionalCoverageCharge?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                      <span>
                                                        {newBookingInfo?.estimatedPrice?.additionalCoverageCharge?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {/* ${" "}
                                                  {eventModificationRequest?.additionalTripCharges?.toFixed(
                                                    2
                                                  )} */}
                                                  {eventModificationRequest?.additionalCoverageCharge <
                                                  0
                                                    ? `- $ ${Math.abs(
                                                        eventModificationRequest?.additionalCoverageCharge
                                                      ).toFixed(2)}`
                                                    : `$ ${eventModificationRequest?.additionalCoverageCharge.toFixed(
                                                        2
                                                      )}`}
                                                </td>
                                              </tr>
            }
                                             {existingBookingInfo?.paymentTransaction?.additionalCoverageCharge !== 0 &&  <tr>
                                                <td colSpan="3">
                                                  <hr className="my-2" />
                                                </td>
                                              </tr>
                                              
            }
                                              <tr>
                                                <td className="opacity">
                                                  Total Cost
                                                </td>
                                                <td className="text-center">
                                                  {existingBookingInfo
                                                    ?.paymentTransaction
                                                    ?.total ===
                                                  newBookingInfo?.estimatedPrice
                                                    ?.total ? (
                                                    <div>
                                                      ${" "}
                                                      {existingBookingInfo?.paymentTransaction?.total?.toLocaleString(
                                                        "en-US",
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <span>$</span>
                                                      <span className="strikethrough opacity70 mx-2">
                                                        {existingBookingInfo?.paymentTransaction?.total?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </span>
                                                      <span>
                                                        {newBookingInfo?.estimatedPrice?.total?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {eventModificationRequest?.total <
                                                  0
                                                    ? `- $ ${Math.abs(
                                                        eventModificationRequest?.total
                                                      ).toLocaleString(
                                                        "en-US",
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}`
                                                    : `$ ${eventModificationRequest?.total.toLocaleString(
                                                        "en-US",
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}`}
                                                  {/* ${" "}
                                                  {eventModificationRequest?.total?.toLocaleString(
                                                    "en-US",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <hr className="my-2" />
                                          <div className="my-3">
                                            <textarea
                                              value={commentFromEvent}
                                              onChange={(e) => {
                                                setCommentFromEvent(e.target.value)
                                              }}
                                              placeholder="Comments"
                                              className="w-100 bg-transparent text-white br12 border-1 p-4 rounded-3 custom-placeholder"
                                              rows="2"
                                            ></textarea>
                                          </div>
                                         {emrData?.documentExpired && <div className="my-3 bgF2994A4D text-white fontSize13 br8 p-2">
                                          The protection plan you had is expired. Please upload the new one
                                            </div>}
                                          {/* <div className="my-3">
                                            <input
                                              type="textarea"
                                              placeholder="Comments"
                                              className="w-100 opacity bg-transparent text-white p-4 borderRadius8 border-0 border3B3936 fontSize13 outlineNone"
                                            />
                                          </div> */}
                                        </div>
                                      )
                                    ) : (
                                      <div>
                                        {cardDError ? (
                                          <div>{cardDError}</div>
                                        ) : (
                                          <div
                                            className={`borderE1 mb-3 border3B3936 borderRadius8 d-flex justify-content-between align-items-center cursorPointer`}
                                          >
                                            <div className=" fontSize13 lineheight21 p-4 fontWeight400 fontAntarctica ">
                                              <div className="mb-1 fontColor5BD">
                                                Card Number
                                                <span className="text-white mx-2">
                                                  XXXX XXXX XXXX{" "}
                                                  {cardDetails?.cardNumber}
                                                </span>
                                              </div>
                                              <div className="mb-1 fontColor5BD">
                                                Card Holder Name{" "}
                                                <span className="text-white mx-2">
                                                  {cardDetails?.name}
                                                </span>
                                              </div>
                                              <div className="mb-1 fontColor5BD">
                                                Expiry Date{" "}
                                                <span className="text-white mx-2">
                                                  {cardDetails?.expiryDate}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="py-2">
                                              <div className="d-flex m-3 justify-content-end p-2 mb-3">
                                                <input
                                                  type="radio"
                                                  checked={true}
                                                  onChange={() => {}}
                                                />
                                              </div>
                                              {/* <div className="bgE933 p-2 m-3 borderRadius8">
                                                {cardDetails?.cardType}
                                              </div> */}
                                              <img
                                                className="mx-1 height35px"
                                                src={
                                                  images[
                                                    cardDetails?.cardIssuedBy
                                                  ] || images.defaultCard
                                                }
                                                alt="images"
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>

                                  <div className="d-flex justify-content-between mt-auto">
                                    <button
                                      onClick={() => {
                                        //closeModal("");
                                        //setSelectedDocId("")
                                        setEmrData("");
                                        setCardDetails("");
                                        if(showCalendarAndtime){
                                          openModal("Modification Requests")
                                        }
                                        if(showCalendarAndtime && historyDetails?.eventModificationRequest?.length === 0){
                                          closeModal("Modification Requests")
                                          closeModal("TripModify")
                                        }
                                        if(!showCalendarAndtime){
                                        setShowCalendarAndtime(true);
                                        }
                                        if(cardDetails && !showCalendarAndtime){
                                          //fetchEstimatedEmrPricing();
                                          fetchEstimatedEmrPricing(false, null, true, false)
                                          setShowCalendarAndtime(false); 
                                        }
                                      }}
                                      className="p-2 mx-1 w-100 bg3B3936 text-white border-0 outlineNone borderRadius8"
                                    >
                                      Prev
                                    </button>
                                    {emrData === "" ? (
                                      <button
                                        onClick={() => {
                                          fetchEstimatedEmrPricing();
                                          setShowCalendarAndtime(false);
                                        }}
                                        className={`p-2 mx-1 w-100 bgCE1 text-white border-0 outlineNone borderRadius8
                                            ${
                                              format(
                                                historyDetails?.pickupDateTime?.split(
                                                  ","
                                                )[0],
                                                "MMM dd yyyy"
                                              ) ===
                                                format(
                                                  currentEmrDatesAndTimings
                                                    ?.pickupAndDropDates
                                                    ?.pickupDate,
                                                  "MMM dd yyyy"
                                                ) &&
                                              format(
                                                historyDetails?.dropOffDateTime?.split(
                                                  ","
                                                )[0],
                                                "MMM dd yyyy"
                                              ) ===
                                                format(
                                                  currentEmrDatesAndTimings
                                                    ?.pickupAndDropDates
                                                    ?.dropDate,
                                                  "MMM dd yyyy"
                                                ) &&
                                              currentEmrDatesAndTimings?.pickupAndDropTimings?.pickupTime?.trim() ===
                                                historyDetails?.pickupDateTime
                                                  .split(",")[1]
                                                  .trim() &&
                                              currentEmrDatesAndTimings?.pickupAndDropTimings?.dropTime?.trim() ===
                                                historyDetails?.dropOffDateTime
                                                  .split(",")[1]
                                                  .trim()
                                                ? "opacity-50"
                                                : "opacity-100"
                                            }
                                            `}
                                        disabled={
                                          format(
                                            historyDetails?.pickupDateTime?.split(
                                              ","
                                            )[0],
                                            "MMM dd yyyy"
                                          ) ===
                                            format(
                                              currentEmrDatesAndTimings
                                                ?.pickupAndDropDates
                                                ?.pickupDate,
                                              "MMM dd yyyy"
                                            ) &&
                                          format(
                                            historyDetails?.dropOffDateTime?.split(
                                              ","
                                            )[0],
                                            "MMM dd yyyy"
                                          ) ===
                                            format(
                                              currentEmrDatesAndTimings
                                                ?.pickupAndDropDates?.dropDate,
                                              "MMM dd yyyy"
                                            ) &&
                                          currentEmrDatesAndTimings?.pickupAndDropTimings?.pickupTime?.trim() ===
                                            historyDetails?.pickupDateTime
                                              ?.split(",")[1]
                                              ?.trim() &&
                                          currentEmrDatesAndTimings?.pickupAndDropTimings?.dropTime?.trim() ===
                                            historyDetails?.dropOffDateTime
                                              ?.split(",")[1]
                                              ?.trim()
                                            ? true
                                            : false
                                        }
                                      >
                                        Proceed
                                      </button>
                                    ) : (
                                      <>
                                        {emrData?.documentExpired ?  
                                        <button
                                        className="p-2 mx-1 w-100 bgCE1 text-white border-0 outlineNone borderRadius8"
                                        onClick={() => {
                                          openModal("documentExpiredModal")
                                        }}
                                      >
                                        Upload
                                      </button>
                                      :
                                        !emrData?.next ? (
                                          <button
                                            className="p-2 mx-1 w-100 bgCE1 text-white border-0 outlineNone borderRadius8"
                                            onClick={() => {
                                              fetchEstimatedEmrPricing(true);
                                              closeModal("");
                                            }}
                                          >
                                            Request Trip Modification
                                          </button>
                                        ) : cardDetails === "" ? (
                                          <button
                                            onClick={() => fetchCardDetails()}
                                            className="p-2 mx-1 w-100 bgCE1 text-white border-0 outlineNone borderRadius8"
                                          >
                                            {cardDetailsSpinner ? <SpinnerLoader /> :  'Next'}
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() => {
                                              fetchEstimatedEmrPricing(
                                                true,
                                                cardDetails?.id
                                              );
                                              closeModal();
                                              // openModal("PaymentDone");
                                            }}
                                            className="p-2 mx-1 w-100 bgCE1 text-white border-0 outlineNone borderRadius8"
                                          >
                                            Request Trip
                                          </button>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </DesktopCustomModal>
                              {/*Open the documentExpiredModal if the insurance doc expired */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes(
                                  "documentExpiredModal"
                                )}
                                closeModal={() => {
                                  closeModal("documentExpiredModal")
                                  setSelectedDocId("")
                                  setEmrData("")
                                  setCardDetails("")
                                  setShowCalendarAndtime(true)
                                }}
                                closeOnOutsideClick={false}
                                modalId="documentExpiredModal"
                                modalCenter={true}
                                modalClassHeight="documentExpiredModalHeight"
                                modalClassWidth="documentExpiredModalWidth"
                                isCloseIconRequired={true}
                                modalTitle={
                                  <div className="d-flex justify-content-center align-items-center px-4 pt-2 fontSize17 fontAntarctica fontWeight400">
                                    Update Coverage
                                  </div>
                                }
                                modalButton=""
                                modalContent={
                                  <div className="d-flex justify-content-between align-content-center w-100">
                                    <button className="bg3B3936 w-100 mt-2 px-3 py-2 text-white bgCE1  p10w10 borderRadius8 border-0 fontSize13" 
                                    onClick={() => {
                                      closeModal("documentExpiredModal")
                                      setSelectedDocId("")
                                      setEmrData("")
                                      setCardDetails("")
                                      setShowCalendarAndtime(true)
                                    }}
                                    >
                                      Cancel
                                    </button>
                                     {!selectedDocId ? 
                                     <button
                                      disabled={
                                        !(
                                          insuranceInputValues?.documentName &&
                                          insuranceInputValues?.policyNumber &&
                                          insuranceInputValues?.issueState &&
                                          insuranceInputValues?.provider &&
                                          insuranceInputValues?.expiryDate
                                        )
                                      }
                                    onClick={handleSubmitInsuranceDetails}
                                      className={`${
                                        (insuranceInputValues?.documentName !==
                                          "" &&
                                        insuranceInputValues?.policyNumber !==
                                          "" &&
                                        insuranceInputValues?.issueState !==
                                          "" &&
                                        insuranceInputValues?.provider !== "" &&
                                        insuranceInputValues?.expiryDate !== "")
                                          ? ""
                                          : "opacity disabled"
                                      } w-100 mt-2 mx-2 px-3 py-2 text-white bgCE1  p10w10 borderRadius8 border-0 fontSize13`}
                                    >
                                      Upload Document
                                    </button>
                                    : 
                                    <button onClick={() => {
                                      fetchCardDetails()
                                      fetchEstimatedEmrPricing(false, null, true)
                                    }} className="w-100 mt-2 mx-2 px-3 py-2 text-white bgCE1  p10w10 borderRadius8 border-0 fontSize13">
                                      {cardDetailsSpinner ? <SpinnerLoader /> :  "Proceed"}
                                    </button>
                                    }
                                  </div>
                                }
                              >
                                <div>
                                <div className="mt-2">
                                  {userInsuranceDocs?.map(
                                    (details) => {
                                      const isSelected =
                                        selectedDocId?.id ===
                                        details?.id;
                                      return (
                                        <div
                                          onClick={() => {
                                            setSelectedDocId(
                                              details
                                            );
                                            setInsuranceInputValues(
                                              (prev) => ({
                                                ...prev,
                                                documentType: "I",
                                                policyNumber: "",
                                                issueState: "",
                                                provider: "",
                                                expiryDate: "",
                                                documentName: "",
                                              })
                                            );
                                          }}
                                          key={details?.id}
                                          className={`p-3 cursorPointer lineheight21 border3B3936 borderRadius8 d-flex justify-content-between align-items-center mb-3 ${
                                            isSelected
                                              ? "borderE1"
                                              : ""
                                          }`}
                                        >
                                          <div className="fontSize13 p-2 fontWeight400 fontAntarctica">
                                            <div className="mb-1 d-flex  align-items-center">
                                              <p className="mb-0 opacity">
                                                Policy Number
                                              </p>
                                              <span className="mx-1">
                                                {
                                                  details?.documentId
                                                }
                                              </span>
                                              {details?.isAxleVerified && <span>
                                              <img src={axleSuccessLogo} alt="axleSuccessLogo" />
                                              </span>}
                                            </div>
                                            <div className="mb-1 d-flex  align-items-center">
                                              <p className="mb-0 opacity">
                                                Issue State
                                              </p>
                                              <span className="mx-1">
                                                {
                                                  details?.issueState
                                                }
                                              </span>
                                            </div>
                                            <div className="mb-1 d-flex  align-items-center">
                                              <p className="mb-0 opacity">
                                                Provider
                                              </p>
                                              <span className="mx-1">
                                                {details?.provider}
                                              </span>
                                            </div>
                                            <div className="d-flex  align-items-center">
                                              <p className="mb-0 opacity">
                                                Exp. Date
                                              </p>
                                              <span className="mx-1">
                                                {
                                                  details?.docExpDate
                                                }
                                              </span>
                                            </div>
                                          </div>
                                          <div>
                                            <div className="p-1 borderRadius8">
                                              <div className="d-flex justify-content-end py-2">
                                                <input
                                                  type="radio"
                                                  name="insurance-doc"
                                                  checked={
                                                    isSelected
                                                  }
                                                  onChange={() =>
                                                    setSelectedDocId(
                                                      details
                                                    )
                                                  }
                                                />
                                              </div>
                                              <img
                                                className="insuranceImagePreview br2"
                                                src={
                                                  details?.fileType ===
                                                    "application/octet-stream" ||
                                                  details?.fileType ===
                                                    "application/pdf"
                                                    ? latestPdfIcon
                                                    : details?.documentName
                                                }
                                                alt="insuranceDocIcon"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div>
                                <div className="mt-2">
                                  <FileUploader
                                    // label={
                                    //   <div className="fontAntarctica fontSize17">
                                    //     Update New Coverage
                                    //   </div>
                                    // }
                                    fileTextMessage={
                                      <div className="borderRadius8">
                                        <img
                                          src={insuranceDocIcon}
                                          alt="insuranceDocIcon"
                                        />
                                      </div>
                                    }
                                    removePreviewImage={
                                      insuranceInputValues?.documentName === ""
                                        ? true
                                        : false
                                    }
                                    onFileChange={(file) => {
                                      setSelectedDocId("");
                                      setInsuranceInputValues((prevValues) => ({
                                        ...prevValues,
                                        documentName: file, // Store the compressed file
                                      }));
                                    }}
                                    handleDeleteFile={handleDeleteFile}
                                  />
                                </div>
                                <div className="pt-3 input-group w-100">
                                  <form
                                    className="w-100"
                                  >
                                    <div className="mb-3">
                                      <input
                                        id="policyNumber"
                                        placeholder="Policy Number"
                                        type="text"
                                        className="form-control supportFormFocus p-2 mb-3"
                                        onChange={handleChangeInsuranceDetails}
                                        value={
                                          insuranceInputValues?.policyNumber
                                        }
                                      />
                                      <select
                                        id="issueState"
                                        onChange={handleChangeInsuranceDetails}
                                        className="form-control supportFormFocus p-2 mb-3"
                                        value={insuranceInputValues?.issueState}
                                      >
                                        <option value="">Issue State</option>
                                        {storeStateNames[0]?.states?.map(
                                          (item) => (
                                            <option
                                              key={item?.name}
                                              value={item?.stateCode}
                                            >
                                              {item?.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <input
                                        id="provider"
                                        placeholder="Provider"
                                        type="text"
                                        className="form-control supportFormFocus p-2 mb-3"
                                        onChange={handleChangeInsuranceDetails}
                                        value={insuranceInputValues?.provider}
                                      />

                                          <DateOfBirthPicker
                                                id="expiryDate"
                                                minDate={
                                                    new Date()
                                                  }
                                                  value={
                                                    insuranceInputValues?.expiryDate
                                                  }
                                                 placeholder="Policy Expiration Date"
                                                  onDateSelect={handleChangeInsuranceDateDetails}
                                                />
                                    </div>
                                  </form>
                                </div>
                                </div>
                                </div>
                              </DesktopCustomModal>
                              {/* open thank you modal after payment */}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes(
                                  "PaymentDone"
                                )}
                                closeModal={() => closeModal("PaymentDone")}
                                closeOnOutsideClick={true}
                                modalId="PaymentDone"
                                modalCenter={true}
                                modalClassHeight="myTripThankModalHeight"
                                modalClassWidth="myTripThankModalWidth"
                                modalTitle=""
                                modalButton=""
                              >
                                <div className="d-flex flex-column justify-content-center align-items-center fontAntarctica h-100">
                                  <div className="my-2">
                                    <img
                                      src={greenTickIcon}
                                      alt="greenTickIcon"
                                    />
                                  </div>
                                  <div className="fontSize17 my-2">
                                    Thank You!
                                  </div>
                                  <div className="mb-3 mt-2 fontSize17 texy-center fontAntarctica opacity">
                                    Your Request has been successfully placed,
                                    Please Sign ZOHO Rental Document.
                                  </div>
                                  <button
                                    onClick={() => closeModal("")}
                                    className="p-2 mx-1 w-100 bgCE1 text-white border-0 outlineNone borderRadius8"
                                  >
                                    Continue
                                  </button>
                                </div>
                              </DesktopCustomModal>
                              {/*show only modifications request data modal*/}
                              <DesktopCustomModal
                                isModalOpen={activeModal.includes(
                                  "Modification Requests"
                                )}
                                closeModal={() =>
                                  closeModal("Modification Requests")
                                }
                                closeOnOutsideClick={false}
                                isCloseIconRequired={true}
                                modalId="Modification"
                                modalCenter={true}
                                modalClassHeight="myTripModalHeight"
                                modalClassWidth="myTripModalWidth"
                                modalTitle={
                                  <div className="d-flex justify-content-center align-items-center px-2 pt-2 fontSize17 fontAntarctica fontWeight400">
                                    Modification Requests
                                  </div>
                                }
                                modalContent={
                                  <div className="px-2 w-100">
                                    {historyDetails?.eventModificationRequest[
                                      historyDetails?.eventModificationRequest
                                        ?.length - 1
                                    ]?.status !== 21 &&
                                      isEMRAllowed(historyDetails) && (
                                        <div className="d-flex justify-content-between mt-auto pb-3">
                                          <button
                                            onClick={() => closeModal("")}
                                            className="p-2 mx-1 w-100 bg3B3936 text-white border-0 outlineNone borderRadius8"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            onClick={() => {
                                              closeModal("");
                                              fetchEmrTimings(
                                                historyDetails?.id
                                              );
                                              openModal("TripModify");
                                              dispatch(
                                                addEmrPickupAndDropDates({
                                                  pickupDate: format(
                                                    historyDetails?.pickupDateTime,
                                                    "MMM dd, yyyy"
                                                  ),
                                                  dropDate: format(
                                                    historyDetails?.dropOffDateTime,
                                                    "MMM dd, yyyy"
                                                  ),
                                                })
                                              );
                                              dispatch(
                                                addEmrPickupAndDropTimings({
                                                  pickupTime:
                                                    historyDetails?.pickupDateTime.split(
                                                      ", "
                                                    )[1],
                                                  dropTime:
                                                    historyDetails?.dropOffDateTime.split(
                                                      ", "
                                                    )[1],
                                                })
                                              );
                                            }}
                                            className="p-2 mx-1 w-100 bgCE1 text-white border-0 outlineNone borderRadius8"
                                          >
                                            Request Trip Modification 
                                          </button>
                                        </div>
                                      )}
                                  </div>
                                }
                              >
                                <div>
                                  {specificModificationError && (
                                    <div>{specificModificationError}</div>
                                  )}
                                  <div className="fontAntarctica fontSize13 fontWeight400">
                                    {isEMRAllowed(historyDetails) &&
                                      historyDetails.eventModificationRequest[
                                        historyDetails?.eventModificationRequest
                                          .length - 1
                                      ]?.status === 19 && (
                                        <div className="bgEB57574D borderRadius8 p-2 d-flex align-items-center">
                                          <div>
                                            <img
                                              src={cancelIcon}
                                              alt="cancelIcon"
                                            />
                                          </div>
                                          <div className="mx-2">
                                            Sorry, but we couldn't approve your
                                            Trip Modification request this time.
                                            Feel free to submit another one if
                                            needed.
                                          </div>
                                        </div>
                                      )}
                                    {isEMRAllowed(historyDetails) &&
                                      historyDetails.eventModificationRequest[
                                        historyDetails?.eventModificationRequest
                                          .length - 1
                                      ]?.status === 21 && (
                                        <div className="bgF2994A4D borderRadius8 p-2 d-flex align-items-center">
                                          <div>
                                            <img
                                              src={clockIcon}
                                              alt="clockIcon"
                                            />
                                          </div>
                                          <div className="mx-2">
                                            Your Trip Modification payment is
                                            still pending.
                                          </div>
                                        </div>
                                      )}
                                    {isEMRAllowed(historyDetails) &&
                                      historyDetails.eventModificationRequest[
                                        historyDetails?.eventModificationRequest
                                          .length - 1
                                      ]?.status === 8 && (
                                        <div className="bgEB57574D borderRadius8 p-2 d-flex align-items-center">
                                          <div>
                                            <img
                                              src={tripCancelIcon}
                                              alt="tripCancelIcon"
                                            />
                                          </div>
                                          <div className="mx-2">
                                            Looks like your Trip got cancelled.
                                          </div>
                                        </div>
                                      )}
                                    {isEMRAllowed(historyDetails) &&
                                      historyDetails.eventModificationRequest[
                                        historyDetails?.eventModificationRequest
                                          .length - 1
                                      ]?.status === 17 && (
                                        <div className="bg2196534D borderRadius8 p-2 d-flex align-items-center">
                                          <div>
                                            <img
                                              src={greenTickIcon}
                                              alt="greenTickIcon"
                                            />
                                          </div>
                                          <div className="mx-2">
                                            Your trip modification is all set!
                                            Feel free to submit another request
                                            if needed.
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  {filterBookingDetailsWithID?.length > 0 &&
                                    filterBookingDetailsWithID?.map(
                                      (details) => {
                                        return (
                                          <div key={details?.id}>
                                            <div
                                              className={`${
                                                individualEmrID === details?.id
                                                  ? "bg191916"
                                                  : ""
                                              } borderRadius8`}
                                            >
                                              <div
                                                onClick={() => {
                                                  if (
                                                    individualEmrID ===
                                                    details?.id
                                                  ) {
                                                    setIndividualEmrID("");
                                                  } else {
                                                    setIndividualEmrID(
                                                      details?.id
                                                    );
                                                  }
                                                }}
                                                className={`d-flex justify-content-between fontAntarctica fontSize13 fontWeight400 mt-3 ${
                                                  individualEmrID ===
                                                  details?.id
                                                    ? ""
                                                    : "borderFFFFFF0D"
                                                } borderRadius8 p-3`}
                                              >
                                                <div>
                                                  <div className="mb-2 text-nowrap">
                                                    {moment(
                                                      details?.pickupDateTime
                                                    ).format(
                                                      "MMM DD, YYYY hh:mm A"
                                                    )}
                                                    {/* {details?.pickupDateTime} */}
                                                  </div>
                                                  <div className="text-nowrap">
                                                    {moment(
                                                      details?.dropOffDateTime
                                                    ).format(
                                                      "MMM DD, YYYY hh:mm A"
                                                    )}
                                                    {/* {details?.dropOffDateTime} */}
                                                  </div>
                                                </div>
                                                {/* <div className="d-none d-lg-flex flex-column">
                                                  <div className="mb-2 opacity">
                                                    Comments
                                                  </div>
                                                  <div className="text-center">
                                                    {details?.comments
                                                      ? details?.comments
                                                      : "-"}
                                                  </div>
                                                </div> */}
                                                <div>
                                                  <div>
                                                    {details.status === 21 && (
                                                      <div className="bg4ACC mt-1 fontcolor2994A px-2 py-2 borderRadius8">
                                                        Pending
                                                      </div>
                                                    )}
                                                    {details.status === 17 && (
                                                      <div className="bg2196534D fontC885 px-3 py-2 borderRadius8">
                                                        Approved
                                                      </div>
                                                    )}
                                                    {details.status === 38 && (
                                                      <div className="bg2196534D  px-3 py-2 borderRadius8">
                                                        Zoho Sign Pending
                                                      </div>
                                                    )}
                                                    {details.status === 19 && (
                                                      <div className="bgEB57574D fontcolor336 px-3 py-2 borderRadius8">
                                                        Rejected
                                                      </div>
                                                    )}
                                                    {details.status === 8 && (
                                                      <div className="bgEB57574D px-3 py-2 borderRadius8">
                                                        Cancelled
                                                      </div>
                                                    )}
                                                    {details.status === 38 && (
                                                      <div className="bg-transparent px-3 py-2 borderRadius8">
                                                        Zoho Sign Pending
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="modificationRequestsLastColumn">
                                                  <div className="mb-2 text-end opacity">
                                                    {details?.noOfdays < 0
                                                      ? `- ${Math.abs(
                                                          details?.noOfdays
                                                        )} ${
                                                          details?.noOfdays ===
                                                          -1
                                                            ? "Day"
                                                            : "Days"
                                                        }`
                                                      : `${details?.noOfdays} ${
                                                          details?.noOfdays ===
                                                          1
                                                            ? "Day"
                                                            : "Days"
                                                        }`}
                                                  </div>

                                                  {/* <div className="text-end text-nowrap">
                                                    $ {details?.total}
                                                  </div> */}
                                                  <div className="text-end text-nowrap">
                                                    {details?.total < 0
                                                      ? `- $ ${Math.abs(
                                                          details?.total
                                                        ).toLocaleString(
                                                          undefined,
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}`
                                                      : `$ ${details?.total.toLocaleString(
                                                          undefined,
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}`}
                                                  </div>
                                                </div>
                                              </div>
                                              {individualEmrID ===
                                                details?.id &&
                                                (details?.status === 21 ? (
                                                  <div className="p-3 fontSize13 fontAntarctica fontWeight400">
                                                    <table className="w-100">
                                                      <thead>
                                                        <tr className="text-center">
                                                          <th className="text-start"></th>
                                                          <th className="emrDetails">
                                                            Changes
                                                          </th>
                                                          <th className="text-end emrDetails">
                                                            Difference
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tr>
                                                        <td colSpan="3">
                                                          <hr className="my-2" />
                                                        </td>
                                                      </tr>
                                                      <tbody>
                                                        <tr
                                                          className="my-2"
                                                          style={{
                                                            height: "10px",
                                                          }}
                                                        >
                                                          <td
                                                            colSpan="2"
                                                            className="border-0"
                                                          ></td>
                                                        </tr>
                                                        <tr>
                                                          <td className="requestTripExtensionwidth175 opacity">
                                                            Pick up date
                                                          </td>
                                                          <td className="text-center">
                                                            <div>
                                                              {specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.pickupDateTime ===
                                                              specificModificationRequest
                                                                ?.newBookingInfo
                                                                ?.pickupDateTime ? (
                                                                <div>
                                                                  {/* {
                                                                    specificModificationRequest
                                                                      ?.existingBookingInfo
                                                                      ?.pickupDateTime
                                                                  } */}
                                                                  <div>
                                                                    {specificModificationRequest
                                                                      ?.existingBookingInfo
                                                                      ?.pickupDateTime
                                                                      ? new Intl.DateTimeFormat(
                                                                          "en-US",
                                                                          {
                                                                            month:
                                                                              "short",
                                                                            day: "2-digit",
                                                                            year: "numeric",
                                                                            hour: "numeric",
                                                                            minute:
                                                                              "2-digit",
                                                                            hour12: true,
                                                                          }
                                                                        ).format(
                                                                          new Date(
                                                                            specificModificationRequest.existingBookingInfo.pickupDateTime
                                                                          )
                                                                        )
                                                                      : "N/A"}
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <div>
                                                                  <div className="strikethrough opacity70">
                                                                    {/* {
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.pickupDateTime
                                                                    } */}
                                                                    <div>
                                                                      {specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.pickupDateTime
                                                                        ? new Intl.DateTimeFormat(
                                                                            "en-US",
                                                                            {
                                                                              month:
                                                                                "short",
                                                                              day: "2-digit",
                                                                              year: "numeric",
                                                                              hour: "numeric",
                                                                              minute:
                                                                                "2-digit",
                                                                              hour12: true,
                                                                            }
                                                                          ).format(
                                                                            new Date(
                                                                              specificModificationRequest.existingBookingInfo.pickupDateTime
                                                                            )
                                                                          )
                                                                        : "N/A"}
                                                                    </div>
                                                                  </div>
                                                                  <div>
                                                                    {
                                                                      specificModificationRequest
                                                                        ?.newBookingInfo
                                                                        ?.pickupDateTime
                                                                    }
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td className="text-end">
                                                            -
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="opacity">
                                                            Drop off date 
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.dropOffDateTime ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.dropOffDateTime ? (
                                                              <div>
                                                                {/* {
                                                                  specificModificationRequest
                                                                    ?.existingBookingInfo
                                                                    ?.dropOffDateTime
                                                                } */}
                                                                <div>
                                                                  {specificModificationRequest
                                                                    ?.existingBookingInfo
                                                                    ?.dropOffDateTime
                                                                    ? new Intl.DateTimeFormat(
                                                                        "en-US",
                                                                        {
                                                                          month:
                                                                            "short",
                                                                          day: "2-digit",
                                                                          year: "numeric",
                                                                          hour: "numeric",
                                                                          minute:
                                                                            "2-digit",
                                                                          hour12: true,
                                                                        }
                                                                      ).format(
                                                                        new Date(
                                                                          specificModificationRequest.existingBookingInfo.dropOffDateTime
                                                                        )
                                                                      )
                                                                    : "N/A"}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <div className="strikethrough opacity70">
                                                                  {/* {
                                                                    specificModificationRequest
                                                                      ?.existingBookingInfo
                                                                      ?.dropOffDateTime
                                                                  } */}
                                                                  <div>
                                                                    {specificModificationRequest
                                                                      ?.existingBookingInfo
                                                                      ?.dropOffDateTime
                                                                      ? new Intl.DateTimeFormat(
                                                                          "en-US",
                                                                          {
                                                                            month:
                                                                              "short",
                                                                            day: "2-digit",
                                                                            year: "numeric",
                                                                            hour: "numeric",
                                                                            minute:
                                                                              "2-digit",
                                                                            hour12: true,
                                                                          }
                                                                        ).format(
                                                                          new Date(
                                                                            specificModificationRequest.existingBookingInfo.dropOffDateTime
                                                                          )
                                                                        )
                                                                      : "N/A"}
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  {/* {
                                                                    specificModificationRequest
                                                                      ?.newBookingInfo
                                                                      ?.dropOffDateTime
                                                                  } */}
                                                                  <div>
                                                                    {specificModificationRequest
                                                                      ?.newBookingInfo
                                                                      ?.dropOffDateTime
                                                                      ? new Intl.DateTimeFormat(
                                                                          "en-US",
                                                                          {
                                                                            month:
                                                                              "short",
                                                                            day: "2-digit",
                                                                            year: "numeric",
                                                                            hour: "numeric",
                                                                            minute:
                                                                              "2-digit",
                                                                            hour12: true,
                                                                          }
                                                                        ).format(
                                                                          new Date(
                                                                            specificModificationRequest.newBookingInfo.dropOffDateTime
                                                                          )
                                                                        )
                                                                      : "N/A"}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            -
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="opacity">
                                                            No. of Days
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.noOfDays ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.noOfDays ? (
                                                              <div>
                                                                {
                                                                  specificModificationRequest
                                                                    ?.existingBookingInfo
                                                                    ?.noOfDays
                                                                }
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span className="strikethrough opacity70">
                                                                  {
                                                                    specificModificationRequest
                                                                      ?.existingBookingInfo
                                                                      ?.noOfDays
                                                                  }
                                                                </span>
                                                                <span className="mx-2">
                                                                  {
                                                                    specificModificationRequest
                                                                      ?.newBookingInfo
                                                                      ?.noOfDays
                                                                  }
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {
                                                              specificModificationRequest
                                                                ?.eventModificationRequest
                                                                ?.noOfdays
                                                            }
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="opacity">
                                                            Base Rate
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.actualPrice ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.baseRate ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.actualPrice?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.actualPrice?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.actualPrice
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.baseRate
                                                                    )
                                                                  ).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.baseRate !==
                                                            undefined
                                                              ? `${
                                                                  specificModificationRequest
                                                                    .eventModificationRequest
                                                                    .baseRate <
                                                                  0
                                                                    ? "-"
                                                                    : ""
                                                                } $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    .eventModificationRequest
                                                                    .baseRate
                                                                ).toLocaleString(
                                                                  "en-US",
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )}`
                                                              : "N/A"}
                                                            {/* ${" "} */}
                                                            {/* {specificModificationRequest?.eventModificationRequest?.baseRate.toLocaleString(
                                                              "en-US",
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )} */}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                        
                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.ageFee !== 0 && (
                                                        <tr>
                                                          <td className="opacity">
                                                            Age Fee
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.ageFee ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.ageFee ? (
                                                              <div>
                                                                {specificModificationRequest
                                                                  ?.existingBookingInfo
                                                                  ?.paymentTransaction
                                                                  ?.ageFee !==
                                                                undefined
                                                                  ? `${
                                                                      specificModificationRequest
                                                                        .existingBookingInfo
                                                                        .paymentTransaction
                                                                        .ageFee <
                                                                      0
                                                                        ? "-"
                                                                        : ""
                                                                    }$ ${Math.abs(
                                                                      specificModificationRequest
                                                                        .existingBookingInfo
                                                                        .paymentTransaction
                                                                        .ageFee
                                                                    ).toFixed(
                                                                      2
                                                                    )}`
                                                                  : "N/A"}
                                                                {/* ${" "} */}
                                                                {/* {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.ageFee?.toFixed(
                                                                  2
                                                                )} */}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.ageFee?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.ageFee
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.ageFee
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.ageFee.toFixed(
                                                              2
                                                            )} */}
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.ageFee < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.ageFee
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.ageFee.toFixed(
                                                                  2
                                                                )}`}
                                                          </td>
                                                        </tr>
                                                              )}

                                                     {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.ageFee !== 0 && (
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                              )}
                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.stateTax !== 0 && (<tr>
                                                          <td className="opacity">
                                                            State Tax{" "} 
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.stateTaxPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.stateTax ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.stateTax ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.stateTax?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.stateTax?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.stateTax
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.stateTax
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.stateTax < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.stateTax
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.stateTax.toFixed(
                                                                  2
                                                                )}`}

                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.stateTax.toFixed(
                                                              
                                                            )} */}
                                                          </td>
                                                        </tr>
                                                              )}
                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.stateTax !== 0 && (<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                              )}


                                                       {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.tourismTax !==0 && (<tr>
                                                          <td className="opacity">
                                                          Tourism Tax 1{" "}
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.tourismTaxPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.tourismTax ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.tourismTax ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.tourismTax?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.tourismTax?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.tourismTax
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.tourismTax
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.tourismTax < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.tourismTax
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.tourismTax.toFixed(
                                                                  2
                                                                )}`}

                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.tourismTax.toFixed(
                                                              
                                                            )} */}
                                                          </td>
                                                        </tr>)}

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.tourismTax  !== 0 && (<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>)}

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.countyLocalTaxSi !==0 && (<tr>
                                                          <td className="opacity">
                                                          County Local Tax SI Tax{" "}
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.countyLocalTaxSiPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.countyLocalTaxSi ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.countyLocalTaxSi ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.countyLocalTaxSi?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.countyLocalTaxSi?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.countyLocalTaxSi
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.countyLocalTaxSi
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.countyLocalTaxSi < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.countyLocalTaxSi
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.countyLocalTaxSi.toFixed(
                                                                  2
                                                                )}`}

                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.countyLocalTaxSi.toFixed(
                                                              
                                                            )} */}
                                                          </td>
                                                        </tr>)}
                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.countyLocalTaxSi !==0 && (<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>)}


                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.countyDistrictTaxSp !== 0 && (
                                                        <tr>
                                                          <td className="opacity">
                                                          County District Tax SP Tax{" "}
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.countyDistrictTaxSpPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.countyDistrictTaxSp ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.countyDistrictTaxSp ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.countyDistrictTaxSp?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.countyDistrictTaxSp?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.countyDistrictTaxSp
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.countyDistrictTaxSp
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.countyDistrictTaxSp < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.countyDistrictTaxSp
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.countyDistrictTaxSp.toFixed(
                                                                  2
                                                                )}`}

                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.countyDistrictTaxSp.toFixed(
                                                              
                                                            )} */}
                                                          </td>
                                                        </tr>)}

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.countyDistrictTaxSp !== 0 && (<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>)}

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.artTaxLocalPortion !== 0 &&(
                                                        <tr>
                                                          <td className="opacity">
                                                          ART Tax Local Portion Tax{" "}
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.artTaxLocalPortionTaxPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.artTaxLocalPortion ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.artTaxLocalPortion ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.artTaxLocalPortion?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.artTaxLocalPortion?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.artTaxLocalPortion
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.artTaxLocalPortion
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.artTaxLocalPortion < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.artTaxLocalPortion
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.artTaxLocalPortion.toFixed(
                                                                  2
                                                                )}`}

                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.artTaxLocalPortion.toFixed(
                                                              
                                                            )} */}
                                                          </td>
                                                        </tr> )}

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.artTaxLocalPortion !== 0  && (
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>)}

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.artMetroPierAndExpoAuth !== 0 && (
                                                        <tr>
                                                          <td className="opacity">
                                                          ART Metro Pier And Expo Auth Tax{" "}
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.artMetroPierAndExpoAuthTaxPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.artMetroPierAndExpoAuth ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.artMetroPierAndExpoAuth ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.artMetroPierAndExpoAuth?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.artMetroPierAndExpoAuth?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.artMetroPierAndExpoAuth
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.artMetroPierAndExpoAuth
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.artMetroPierAndExpoAuth < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.artMetroPierAndExpoAuth
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.artMetroPierAndExpoAuth.toFixed(
                                                                  2
                                                                )}`}

                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.artMetroPierAndExpoAuth.toFixed(
                                                              
                                                            )} */}
                                                          </td>
                                                        </tr> )} 

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.artMetroPierAndExpoAuth !== 0 && (
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>)}

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.cityTax !== 0 && (

                                                        <tr>
                                                          <td className="opacity">
                                                            City Tax{" "}
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.cityTaxPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.cityTax ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.cityTax ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.cityTax?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.cityTax?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.cityTax
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.cityTax
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.cityTax < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.cityTax
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.cityTax.toFixed(
                                                                  2
                                                                )}`}
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.cityTax.toFixed(
                                                              2
                                                            )} */}
                                                          </td>
                                                        </tr>)}

                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.cityTax !== 0 && (<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                              )}

                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.useTax !== 0 && (
                                                        <tr>
                                                          <td className="opacity">
                                                            Use Tax{" "}
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.useTaxPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.useTax ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.useTax ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.useTax?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.useTax?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.useTax
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.useTax
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.useTax < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.useTax
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.useTax.toFixed(
                                                                  2
                                                                )}`}
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.useTax.toFixed(
                                                              2
                                                            )} */}
                                                          </td>
                                                        </tr>
                                                              )}
                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.useTax !== 0 && (<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                              )}


                                                         {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.metropolitanTax !==0 && (
                                                        <tr>
                                                          <td className="opacity">
                                                            Metropolitan Tax{" "}
                                                            {
                                                              specificModificationRequest
                                                                ?.existingBookingInfo
                                                                ?.paymentTransaction
                                                                ?.metropolitanTaxPercent
                                                            }
                                                            %
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.metropolitanTax ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.metropolitanTax ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.metropolitanTax?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.metropolitanTax?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.metropolitanTax
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.metropolitanTax
                                                                    )
                                                                  ).toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.metropolitanTax <
                                                            0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.metropolitanTax
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.metropolitanTax.toFixed(
                                                                  2
                                                                )}`}
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.metropolitanTax.toFixed(
                                                              2
                                                            )} */}
                                                          </td>
                                                        </tr>
                                                              )}
                                                        {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.metropolitanTax !==0 && (<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                              )}

                                                       { specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.rentalCoverageCharge !== 0 && <tr>
                                                          <td className="opacity">
                                                            Rental Coverage
                                                            Charges 
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.rentalCoverageCharge ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.rentalCoverageCharge ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.rentalCoverageCharge?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.rentalCoverageCharge?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(Number(
                                                                    specificModificationRequest?.existingBookingInfo?.paymentTransaction?.rentalCoverageCharge.toFixed(
                                                                      2
                                                                    )
                                                                  ) +
                                                                    Number(
                                                                      specificModificationRequest?.eventModificationRequest?.rentalCoverageCharge.toFixed(
                                                                        2
                                                                      )
                                                                    ))?.toFixed(2)}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.rentalCoverageCharge <
                                                            0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.rentalCoverageCharge
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.rentalCoverageCharge.toFixed(
                                                                  2
                                                                )}`}
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.rentalCoverageCharge?.toFixed(
                                                              2
                                                            )} */}
                                                          </td>
                                                        </tr>
                                      }
                                                         { specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.rentalCoverageCharge !== 0 &&<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>}

                                                        {specificModificationRequest
                                                          ?.existingBookingInfo
                                                          ?.paymentTransaction
                                                          ?.additionalTripCharges !==
                                                          0 && (
                                                          <>
                                                            {/* <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr> */}
                                                            <tr>
                                                              <td className="opacity">
                                                                Additional
                                                                Charges  
                                                              </td>
                                                              <td className="text-center">
                                                                {specificModificationRequest
                                                                  ?.existingBookingInfo
                                                                  ?.paymentTransaction
                                                                  ?.additionalTripCharges ===
                                                                specificModificationRequest
                                                                  ?.newBookingInfo
                                                                  ?.estimatedPrice
                                                                  ?.additionalTripCharges ? (
                                                                  <div>
                                                                    ${" "}
                                                                    {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.additionalTripCharges?.toFixed(
                                                                      2
                                                                    )}
                                                                  </div>
                                                                ) : (
                                                                  <div>
                                                                    <span>
                                                                      $
                                                                    </span>
                                                                    <span className="strikethrough opacity70 mx-2">
                                                                      {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.additionalTripCharges?.toFixed(
                                                                        2
                                                                      )}
                                                                    </span>
                                                                    <span>
                                                                      {specificModificationRequest?.newBookingInfo?.estimatedPrice?.additionalTripCharges?.toFixed(
                                                                        2
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                )}
                                                              </td>
                                                              <td className="text-end">
                                                                {specificModificationRequest
                                                                  ?.eventModificationRequest
                                                                  ?.additionalTripCharges <
                                                                0
                                                                  ? `- $ ${Math.abs(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.additionalTripCharges
                                                                    ).toFixed(
                                                                      2
                                                                    )}`
                                                                  : `$ ${specificModificationRequest?.eventModificationRequest?.additionalTripCharges.toFixed(
                                                                      2
                                                                    )}`}
                                                                {/* ${" "}
                                                                {specificModificationRequest?.eventModificationRequest?.additionalTripCharges?.toFixed(
                                                                  2
                                                                )} */}
                                                              </td>
                                                            </tr>
                                                          </>
                                                        )}
                                                        {specificModificationRequest
                                                          ?.existingBookingInfo
                                                          ?.paymentTransaction
                                                          ?.additionalTripCharges !==
                                                          0 &&<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                      }




                                            { specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.preTripCharges !== 0 && <tr>
                                                          <td className="opacity">
                                                          PreTrip 
                                                            Charges 
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.preTripCharges ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.preTripCharges ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.preTripCharges?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.preTripCharges?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {Number(
                                                                    specificModificationRequest?.existingBookingInfo?.paymentTransaction?.preTripCharges.toFixed(
                                                                      2
                                                                    )
                                                                  ) +
                                                                    Number(
                                                                      specificModificationRequest?.eventModificationRequest?.preTripCharges.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.preTripCharges <
                                                            0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.preTripCharges
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.preTripCharges.toFixed(
                                                                  2
                                                                )}`}
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.rentalCoverageCharge?.toFixed(
                                                              2
                                                            )} */}
                                                          </td>
                                                        </tr>
                                      }
                                                         { specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.preTripCharges !== 0 &&<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>}

                                                       



                                                        { specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.additionalCoverageCharge !== 0 && <tr>
                                                          <td className="opacity">
                                                          Additional Coverage Charges
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.additionalCoverageCharge ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.additionalCoverageCharge ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.additionalCoverageCharge?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.additionalCoverageCharge?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {Number(
                                                                    specificModificationRequest?.existingBookingInfo?.paymentTransaction?.additionalCoverageCharge.toFixed(
                                                                      2
                                                                    )
                                                                  ) +
                                                                    Number(
                                                                      specificModificationRequest?.eventModificationRequest?.additionalCoverageCharge.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.additionalCoverageCharge <
                                                            0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.additionalCoverageCharge
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.additionalCoverageCharge.toFixed(
                                                                  2
                                                                )}`}
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.rentalCoverageCharge?.toFixed(
                                                              2
                                                            )} */}
                                                          </td>
                                                        </tr>
                                      }
                                                         { specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.additionalCoverageCharge !== 0 &&<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>}

                                                       







                                                        { specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.discount !== 0 && <tr>
                                                          <td className="opacity">
                                                          Discount  Applied
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.discount ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.discount ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.discount?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.discount?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {Number(
                                                                    specificModificationRequest?.existingBookingInfo?.paymentTransaction?.discount.toFixed(
                                                                      2
                                                                    )
                                                                  ) +
                                                                    Number(
                                                                      specificModificationRequest?.eventModificationRequest?.discount.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.discount <
                                                            0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.discount
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.discount.toFixed(
                                                                  2
                                                                )}`}
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.rentalCoverageCharge?.toFixed(
                                                              2
                                                            )} */}
                                                          </td>
                                                        </tr>
                                      }
                                                         { specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.discount !== 0 &&<tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>}

                                                       

                                                      

                                                       

                                                        <tr>
                                                          <td className="opacity">
                                                            Total Cost
                                                          </td>
                                                          <td className="text-center">
                                                            {specificModificationRequest
                                                              ?.existingBookingInfo
                                                              ?.paymentTransaction
                                                              ?.total ===
                                                            specificModificationRequest
                                                              ?.newBookingInfo
                                                              ?.estimatedPrice
                                                              ?.total ? (
                                                              <div>
                                                                ${" "}
                                                                {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.total?.toFixed(
                                                                  2
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <span>$</span>
                                                                <span className="strikethrough opacity70 mx-2">
                                                                  {specificModificationRequest?.existingBookingInfo?.paymentTransaction?.total?.toFixed(
                                                                    2
                                                                  )}
                                                                </span>
                                                                <span>
                                                                  {(
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.existingBookingInfo
                                                                        ?.paymentTransaction
                                                                        ?.total
                                                                    ) +
                                                                    Number(
                                                                      specificModificationRequest
                                                                        ?.eventModificationRequest
                                                                        ?.total
                                                                    )
                                                                  ).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {specificModificationRequest
                                                              ?.eventModificationRequest
                                                              ?.total < 0
                                                              ? `- $ ${Math.abs(
                                                                  specificModificationRequest
                                                                    ?.eventModificationRequest
                                                                    ?.total
                                                                ).toFixed(2)}`
                                                              : `$ ${specificModificationRequest?.eventModificationRequest?.total.toFixed(
                                                                  2
                                                                )}`}
                                                            {/* ${" "}
                                                            {specificModificationRequest?.eventModificationRequest?.total?.toFixed(
                                                              2
                                                            )} */}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                ) : (
                                                  <div className="p-3 fontSize13 fontWeight400 fontAntarctica">
                                                    <table className="w-100">
                                                      <thead>
                                                        <tr className="text-center fw-bold">
                                                          <th className="text-start"></th>
                                                          <th className="text-end fw-normal opacity">
                                                            Changes
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tr>
                                                        <td colSpan="3">
                                                          <hr className="my-2" />
                                                        </td>
                                                      </tr>
                                                      <tbody>
                                                        <tr
                                                          className="my-2"
                                                          style={{
                                                            height: "10px",
                                                          }}
                                                        >
                                                          <td
                                                            colSpan="2"
                                                            className="border-0"
                                                          ></td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>Pickup date</td>
                                                            <td className="text-end">
                                                              <div>
                                                                {
                                                                  details?.pickupDateTime
                                                                }
                                                              </div>
                                                            </td>
                                                          </tr> */}
                                                        {/* <tr>
                                                            <td colSpan="3">
                                                              <hr className="my-2" />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>Drop date</td>
                                                            <td className="text-end">
                                                              <div>
                                                                {
                                                                  details?.dropOffDateTime
                                                                }
                                                              </div>
                                                            </td>
                                                          </tr> */}
                                                        {/* <tr>
                                                            <td colSpan="3">
                                                              <hr className="my-2" />
                                                            </td>
                                                          </tr> */}
                                                        <tr>
                                                          <td className="opacity">
                                                            No. of Days
                                                          </td>
                                                          <td className="text-end">
                                                            <div className="">
                                                              {/* {
                                                                details?.noOfdays
                                                              } */}
                                                              <span>
                                                                {details?.noOfdays <
                                                                0
                                                                  ? `- ${Math.abs(
                                                                      details?.noOfdays
                                                                    )}`
                                                                  : details?.noOfdays}
                                                              </span>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="opacity">
                                                            Base Rate
                                                          </td>
                                                          <td className="text-end">
                                                            <div>
                                                              {/* ${" "}
                                                              {details?.baseRate?.toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )} */}
                                                              <span>
                                                                {details?.baseRate <
                                                                0
                                                                  ? `- $ ${Math.abs(
                                                                      details?.baseRate
                                                                    ).toLocaleString(
                                                                      undefined,
                                                                      {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )}`
                                                                  : `$ ${details?.baseRate.toLocaleString(
                                                                      undefined,
                                                                      {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )}`}
                                                              </span>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        {details?.ageFee ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                                Age Fee
                                                              </td>
                                                              <td className="text-end">
                                                                <div className="">
                                                                  {/* ${" "}
                                                                  {details?.ageFee?.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )} */}
                                                                  <span>
                                                                    {details?.ageFee <
                                                                    0
                                                                      ? `- $ ${Math.abs(
                                                                          details?.ageFee
                                                                        ).toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}`
                                                                      : `$ ${details?.ageFee.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}`}
                                                                  </span>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {details?.stateTax ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                                State Tax{" "}
                                                                {
                                                                  details?.stateTaxPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end">
                                                                <div className="">
                                                                  {/* {details?.stateTax?.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )} */}
                                                                  <span>
                                                                    {details?.stateTax <
                                                                    0
                                                                      ? `- $ ${Math.abs(
                                                                          details?.stateTax
                                                                        ).toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}`
                                                                      : `$ ${details?.stateTax.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}`}
                                                                  </span>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {details?.cityTax ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                                City Tax{" "}
                                                                {
                                                                  details?.cityTaxPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.cityTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.cityTax <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.cityTax
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.cityTax.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {details?.useTax ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                                Use Tax{" "}
                                                                {
                                                                  details?.useTaxPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.useTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.useTax <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.useTax
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.useTax.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {details?.metropolitanTax ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                                Metropolitan Tax{" "}
                                                                {
                                                                  details?.metropolitanTaxPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.metropolitanTax <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.metropolitanTax
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.metropolitanTax.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}

                                                        {details?.tourismTax ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              Tourism Tax{" "}
                                                                {
                                                                  details?.tourismTaxPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.tourismTax <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.tourismTax
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.tourismTax.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}



                                                 {details?.artMetroPierAndExpoAuth ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              ART Metro Pier And Expo Auth Tax {" "}
                                                                {
                                                                  details?.artMetroPierAndExpoAuthTaxPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.artMetroPierAndExpoAuth <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.artMetroPierAndExpoAuth
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.artMetroPierAndExpoAuth.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}









                                     {details?.artTaxLocalPortion ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              ART Tax-Local Portion Tax{" "}
                                                                {
                                                                  details?.artTaxLocalPortionTaxPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.artTaxLocalPortion <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.artTaxLocalPortion
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.artTaxLocalPortion.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}



{details?.countyDistrictTaxSp ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              County District Tax SP {" "}
                                                                {
                                                                  details?.countyDistrictTaxSpPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.countyDistrictTaxSp <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.countyDistrictTaxSp
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.countyDistrictTaxSp.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}




{details?.countyLocalTaxSi ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              County Local Tax SI{" "}
                                                                {
                                                                  details?.countyLocalTaxSiPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.countyLocalTaxSi <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.countyLocalTaxSi
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.countyLocalTaxSi.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}








        {details?.preTripCharges ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              Pre Trip Charges{" "}
                                                               
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.preTripCharges <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.preTripCharges
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.preTripCharges.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}


{details?.rentalCoverageCharge ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              Rental Coverage Charge{" "}
                                                               
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.rentalCoverageCharge <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.rentalCoverageCharge
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.rentalCoverageCharge.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}



{details?.additionalCoverageCharge ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              Additional Coverage Charge{" "}
                                                                
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.additionalCoverageCharge <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.additionalCoverageCharge
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.additionalCoverageCharge.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}


{details?.discount ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                              Discount Applied{" "}
                                                                {
                                                                  details?.tourismTaxPercent
                                                                }
                                                                %
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {/* $
                                                                {details?.metropolitanTax?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                                <span>
                                                                  {details?.discount <
                                                                  0
                                                                    ? `- $ ${Math.abs(
                                                                        details?.discount
                                                                      ).toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`
                                                                    : `$ ${details?.discount.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                        }
                                                                      )}`}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}





                                                        {details?.additionalTripCharges ? (
                                                          <>
                                                            <tr>
                                                              <td colSpan="3">
                                                                <hr className="my-2" />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td className="opacity">
                                                                Additional
                                                                Charges
                                                              </td>
                                                              <td className="text-end mx-2">
                                                                {details?.additionalTripCharges <
                                                                0
                                                                  ? `- $ ${Math.abs(
                                                                      details?.additionalTripCharges
                                                                    ).toLocaleString(
                                                                      undefined,
                                                                      {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )}`
                                                                  : `$ ${details?.additionalTripCharges.toLocaleString(
                                                                      undefined,
                                                                      {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )}`}
                                                                {/* $
                                                                {details?.additionalTripCharges?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )} */}
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="opacity">
                                                            Total Cost
                                                          </td>
                                                          <td className="text-end">
                                                            <div>
                                                              {/* {details?.total?.toLocaleString(
                                                                "en-US",
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )} */}
                                                              {details?.total <
                                                              0
                                                                ? `- $ ${Math.abs(
                                                                    details?.total
                                                                  ).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )}`
                                                                : `$ ${details?.total.toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )}`}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td colSpan="3">
                                                            <hr className="my-2" />
                                                          </td>
                                                        </tr>
                                                      <tr>
                                                        <td className="opacity">
                                                            Comments
                                                        </td>
                                                          <td className="text-end">
                                                          <div>
                                                        {details?.comments
                                                          ? details?.comments
                                                          : "-"}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                        {(details?.rentalDocumentSharedLink && details?.rentalDocumentSharedLink !=
                                                          null) &&(
                                                          <div className="mt-2">
                                                            <div>
                                                              <a
                                                                className="fontColorCE1 text-decoration-none fontSize14"
                                                                href={
                                                                  details?.rentalDocumentSharedLink
                                                                }
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                View Rental
                                                                Agreement
                                                              </a>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </DesktopCustomModal>
                              {isEMRAllowed(historyDetails) &&
                                historyDetails.eventModificationRequest
                                  .length === 0 && (
                                  <button
                                    onClick={() => {
                                      // handleToggle("calendarAndTime");
                                      openModal("TripModify");
                                      fetchEmrTimings(historyDetails?.id);
                                      dispatch(
                                        addEmrPickupAndDropDates({
                                          pickupDate: format(
                                            historyDetails?.pickupDateTime,
                                            "MMM dd, yyyy"
                                          ),
                                          dropDate: format(
                                            historyDetails?.dropOffDateTime,
                                            "MMM dd, yyyy"
                                          ),
                                        })
                                      );
                                      dispatch(
                                        addEmrPickupAndDropTimings({
                                          pickupTime:
                                            historyDetails?.pickupDateTime.split(
                                              ", "
                                            )[1],
                                          dropTime:
                                            historyDetails?.dropOffDateTime.split(
                                              ", "
                                            )[1],
                                        })
                                      );
                                    }}
                                    className="fontSize13 border-0 bg3B3936 outlineNone myTripsButtons text-white borderRadius8"
                                  >
                                    Modify Trip
                                  </button>
                                )}
                              {historyDetails.eventModificationRequest
                                .length !== 0 && (
                                <button
                                  onClick={() => {
                                    openModal("Modification Requests");
                                    filteredBookingId(
                                      historyDetails?.eventModificationRequest
                                    );
                                    if (
                                      historyDetails?.eventModificationRequest[
                                        historyDetails?.eventModificationRequest
                                          ?.length - 1
                                      ]?.status === 21
                                    ) {
                                      fetchSpecificModificationRequest(
                                        historyDetails
                                          ?.eventModificationRequest[
                                          historyDetails
                                            ?.eventModificationRequest?.length -
                                            1
                                        ]?.id
                                      );
                                    }
                                  }}
                                  className="fontSize13 border-0 bg3B3936 outlineNone myTripsButtons text-white borderRadius8"
                                >
                                  Modification Requests
                                </button>
                              )}
                              {historyDetails?.statusMessage !== "Rejected" &&
                                historyDetails?.statusMessage !== "Cancelled" &&
                                historyDetails?.status !== 13 && (
                                  <button
                                    onClick={() => {
                                      pdfByBookingId(historyDetails?.id);
                                    }}
                                    className="fontSize13 border-0  bgCE1 outlineNone myTripsButtons text-white borderRadius8"
                                  >
                                    Download Receipt
                                  </button>
                                )}
                              {/* <button className="fontSize9 border-0  bg3B3936 outlineNone myTripsButtons text-white borderRadius8">
                                  Transaction History
                                </button> */}
                              {historyDetails?.statusMessage === "Rejected" ||
                              historyDetails?.statusMessage === "Completed" ||
                              historyDetails?.statusMessage === "On-Going" ||
                              historyDetails?.statusMessage === "Cancelled" ? (
                                ""
                              ) : (
                                <button
                                  onClick={() => {
                                    // navigate("/cancel-booking")
                                    navigate("/cancel-booking", {
                                      state: {
                                        bookingDetails: historyDetails,
                                      },
                                    });
                                  }}
                                  className="fontSize13 fontAntarctica border-0  bgB5757 outlineNone myTripsButtons text-white borderRadius8"
                                >
                                  Cancel Booking
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-none d-lg-block  borderC780 borderRadius12 mb-4 col-12 cursorPointer">
                        <div className="d-flex justify-content-between align-items-center p-3 fontAntarctica fontWeight400">
                          <div className="col-3 p-1 d-flex align-items-center">
                            <div>
                              <img
                                src={
                                  historyDetails?.carImage ||
                                  "https://s3.amazonaws.com/joulez-dev/3fc2c8c1-ca83-4801-9bbf-50ae0d59492c/fc842b92-d7f1-4ad8-a1b8-3f853c0872e1_file1"
                                }
                                alt="duplicateCarImage"
                                className="myTripOpenCarImage borderRadius4 mx-2 mb-1"
                              />
                            </div>
                            <div>
                              <div>
                                <div className="mb-1 fontSize14">
                                  {historyDetails?.carName}{" "}
                                  {historyDetails?.carModel}
                                </div>
                                <div className="fontSize13 opacity70">
                                  {historyDetails?.carType}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-5 p-1 d-flex">
                            <div>
                              <div className="mb-1 fontSize14">
                                {moment(historyDetails?.pickupDateTime).format(
                                  "MMM DD, YYYY hh:mm A"
                                )}
                                &nbsp; - &nbsp;
                                {moment(historyDetails?.dropOffDateTime).format(
                                  "MMM DD, YYYY hh:mm A"
                                )}
                                {/* {historyDetails?.dropOffDateTime} */}
                              </div>
                              <div className="fontSize13">
                                Booked for {historyDetails?.noOfDays}{" "}
                                {historyDetails?.noOfDays === 1 ||
                                historyDetails?.noOfDays === -1
                                  ? "Day"
                                  : "Days"}
                              </div>
                            </div>
                          </div>
                          <div className="col-2 p-1 d-flex">
                            <div>
                              <div className="mb-1 fontSize14">
                                ${" "}
                                {historyDetails?.grandTotal?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </div>
                              <div className="fontSize13">
                                Paid using XX {historyDetails?.last4}
                              </div>
                            </div>
                          </div>
                          <div className="col-2 p-1 d-flex justify-content-end">
                            <div className="fontSize13 text-end">
                              <div
                                className={`mb-2 px-3 px-lg-1 py-1 borderRadius4 text-center  ${
                                  historyDetails?.statusMessage === "Upcoming"
                                    ? "bgCE1"
                                    : historyDetails?.statusMessage ===
                                      "Review Pending"
                                    ? "bg4ACC"
                                    : historyDetails?.statusMessage ===
                                      "On-Going"
                                    ? "bg653CC"
                                    : historyDetails?.statusMessage ===
                                      "Completed"
                                    ? "bgD0C7 text-dark"
                                    : historyDetails?.statusMessage ===
                                      "Cancelled"
                                    ? "bgB5757"
                                    : historyDetails?.statusMessage ===
                                      "Rejected"
                                    ? "bgB5757"
                                    : ""
                                } `}
                              >
                                {historyDetails?.statusMessage}
                              </div>
                              <div>{historyDetails?.bookingRefId}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-block d-lg-none  borderC780 borderRadius12 mb-4 col-12">
                        <div className="p-2 fontAntarctica fontWeight400">
                          <div className="p-1 d-flex justify-content-between">
                            <div className="fontSize14">
                              {historyDetails?.carName}{" "}
                              {historyDetails?.carModel}{" "}
                              <span className="fontSize13 opacity70">
                                {historyDetails?.carType}
                              </span>
                            </div>
                            <div>
                              $
                              {historyDetails?.grandTotal?.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}{" "}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <img
                                src={
                                  historyDetails?.carImage ||
                                  "https://s3.amazonaws.com/joulez-dev/3fc2c8c1-ca83-4801-9bbf-50ae0d59492c/fc842b92-d7f1-4ad8-a1b8-3f853c0872e1_file1"
                                }
                                alt="duplicateCarImage"
                                className="myTripOpenCarImage borderRadius4 mx-2 mb-1"
                              />
                            </div>
                            <div className="p-1 fontSize13 text-center">
                              <div
                                className={`px-4 py-1 mb-1
                               ${
                                 historyDetails?.statusMessage === "Upcoming"
                                   ? "bgCE1"
                                   : historyDetails?.statusMessage ===
                                     "Review Pending"
                                   ? "bg4ACC"
                                   : historyDetails?.statusMessage ===
                                     "On-Going"
                                   ? "bg653CC"
                                   : historyDetails?.statusMessage ===
                                     "Completed"
                                   ? "bgD0C7 text-dark"
                                   : historyDetails?.statusMessage ===
                                     "Cancelled"
                                   ? "bgB5757"
                                   : ""
                               }  borderRadius4 fontSize13`}
                              >
                                {historyDetails?.statusMessage}
                              </div>
                              <div>{historyDetails?.bookingRefId}</div>
                            </div>
                          </div>
                          <div className="p-1 fontSize13 justify-content-between">
                            <div className="p-1 fontSize13 d-flex justify-content-between">
                              <span>
                                {moment(historyDetails?.pickupDateTime).format(
                                  "MMM DD, YYYY hh:mm A"
                                )}
                              </span>{" "}
                              -
                              <span>
                                {moment(historyDetails?.dropOffDateTime).format(
                                  "MMM DD, YYYY hh:mm A"
                                )}
                              </span>
                            </div>

                            {/* {moment(historyDetails?.pickupDateTime).format(
                                "MMM DD, YYYY hh:mm A"
                              )} */}
                            {/* {historyDetails?.pickupDateTime} */}
                            {/* &nbsp; - &nbsp;
                              {moment(historyDetails?.dropOffDateTime).format(
                                "MMM DD, YYYY hh:mm A"
                              )} */}
                            {/* {historyDetails?.dropOffDateTime} */}
                          </div>
                          <div className="p-1 fontSize13">
                            Booked for {historyDetails?.noOfDays}{" "}
                            {historyDetails?.noOfDays === 1 ||
                            historyDetails?.noOfDays === -1
                              ? "Day"
                              : "Days"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })
          )}
          {otherBookingHistoryFilteredData?.length > 0 &&
            otherBookingHistoryFilteredData?.map((otherBooking, index) => {
              return (
                <div key={index}>
                  <div
                    onClick={() => setOtherBookingID(index)}
                    className={`borderC780 ${
                      index === otherBookingID ? "bg191916" : ""
                    } borderRadius12 p-2 p-lg-3 mb-3`}
                  >
                    <div className="d-none d-lg-flex justify-content-between align-items-center  col-12 fontAntarctica fontWeight400">
                      <div className="col-3">
                        <div className="d-flex align-items-center">
                          <img
                            className="myTripOpenCarImage borderRadius4 mx-2 mb-1"
                            src="https://s3.amazonaws.com/joulez-dev/3fc2c8c1-ca83-4801-9bbf-50ae0d59492c/fc842b92-d7f1-4ad8-a1b8-3f853c0872e1_file1"
                            alt="otherBookingCarImage"
                          />
                          <div>
                            <div className="mb-1 fontSize14">
                              {otherBooking?.brand} {otherBooking?.vehicleModel}
                            </div>
                            <div className="fontSize13 opacity70">
                              {otherBooking?.vehicleType}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="mb-1 fontSize14">
                          {format(
                            new Date(otherBooking?.startDateTime),
                            "MMM dd, yyyy hh:mm a"
                          )}{" "}
                          -{" "}
                          {format(
                            new Date(otherBooking?.endDateTime),
                            "MMM dd, yyyy hh:mm a"
                          )}
                        </div>
                        <div className="fontSize13">
                          Booked for{" "}
                          {differenceInDays(
                            new Date(otherBooking?.endDateTime),
                            new Date(otherBooking?.startDateTime)
                          )}{" "}
                          day
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="fontSize14">Other Bookings</div>
                        <div className="fontSize13">
                          Booking Id: {otherBooking?.bookingReferenceId}
                        </div>
                        <div className="fontSize13">
                          Booking type: {otherBooking?.bookingType}{" "}
                        </div>
                      </div>
                      {getTripStatus(
                        otherBooking?.startDateTime,
                        otherBooking?.endDateTime,
                        otherBooking?.timeZone
                      ) === "Completed" && (
                        <div
                          className={`col-2 p-1 d-flex justify-content-end `}
                        >
                          <div className="fontSize13 text-end">
                            <div
                              className={`mb-2 px-3 py-1 borderRadius4 text-center  bgD0C7 text-dark`}
                            >
                              Completed
                            </div>
                          </div>
                        </div>
                      )}
                      {getTripStatus(
                        otherBooking?.startDateTime,
                        otherBooking?.endDateTime,
                        otherBooking?.timeZone
                      ) === "Upcoming" && (
                        <div className="col-2 p-1 d-flex justify-content-end">
                          <div className="fontSize13 text-end">
                            <div
                              className={`mb-2 px-3 px-lg-1 py-1 borderRadius4 text-center bgCE1`}
                            >
                              Upcoming
                            </div>
                            <div>BHJHGHJ4567</div>
                          </div>
                        </div>
                      )}
                      {getTripStatus(
                        otherBooking?.startDateTime,
                        otherBooking?.endDateTime,
                        otherBooking?.timeZone
                      ) === "On-Going" && (
                        <div className="col-2 p-1 d-flex justify-content-end">
                          <div className="fontSize13 text-end">
                            <div
                              className={`mb-2 px-3 px-lg-1 py-1 borderRadius4 text-center bg653CC`}
                            >
                              Upcoming
                            </div>
                            <div>BHJHGHJ4567</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-block d-lg-none  col-12">
                      <div className="p-2 fontAntarctica fontWeight400">
                        <div className="p-1 d-flex justify-content-between">
                          <div className="fontSize14">
                            {otherBooking?.brand} {}{" "}
                            <span className="fontSize13 opacity70">
                              {otherBooking?.vehicleType}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <img
                              src={
                                "https://s3.amazonaws.com/joulez-dev/3fc2c8c1-ca83-4801-9bbf-50ae0d59492c/fc842b92-d7f1-4ad8-a1b8-3f853c0872e1_file1"
                              }
                              alt="otherBookingduplicateMobileScreenCarImage"
                              className="myTripOpenCarImage borderRadius4 mx-2 mb-1"
                            />
                          </div>
                          <div className="p-1 fontSize13 text-center">
                            {getTripStatus(
                              otherBooking?.startDateTime,
                              otherBooking?.endDateTime,
                              otherBooking?.timeZone
                            ) === "Completed" && (
                              <div
                                className={`px-4 py-1 mb-1 borderRadius4 fontSize13 bgD0C7 text-dark`}
                              >
                                Completed
                              </div>
                            )}
                            {getTripStatus(
                              otherBooking?.startDateTime,
                              otherBooking?.endDateTime,
                              otherBooking?.timeZone
                            ) === "Upcoming" && (
                              <div
                                className={`px-4 py-1 mb-1 borderRadius4 fontSize13 bgCE1`}
                              >
                                Upcoming
                              </div>
                            )}
                            {getTripStatus(
                              otherBooking?.startDateTime,
                              otherBooking?.endDateTime,
                              otherBooking?.timeZone
                            ) === "On-Going" && (
                              <div
                                className={`px-4 py-1 mb-1 borderRadius4 fontSize13 bg653CC`}
                              >
                                On-Going
                              </div>
                            )}
                            <div>{otherBooking?.bookingReferenceId}</div>
                          </div>
                        </div>
                        <div className="p-1 fontSize13 justify-content-between fontAntarctica">
                          <div className="p-1 fontSize13 d-flex justify-content-between">
                            <span>
                              {format(
                                new Date(otherBooking?.startDateTime),
                                "MMM dd, yyyy hh:mm a"
                              )}
                            </span>{" "}
                            -
                            <span>
                              {format(
                                new Date(otherBooking?.endDateTime),
                                "MMM dd, yyyy hh:mm a"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="p-1 fontSize13">
                          Booked for{" "}
                          {differenceInDays(
                            new Date(otherBooking?.endDateTime),
                            new Date(otherBooking?.startDateTime)
                          )}{" "}
                          {differenceInDays(
                            new Date(otherBooking?.endDateTime),
                            new Date(otherBooking?.startDateTime)
                          ) === 1
                            ? "Day"
                            : "Days"}
                        </div>
                      </div>
                    </div>
                    {otherBookingID === index && (
                      <div>
                        <div className=" bg3B3936 px-2 py-4 borderRadius8 mb-2 col-12 col-lg-6">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="fontSize14">Pickup</div>
                            <div className="fontSize13 fontWeight400">
                              {format(
                                new Date(otherBooking?.startDateTime),
                                "MMM dd, yyyy hh:mm a"
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="d-flex">
                              <img
                                src={colorLocateIcon}
                                alt="colorLocateIcon"
                                className="colorLocateIcon"
                              />
                              <div className="mx-2 fontSize13 fontWeight400 opacity80">
                                {otherBooking?.pickupAddressLine}{" "}
                                {otherBooking?.pickupState}{" "}
                                {otherBooking?.pickupZipcode}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" bg3B3936 px-2 py-4 borderRadius8 mb-2 col-12 col-lg-6">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="fontSize14">Drop</div>
                            <div className="fontSize13 fontWeight400">
                              {format(
                                new Date(otherBooking?.endDateTime),
                                "MMM dd, yyyy hh:mm a"
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="d-flex">
                              <img
                                src={colorLocateIcon}
                                alt="colorLocateIcon"
                                className="colorLocateIcon"
                              />
                              <div className="mx-2 fontSize13 fontWeight400 opacity80">
                                {otherBooking?.dropoffAddressLine}{" "}
                                {otherBooking?.dropoffState}{" "}
                                {otherBooking?.dropoffZipcode}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default MyTrips;

// import { useDispatch, useSelector } from "react-redux";
// import CalendarSelector from "./CalendarSelector";
// import { addPickupAndDropTimings } from "../../Store/dateAndTimeSlice";
// import { addEmrPickupAndDropTimings } from "../../Store/emrTimeAndDateSlice";

// const DateTimeSlotContainer = ({
//   setPickupBg,
//   SetDropBg,
//   pickupBg,
//   dropBg,
//   pickupTimeSlotData,
//   dropTimeSlotData,
//   bookingUIPage,
//   myTripPage,
//   bookingStatus
// }) => {

//     // Format options for displaying date
//     const options = { year: "numeric", month: "short", day: "numeric" };

//     // Get the current day formatted
//     const currentDay = new Date().toLocaleDateString("en-US", options);

//   const currentTimings = useSelector(
//     (store) => store?.currentDatesAndTimings?.pickupAndDropTimings
//   );

//   const currentEmrTimings = useSelector(
//     (store) => store?.emrDatesAndTimings?.pickupAndDropTimings
//   );

//   const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
//   const dispatch = useDispatch();
//   return (
//     <div
//       className={`dateAndTimeSlotConatiner ${
//         bookingUIPage || myTripPage ? "" : "marginLeft-350px"
//       } `}
//     >
//       <CalendarSelector myTripPage={myTripPage} bookingStatus={bookingStatus} />
//       <hr />
//       <div
//         className={`timeContainer ${isDarkMode ? "darkGreyMode" : "lightMode"}`}
//       >
//         <div className="mt-3">
//           <div className="d-flex justify-content-around  ">
//             <div>
//               <h6 className="fontAntarctica timeHeaders">Pick up</h6>
//               <div className="timeSlotContainer customizedScrollBar mt-3">
//                 {pickupTimeSlotData?.length > 0 && pickupTimeSlotData?.map((pickupTime, index) => {
//                   return (
//                     <p
//                       onClick={() => {
//                         setPickupBg(index);
//                         if (myTripPage) {
//                           dispatch(addEmrPickupAndDropTimings({ pickupTime }));
//                           // dispatch(
//                           //   addEmrPickupAndDropTimings({
//                           //     dropTime: pickupTimeSlotData[index + 1],
//                           //   })
//                           // );
//                         } else {
//                           dispatch(addPickupAndDropTimings({ pickupTime }));
//                           if(currentDay === currentTimings?.pickupAndDropDates?.pickupDate && currentDay === currentTimings?.pickupAndDropDates?.dropDate){
//                             dispatch(
//                               addPickupAndDropTimings({
//                                 dropTime: pickupTimeSlotData[index + 1],
//                               })
//                             );
//                           } else {
//                             dispatch(
//                               addPickupAndDropTimings({
//                                 dropTime: pickupTimeSlotData[index],
//                               })
//                             );
//                           }
//                         }
//                       }}
//                       className={`${
//                         myTripPage
//                           ? index === pickupBg ||
//                             pickupTime === currentEmrTimings?.pickupTime
//                             ? "bgCE1"
//                             : isDarkMode
//                             ? "bg4d4c4f"
//                             : "bgFEFDFD"
//                           : index === pickupBg ||
//                             pickupTime === currentTimings?.pickupTime
//                           ? "bgCE1"
//                           : isDarkMode
//                           ? "bg4d4c4f"
//                           : "bgFEFDFD"
//                       } ${ bookingStatus === "On-Going"  ? "opacity-50 pointerEventNone" :""} time m-2 text-center fontAntarctica`}
//                       key={index}
//                     >
//                       {pickupTime}
//                     </p>
//                   );
//                 })}
//               </div>
//             </div>
//             <div>
//               <h6 className="fontAntarctica timeHeaders">Drop off</h6>
//               <div className="timeSlotContainer customizedScrollBar mt-3">
//                 {dropTimeSlotData?.map((dropTime, index) => {
//                   return (
//                     <p
//                       onClick={() => {
//                         SetDropBg(index);
//                         if (myTripPage) {
//                           dispatch(addEmrPickupAndDropTimings({ dropTime }));
//                         } else {
//                           dispatch(addPickupAndDropTimings({ dropTime }));
//                         }
//                       }}
//                       className={`${
//                         myTripPage
//                           ? index === dropBg ||
//                             dropTime === currentEmrTimings?.dropTime
//                             ? "bgCE1"
//                             : isDarkMode
//                             ? "bg4d4c4f"
//                             : "bgFEFDFD"
//                           : index === dropBg ||
//                             dropTime === currentTimings?.dropTime
//                           ? "bgCE1"
//                           : isDarkMode
//                           ? "bg4d4c4f"
//                           : "bgFEFDFD"
//                       } time m-2 text-center `}
//                       key={index}
//                     >
//                       {dropTime}
//                     </p>
//                   );
//                 })}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DateTimeSlotContainer;

import { useDispatch, useSelector } from "react-redux";
import CalendarSelector from "./CalendarSelector";
import { addPickupAndDropTimings } from "../../Store/dateAndTimeSlice";
import { addEmrPickupAndDropTimings } from "../../Store/emrTimeAndDateSlice";
import { useEffect, useRef } from "react";

const DateTimeSlotContainer = ({
  pickupTimeSlotData,
  dropTimeSlotData,
  bookingUIPage,
  myTripPage,
  bookingStatus,
  setPickupTimeIndex = () => {},
}) => {
  const currentDates = useSelector(
    (store) => store?.currentDatesAndTimings?.pickupAndDropDates
  );

  const currentTimings = useSelector(
    (store) => store?.currentDatesAndTimings?.pickupAndDropTimings
  );

  const currentEmrTimings = useSelector(
    (store) => store?.emrDatesAndTimings?.pickupAndDropTimings
  );

  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const dispatch = useDispatch();

    // Create refs for each dropTime slot
    const dropTimeRefs = useRef([]);

    // Ensure the refs array has the correct number of refs for each dropTime
    useEffect(() => {
      dropTimeRefs.current = dropTimeRefs.current?.slice(0, dropTimeSlotData?.length);
    }, [dropTimeSlotData]);
  
    // Effect to scroll to the matched drop time if available
    useEffect(() => {
      if (currentTimings?.dropTime) {
        const matchIndex = dropTimeSlotData?.indexOf(currentTimings?.dropTime);
        if (matchIndex !== -1 && dropTimeRefs.current[matchIndex]) {
          // Scroll into view the matched drop time
          dropTimeRefs.current[matchIndex].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }, [currentTimings?.dropTime, dropTimeSlotData]);

    //this is set the pickupTimeIndex if the consumer not interacted with timeSlotContainer
    useEffect(() => {
      // Find the index of the pickup time that matches the currentTimings.pickupTime
      if (pickupTimeSlotData?.length > 0 && currentTimings?.pickupTime) {
        const initialIndex = pickupTimeSlotData.findIndex(
          (time) => time === currentTimings?.pickupTime
        );
        if (initialIndex !== -1) {
          setPickupTimeIndex(initialIndex);  // Set the initial index
        }
      }
         //eslint-disable-next-line
    }, [pickupTimeSlotData, currentTimings?.pickupTime]);  // Dependencies to re-run if data changes
    

  return (
    <div
      className={`dateAndTimeSlotConatiner ${
        bookingUIPage || myTripPage ? "" : "marginLeft-350px"
      } `}
    >
      <CalendarSelector myTripPage={myTripPage} bookingStatus={bookingStatus} />
      <hr />
      <div
        className={`timeContainer ${isDarkMode ? "darkGreyMode" : "lightMode"}`}
      >
        <div className="mt-3">
          <div className="d-flex justify-content-around  ">
            <div>
              <h6 className="fontAntarctica timeHeaders">Pick up</h6>
              <div className="timeSlotContainer customizedScrollBar mt-3">
                {pickupTimeSlotData?.length > 0 &&
                  pickupTimeSlotData?.map((pickupTime, index) => {
                    return (
                      <p
                        onClick={() => {
                      setPickupTimeIndex(index);
                          if (myTripPage) {
                            dispatch(
                              addEmrPickupAndDropTimings({ pickupTime })
                            );
                          } else {
                            // console.log(locationDatawithTimes[0]?.timeSlots?.currentDateTimeSlots?.includes(pickupTime));
                            dispatch(addPickupAndDropTimings({ pickupTime }));
                            if (
                              // currentDay === currentDates?.pickupDate &&
                              // currentDay === currentDates?.dropDate
                              currentDates?.pickupDate ===
                              currentDates?.dropDate
                            ) {
                              dispatch(
                                addPickupAndDropTimings({
                                  dropTime: pickupTimeSlotData[index + 1],
                                })
                              );
                            } else {
                              dispatch(
                                addPickupAndDropTimings({
                                  dropTime: pickupTimeSlotData[index],
                                })
                              );
                            }
                          }
                        }}
                        className={`${
                          myTripPage
                            ? pickupTime === currentEmrTimings?.pickupTime
                              ? "bgCE1"
                              : isDarkMode
                              ? "bg4d4c4f"
                              : "bgFEFDFD"
                            : pickupTime === currentTimings?.pickupTime
                            ? "bgCE1"
                            : isDarkMode
                            ? "bg4d4c4f"
                            : "bgFEFDFD"
                        } ${
                          bookingStatus === "On-Going" || bookingStatus === "Completed"
                            ? "opacity-50 pointerEventNone"
                            : ""
                        } time m-2 text-center fontAntarctica`}
                        key={index}
                      >
                        {pickupTime}
                      </p>
                    );
                  })}
              </div>
            </div>
            <div>
              <h6 className="fontAntarctica timeHeaders">Drop off</h6>
              <div className="timeSlotContainer customizedScrollBar mt-3">
                {dropTimeSlotData?.map((dropTime, index) => {
                  return (
                    <p
                      onClick={() => {
                        if (myTripPage) {
                          dispatch(addEmrPickupAndDropTimings({ dropTime }));
                        } else {
                          dispatch(addPickupAndDropTimings({ dropTime }));
                        }
                      }}
                      className={`${
                        myTripPage
                          ? dropTime === currentEmrTimings?.dropTime
                            ? "bgCE1"
                            : isDarkMode
                            ? "bg4d4c4f"
                            : "bgFEFDFD"
                          : dropTime === currentTimings?.dropTime
                          ? "bgCE1"
                          : isDarkMode
                          ? "bg4d4c4f"
                          : "bgFEFDFD"
                      } time m-2 text-center `}
                      key={index}
                      ref={(el) => (dropTimeRefs.current[index] = el)} // Assigning refs dynamically
                    >
                      {dropTime}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimeSlotContainer;

// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const DateOfBirthPicker = () => {
//   const [dob, setDob] = useState(null);

//   const handleDateChange = (date) => {
//     setDob(date);
//   };

//   return (
//     <div>
//       <DatePicker
//         selected={dob}
//         onChange={handleDateChange}
//         showYearDropdown
//         dateFormat="MMMM d, yyyy"
//         yearDropdownItemNumber={100} // Adjust the number of years to show
//         scrollableYearDropdown
//         placeholderText="Select a date"
//       />
//       <div>
//         {dob && <p>Your Date of Birth: {dob.toLocaleDateString()}</p>}
//       </div>
//     </div>
//   );
// };

// export default DateOfBirthPicker;


// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const DateOfBirthPicker = () => {
//   const [dob, setDob] = useState(null); // Date of Birth state
//   const [formattedDob, setFormattedDob] = useState(""); // Formatted Date of Birth

//   const handleDateChange = (date) => {
//     setDob(date);

//     // Format the date to MM/DD/YYYY or MM/DD/YY
//     const day = date.getDate();
//     const month = date.getMonth() + 1; // Months are 0-indexed in JavaScript Date
//     const year = date.getFullYear();

//     // Format the date
//     const formatted = `${month < 10 ? `0${month}` : month}/${
//       day < 10 ? `0${day}` : day
//     }/${year}`;

//     setFormattedDob(formatted);
//   };

//   return (
//       <DatePicker
//         selected={dob}
//         onChange={handleDateChange}
//         showMonthDropdown
//         showYearDropdown
//         dateFormat="MM/dd/yyyy"
//         yearDropdownItemNumber={100} // Number of years in the dropdown
//         scrollableYearDropdown
//         placeholderText="Date Of Birth"
//         maxDate={new Date()} // Prevent future dates
//         popperPlacement="bottom"
//       />
//   );
// };

// export default DateOfBirthPicker;


// import { format } from "date-fns";
// import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const DateOfBirthPicker = ({ value="", handleBlur, onDateSelect, maxDate, placeholder, name, minDate = new Date(1900, 0, 1), }) => {
//   const [dob, setDob] = useState(value); // Initialize with the received value prop
//   useEffect(() => {
//     // Update local state if parent component updates `dob`
//     setDob(value);
//   }, [value]);

//   const handleDateChange = (date) => {
//     setDob(date);
//     onDateSelect(date); // Pass the selected date back to parent component
//     const formattedDate = format(date, "MM/dd/yyyy");
//     handleBlur({
//         target: {
//           name,
//           value: formattedDate,
//         },
//       });
//   };

//   return (
//     <DatePicker
//       selected={dob}
//       onChange={handleDateChange}
//       showMonthDropdown
//       showYearDropdown
//       dateFormat="MM/dd/yyyy"
//       yearDropdownItemNumber={100} // Number of years in the dropdown
//       scrollableYearDropdown
//       placeholderText={placeholder}
//       maxDate={maxDate} // Prevent future dates and ensure the date is limited
//       popperPlacement="bottom"
//       onBlur={handleBlur} // Pass blur event to parent if needed
//     />
//   );
// };

// export default DateOfBirthPicker;


// import { format } from "date-fns";
// import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const DateOfBirthPicker = ({
//   value = "",
//   handleBlur,
//   onDateSelect,
//   maxDate,
//   placeholder,
//   name,
//   minDate = new Date(1900, 0, 1),
// }) => {
//   const [dob, setDob] = useState(value ? new Date(value) : null); // Initialize with the received value prop

//   useEffect(() => {
//     // If value is empty or invalid, reset dob to null
//     if (!value || isNaN(new Date(value))) {
//       setDob(null);
//     } else {
//       setDob(new Date(value)); // Update with valid date
//     }
//   }, [value]);

//   const handleDateChange = (date) => {
//     if (date) {
//       setDob(date);
//       onDateSelect(date); // Pass the selected date back to parent component
//       const formattedDate = format(date, "MM/dd/yyyy");
//       handleBlur({
//         target: {
//           name,
//           value: formattedDate,
//         },
//       });
//     } else {
//       // Handle clearing the date
//       setDob(null);
//       handleBlur({
//         target: {
//           name,
//           value: "",
//         },
//       });
//     }
//   };

//   return (
//     <DatePicker
//       selected={dob}
//       onChange={handleDateChange}
//       showMonthDropdown
//       showYearDropdown
//       dateFormat="MM/dd/yyyy"
//       yearDropdownItemNumber={106} // Number of years in the dropdown
//       scrollableYearDropdown
//       placeholderText={placeholder}
//       maxDate={maxDate} // Prevent future dates and ensure the date is limited
//       popperPlacement="bottom"
//       onBlur={handleBlur} // Pass blur event to parent if needed
//     />
//   );
// };

// export default DateOfBirthPicker;


import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateOfBirthPicker = ({
  value = "",
  handleBlur = () => {},
  onDateSelect,
  maxDate,
  placeholder,
  name,
  minDate = new Date(1900, 0, 1),
  isDisabled
}) => {
  const [dob, setDob] = useState(value ? new Date(value) : null); // Initialize with the received value prop

  useEffect(() => {
    // If value is empty or invalid, reset dob to null
    if (!value || isNaN(new Date(value))) {
      setDob(null);
    } else {
      setDob(new Date(value)); // Update with valid date
    }
  }, [value]);

  const handleDateChange = (date) => {
    if (date) {
      setDob(date);
      onDateSelect(date); // Pass the selected date back to parent component
      const formattedDate = format(date, "MM/dd/yyyy");
      handleBlur({
        target: {
          name,
          value: formattedDate,
        },
      });
    } else {
      // Handle clearing the date
      setDob(null);
      handleBlur({
        target: {
          name,
          value: "",
        },
      });
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      type="text"
      value={value}
      onClick={onClick} // Trigger the calendar on input click
      placeholder={placeholder}
      style={{ width: '100%' }} // Ensure the input takes full width
    />
  ));

  return (
    <DatePicker
      disabled={isDisabled}
      minDate={minDate}
      selected={dob}
      onChange={handleDateChange}
      showMonthDropdown
      showYearDropdown
      dateFormat="MM/dd/yyyy"
      yearDropdownItemNumber={106} // Number of years in the dropdown
      scrollableYearDropdown
      placeholderText={placeholder}
      maxDate={maxDate} // Prevent future dates and ensure the date is limited
      popperPlacement="bottom"
      onBlur={handleBlur} // Pass blur event to parent if needed
    //   customInput={<CustomInput />} // Use custom input to handle clicking behavior
    />
  );
};

export default DateOfBirthPicker;

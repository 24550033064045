import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DesktopCustomModal from "./DesktopCustomModal";
import FileUploader from "./FileUploader";
import Overlay from "./Overlay";
import infoIcon from "../assets/svg/InfoIcon.svg";
import greyInfoIcon from "../assets/svg/greyInfoIcon.svg";
import insuranceLogo from "../assets/svg/insuranceLogo.svg";
import axleLogo from "../assets/svg/axle.svg";
import creditCardLogo from "../assets/svg/creditCardLogo.svg";
import insuranceDocIcon from "../assets/svg/insuranceDocumentIcon.svg";
import axleSuccessLogo from "../assets/svg/AxleSuccessLogo.svg";
import latestPdfIcon from "../assets/svg/latestPdfIcon.svg";
import greenTickIcon from "../assets/svg/greenTickIcon.svg";
import latestGreenTick from "../assets/svg/latestGreenTick.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleShowModalBookingDetails,
  toggleShowModalDriverLicenseDetails,
} from "../Store/modalStatusSlice";
import {
  setOpenLoginDesktopModal,
  setOpenProceedBookingDesktopModal,
  setOpenSignUpDesktopModal,
} from "../Store/buttonSlice";
import axios from "axios";
import { BASE_URL } from "../api";
import { format, parse } from "date-fns";
import Checkout from "./Checkout";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ShowPricingDetails from "./ShowPricingDetails";
import AddressAutocomplete from "./AddressAutocomplete";
import PhoneInput from "react-phone-input-2";
import CommonCalendar from "./CommonCalendar";
import SpinnerLoader from "./SpinnerLoader";
import images from "../assets/Images";
import DateOfBirthPicker from "./DateOfBirthPicker";
const ExtrasAndRentalDetails = ({
  data,
  modalBookingStatus,
  handleFileChange,
  estimatedPriceDetails,
  setEstimatedPriceDetails,
  estimatedResultData,
  loadingUntilResponse,
  handleZIndex = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAxleNeed = process.env.REACT_APP_AXLE;

  const userToken = useSelector(
    (store) => store?.userDetailsSlice?.userDetails?.token
  );

  const isProceedBooking = useSelector(
    (store) => store?.currentButton?.bookingScreenNextButton
  );

  // const isDrivingLicenseOpen = useSelector(
  //   (store) => store?.currentButton?.myTripDrivingLicenseButton
  // );

  // const isDarkMode = useSelector((store) => store?.currentModeStatus?.darkMode);

  const [showCoverageSection, setShowCoverageSection] = useState(false)

  const [selectCoverageLater, setSelectCoverageLater] = useState(false);

  const [showConditions, setShowConditions] = useState(false);

  const [licenseDetailsCheckbox, setLicenseDetailsCheckbox] = useState(false);
  const [mailingAddressCheckbox, setMailingAddressCheckbox] = useState(false);
  const [addressDetails, setAddressDetails] = useState({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    countryCode: "",
    secondAddressline: "",
  }); //this state is using for AddressAutocomplete component
  const [drivingLicenseDocument, setDrivingLicenseDocument] = useState({
    frontPage: "",
    backPage: "",
    drivingLicenseNumber: "",
    expiryDate: "",
  });

  const [drivingLicenseErrors, setDrivingLicenseErrors] = useState({
    drivingLicenseNumber: "",
  });

  const [errors, setErrors] = useState({
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const [storeStateNames, setStoreStateNames] = useState([]);
  const [insuranceInputValues, setInsuranceInputValues] = useState({
    documentType: "I",
    policyNumber: "",
    issueState: "",
    provider: "",
    expiryDate: "",
    documentName: "",
  });
  const [userInsuranceDocs, setUserInsuranceDocs] = useState([]);
  const [userInsuranceError, setUserInsuranceError] = useState("");
  const [userCreditDetails, setUserCreditDetails] = useState([]);
  const [userCreditError, setUserCreditError] = useState("");
  const [selectedDocId, setSelectedDocId] = useState("");
  const [selectedCreditId, setSelectedCreditId] = useState("");
  const [selectedPaymentCardId, setSelectedPaymentCardId] = useState("");

  const [showPriceDetails, setShowPriceDetails] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  //console.log(couponCode);
  
  const [showButtonCoupon, setShowButtonCoupon] = useState(true);

  const [isInsuranceClicked, setIsInsuranceClicked] = useState(false);
  const [isCreditCardClicked, setIsCreditCardClicked] = useState(false);
  const [isSelectCoverage, setIsSelectCoverage] = useState(false);
  const [isSelectedCreditCard, setIsSelectedCreditCard] = useState(false);

  const [disClaimer, setDisClaimer] = useState(false);
  const [selectStripePayment, setSelectStripePayment] = useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [selectCardIDForPay, setSelectCardIDForPay] = useState("");

  // const [bookingReferenceError, setBookingReferenceError] = useState("");
  const [bookingReferenceLoading, setBookingReferenceLoding] = useState(false);

  // const [verificationStripeURL, setVerificationStripeURL] = useState("");
  const [licenseDetails, setLicenseDetails] = useState("");
  //const [licenseError, setLicenseError] = useState("");

  const [axleDetails, setAxleDetails] = useState("");
  const [axleError, setAxleError] = useState("");

  // const [isId1Disabled, setIsId1Disabled] = useState(false);
  const localIsId1Disabled = JSON.parse(
    localStorage.getItem("localIsId1Disabled")
  );
  const [activeModal, setActiveModal] = useState([]);

  const openModal = (modalId, allowMultiple = false) => {
    if(modalId === "RentalCoverageModal"){
      localStorage.setItem("isRentalCoverageModal", true)
    }
    // If multiple modals are allowed, append to the array
    if (allowMultiple) {
      setActiveModal((prevModals) => [...prevModals, modalId]);
    } else {
      // If multiple modals are not allowed, only keep the new modal
      setActiveModal([modalId]);
    }
  };

  const closeModal = (modalId) => {
    localStorage.setItem("isRentalCoverageModal", false)
    if (modalId === "") {
      //this is used for only id:4 i have my own option
      setActiveModal("");
    } else {
      setActiveModal(
        (prevModals) => prevModals?.filter((id) => id !== modalId) // Remove the specific modal from the array
      );
    }

    // Only remove ID 4 if closing the Rental Coverage Modal
    if (modalId === "RentalCoverageModal") {
      setSelectedDocId("");
      setSelectedCreditId("");
      // setEstimatedPriceDetails((prev) => ({
      //   ...prev,
      //   rentalCoverageId: prev.rentalCoverageId.filter((id) => id !== 4),
      //   rentalKey: prev.rentalKey.filter((id) => id !== "own_liability"),
      // }));

      setEstimatedPriceDetails((prev) => {
        // Remove specific IDs from rentalCoverageId and rentalKey
        const updatedState = {
          ...prev,
          rentalCoverageId: prev.rentalCoverageId.filter((id) => id !== 4),
          rentalKey: prev.rentalKey.filter((id) => id !== "own_liability"),
          emailFlag: false,
          // couponCodeResponse: null,
        };
        const updatedDetails = {
          ...updatedState,
        };
        localStorage.setItem("bookingInputs", JSON.stringify(updatedDetails));
        return updatedDetails;
      });
    }
  };

  useEffect(() => {
    if (isProceedBooking) {
      openModal("ProceedBooking");
    }
  }, [isProceedBooking]);

  const filterSelectedInsuranceDoc = userInsuranceDocs?.find(
    (details) => details?.id === selectedDocId
  );

  const filterSelectedCreditDoc = userCreditDetails?.find(
    (details) => details?.id === selectedCreditId
  );

  const getPaymentCard = localStorage.getItem("PaymentCard")
  const getInsuranceDoc = localStorage.getItem("insuranceDoc")
  const paymentCardDetails = userCreditDetails?.find(
    (details) => details?.id === getPaymentCard
  );

  const { estimatedPrice, pricingDetails } = estimatedResultData?.data || {};

  const { total } = estimatedPrice || {};

  const [field, setField] = useState({
    extrasField: window?.innerWidth > 992 ? true : false,
    rentalCoverageField: window?.innerWidth > 992 ? false : false,
    additionalCoverageField: window?.innerWidth > 992 ? false : false,
  });

  const handleField = (fieldNames) => {
    // setField((prev) => {
    //   const updatedFields = { ...prev };
    //   fieldNames?.forEach((fieldName) => {
    //     updatedFields[fieldName] = false;
    //   });
    //   return updatedFields;
    // });
  };

  // Function to get the state from localStorage or set initial state
  const getLocalStorageData = () => {
    const savedData = localStorage.getItem("bookingInputs");
    return savedData ? JSON.parse(savedData) : {}; // If no data in LS, return empty object
  };

  useEffect(() => {
    //In the rentalCoverage filed no option is selected we will call this
    // if (estimatedPriceDetails?.rentalCoverageId?.length === 0) {
    //   //setIsId1Disabled(false);
    //   localStorage.setItem("localIsId1Disabled", false);
    // }
    const storedDetails = getLocalStorageData();
    if (
      (storedDetails?.rentalCoverageId?.includes(1) &&
        storedDetails?.protectionPlanType === 2) ||
      storedDetails?.rentalCoverageId?.includes(2) ||
      storedDetails?.rentalCoverageId?.includes(3)
    ) {
      localStorage.setItem("localIsId1Disabled", true);
    } else {
      localStorage.setItem("localIsId1Disabled", false);
    }
    // localStorage.setItem("localIsId1Disabled", false);
  }, []);

  const handleDetailsSelect = (type, value) => {
    const { id, rentalDocKey } = value;

    if (type === "rentalCoverageId") {
      if (value?.id === 2 || value?.id === 3) {
        setSelectedPaymentCardId("");
        setSelectCardIDForPay("")
        localStorage.removeItem("PaymentCard")
        localStorage.removeItem("insuranceDoc")
        //setIsId1Disabled(true);
        localStorage.setItem("localIsId1Disabled", true);
      } else if (value?.id === 4) {
        const storedDetails = getLocalStorageData();
        //setIsId1Disabled(false);
        if (storedDetails?.rentalCoverageId?.includes(1)) {
          localStorage.setItem("localIsId1Disabled", true);
        } else {
          localStorage.setItem("localIsId1Disabled", false);
        }

        if (!storedDetails?.rentalCoverageId?.includes(4)) {
          openModal("RentalCoverageModal");
        }
        // openModal("RentalCoverageModal");
        handleZIndex(true);
      }
    }

    setEstimatedPriceDetails((prev) => {
      const storedDetails = getLocalStorageData(); // Load previous data from localStorage

      // Ensure that storedDetails[type] is always an array, even if it's undefined
      let newSelection = storedDetails[type] ? [...storedDetails[type]] : [];
      let rentalKeySelection = storedDetails?.rentalKey || []; // Preserve existing rentalKey selection
      let newProtectionPlanType = storedDetails?.protectionPlanType || 0

      if (type === "extraIds") {
        newSelection = newSelection?.includes(value?.id)
          ? newSelection?.filter((itemID) => itemID !== id)
          : [...newSelection, id];
      } else if (type === "rentalCoverageId") {
        if (storedDetails[type]?.includes(1) && id === 1) {
          newSelection = storedDetails[type]?.filter((itemID) => itemID !== 1);
          rentalKeySelection = storedDetails?.["rentalKey"]?.filter(
            (rentalValue) => rentalValue !== "minimum_liability"
          );
        } else {
          if (id === 2 || id === 3) {
            newSelection = [id];
            rentalKeySelection = [rentalDocKey];
            newProtectionPlanType = 0
          } else if (id === 4) {
            let IsResultId1 = storedDetails?.rentalCoverageId?.includes(1);
            if (IsResultId1) {
              newSelection = [...storedDetails[type], id];
              rentalKeySelection = [
                ...(storedDetails?.["rentalKey"] || []),
                rentalDocKey,
              ];
            } else {
              newSelection = [id];
              rentalKeySelection = [rentalDocKey];
            }
          } else if (id === 1) {
            let IsResultId4 = storedDetails?.rentalCoverageId?.includes(4);
            if (IsResultId4) {
              newSelection = [...storedDetails[type], id];
              rentalKeySelection = [
                ...(storedDetails?.["rentalKey"] || []),
                rentalDocKey,
              ];
            } else {
              newSelection = [id];
              rentalKeySelection = [rentalDocKey];
            }
          } else {
            newSelection = [id];
            rentalKeySelection = [rentalDocKey];
          }
        }
      } else if (type === "additionalCoverageIds") {
        newSelection = newSelection?.includes(value?.id)
          ? newSelection?.filter((itemID) => itemID !== id)
          : [...newSelection, id];
      }

      // Return the updated state, merging new selections
      const updatedDetails = {
        ...prev,
        ...storedDetails, // Merge stored data (preserve other selections)
        [type]: newSelection, // Update the type you are modifying
        rentalKey: rentalKeySelection, // Preserve rentalKey or update it
        emailFlag: false,
        protectionPlanType: newProtectionPlanType
        // couponCodeResponse: null,
      };

      // Save the updated details to localStorage
      localStorage.setItem("bookingInputs", JSON.stringify(updatedDetails));

      return updatedDetails; // Return the updated state
    });
  };

  // const handleDetailsSelect = (type, value) => {
  //   const { id, rentalDocKey } = value;

  //   // Function to get the state from localStorage or set initial state
  //   const getLocalStorageData = () => {
  //     const savedData = localStorage.getItem("estimatedPriceDetails");
  //     return savedData ? JSON.parse(savedData) : {}; // If no data in LS, return empty object
  //   };

  //   // Update the state
  //   setEstimatedPriceDetails((prev) => {
  //     const storedDetails = getLocalStorageData(); // Load previous data from localStorage

  //     // Ensure that storedDetails[type] is always an array, even if it's undefined
  //     let newSelection = storedDetails[type] ? [...storedDetails[type]] : [];
  //     let rentalKeySelection = storedDetails?.rentalKey || []; // Preserve existing rentalKey selection

  //     // Handle extraIds selection
  //     if (type === "extraIds") {
  //       // Only modify the array if it's an array, otherwise start with an empty array
  //       newSelection = newSelection.includes(value?.id)
  //         ? newSelection.filter((itemID) => itemID !== id)  // Remove if already selected
  //         : [...newSelection, id];  // Add the new selection
  //     }

  //     // Handle rentalCoverageId selection (your existing logic)
  //     else if (type === "rentalCoverageId") {
  //       if (storedDetails[type]?.includes(1) && id === 1) {
  //         newSelection = storedDetails[type]?.filter((itemID) => itemID !== 1);
  //         rentalKeySelection = storedDetails?.["rentalKey"]?.filter(
  //           (rentalValue) => rentalValue !== "minimum_liability"
  //         );
  //       } else {
  //         if (id === 2 || id === 3) {
  //           newSelection = [id];
  //           rentalKeySelection = [rentalDocKey];
  //           setIsId1Disabled(true);
  //         } else if (id === 4) {
  //           setIsId1Disabled(false);
  //           openModal("RentalCoverageModal");
  //           handleZIndex(true);
  //           let IsResultId1 =
  //             storedDetails?.rentalCoverageId?.includes(1);
  //           if (IsResultId1) {
  //             newSelection = [...storedDetails[type], id];
  //             rentalKeySelection = [
  //               ...(storedDetails?.["rentalKey"] || []),
  //               rentalDocKey,
  //             ];
  //           } else {
  //             newSelection = [id];
  //             rentalKeySelection = [rentalDocKey];
  //           }
  //         } else if (id === 1) {
  //           let IsResultId4 =
  //             storedDetails?.rentalCoverageId?.includes(4);
  //           if (IsResultId4) {
  //             newSelection = [...storedDetails[type], id];
  //             rentalKeySelection = [
  //               ...(storedDetails?.["rentalKey"] || []),
  //               rentalDocKey,
  //             ];
  //           } else {
  //             newSelection = [id];
  //             rentalKeySelection = [rentalDocKey];
  //           }
  //         } else {
  //           newSelection = [id];
  //           rentalKeySelection = [rentalDocKey];
  //         }
  //       }
  //     }

  //     // Handle additionalCoverageIds selection
  //     else if (type === "additionalCoverageIds") {
  //       newSelection = newSelection.includes(value?.id)
  //         ? newSelection.filter((itemID) => itemID !== id)
  //         : [...newSelection, id];
  //     }

  //     // Return the updated state, merging new selections
  //     const updatedDetails = {
  //       ...storedDetails, // Merge stored data (preserve other selections)
  //       [type]: newSelection,  // Update the type you are modifying
  //       rentalKey: rentalKeySelection,  // Preserve rentalKey or update it
  //     };

  //     // Save the updated details to localStorage
  //     localStorage.setItem("estimatedPriceDetails", JSON.stringify(updatedDetails));

  //     return updatedDetails; // Return the updated state
  //   });
  // };

  const handleChangeInsuranceDetails = (e) => {
    setSelectedDocId("");
    const { id, value } = e.target;
    setInsuranceInputValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleChangeInsuranceDateDetails = (selectedDate) => {
    setSelectedDocId("");
    setInsuranceInputValues((prev) => ({
      ...prev,
      expiryDate: selectedDate,
    }));
  };

  const token = sessionStorage.getItem("AccessToken");

  const getUserInsuranceDocs = async () => {
    // setBookingReferenceLoding(true);
    //console.log("insurance Rendered");
    
    try {
      setBookingReferenceLoding(true);
      const userResponse = await axios.get(
        `${BASE_URL}userDocument/currentUser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserInsuranceDocs(userResponse?.data?.data);
      setBookingReferenceLoding(false);
      //console.log(userResponse?.data?.data?.length);

    } catch (error) {
      setUserInsuranceError("Error in getting Insurance docs");
      setBookingReferenceLoding(false);
    }
  };

  const getUserCreditDocs = async () => {
    try {
      const userCreditResponse = await axios.get(
        `${BASE_URL}savedcard/credit`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserCreditDetails(userCreditResponse?.data?.data);
    } catch (error) {
      setUserCreditError("Error in getting Credit docs");
    }
  };

  const getStates = async () => {
    const resultStatesList = await axios.get(
      `${BASE_URL}location/getStateList/open`
    );
    setStoreStateNames(resultStatesList?.data?.data?.countries);
  };

  useEffect(
    () => {
      if (token) {
        getUserInsuranceDocs();
        getUserCreditDocs();
        getStates();
        getLicenseDetails();
      }
    },
    //eslint-disable-next-line
    [token]
  );

  const getSavedCards = useCallback(async () => {
    try {
      const savedResponse = await axios.get(`${BASE_URL}savedcard`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setSavedCards(savedResponse?.data);
    } catch (error) {
      console.error(
        "Error fetching saved cards:",
        error.response ? error.response.data : error.message
      );
    }
  }, [userToken]);

  useEffect(() => {
    if (userToken) {
      getSavedCards();
    }
  }, [userToken, getSavedCards]);

  const handleSubmitInsuranceDetails = async (e) => {
    setBookingReferenceLoding(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("documentType", insuranceInputValues?.documentType);
    formData.append("documentId", insuranceInputValues?.policyNumber);
    formData.append("issueState", insuranceInputValues?.issueState);
    formData.append("provider", insuranceInputValues?.provider);
    formData.append(
      "expiration",
      format(insuranceInputValues?.expiryDate, "yyyy-MM-dd HH:mm:ss")
    );
    formData.append("documentName", insuranceInputValues?.documentName); // The binary file
    setInsuranceInputValues((prev) => ({
      ...prev,
      documentType: "I",
      policyNumber: "",
      issueState: "",
      provider: "",
      expiryDate: "",
      documentName: "",
    }));
    try {
      const response = await axios.post(
        `${BASE_URL}userDocument/createProtectionPlan`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        getUserInsuranceDocs(); // to get the user details
      }
      toast.success(
        response?.data?.message ||
          "Protection Plan document uploaded successfully"
      );
      setBookingReferenceLoding(false);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      toast.error(
        error?.response?.data?.message || "Unable to process your request"
      );
      setBookingReferenceLoding(false);
    }
  };
  const carDetails = JSON.parse(localStorage.getItem("selectedCar"));
  const currentUser = JSON.parse(sessionStorage.getItem("CurrentUserDetails"));
  const callGoogletag = () => {
    window.gtag("event", "view_product", {
      send_to: "G-DE7JLFVL37",
      car: carDetails?.carName,
      duration: carDetails?.estimatedPrice?.noOfdays,
      city: carDetails.location?.city,
      total: carDetails?.estimatedPrice?.total,
    });
  };

  //this is to confirm the payment and routed to next screen based on the response
  const paymentID = localStorage?.getItem("PaymentCard");
  const handleBooking = async () => {
    setBookingReferenceLoding(true);
    try {
      const response = await axios.post(
        `${BASE_URL}booking`,
        { ...estimatedPriceDetails, paymentMethod: paymentID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //this is used to navigate to new screen booking-status and its passing booking id through router state
      navigate("/booking-status", {
        state: { bookingId: response?.data?.data?.bookingId },
      });
      callGoogletag();
      // toast("Payment Success");
    } catch (error) {
      // Handle error more professionally
      if (error.response && error.response.data) {
        // Check if the server sent a specific error message
        // setBookingReferenceError(
        //   error.response.data.message ||
        //     "An error occurred while processing your booking. Please try again later."
        // );
        toast.error(
          error.response.data.message ||
            "An error occurred while processing your booking. Please try again later."
        );
        // toast.warning(error.response.data.message);
        setBookingReferenceLoding(false);
      } else {
        // General error handling
        // setBookingReferenceError(
        //   "An unexpected error occurred. Please try again later."
        // );
        toast.error(
          error.response.data.message ||
            "An error occurred while processing your booking. Please try again later."
        );
        // toast.warning(error.response.data.message);
        setBookingReferenceLoding(false);
      }
    }
  };

  //this is to delete the current file in fileUploader reusable document
  const handleDeleteFile = () => {
    setInsuranceInputValues((prev) => ({
      ...prev,
      documentName: "",
    }));
  };
  // Related to strip will enable in improvements
  // const getStripeLinkToverify = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}licensedetail/createVerificationSession?returnUrl=booking`,
  //       "",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response?.status === 200) {
  //       localStorage.setItem(
  //         "verificationSessionId",
  //         response?.data?.data?.verificationSessionId
  //       );
  //       setVerificationStripeURL(response?.data?.data?.verificationURL);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error:",
  //       error.response ? error.response.data : error.message
  //     );
  //   }
  // };

  const staticValue = { id: 1, rentalDocKey: "minimum_liability" };
  const [drivingLicenseLoader, setDrivingLicenseLoader] = useState(false)

  const postDrivingLicenseData = async () => {
    // Create FormData instance
    const formData = new FormData();

    // Append addressDetails data to FormData (replace with actual fields)
    formData.append("licenseFrontSide", drivingLicenseDocument?.frontPage);
    formData.append("licenseBacksideSide", drivingLicenseDocument?.backPage);
    formData.append(
      "licenseNumber",
      drivingLicenseDocument?.drivingLicenseNumber
    );
    formData.append("expiration", drivingLicenseDocument?.expiryDate);
    formData.append("issuingCountryCode", addressDetails.countryCode);
    formData.append("issuingState", addressDetails.state);
    formData.append("issuingCityZipCode", addressDetails.zipCode);
    formData.append("addressLine1", addressDetails.address);
    formData.append("addressLine2", addressDetails.secondAddressline);
    formData.append("country", addressDetails.country);
    formData.append("city", addressDetails.city);
    formData.append("isMailingAddress", mailingAddressCheckbox);

    try {
      setDrivingLicenseLoader(true)
      // Send the POST request with FormData and token header
      const response = await axios.post(
        `${BASE_URL}licensedetail/createLicenseDetail`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );
      // toast.success(" Driver License details updated Successfully.");
      closeModal("DrivingLicenseModal");
      // Handle the response if needed
      setDrivingLicenseLoader(false)
      getLicenseDetails();
      return response.data;
    } catch (error) {
      setDrivingLicenseLoader(false)
      console.error(
        "Error posting driving license data:",
        error?.response?.data?.message
      );
      toast.error(error?.response?.data?.message);
    }
  };
  // Related to strip will enable in improvements
  //const verficationID = localStorage.getItem("verificationSessionId");

  // const { stripeStatusCode, setStripeStatusCode, stripeStatusResponse } =
  //   useGetStripeVerficationStatus(verficationID);
  // useEffect(() => {
  //   if (stripeStatusCode !== "") {
  //     openModal("DrivingLicenseModal");
  //   }
  // }, [stripeStatusCode, stripeStatusResponse]);

  const getLicenseDetails = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}licensedetail/getCurrentUserLicenseDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLicenseDetails(response?.data);
    } catch (error) {
      // setLicenseError(
      //   `Error fetching in License due to ${error?.response?.data?.message}`
      // );
    }
  };
  // Related to strip will enable in improvements
  // useEffect(
  //   () => {
  //     if (
  //       stripeStatusCode === 4 ||
  //       stripeStatusCode === 5 ||
  //       stripeStatusCode === 2
  //     ) {
  //       getStripeLinkToverify();
  //     }
  //   },
  //   // eslint-disable-next-line
  //   [stripeStatusCode]
  // );

  // useEffect(() => {
  //   getLicenseDetails();
  //   // eslint-disable-next-line
  // }, [stripeStatusCode]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const axleStatus = queryParams.get("status");

  const [axleloader, setAxleLoader] = useState(false)
  useEffect(() => {
    //localStorage.removeItem("isRentalCoverageModal")

    const getAxelCompleted = sessionStorage.getItem("axelCompleted")
    if(getAxelCompleted){
      handleZIndex(true);
    if(window?.innerWidth < 992){
      setAxleLoader(true)
      setTimeout(() => {
        getUserInsuranceDocs()
        setAxleLoader(false)
      }, 6000);
      dispatch(toggleShowModalBookingDetails(true))
      }
        setField((prev) => ({
          ...prev,
          rentalCoverageField:true
        }))
        openModal("RentalCoverageModal")
        setIsInsuranceClicked(true)
        setTimeout(() => {
          sessionStorage.removeItem("axelCompleted")
        }, 2000)
      }
        
    if (axleStatus === "complete" || axleStatus === "error") {
      handleZIndex(true);
      sessionStorage.setItem("axelCompleted", true)
      if(axleStatus === "complete" ){
        getAxleDetails();
      }
      clearQueryParams();
      //openModal("RentalCoverageModal");
      setIsInsuranceClicked(true);
    } else {
      if (axleStatus === "exit") {
        //clearQueryParams();
        //openModal("RentalCoverageModal");
        // setIsInsuranceClicked(true);

        if (axleStatus === "exit") {
          handleZIndex(true);
          dispatch(toggleShowModalBookingDetails(true))
          setField((prev) => ({
            ...prev,
            rentalCoverageField:true
          }))
          openModal("RentalCoverageModal");
          // setEstimatedPriceDetails((prev) => {
          //   // Remove specific IDs from rentalCoverageId and rentalKey
          //   const updatedState = {
          //     ...prev,
          //     rentalCoverageId: prev.rentalCoverageId.filter((id) => id !== 4),
          //     rentalKey: prev.rentalKey.filter((id) => id !== "own_liability"),
          //   };
          //   const updatedDetails = {
          //     ...updatedState,
          //   };
          //   localStorage.setItem("bookingInputs", JSON.stringify(updatedDetails));
          //   return updatedDetails;
          // });
          setTimeout(() => {
            clearQueryParams()
          }, 1000)
        }
        
      }
    }
    // eslint-disable-next-line
  }, [axleStatus]);

   // Function to handle back button press
   const onBackButtonEvent = (e) => {
    e.preventDefault(); // Prevent the default back action
    navigate(`/cars-list`, { replace: true });
  };

  useEffect(() => {
    // Add event listener for popstate to capture browser back button
    window.addEventListener('popstate', onBackButtonEvent);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [navigate]); // Re-run effect if `navigate` changes


  const clearQueryParams = () => {
    // Remove all specific query parameters
    searchParams.delete("status");
    searchParams.delete("authCode");
    searchParams.delete("client");
    searchParams.delete("result");
    searchParams.delete("step");
    // Update the URL with the new query parameters (which will be empty)
    setSearchParams(searchParams); // This will trigger a URL update
  };

  const getAxleLink = async () => {
    setBookingReferenceLoding(true);
    try {
      const response = await axios.get(
        `${BASE_URL}axle/generateIgnitionToken?returnUrl=booking`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
     
      // const parsedData = JSON.parse(response?.data?.data);
      const ignitionUri = response?.data?.data?.ignitionUri;
      if(ignitionUri){
        window.location.href = ignitionUri;
      }
      // localStorage.setItem("", true);
      setAxleDetails(response?.data);
      setBookingReferenceLoding(false);
    } catch (error) {
      setBookingReferenceLoding(false);
      setAxleError(
        `Error fetching in License due to ${error?.response?.data?.message}`
      );
    }
  };

  const getAxleDetails = async () => {
    // setBookingReferenceLoding(true);
    try {
      setBookingReferenceLoding(true);
      const response = await axios.get(
        `${BASE_URL}axle/getIgnitionResponse${location?.search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.message || "Insurance details added successfully");
      if(response?.data?.statusCode === 200){
        setAxleLoader(true)
        setTimeout(() => {
          getUserInsuranceDocs();
          setAxleLoader(false)
        }, 2000)
      }
      // setBookingReferenceLoding(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unable to process");
      setBookingReferenceLoding(false);
    }
  };
  const [editUserDetails, setEditUserDetails] = useState({
    email: currentUser?.email,
    id: currentUser?.id,
    dob: currentUser?.dob,
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName || "",
    phoneNumberCountryCode: currentUser?.phoneNumberCountryCode,
    primaryContact: currentUser?.primaryContact,
    countryCode: currentUser?.countryCode,
  });

  const [updateUserLoading, setUpdateUserLoading] = useState(false);

  // const handleChangeUpdateUserDetails = (e) => {
  //   const { id, value } = e.target;
  //   setEditUserDetails((prevState) => ({
  //     ...prevState,
  //     [id]: value,
  //   }));
  // };

  const handleChangeUpdateUserDetails = (e) => {
    const { id, value } = e.target;

    // Optionally validate in real-time on change as well
    if (touched[id]) {
      const error = validateField(id, value);
      setUserErrors((prevState) => ({ ...prevState, [id]: error }));
    }

    setEditUserDetails((prevState) => {
      // Check if the flag (id) already exists in the state object
      // if (!prevState.hasOwnProperty(id)) {
      // }
      return {
        ...prevState,
        [id]: value, // Add or update the flag with the new value
      };
    });
  };

  const [showCalendarForSignUp, setShowCalendarForSignUp] = useState(false);

  const handleDateSelect = (selectedDate) => {
    setDrivingLicenseDocument((prevValues) => ({
      ...prevValues,
      expiryDate: format(
        parse(selectedDate, "MM/dd/yyyy", new Date()),
        "yyyy-MM-dd"
      ),
    }));

    // setEditUserDetails({
    //   ...editUserDetails,
    //   dob: format(parse(selectedDate, "MM/dd/yyyy", new Date()), "yyyy-MM-dd"),
    // }); // Update DOB with the selected date
    setUserErrors({ ...userErrors, dob: "" });
    setShowCalendarForSignUp(false);
  };
  const handleDobUpdate = (selectedDate) => {
    setEditUserDetails({
      ...editUserDetails,
      dob: selectedDate,
    }); // Update DOB with the selected date
    setUserErrors({ ...userErrors, dob: "" });
    setShowCalendarForSignUp(false);
  };



  const [userErrors, setUserErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    primaryContact: "",
    email: "",
    dob: "",
  });

  // Validation functions for signup
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    primaryContact: false,
    dob: false,
  });

  const validateField = (id, value) => {
    let error = ""; // Default to no error

    const emailRegex = /^[^ @]+@[^ @]+\.[^ @]+$/;
    const phoneRegex = /^[0-9]{1}[0-9]{9}$/;

    switch (id) {
      case "firstName":
        if (!value.trim()) {
          error = "First name is required.";
        } else if (value.trim().length < 2) {
          error = "First name must be at least 2 characters long.";
        }
        break;

      case "lastName":
        if (!value.trim()) {
          error = "Last name is required.";
        } else if (value.trim().length < 2) {
          error = "Last name must be at least 2 characters long.";
        }
        break;

      case "dob":
        if (!value.trim()) {
          error = "Dob is required.";
        }
        break;

      case "email":
        if (value && !emailRegex.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;
      case "primaryContact":
        if (!value || !phoneRegex.test(value)) {
          error = "Please enter valid Phone Number.";
        }
        break;

      // Additional validation can go here for other fields like phoneNumber, etc.

      default:
        break;
    }

    return error;
  };

  const [socialEmail, setSocialEmail] = useState(false);
  const [socialPhone, setSocialPhone] = useState(false);
  const [socialDob, setSocialDob] = useState(false);
  // const [socialUserCont, setSocialUserCont] = useState(false);
  const [socialFlName, setSocialFlName] = useState(false);

  useEffect(() => {
    if (!socialEmail) {
      // const updatedUserDetails = { ...editUserDetails };
      // delete updatedUserDetails.email; // Removes the 'email' key
      // setEditUserDetails(updatedUserDetails);

      setEditUserDetails((prev) => {
        // Directly delete the 'email' field from the previous state
        delete prev.email;
        // Log to confirm
        return { ...prev }; // Return the updated state
      });
    }
  }, [socialEmail]);

  const handleSubmitUpdateUserDetails = async () => {
    setUpdateUserLoading(true);
    // Clear any previous errors before validating again
    setUserErrors({
      firstName: "",
      middleName: "",
      lastName: "",
      primaryContact: "",
      email: "",
      dob: "",
    });

    let hasError = false;
    const newErrors = { ...userErrors };

    // Validate firstName (at least 2 characters)
    if (
      !editUserDetails.firstName ||
      editUserDetails.firstName.trim().length < 2
    ) {
      newErrors.firstName =
        "Please provide a valid first name (at least 2 characters)";
      hasError = true;
    }

    // Validate lastName (at least 2 characters)
    if (
      !editUserDetails.lastName ||
      editUserDetails.lastName.trim().length < 2
    ) {
      newErrors.lastName =
        "Please provide a valid last name (at least 2 characters)";
      hasError = true;
    }

    // Validate Dob
    if (!editUserDetails.dob || editUserDetails.dob === undefined) {
      newErrors.dob = "Please provide a dob";
      hasError = true;
    }

    // If there are validation errors, update the state and exit
    if (hasError) {
      setUserErrors(newErrors);
      return; // Prevent the API call if there are validation errors
    }
    try {
      const response = await axios.post(
        `${BASE_URL}user/updateContact`,
        {
          ...editUserDetails,
          dob:format(editUserDetails?.dob, 'yyyy-MM-dd'),
          primaryContact: editUserDetails?.primaryContact?.slice(
            editUserDetails?.countryCode?.length
          ),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
            Accept: "application/json", // Include Accept header
          },
        }
      );
      // Handle response here if needed, e.g., show a success message
      sessionStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(response?.data?.data)
      );
      closeModal("UpdateUserDetails");
      toast.success("Profile details updated successfully");
      if (response?.status === 200) {
        getUserDetails();
        // setting Estimated price to update Pricing Details after user details updated and will open I understand modal
        setEstimatedPriceDetails((prev) => ({
          ...prev,
          emailFlag: true,
          // couponCodeResponse: null,
        }));
      }
      setUpdateUserLoading(false);
    } catch (error) {
      // Handle error here, e.g., show an error message
      toast.error(
        error?.response?.data?.message || "Unable to process your request"
      );
      setUpdateUserLoading(false);
    }
  };

  //it is used to get the current user details
  const getUserDetails = async () => {
    const token = sessionStorage.getItem("AccessToken");
    try {
      const userResponse = await axios.get(`${BASE_URL}user/currentUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      sessionStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(userResponse?.data?.data)
      );
    } catch (error) {}
  };

  const handlePhoneChange = (value, country) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue?.length < 3) {
      setUserErrors({
        ...userErrors,
        primaryContact: "Please enter valid number",
      });
    } else {
      setUserErrors({ ...userErrors, primaryContact: "" });
    }

    if (numericValue !== "") {
      setEditUserDetails((prev) => ({
        ...prev,
        primaryContact: numericValue,
        phoneNumberCountryCode: country?.countryCode?.toUpperCase(),
        countryCode: country?.dialCode,
      }));
    }
  };

  return (
    <div
      className={`${
        modalBookingStatus
          ? "d-flex py-0 h-100"
          : "carDetailsContainer position-relative d-none d-lg-block py-3"
      }`}
    >
      {(bookingReferenceLoading || drivingLicenseLoader || axleloader) && (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      {token === null || token === "" ? (
        <div className="d-flex flex-column justify-content-center justify-content-lg-start align-items-center text-center h-100 w-100 bgF03 mt-4">
          <div
            className={`${
              modalBookingStatus ? "fontSize30" : "fontSize30"
            } fontAtacama mb-2 pt-5`}
          >
            Let's Get You on the Road!
          </div>
          <div className="fontSize14 fontAntarctica mb-5">
            Just sign in and you'll be all set to book your eco-friendly ride.
          </div>
          <div className="d-flex justify-content-between align-items-center w-50">
            <button
              onClick={() => {
                dispatch(setOpenSignUpDesktopModal(true));
                dispatch(toggleShowModalBookingDetails(false));
              }}
              className="bg-transparent borderE1 text-white p-2 outlineNone border-0 w-100 borderRadius8 mx-2"
            >
              Join Us
            </button>
            <button
              onClick={() => {
                dispatch(setOpenLoginDesktopModal(true));
                dispatch(toggleShowModalBookingDetails(false));
              }}
              className="bgCE1 text-white p-2 outlineNone border-0 borderRadius8 w-100 mx-2"
            >
              Log in
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className={`${modalBookingStatus ? "w-100" : "py-4"}`}>
            <div
              className={`extrasHeightContainer ${
                modalBookingStatus ? "" : "customizedScrollBar p-2"
              }`}
            >
              <div className="px-2 py-2 mb-2 br8 bgF08 d-flex justify-content-between align-items-center">
                <div className="p-1 m-1 fontAntarctica fontSize17">Driver License</div>
                <div className="text-decoration-underline d-flex align-items-center">
                  <div className="d-none d-lg-flex">
                    {/* Related to strip will enable in improvements */}
                    {/* <DesktopCustomModal
                      isModalOpen={activeModal.includes("DrivingLicenseModal")}
                      closeModal={() => closeModal("DrivingLicenseModal")}
                      closeOnOutsideClick={true}
                      modalCenter={true}
                      modalId="driverLicenseModal"
                      modalClassHeight="modalHeightDrivingLicense"
                      modalButton={
                        licenseDetails?.data === null ? (
                          <div
                            onClick={() => {
                              openModal("DrivingLicenseModal");
                              getStripeLinkToverify();
                            }}
                          >
                            Verify Now
                          </div>
                        ) : (
                          "Verified"
                        )
                      }
                    >
                      <StripeAndManualDrivingLicense
                        stripeStatusCode={stripeStatusCode}
                        setStripeStatusCode={setStripeStatusCode}
                        stripeStatusResponse={stripeStatusResponse}
                        closeModal={closeModal}
                        verificationStripeURL={verificationStripeURL}
                      />
                     
                     
                    </DesktopCustomModal> */}
                    <DesktopCustomModal
                      isModalOpen={activeModal.includes("DrivingLicenseModal")}
                      closeModal={() => closeModal("DrivingLicenseModal")}
                      closeOnOutsideClick={false}
                      isCloseIconRequired={true}
                      modalId="driverLicenseModal"
                      modalClassHeight="modalHeightDrivingLicense"
                      modalTitle={
                        <div className="d-flex justify-content-center align-items-center py-3">
                          Add Driver License
                        </div>
                      }
                      modalButton={
                        licenseDetails?.data === null ? (
                          <div
                            onClick={() => {
                              openModal("DrivingLicenseModal");
                              // getStateListAll();
                            }}
                          >
                            <span className="underline-text fontAntarctica">
                              {" "}
                              Upload Now
                            </span>
                          </div>
                        ) : (
                          <span className="underline-text fontAntarctica">
                            {" "}
                            Verified
                          </span>
                        )
                      }
                    >
                      <div className="mb-2 d-flex justify-content-between align-items-center cursorPointer">
                        <div className="m-1 cursor">
                          <FileUploader
                            onFileChange={(file) => {
                              setDrivingLicenseDocument((prevValues) => ({
                                ...prevValues,
                                frontPage: file,
                              }));
                            }}
                            handleDeleteFile={() => {
                              setDrivingLicenseDocument((prevValues) => ({
                                ...prevValues,
                                frontPage: "",
                              }));
                            }}
                            label="Front Page"
                            fileTextMessage="Drag & drop some files here, or click to select files"
                          />
                        </div>
                        <div className="m-1 cursor">
                          <FileUploader
                            onFileChange={(file) => {
                              setDrivingLicenseDocument((prevValues) => ({
                                ...prevValues,
                                backPage: file,
                              }));
                            }}
                            handleDeleteFile={() => {
                              setDrivingLicenseDocument((prevValues) => ({
                                ...prevValues,
                                backPage: "",
                              }));
                            }}
                            label="Back Page"
                            fileTextMessage="Drag & drop some files here, or click to select files"
                          />
                        </div>
                      </div>
                      <div>
                        <form>
                          <div className="mb-3">
                            <input
                              id="DrivingLicenseNumber"
                              placeholder="Enter Driver License Number"
                              type="text"
                              className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
                              value={
                                drivingLicenseDocument?.drivingLicenseNumber
                              }
                              onChange={(e) => {
                                setDrivingLicenseDocument((prevValues) => ({
                                  ...prevValues,
                                  drivingLicenseNumber: e.target.value,
                                }));
                                const regex = /^[a-zA-Z0-9]{1,15}$/; // Only allow alphabets and numbers
                                if (!regex.test(e.target.value)) {
                                  setDrivingLicenseErrors((prev) => ({
                                    ...prev,
                                    drivingLicenseNumber:
                                      "Invalid Driving License format",
                                  }));
                                } else {
                                  setDrivingLicenseErrors((prev) => ({
                                    ...prev,
                                    drivingLicenseNumber: "",
                                  })); // Clear error if valid
                                }
                              }}
                              onBlur={(e) => {
                                const value = e.target.value;
                                if (!value) {
                                  setDrivingLicenseErrors((prev) => ({
                                    ...prev,
                                    drivingLicenseNumber:
                                      "Invalid Driving License format",
                                  }));
                                }
                              }}
                            />
                            {drivingLicenseErrors?.drivingLicenseNumber && (
                              <div className="text-danger mt-2">
                                {drivingLicenseErrors?.drivingLicenseNumber}
                              </div>
                            )}
                          </div>
                          {/* <div className="mb-3 position-relative">
                            <input
                              min={new Date().toISOString().split("T")[0]}
                              id="expiryDate"
                              placeholder="Enter Expiry Date (dd/mm/yyyy)"
                              type="date"
                              className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
                              value={drivingLicenseDocument?.expiryDate}
                              onChange={(e) => {
                                setDrivingLicenseDocument((prevValues) => ({
                                  ...prevValues,
                                  expiryDate: e.target.value,
                                }));
                              }}
                              onInput={(e) => e.preventDefault()} 
                            />
                          </div> */}

                          <div className="mb-3 position-relative">
                            <CommonCalendar
                              onDateSelect={handleDateSelect}
                              handleBlur={() => {}}
                              defaultViewDate={new Date()}
                              minDate={new Date()}
                              maxDate=""
                              placeholder="Enter Expiry Date"
                              value={drivingLicenseDocument?.expiryDate}
                            />
                          </div>

                          <AddressAutocomplete
                            addressDetails={addressDetails}
                            setAddressDetails={setAddressDetails}
                            errors={errors}
                            setErrors={setErrors}
                          />

                          <div className="d-flex align-items-center mb-3">
                            <input
                              type="checkbox"
                              id="mailingAddress"
                              className="me-2"
                              checked={mailingAddressCheckbox}
                              onChange={() =>
                                setMailingAddressCheckbox(
                                  !mailingAddressCheckbox
                                )
                              }
                            />
                            <label
                              htmlFor="mailingAddress"
                              className="text-white"
                            >
                              Use this address as my Mailing Address
                            </label>
                          </div>

                          <div className="d-flex align-items-center mb-3">
                            <input
                              type="checkbox"
                              id="licenseDetails"
                              className="me-2"
                              checked={licenseDetailsCheckbox}
                              onChange={() =>
                                setLicenseDetailsCheckbox(
                                  !licenseDetailsCheckbox
                                )
                              }
                            />
                            <div
                              onMouseEnter={() => setShowConditions(true)} // Show content on hover
                              onMouseLeave={() => setShowConditions(false)} // Hide content when mouse leaves
                            >
                              <label
                                htmlFor="licenseDetails"
                                className="text-white"
                              >
                                We will use your license details to perform a
                                driver and criminal background check. For
                                details{" "}
                                <span className="cursorPointer fontColorCE1">
                                  Read More
                                </span>
                              </label>
                              {showConditions && (
                                <div className="col-md-12">
                                  <div className="row">
                                    <div
                                      className="col-md-12 customizedScrollBar bg23 p-2 px-4 borderRadius8"
                                      style={{
                                        height: "300px",
                                        width: "400px",
                                        overflow: "auto",
                                        position: "absolute",
                                        top: "68%",
                                        left: "1%",
                                        border: "1px solid grey",
                                      }}
                                    >
                                      <p>
                                        Joulez may need to request a consumer
                                        report, drivers record report, or other
                                        background check in order to approve
                                        your rental application. By checking the
                                        box, you authorize Joulez and its
                                        designated agents and representatives to
                                        conduct a comprehensive review of your
                                        records. By checking the box, you
                                        indicate that you understand the scope
                                        of the consumer report may include, but
                                        is not limited to: criminal history,
                                        including records from any criminal
                                        justice agency in any or all federal,
                                        state, or county jurisdictions; motor
                                        vehicle records, including traffic
                                        citations and registration; and any
                                        other public record that might be
                                        relevant to determining your risk as a
                                        vehicle renter.
                                      </p>
                                      <p>
                                        If you are a California resident, you
                                        have the right to receive the report
                                        Joulez reviews. By sending us an e-mail
                                        at reservations@drivejoulez.com, you can
                                        request to receive a copy of the report
                                        Joulez reviews. Upon receipt of the
                                        report, Joulez will provide a copy of
                                        the report to you within three business
                                        days to the e-mail address you have
                                        associated with this rental.
                                      </p>
                                      <p>
                                        By withholding your permission, no
                                        records will be reviewed and your rental
                                        reservation will not be processed
                                        further.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <button
                            type="button"
                            className={`signInButton bgCE1 text-white w-100 ${
                              drivingLicenseErrors?.drivingLicenseNumber ===
                                "" &&
                              errors?.country === "" &&
                              errors?.address === "" &&
                              errors?.city === "" &&
                              errors?.state === "" &&
                              errors?.zipCode === "" &&
                              licenseDetailsCheckbox &&
                              addressDetails?.address !== "" &&
                              addressDetails?.city !== "" &&
                              addressDetails?.country !== "" &&
                              addressDetails?.countryCode !== "" &&
                              addressDetails?.state !== "" &&
                              addressDetails?.zipCode !== "" &&
                              drivingLicenseDocument?.frontPage !== "" &&
                              drivingLicenseDocument?.backPage !== "" &&
                              drivingLicenseDocument?.drivingLicenseNumber !==
                                "" &&
                              drivingLicenseDocument?.expiryDate !== ""
                                ? ""
                                : "disabled-class"
                            }`}
                            onClick={postDrivingLicenseData}
                          >
                            Proceed
                          </button>
                        </form>
                      </div>
                    </DesktopCustomModal>
                  </div>
                  <div
                    className="d-flex d-lg-none"
                    onClick={() =>
                      dispatch(toggleShowModalDriverLicenseDetails(true))
                    }
                  >
                    {licenseDetails?.data === null ? "Upload Now" : ""}
                  </div>
                  {licenseDetails?.data !== null && (
                    <div className="mx-1 d-block d-lg-none">Verified</div>
                  )}
                  {licenseDetails?.data === null ? (
                    <Overlay
                      dataBsTitle="Please upload the License to confirm the booking"
                      direction="bottom"
                    >
                      <img
                        className="m-1"
                        src={
                          licenseDetails?.data === null
                            ? infoIcon
                            : latestGreenTick
                        }
                        alt="infoIcon"
                      />
                    </Overlay>
                  ) : (
                    <img
                      className="mx-1"
                      src={latestGreenTick}
                      alt="latestGreenTick"
                    />
                  )}
                </div>
              </div>

              <div className="py-2">
                <div>
                <div 
                 className="bgF08 br8 d-flex flex-column px-2 py-2 d-flex justify-content-between align-items-center cursorPointer">
                  <div  onClick={() =>
                      setField((prev) => ({
                        ...prev,
                        extrasField: !prev?.extrasField,
                      }))
                    } className="d-flex justify-content-between align-items-center w-100">
                  <div>
                    <h5 className="p-1 m-1 fontAntarctica fontSize17">
                      Extras
                    </h5>
                  </div>
                  <div
                    className="fontSize13 fontAntarctica fontColorCE1 cursor"
                  >
                    {field?.extrasField ? "Hide" : "Show"}
                  </div>
                  </div>
                  {field?.extrasField && (
                  <div className="d-flex justify-content-between cursor align-items-center flex-wrap">
                    {data?.extrasChart?.map((item) => {
                      return (
                        <div
                          key={item?.id}
                          className={`extras-field-hoverEffect d-flex justify-content-between fontAntarctica fontSize13 border3B3936 p-3 m-1 rounded rounded-3 ${
                            modalBookingStatus ? "w-100" : "width48"
                          } ${
                            estimatedPriceDetails?.extraIds?.includes(item?.id)
                              ? "extrasborderE1"
                              : ""
                          }`}
                          onClick={() => handleDetailsSelect("extraIds", item)}
                        >
                          <div className="d-flex align-items-center">
                            <div className="mx-1">{item?.name}</div>
                          <div>
                              {item?.description && (
                                <Overlay
                                  dataBsTitle={item?.description || ""}
                                  direction="top"
                                >
                                  <img src={greyInfoIcon} alt="greyInfoIcon" />
                                </Overlay>
                              )}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="fontWeight400 mx-2">
                              ${item?.price}/{item?.priceModel}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                  </div>
                </div>
              </div>

              <div className="py-2">
                <div>
                  <div className="px-2 py-2 br8 bgF08">
                <div   
                onClick={() =>
                      setField((prev) => ({
                        ...prev,
                        rentalCoverageField: !prev?.rentalCoverageField,
                      }))
                    } className="d-flex justify-content-between align-items-center cursorPointer">
                  <div>
                    <h5 className="p-1 m-1 fontAntarctica fontSize17">
                      Rental Coverage
                    </h5>
                  </div>
                  <div
                    className="fontSize13 fontAntarctica fontColorCE1 cursor"
                  >
                    {field?.rentalCoverageField ? "Hide" : "Show"}
                  </div>
                </div>
                <div>
                {field?.rentalCoverageField && (
                  <div>
                    {/* here DesktopModal is rendered here to open when user clicks on I have my own option */}
                    <DesktopCustomModal
                      isModalOpen={activeModal.includes("RentalCoverageModal")}
                      closeModal={() => {
                        closeModal("RentalCoverageModal");
                        handleZIndex(false);
                        setIsInsuranceClicked(false);
                        setIsCreditCardClicked(false);
                        setIsSelectedCreditCard(false);
                        setIsSelectCoverage(false);
                        setSelectCardIDForPay("");
                        setSelectedPaymentCardId("");
                        localStorage.removeItem("PaymentCard")
                        localStorage.removeItem("insuranceDoc")
                      }} // Pass the modalId}
                      modalId="RentalCoverageModal"
                      modalClassHeight="modalHeightOwnCoverage"
                      modalTitle={
                        isCreditCardClicked ? (
                          <div className="mt-2 p-2">
                            I have my own Credit Card Coverage
                          </div>
                        ) : isInsuranceClicked ? (
                          <div className="mt-2 p-2">
                            I have my own Insurance coverage
                          </div>
                        ) : (
                          <div className="mt-2 p-2">I have my own coverage</div>
                        )
                      }
                      modalButton=""
                      closeOnOutsideClick={false}
                      isCloseIconRequired={true}
                      isHRLineRequired={true}
                      modalContent={
                        isInsuranceClicked || isCreditCardClicked ? (
                          <div className="d-flex w-100">
                            <button
                              className="bg3B3936 outlineNone fontcolorFFF w-100 border-0 p-2 borderRadius8"
                              onClick={() => {
                                if (isSelectCoverage) {
                                  setIsCreditCardClicked(false);
                                  setIsInsuranceClicked(true);
                                  setIsSelectCoverage(false);
                                } else if (isInsuranceClicked) {
                                  setIsInsuranceClicked(false);
                                }

                                if (isCreditCardClicked) {
                                  setIsCreditCardClicked(false);
                                }

                                if (isSelectedCreditCard) {
                                  setIsSelectedCreditCard(false);
                                  setIsInsuranceClicked(false);
                                  setIsCreditCardClicked(true);
                                }
                              }}
                            >
                              Go Back
                            </button>
                            {isCreditCardClicked ? (
                              isSelectedCreditCard ? (
                                <button
                                  onClick={() => {
                                    closeModal("");
                                    setIsCreditCardClicked(false);
                                    setIsSelectedCreditCard(false);
                                    setSelectedCreditId("");
                                    //setIsId1Disabled(true);
                                    localStorage.setItem(
                                      "localIsId1Disabled",
                                      true
                                    );
                                    handleZIndex(false);
                                    setEstimatedPriceDetails((prev) => ({
                                      ...prev,
                                      protectionPlanType: 2,
                                      referenceId: selectedCreditId,
                                      // couponCodeResponse: null,
                                    }));
                                  }}
                                  className="bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8"
                                >
                                  Continue Card
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    localStorage.setItem("isRentalCoverageModal", true)
                                    if (selectedCreditId !== "") {
                                      setIsSelectedCreditCard(true);
                                      if (
                                        !estimatedPriceDetails?.rentalCoverageId?.includes(
                                          1
                                        )
                                      ) {
                                        handleDetailsSelect(
                                          "rentalCoverageId",
                                          staticValue
                                        );
                                      }
                                    }
                                  }}
                                  className={`bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8
                                     ${
                                       selectedCreditId === ""
                                         ? "disabled-class"
                                         : ""
                                     }`}
                                >
                                  Continue
                                </button>
                              )
                            ) : isSelectCoverage ? (
                              <button
                                onClick={() => {
                                  localStorage.setItem("isRentalCoverageModal", false)
                                  closeModal("");
                                  setIsInsuranceClicked(false);
                                  setIsSelectCoverage(false);
                                  setSelectedDocId("");
                                  handleZIndex(false);

                                  setEstimatedPriceDetails((prev) => ({
                                    ...prev,
                                    protectionPlanType: 1,
                                    referenceId: selectedDocId,
                                    // couponCodeResponse: null,
                                    rentalCoverageId:
                                      estimatedPriceDetails?.rentalCoverageId?.filter(
                                        (id) => id !== 1
                                      ),
                                    rentalKey:
                                      estimatedPriceDetails?.rentalKey?.filter(
                                        (key) => key !== "minimum_liability"
                                      ),
                                  }));
                                }}
                                className="bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8"
                              >
                                Continue Coverage
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  // localStorage.setItem("isRentalCoverageModal", false)
                                  if (selectedDocId !== "") {
                                    setIsSelectCoverage(true);
                                  }
                                }}
                                className={`bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8
                                  ${
                                    selectedDocId === "" ? "disabled-class" : ""
                                  }`}
                              >
                                Select Coverage
                              </button>
                            )}
                          </div>
                        ) : (
                          <div className="d-flex w-100">
                            <button
                              onClick={() => {
                                closeModal("RentalCoverageModal");
                                setSelectCardIDForPay("")
                                setSelectedPaymentCardId("")
                                setSelectedDocId("");
                                setSelectedCreditId("");
                                setIsSelectedCreditCard(false);
                                handleZIndex(false);
                                localStorage.removeItem("PaymentCard")
                                localStorage.removeItem("insuranceDoc")
                                // if (
                                //   estimatedPriceDetails?.rentalCoverageId?.includes(
                                //     1
                                //   )
                                // ) {
                                //   handleDetailsSelect(
                                //     "rentalCoverageId",
                                //     staticValue
                                //   );
                                // }
                              }}
                              className="bg3B3936 outlineNone fontcolorFFF w-100 border-0 p-2 borderRadius8"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => setIsInsuranceClicked(true)}
                              className="bgCE1 outlineNone fontcolorFFF w-100 border-0 mx-2 p-2 borderRadius8"
                            >
                              Continue
                            </button>
                          </div>
                        )
                      }
                    >
                      <div>
                        {(isInsuranceClicked || isCreditCardClicked) &&
                        !isSelectCoverage &&
                        !isSelectedCreditCard ? (
                          <div>
                            <div className="d-flex mt-2 justify-content-between align-items-center fontAntarctica bg3B3936 borderRadius8">
                              <button
                                onClick={() => {
                                  setIsCreditCardClicked(false);
                                  setIsInsuranceClicked(true);
                                  setSelectedCreditId("")
                                  localStorage.removeItem("PaymentCard")
                                }}
                                className={`${
                                  isInsuranceClicked &&
                                  isCreditCardClicked === false
                                    ? "bgCE1"
                                    : "bg3B3936"
                                } fontcolorFFF fontSize13 border-0 p-2 m-1 w-100 borderRadius8`}
                              >
                                Insurance
                              </button>
                              <button
                                onClick={() => {
                                  setIsCreditCardClicked(true);
                                  setIsInsuranceClicked(false);
                                  setSelectedDocId("")
                                  localStorage.removeItem("insuranceDoc")
                                }}
                                className={`${
                                  isCreditCardClicked ? "bgCE1" : "bg3B3936"
                                } fontcolorFFF fontSize13 border-0 p-2 m-1 w-100 borderRadius8`}
                              >
                                Credit Card
                              </button>
                            </div>
                            {isCreditCardClicked ? (
                              <div>
                                <div className="mt-2">
                                  <div className="fontColor5BD">
                                    If you're using credit card coverage, just
                                    make sure to use the same card for payments.
                                  </div>
                                  <div className="py-2 fontAntarctica fontSize17">
                                    Credit Cards
                                  </div>
                                  <div>
                                    <div>
                                      {/* <div className="py-2 fontAntarctica fontSize17">
                                        Insurances
                                      </div> */}
                                      <div>
                                        {userCreditDetails?.map((details) => {
                                          return (
                                            <div
                                              onClick={() => {
                                                localStorage.setItem(
                                                  "PaymentCard",
                                                  details?.id
                                                );
                                                setSelectedCreditId(
                                                  details?.id
                                                );
                                                setSelectedPaymentCardId(
                                                  details?.id
                                                );
                                                setSelectCardIDForPay(
                                                  details?.id
                                                );
                                              }}
                                              key={details?.id}
                                              className={`${
                                                selectedCreditId === details?.id
                                                  ? "borderE1"
                                                  : ""
                                              } p-4 mb-3 border3B3936 borderRadius8 d-flex justify-content-between align-items-center`}
                                            >
                                              <div className=" fontSize13 fontWeight400 lineheight21 fontAntarctica ">
                                                <div className="mb-1 fontColor5BD">
                                                  Card Number
                                                  <span className="text-white mx-2 text-nowrap">
                                                    XXXX XXXX XXXX{" "}
                                                    {details?.cardNumber}
                                                  </span>
                                                </div>
                                                <div className="mb-1 fontColor5BD">
                                                  Card Holder Name{" "}
                                                  <span className="text-white mx-2">
                                                    {details?.name}
                                                  </span>
                                                </div>
                                                <div className="mb-1 fontColor5BD">
                                                  Expiry Date{" "}
                                                  <span className="text-white mx-2">
                                                    {details?.expiryDate}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="">
                                                <div className="d-flex justify-content-end px-2 pt-1 mb-4">
                                                  <input
                                                    type="radio"
                                                    checked={
                                                      selectedCreditId ===
                                                      details?.id
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={() =>
                                                      setSelectedCreditId(
                                                        details?.id
                                                      )
                                                    }
                                                  />
                                                </div>
                                                {/* <div className="bgE933 p-4 borderRadius8"> */}
                                                {/* {details?.cardIssuedBy} */}
                                                <img
                                                  className="mx-1 height35px"
                                                  src={
                                                    images[
                                                      details?.cardIssuedBy
                                                    ] || images.defaultCard
                                                  }
                                                  alt="images"
                                                />
                                                {/* </div> */}
                                              </div>
                                            </div>
                                          );
                                        })}
                                        <button
                                          onClick={() => {
                                            openModal("AddNewCard", true);
                                            setSelectedCreditId("");
                                            localStorage.setItem("isRentalCoverageModal", true)
                                          }}
                                          className="mt-2 px-3 py-2 text-white bg3B3936 borderRadius8 border-0 fontSize13"
                                        >
                                          Add New Card
                                        </button>
                                      </div>
                                      <div>{userCreditError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div>
                                  <div className="py-2 fontAntarctica fontSize17">
                                    Insurances
                                  </div>
                                  <div>
                                    {userInsuranceDocs?.map((details) => {
                                      const isSelected =
                                        selectedDocId === details?.id;
                                      return (
                                        <div
                                          onClick={() => {
                                            setSelectedPaymentCardId("")
                                            localStorage.removeItem("PaymentCard")
                                            localStorage.setItem("insuranceDoc", details?.id)
                                            setSelectCardIDForPay("")
                                            setSelectedDocId(details?.id);
                                            setInsuranceInputValues((prev) => ({
                                              ...prev,
                                              documentType: "I",
                                              policyNumber: "",
                                              issueState: "",
                                              provider: "",
                                              expiryDate: "",
                                              documentName: "",
                                            }));
                                          }}
                                          key={details?.id}
                                          className={`p-2 cursorPointer border3B3936 borderRadius8 d-flex justify-content-between align-items-center mb-3 ${
                                            isSelected ? "borderE1" : ""
                                          }`}
                                        >
                                          <div className="p-3 lineheight21 fontSize13 fontWeight400 fontAntarctica ">
                                            <div className="mb-1 d-flex  align-items-center ">
                                              <p className="opacity mb-0">
                                                Policy Number
                                              </p>
                                              <span className="mx-1 no-opacity fontWeightBold text-white">
                                                {details?.documentId}
                                              </span>
                                              {details?.isAxleVerified && <span>
                                                <img src={axleSuccessLogo} alt="axleSuccessLogo" />
                                              </span>}
                                            </div>

                                            <div className="fontSize13 fontWeight400 fontAntarctica">
                                              <div className="mb-1 d-flex  align-items-center">
                                                <p className=" opacity mb-0">
                                                  Issue State
                                                </p>
                                                <span className="mx-1">
                                                  {details?.issueState}
                                                </span>
                                              </div>
                                              <div className="mb-1 d-flex  align-items-center">
                                                <p className="mb-0 opacity">
                                                  Provider
                                                </p>
                                                <span className="mx-1">
                                                  {details?.provider}
                                                </span>
                                              </div>
                                              <div className="mb-1 d-flex align-items-center">
                                                <p className="mb-0 opacity">
                                                  Exp. Date
                                                </p>
                                                <span className="mx-1">
                                                  {details?.docExpDate}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div>
                                            <div className="p-3 borderRadius8">
                                              <div className="d-flex justify-content-end py-2">
                                                <input
                                                  type="radio"
                                                  name="insurance-doc"
                                                  checked={isSelected}
                                                  onChange={() =>
                                                    setSelectedDocId(
                                                      details?.id
                                                    )
                                                  }
                                                />
                                              </div>
                                              <img
                                                className="insuranceImagePreview"
                                                src={
                                                  details?.fileType ===
                                                    "application/octet-stream" ||
                                                  details?.fileType ===
                                                    "application/pdf"
                                                    ? latestPdfIcon
                                                    : details?.documentName
                                                }
                                                alt="insuranceDocIcon"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div>{userInsuranceError}</div>
                                </div>
                                <div>
                                    <div className="text-white fontSize14 mb-0 me-1 px-1">
                                      If you want to add document please
                                    <span
                                      className="cursorPointer fontSize14 fontColorCE1 text-decoration-none mx-1"
                                      onClick={() =>{
                                        setShowCoverageSection(!showCoverageSection)
                                      }
                                      }
                                    >
                                      {showCoverageSection ? "Show Less" : "Click Here"}
                                    </span>
                                    </div>
                                </div>
                                {showCoverageSection && <div>
                                <div className="mt-2">
                                  <FileUploader
                                    // label={
                                    //   <div className="fontAntarctica fontSize17">
                                    //     Update New Coverage
                                    //   </div>
                                    // }
                                    fileTextMessage={
                                      <div className="borderRadius8">
                                        <img
                                          src={insuranceDocIcon}
                                          alt="insuranceDocIcon"
                                        />
                                      </div>
                                    }
                                    removePreviewImage={
                                      insuranceInputValues?.documentName === ""
                                        ? true
                                        : false
                                    }
                                    onFileChange={(file) => {
                                      setSelectedDocId("");
                                      setInsuranceInputValues((prevValues) => ({
                                        ...prevValues,
                                        documentName: file, // Store the compressed file
                                      }));
                                    }}
                                    handleDeleteFile={handleDeleteFile}
                                  />
                                </div>
                                <div className="pt-3 input-group w-100">
                                  <form
                                    className="w-100"
                                    onSubmit={handleSubmitInsuranceDetails}
                                  >
                                    <div className="mb-3">
                                      <input
                                        id="policyNumber"
                                        placeholder="Policy Number"
                                        type="text"
                                        className="form-control supportFormFocus p-2 mb-3"
                                        onChange={handleChangeInsuranceDetails}
                                        value={
                                          insuranceInputValues?.policyNumber
                                        }
                                      />
                                      <select
                                        id="issueState"
                                        onChange={handleChangeInsuranceDetails}
                                        className="form-control supportFormFocus p-2 mb-3"
                                        value={insuranceInputValues?.issueState}
                                      >
                                        <option value="">Issue State</option>
                                        {storeStateNames[0]?.states?.map(
                                          (item) => (
                                            <option
                                              key={item?.name}
                                              value={item?.stateCode}
                                            >
                                              {item?.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <input
                                        id="provider"
                                        placeholder="Provider"
                                        type="text"
                                        className="form-control supportFormFocus p-2 mb-3"
                                        onChange={handleChangeInsuranceDetails}
                                        value={insuranceInputValues?.provider}
                                      />

                                          <DateOfBirthPicker
                                                id="expiryDate"
                                                minDate={
                                                    new Date()
                                                  }
                                                  value={
                                                    insuranceInputValues?.expiryDate
                                                  }
                                                 placeholder="Policy Expiration Date"
                                                  onDateSelect={handleChangeInsuranceDateDetails}
                                                />
                                    </div>
                                    <button
                                      disabled={
                                        !(
                                          insuranceInputValues?.documentName &&
                                          insuranceInputValues?.policyNumber &&
                                          insuranceInputValues?.issueState &&
                                          insuranceInputValues?.provider &&
                                          insuranceInputValues?.expiryDate
                                        )
                                      }
                                      type="submit"
                                      className={`${
                                        insuranceInputValues?.documentName !==
                                          "" &&
                                        insuranceInputValues?.policyNumber !==
                                          "" &&
                                        insuranceInputValues?.issueState !==
                                          "" &&
                                        insuranceInputValues?.provider !== "" &&
                                        insuranceInputValues?.expiryDate !== ""
                                          ? ""
                                          : "opacity disabled"
                                      } w-100 mt-2 px-3 py-2 text-white bg3B3936  p10w10 borderRadius8 border-0 fontSize13`}
                                    >
                                      Upload
                                    </button>
                                  </form>
                                </div>
                                </div> }
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className="pb-3 mt-3 margin110">
                              Please provide your Insurance details
                            </div>
                            {isSelectedCreditCard ? (
                              ""
                            ) : (
                              <div>
                                {isAxleNeed === "true" ?
                                
                                <div
                                // onClick={() =>
                                //   setIsInsuranceClicked(true)
                                // }
                                  onClick={() => getAxleLink()}
                                  className={`${
                                    isSelectCoverage
                                      ? "bgInsuranceSelected"
                                      : ""
                                  } d-flex justify-content-start align-items-center bg3B3936 p-3 borderRadius8 cursorPointer mb-1`}
                                >
                                  <div>
                                   {/* <img
                                    className="mx-1"
                                    src={insuranceLogo}
                                    alt="insuranceLogo"
                                  />  */}
                                    <img
                                      className="mx-1"
                                      src={axleLogo}
                                      alt="axleLogo"
                                    />
                                  </div>
                                  <div className="InsuranceAndCreditPaddingLeft fontAntarctica d-flex justify-content-between align-items-center w-100">
                                    <div>
                                      <div className="fontSize17">
                                        {/* Insurance Coverage */}
                                        Axle Insurance Verification
                                      </div>
                                      <div className="fontSize13 fontColor5BD mt-1">
                                        {/* Make sure the insurance provides rental
                                      coverage */}
                                        Go Ahead with Axle to get verified
                                        quickly.
                                      </div>
                                    </div>
                                    {isSelectCoverage ? (
                                      <div>
                                        <img
                                          src={greenTickIcon}
                                          alt="greenTickIcon"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                :
                                <div
                                onClick={() =>
                                  setIsInsuranceClicked(true)
                                }
                                  className={`${
                                    isSelectCoverage
                                      ? "bgInsuranceSelected"
                                      : ""
                                  } d-flex justify-content-start align-items-center bg3B3936 p-3 borderRadius8 cursorPointer mb-1`}
                                >
                                  <div>
                                   <img
                                    className="mx-1"
                                    src={insuranceLogo}
                                    alt="insuranceLogo"
                                  /> 
                                  </div>
                                  <div className="InsuranceAndCreditPaddingLeft fontAntarctica d-flex justify-content-between align-items-center w-100">
                                    <div>
                                      <div className="fontSize17">
                                        Insurance Coverage
                                      </div>
                                      <div className="fontSize13 fontColor5BD mt-1">
                                        Make sure the insurance provides rental
                                      coverage
                                      </div>
                                    </div>
                                    {isSelectCoverage ? (
                                      <div>
                                        <img
                                          src={greenTickIcon}
                                          alt="greenTickIcon"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                               }

                                {isAxleNeed === "true" && !isSelectCoverage && (
                                  <div className="d-flex align-items-center fontAntarctica flex-wrap">
                                    <p className="p-2 opacity fontSize14 mb-0">
                                      If your Insurance provider is not listed
                                      in Axle please
                                    </p>
                                    <span
                                      className="cursorPointer fontSize14 fontColorCE1 text-decoration-none"
                                      onClick={() =>
                                        setIsInsuranceClicked(true)
                                      }
                                    >
                                      click here
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                            {isSelectCoverage && (
                              <div>
                                <div
                                  className={`p-3 cursorPointer border3B3936 borderRadius8 d-flex justify-content-between align-items-center mb-3 borderE1`}
                                >
                                  <div className="fontSize13 fontWeight400 fontAntarctica d-flex flex-column">
                                    <span className="mb-2">
                                      <span className="opacity-50">Policy Number</span> {filterSelectedInsuranceDoc?.documentId}
                                    </span>
                                    <span className="mb-2">
                                      <span className="opacity-50">Issue State</span> {filterSelectedInsuranceDoc?.issueState}
                                    </span>
                                    <span className="mb-2">
                                      <span className="opacity-50">Provider</span> {filterSelectedInsuranceDoc?.provider}
                                    </span>
                                    <span>
                                      <span className="opacity-50">Exp. Date</span> {filterSelectedInsuranceDoc?.docExpDate}
                                    </span>
                                  </div>
                                  {/* <div className=" fontSize13 fontWeight400 fontAntarctica ">
                                    <div className="mb-1">
                                      Policy Number
                                      <span className="mx-1">
                                        {filterSelectedInsuranceDoc?.documentId}
                                      </span>
                                    </div>
                                    <div className="mb-1">
                                      Issue State
                                      <span className="mx-1">
                                        {filterSelectedInsuranceDoc?.issueState}
                                      </span>
                                    </div>
                                    <div className="mb-1">
                                      Provider
                                      <span className="mx-1">
                                        {filterSelectedInsuranceDoc?.provider}
                                      </span>
                                    </div>
                                    <div>
                                      Exp. Date
                                      <span className="mx-1">
                                        {filterSelectedInsuranceDoc?.docExpDate}
                                      </span>
                                    </div>
                                  </div> */}
                                  <div>
                                    <div className="p-1 borderRadius8">
                                      <div className="d-flex justify-content-end py-2">
                                        <input
                                          type="radio"
                                          name="insurance-doc"
                                          checked={true}
                                          onChange={() =>
                                            setSelectedDocId(
                                              filterSelectedInsuranceDoc?.id
                                            )
                                          }
                                        />
                                      </div>
                                      <img
                                        className="insuranceImagePreview"
                                        // src={
                                        //   filterSelectedInsuranceDoc?.documentName
                                        // }
                                        src={
                                          filterSelectedInsuranceDoc?.fileType ===
                                            "application/octet-stream" ||
                                          filterSelectedInsuranceDoc?.fileType ===
                                            "application/pdf"
                                            ? latestPdfIcon
                                            : filterSelectedInsuranceDoc?.documentName
                                        }
                                        alt="insuranceDocIcon"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {isSelectCoverage || isSelectedCreditCard ? (
                              ""
                            ) : (
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="borderDash"></div>
                                <div className="mx-3 fontColor5BD fontAntarctica">
                                  OR
                                </div>
                                <div className="borderDash"></div>
                              </div>
                            )}
                            {isSelectCoverage ? (
                              ""
                            ) : (
                              <div
                                onClick={() => setIsCreditCardClicked(true)}
                                className={`${
                                  isSelectedCreditCard
                                    ? "bgInsuranceSelected"
                                    : ""
                                } d-flex justify-content-start align-items-center bg3B3936 p-3 borderRadius8 cursorPointer`}
                              >
                                <div>
                                  <img
                                    className="mx-1"
                                    src={creditCardLogo}
                                    alt="creditCardLogo"
                                  />
                                </div>
                                <div className="InsuranceAndCreditPaddingLeft fontAntarctica">
                                  <div className="fontSize17">
                                    Credit Card Coverage
                                  </div>
                                  <div className="fontSize13 mt-1 fontColor5BD">
                                    Don't forget to check if the credit card
                                    covers rental coverage.
                                  </div>
                                </div>
                              </div>
                            )}
                            {isSelectedCreditCard && (
                              <div>
                                <div
                                  className={`borderE1 lineheight21 p-4 my-3 border3B3936 borderRadius8 d-flex justify-content-between align-items-center`}
                                >
                                  <div className=" fontSize13 lineheight21 fontWeight400 fontAntarctica ">
                                    <div className="mb-1 fontColor5BD text-nowrap">
                                      Card Number
                                      <span className="text-white mx-2 text-nowrap">
                                        XXXX XXXX XXXX{" "}
                                        {filterSelectedCreditDoc?.cardNumber}
                                      </span>
                                    </div>
                                    <div className="mb-1 fontColor5BD">
                                      Card Holder Name{" "}
                                      <span className="text-white mx-2">
                                        {filterSelectedCreditDoc?.name}
                                      </span>
                                    </div>
                                    <div className="mb-1 fontColor5BD">
                                      Expiry Date{" "}
                                      <span className="text-white mx-2">
                                        {filterSelectedCreditDoc?.expiryDate}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <div className="d-flex justify-content-end px-2 mb-3">
                                      <input
                                        type="radio"
                                        checked={
                                          selectedCreditId ===
                                          filterSelectedCreditDoc?.id
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          setSelectedCreditId(
                                            filterSelectedCreditDoc?.id
                                          )
                                        }
                                      />
                                    </div>
                                    {/* <div className="bgE933 p-4 borderRadius8"> */}
                                    {/* {filterSelectedCreditDoc?.cardIssuedBy} */}
                                    <img
                                      className="mx-1 height35px"
                                      src={
                                        images[
                                          filterSelectedCreditDoc?.cardIssuedBy
                                        ] || images.defaultCard
                                      }
                                      alt="images"
                                    />
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            )}
                            {isSelectCoverage ? "" : <hr />}
                            <div className="fontAntarctica">
                              <div className="mb-1 fontSize17 fontcolorFFF">
                                Please keep in mind
                              </div>
                              <ul className="fontSize13 fontColor5BD">
                                <li>
                                  Having liability coverage is required by law.
                                </li>
                                <li>
                                  If your card/insurance doesn't cover it, just
                                  add the minimum liability.
                                </li>
                                <li>
                                  If you cause any damage or injuries to others,
                                  that's on you to handle through your
                                  insurance.
                                </li>
                                <li className="px-1">
                                  Same goes for the rental car itself - any
                                  dings, dents, or theft issues are your
                                  responsibility.
                                </li>
                                <li>
                                  We are no longer accepting rental coverage
                                  purchased through &nbsp;&nbsp;
                                  <a
                                    href="https://rentalcover.com"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <u className="fontColorCE1">
                                      RentalCover.com
                                    </u>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <div
                                onClick={() =>
                                  isSelectedCreditCard
                                    ? ""
                                    : handleDetailsSelect(
                                        "rentalCoverageId",
                                        staticValue
                                      )
                                }
                                className={`${
                                  estimatedPriceDetails?.rentalCoverageId?.includes(
                                    1
                                  ) &&
                                  selectedCreditId &&
                                  isCreditCardClicked
                                    ? "borderE1"
                                    : ""
                                }  fontAntarctica border3B3936 borderRadius8 p-2`}
                              >
                                <input
                                  type="radio"
                                  onChange={() => {}}
                                  checked={
                                    estimatedPriceDetails?.rentalCoverageId?.includes(
                                      1
                                    ) &&
                                    selectedCreditId &&
                                    isCreditCardClicked
                                      ? true
                                      : false
                                  }
                                />
                                <label className="mx-2">
                                  Minimum Liability Coverage
                                </label>
                                <ul className="fontSize13 px-3">
                                  <li className="py-1 opacity">
                                    Covers damage to third-party property or
                                    persons.
                                  </li>
                                  <li className="py-1 opacity">
                                    Meets minimum liability insurance
                                    requirements.
                                  </li>
                                  <li className="fontColor335">
                                    Does not cover damage to the vehicle.
                                  </li>
                                </ul>
                                <div className="fontSize13 fontColor335 bg71A p-2 borderRadius8 px-1">
                                  Warning: If your card/insurance doesn't offer
                                  liability coverage, We won't be able to
                                  confirm your booking without a liability
                                  coverage.
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </DesktopCustomModal>
                    {/* here DesktopModal is rendered here to open when user clicks on Add New Card in the above DesktopModal */}
                    <DesktopCustomModal
                      isModalOpen={activeModal.includes("AddNewCard")}
                      closeModal={() => {
                        closeModal("AddNewCard")
                        localStorage.setItem("isRentalCoverageModal", true)
                      }}
                      closeOnOutsideClick={false}
                      modalId="AddNewCard"
                      modalTitle={
                        <div className="fontColorCE1 p-3">Add New Card</div>
                      }
                      modalButton=""
                      modalCenter={true}
                      modalBackgroudColor="transparent"
                      isCloseIconRequired={true}
                    >
                      <Checkout
                        closeModal={() => {
                          closeModal("AddNewCard");
                          getUserCreditDocs();
                          getSavedCards();
                          localStorage.setItem("isRentalCoverageModal", true)
                        }}
                      />
                    </DesktopCustomModal>
                    <p className="fontAntarctica fontSize13 opacity py-2 px-2 m-1">
                      Please select a Rental Coverage
                    </p>
                    <div className="d-flex justify-content-between align-items-stretch align-items-center flex-wrap mb-1">
                      {data?.rentalCoverage?.map((item) => {
                        return (
                          <div
                            onClick={() => {
                              if(!estimatedPriceDetails?.rentalCoverageId?.includes(
                                item?.id
                              ) || item?.id === 1) {
                              handleDetailsSelect("rentalCoverageId", item);
                              handleField(["extrasField"]);
                              setSelectCoverageLater(false);
                              }
                            }}
                            key={item?.id}
                            className={`extras-field-hoverEffect border3B3936 p-2 m-1 rounded rounded-3 cursorPointer ${
                              modalBookingStatus ? "w-100" : "width48"
                            } ${
                              estimatedPriceDetails?.rentalCoverageId?.includes(
                                item?.id
                              )
                                ? "extrasborderE1"
                                : ""
                            } ${
                              item?.id === 1 && localIsId1Disabled
                                ? "disableRentalCard1"
                                : ""
                            } `}
                          >
                            <div className="fontSize13 fontAntarctica d-flex justify-content-between align-items-center position-relative">
                              <div className="p-2 d-flex justify-content-between">
                                {item?.id === 1 ? (
                                  <input
                                    onChange={() => {}} //we put this just to ignore the console errors
                                    checked={
                                      estimatedPriceDetails?.rentalCoverageId?.includes(
                                        item?.id
                                      )
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                    id={`checkbox-${item?.id}`}
                                    className="input-spacing"
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id={`radio-${item?.id}`}
                                    name="coverages"
                                    value={item?.title}
                                    onChange={() => {}} //we put this just to ignore the console errors
                                    checked={
                                      estimatedPriceDetails?.rentalCoverageId?.includes(
                                        item?.id
                                      )
                                        ? true
                                        : false
                                    }
                                    className="input-spacing"
                                  />
                                )}
                                <label className="fontSize13">
                                  {item?.title}
                                </label>
                              </div>
                              {/* <span className="rentalPopular">Most popular</span> */}
                              {item?.title === "Standard Damage Coverage" && (
                                <span className="rentalPopular">
                                  Most popular
                                </span>
                              )}
                              <div>
                                {item?.description && (
                                  <Overlay
                                    className="fontSize10 fontColor5BD"
                                    dataBsTitle={item?.description || ""}
                                    direction="top"
                                  >
                                    <img
                                      src={greyInfoIcon}
                                      alt="greyInfoIcon"
                                    />
                                  </Overlay>
                                )}
                              </div>
                            </div>
                            {item?.description
                              .split("\r\n")
                              .map((point, index) => (
                                <ul key={index} className="mb-1 px-4">
                                  <li className="fontSize10 fontAntarctica fontColor5BD">
                                    {point}
                                  </li>
                                </ul>
                              ))}
                            {item?.id === 1 && (
                              <span className="fontSize10 fontAntarctica  fontColor994A">
                                CAUTION: Does not cover damage to the rented
                                vehicle.
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      onClick={() => {
                        setSelectCardIDForPay("")
                        setSelectedPaymentCardId("")
                        localStorage.removeItem("PaymentCard")
                        localStorage.removeItem("insuranceDoc")
                        setSelectCoverageLater(true);
                        setEstimatedPriceDetails((prev) => ({
                          ...prev,
                          rentalKey: [],
                          rentalCoverageId: [],
                          protectionPlanType: 0,
                          referenceId: "",
                        }));
                        localStorage.setItem("localIsId1Disabled", false);
                      }}
                      className={`extras-field-hoverEffect d-flex align-items-center p-2 m-1 rounded rounded-3 cursorPointer ${
                        modalBookingStatus ? "width98" : "width48"
                      } ${selectCoverageLater ? "extrasborderE1" : "border3B3936"}`}
                    >
                      <div className="mx-2">
                        <input
                          type="radio"
                          checked={selectCoverageLater}
                          readOnly
                        />
                      </div>
                      <div className="fontSize13 fontAntarctica ">I Will Select Coverage Later</div>
                    </div>
                  </div>
                )}
                </div>
                </div>
                </div>
              </div>

              <div className="py-2">
                <div className="px-2 py-2 br8 bgF08">
                <div
                 onClick={() =>
                  setField((prev) => ({
                    ...prev,
                    additionalCoverageField: !prev?.additionalCoverageField,
                  }))
                }
                className="d-flex justify-content-between align-items-center cursorPointer">
                  <div>
                    <h5 className="p-1 m-1 fontAntarctica fontSize17">
                      Additional Coverage
                    </h5>
                  </div>
                  <div
                   
                    className="fontSize13 fontAntarctica fontColorCE1 cursor"
                  >
                    {field?.additionalCoverageField ? "Hide" : "Show"}
                  </div>
                </div>
                <div>
                {field?.additionalCoverageField && (
                  <div className="d-flex justify-content-between align-items-center align-items-stretch flex-wrap">
                    {data?.additionalCoverage?.map((item) => {
                      return (
                        <div
                          onClick={() => {
                            handleDetailsSelect("additionalCoverageIds", item);
                            handleField(["rentalCoverageField", "extrasField"]);
                          }}
                          key={item?.id}
                          className={`extras-field-hoverEffect border3B3936 cursor p-2 m-1 rounded rounded-3 ${
                            modalBookingStatus ? "w-100" : "width48"
                          } ${
                            estimatedPriceDetails?.additionalCoverageIds?.includes(
                              item?.id
                            )
                              ? "extrasborderE1"
                              : ""
                          }`}
                        >
                          <div className="fontSize13 fontAntarctica d-flex justify-content-between align-items-center">
                            <div className="p-2 d-flex justify-content-between">
                              <input
                                onChange={() => {}}
                                type="checkbox"
                                id={item?.title}
                                name="coverages"
                                value={item?.title}
                                checked={
                                  estimatedPriceDetails?.additionalCoverageIds?.includes(
                                    item?.id
                                  )
                                    ? true
                                    : false
                                }
                                className="input-spacing"
                              />
                              <label>{item?.title}</label>
                            </div>
                            <div>
                              {item?.description && (
                                <Overlay
                                  dataBsTitle={item?.description || ""}
                                  direction="top"
                                >
                                  <img src={greyInfoIcon} alt="greyInfoIcon" />
                                </Overlay>
                              )}
                            </div>
                          </div>
                          {item?.description
                            .split("\r\n")
                            .map((point, index) => (
                              <ul key={index} className="mb-1 px-4">
                                <li className="fontSize10 fontAntarctica fontColor5BD ">
                                  {point}
                                </li>
                              </ul>
                            ))}
                        </div>
                      );
                    })}
                  </div>
                )}
                </div>
                </div>
              </div>
              
              <div
                className={`py-2 mb-4 ${
                  modalBookingStatus ? "couponPaddingBottom" : ""
                }`}
              >
                <h6 className="p-1 m-1 fontAntarctica fontSize17">Coupon</h6>
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                  <input
                    className={`w-100 p-1 rounder rounded-2 m-1 outlineNone fontAntarctica bg-transparent  text-white
                      ${ showButtonCoupon ? "border3B3936" : "borderE1"}  `}
                    type="text"
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                    }}
                    value={couponCode}
                    disabled={showButtonCoupon ? false : true}
                  />
                  <button
                    className={`outlineNone cursorPointer border-0 p-2 mx-1 borderRadius8 bgF08 text-white fontAntarctica ${
                      modalBookingStatus ? "w-100 mt-1" : "w-50"
                    } ${couponCode?.length === 0 && "disabled-class"}`}
                    onClick={
                      showButtonCoupon
                        ? () => {
                            if (
                              currentUser?.email.includes(
                                "privaterelay.appleid.com"
                              ) ||
                              currentUser?.email === null ||
                              currentUser?.email.length === 0 ||
                              currentUser?.primaryContact === undefined ||
                              currentUser?.primaryContact === null ||
                              currentUser?.primaryContact.length === 0 ||
                              currentUser?.dob === undefined ||
                              currentUser?.dob === null ||
                              currentUser?.dob?.length === 0 ||
                              currentUser?.firstName === null ||
                              currentUser?.firstName === undefined ||
                              currentUser?.firstName?.length === 0 ||
                              currentUser?.lastName === null ||
                              currentUser?.lastName === undefined ||
                              currentUser?.lastName?.length === 0
                            ) {
                              if (
                                currentUser?.email.includes(
                                  "privaterelay.appleid.com"
                                ) ||
                                currentUser?.email === null
                              ) {
                                setSocialEmail(true);
                              } else {
                                setSocialEmail(false);
                                //this.socailUserContForm.get('email')?.disable();
                              }
                              if (
                                currentUser?.primaryContact === undefined ||
                                currentUser?.primaryContact === null ||
                                currentUser?.primaryContact?.length === 0
                              ) {
                                setSocialPhone(true);
                              } else {
                                setSocialPhone(false);
                                // socailUserContForm.get('phoneNumber')?.disable();
                                // socailUserContForm.get('countryCode')?.disable();
                              }
                              if (
                                currentUser?.dob === undefined ||
                                currentUser?.dob === null ||
                                currentUser?.dob?.length === 0
                              ) {
                                setSocialDob(true);
                              } else {
                                setSocialDob(false);
                                // socailUserContForm.get('dob')?.disable();
                              }
                              if (
                                currentUser?.firstName === undefined ||
                                currentUser?.firstName === null ||
                                currentUser?.firstName?.length === 0 ||
                                currentUser?.lastName === undefined ||
                                currentUser?.lastName === null ||
                                currentUser?.lastName?.length === 0
                              ) {
                                setSocialFlName(true);
                              } else {
                                setSocialFlName(false);
                                // socailUserContForm.get('firstName')?.disable();
                                // socailUserContForm.get('lastName')?.disable();
                              }
                              // socialUserCont = true;
                              openModal("UpdateUserDetails");
                            } else {
                              setEstimatedPriceDetails((prev) => ({
                                ...prev,
                                couponCode: couponCode,
                                emailFlag: false,
                                couponCodeResponse: null,
                              }));
                              setShowButtonCoupon(!showButtonCoupon);
                            }
                          }
                        : () => {
                            setCouponCode("");
                            setEstimatedPriceDetails((prev) => ({
                              ...prev,
                              couponCode: "",
                              emailFlag: false,
                              // couponCodeResponse: null,
                            }));
                            setShowButtonCoupon(!showButtonCoupon);
                            // toast.success("removed coupon code successfully")
                          }
                    }
                  >
                    {showButtonCoupon ? "Apply Coupon" : "Remove Coupon"}
                  </button>
                </div>
              </div>


              {showPriceDetails && (
                <div className="extrasCompoShowPricing d-none d-lg-block">
          <ShowPricingDetails
            estimatedPrice={estimatedPrice}
            pricingDetails={pricingDetails}
          />
          
        </div>
        )}
            </div>
          </div>
        </div>
      )}
      {/**this will show the pricing details if the customer not logs in*/}
      <div className="position-absolute bottom-0 w-100 bg23">
        {showPriceDetails && (token === "" || token === null) && window?.innerWidth > 992  && (
          <ShowPricingDetails
            estimatedPrice={estimatedPrice}
            pricingDetails={pricingDetails}
          />
        )}
        <div className="pb-3 d-flex justify-content-between align-items-end">
          <div  onClick={() => setShowPriceDetails(!showPriceDetails)} className="cursorPointer d-none d-lg-flex flex-column">
            <div className="d-flex">
              <div className="fontSize17 m-1">Total</div>
              <span className="m-1">
                <img
                  src={greyInfoIcon}
                  alt="greyInfoIcon"
                />
              </span>
            </div>
            <div className="fontSize22">
              ${" "}
              {total?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <DesktopCustomModal
            isModalOpen={activeModal.includes("ProceedBooking")}
            closeModal={() => {
              //setSelectCardIDForPay("")
              closeModal("ProceedBooking");
              dispatch(setOpenProceedBookingDesktopModal(false)); //this is used to close the desktopmodal of disclaimer one
              //dispatch(toggleShowModalBookingDetails(false)); //this is used to close the bottom booking details modal
            }}
            closeOnOutsideClick={selectStripePayment ? false : true}
            modalCenter={true}
            modalId="ProceedBooking"
            modalClassHeight={
              selectStripePayment ? "modalHeightOwnCoverage" : ""
            }
            isCloseIconRequired={selectStripePayment ? true : false}
            modalTitle={
              selectStripePayment ? (
                <div className="p-2 fontAntarctica fontSize22 fontWeight400">
                  Select your payment method
                </div>
              ) : disClaimer ? (
                <div className="d-flex justify-content-start align-items-center py-2 px-4 mt-3 fontAntarctica fontSize17">
                  Need a hand getting to know your {carDetails?.name}{" "}
                  {carDetails?.vehicleModel}?
                </div>
              ) : (
                <div className="text-center px-3 fontAntarctica fontSize17">
                  Disclaimer
                </div>
              )
            }
            modalButton={
              <div
                className="bgCE1 nextBookingButton text-white d-none d-lg-flex justify-content-center align-items-center"
                onClick={() => {
                  
                  if (userToken === "") {
                    dispatch(setOpenLoginDesktopModal(true));
                  } else {
                    if (
                      currentUser?.email.includes("privaterelay.appleid.com") ||
                      currentUser?.email === null ||
                      currentUser?.email.length === 0 ||
                      currentUser?.primaryContact === undefined ||
                      currentUser?.primaryContact === null ||
                      currentUser?.primaryContact.length === 0 ||
                      currentUser?.dob === undefined ||
                      currentUser?.dob === null ||
                      currentUser?.dob?.length === 0 ||
                      currentUser?.firstName === null ||
                      currentUser?.firstName === undefined ||
                      currentUser?.firstName?.length === 0 ||
                      currentUser?.lastName === null ||
                      currentUser?.lastName === undefined ||
                      currentUser?.lastName?.length === 0 ||
                      currentUser?.dob === ""
                    ) {
                      if (
                        currentUser?.email.includes(
                          "privaterelay.appleid.com"
                        ) ||
                        currentUser?.email === null
                      ) {
                        setSocialEmail(true);
                      } else {
                        setSocialEmail(false);
                        //this.socailUserContForm.get('email')?.disable();
                      }
                      if (
                        currentUser?.primaryContact === undefined ||
                        currentUser?.primaryContact === null ||
                        currentUser?.primaryContact?.length === 0
                      ) {
                        setSocialPhone(true);
                      } else {
                        setSocialPhone(false);
                        // socailUserContForm.get('phoneNumber')?.disable();
                        // socailUserContForm.get('countryCode')?.disable();
                      }
                      if (
                        currentUser?.dob === undefined ||
                        currentUser?.dob === null ||
                        currentUser?.dob?.length === 0 ||
                        currentUser?.dob === ""
                      ) {
                        setSocialDob(true);
                      } else {
                        setSocialDob(false);
                        // socailUserContForm.get('dob')?.disable();
                      }
                      if (
                        currentUser?.firstName === undefined ||
                        currentUser?.firstName === null ||
                        currentUser?.firstName?.length === 0 ||
                        currentUser?.lastName === undefined ||
                        currentUser?.lastName === null ||
                        currentUser?.lastName?.length === 0
                      ) {
                        setSocialFlName(true);
                      } else {
                        setSocialFlName(false);
                        // socailUserContForm.get('firstName')?.disable();
                        // socailUserContForm.get('lastName')?.disable();
                      }
                      // socialUserCont = true;
                      openModal("UpdateUserDetails");
                    } else {
                      // openModal("ProceedBooking");
                      if(userToken !== ""){
                        dispatch(setOpenProceedBookingDesktopModal(true));
                      } 
                      setEstimatedPriceDetails((prev) => ({
                        ...prev,
                        emailFlag: true,
                        // couponCodeResponse: null,
                      }));
                    }
                  }
                }}
              >
                {loadingUntilResponse ? (
                  <div>
                    <SpinnerLoader />
                  </div>
                ) : (
                  "Pay Now"
                )}
              </div>
            }
            modalContent={
              selectStripePayment ? (
                <div className="d-flex flex-column align-items-center mt-3 w-100">
                  {/* Authorization message */}
                  <div className="mb-2">
                    I authorize $
                    {total?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    to be charged to the selected payment method above.
                  </div>
                  {/* Add Card and Confirm Payment buttons */}
                  <div className="d-flex justify-content-center w-100">
                    {(!getLocalStorageData()?.rentalCoverageId?.includes(4) || getInsuranceDoc !== null) && (
                      <button
                        onClick={() => {
                          openModal("AddNewCards", true)
                          closeModal("ProceedBooking");
                          localStorage.setItem("isRentalCoverageModal", true)
                        }}
                        className="bg3B3936 w-100 p-2 text-center border-0 outlineNone borderRadius8 fontcolorFFF marginRight"
                      >
                        Add Card
                      </button>
                    )}
                    <button
                      onClick={() => {
                        localStorage.setItem("isRentalCoverageModal", false)
                        if (getPaymentCard !== "") {
                          handleBooking();
                        }
                      }}
                      className={`${
                        !getPaymentCard || bookingReferenceLoading
                          ? "disabled-class"
                          : ""
                      } bgCE1 w-100 p-2 text-center border-0 outlineNone borderRadius8 fontcolorFFF marginRight`}
                    >
                      Confirm Payment 
                    </button>
                  </div>
                </div>
              ) : disClaimer ? (
                <div className="d-flex justify-content-between align-items-center mt-2 w-100">
                  <button
                    onClick={() => {
                      setSelectStripePayment(true)
                      setEstimatedPriceDetails((prev) => ({
                        ...prev,
                        walkThroughFlag: false
                      }))
                    }}
                    className="bg3B3936 w-100 p-2 text-center border-0 outlineNone borderRadius8 fontcolorFFF marginRight"
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      setSelectStripePayment(true)
                      setEstimatedPriceDetails((prev) => ({
                        ...prev,
                        walkThroughFlag: true
                      }))
                    }}
                    className="bgCE1 w-100 p-2 text-center border-0 outlineNone borderRadius8 fontcolorFFF marginLeft"
                  >
                    Yes
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setDisClaimer(true)}
                  className="bgCE1 w-100 p-2 text-center border-0 outlineNone borderRadius8 fontcolorFFF"
                >
                  I Understand
                </button>
              )
            }
          >
            {/* <DesktopCustomModal
              isModalOpen={activeModal.includes("AddNewCards")}
              closeModal={() => closeModal("AddNewCards")}
              closeOnOutsideClick={true}
              modalId="AddNewCards"
              modalTitle={<div className="fontColorCE1 p-3">Add New Card</div>}
              modalButton=""
              modalCenter={true}
              modalBackgroudColor="transparent"
              modalClassHeight="modalHeightAddNewCardStripe"
            >
              <Checkout
                closeModal={() => {
                  closeModal("AddNewCards");
                  getSavedCards();
                }}
              />
            </DesktopCustomModal> */}
            <div>
              {selectStripePayment ? (
                <div>
                  {getPaymentCard && getLocalStorageData()?.rentalCoverageId?.includes(4) && !getInsuranceDoc ? (
                    <div>
                      <div
                        className={`borderE1 p-4 my-3 border3B3936 borderRadius8 d-flex justify-content-between align-items-center`}
                      >
                        <div className=" fontSize13 fontWeight400 fontAntarctica ">
                          <div className="mb-1 fontColor5BD">
                            Card Number
                            <span className="text-white mx-2">
                              XXXX XXXX XXXX {paymentCardDetails?.cardNumber}
                            </span>
                          </div>
                          <div className="mb-1 fontColor5BD">
                            Card Holder Name{" "}
                            <span className="text-white mx-2">
                              {paymentCardDetails?.name}
                            </span>
                          </div>
                          <div className="mb-1 fontColor5BD">
                            Expiry Date{" "}
                            <span className="text-white mx-2">
                              {paymentCardDetails?.expiryDate}
                            </span>
                          </div>
                        </div>
                        <div className="py-2">
                          <div className="d-flex justify-content-end mb-4">
                            <input
                              type="radio"
                              onChange={() => {}}
                              checked={
                                getPaymentCard === paymentCardDetails?.id
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <img
                            className="mx-1 height35px"
                            src={
                              images[filterSelectedCreditDoc?.cardIssuedBy] ||
                              images.defaultCard
                            }
                            alt="images"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {savedCards?.data?.length > 0 ? (
                        savedCards?.data?.map((details) => {
                          return (
                            <div
                              onClick={() => {
                                localStorage.setItem("isRentalCoverageModal", true)
                                setSelectCardIDForPay(details?.id);
                                localStorage.setItem(
                                  "PaymentCard",
                                  details?.id
                                );
                              }}
                              key={details?.id}
                              className={`${
                                getPaymentCard === details?.id
                                  ? "borderE1"
                                  : ""
                              } p-3 mb-3 lineheight21 border3B3936 borderRadius8 d-flex justify-content-between align-items-center cursorPointer`}
                            >
                              <div className=" fontSize13 fontWeight400 fontAntarctica ">
                                <div className="mb-1 fontColor5BD">
                                  Card Number
                                  <span className="text-white mx-2">
                                    XXXX XXXX XXXX {details?.cardNumber}
                                  </span>
                                </div>
                                <div className="mb-1 fontColor5BD">
                                  Card Holder Name{" "}
                                  <span className="text-white mx-2">
                                    {details?.name}
                                  </span>
                                </div>
                                <div className="mb-1 fontColor5BD">
                                  Expiry Date{" "}
                                  <span className="text-white mx-2">
                                    {details?.expiryDate}
                                  </span>
                                </div>
                              </div>
                              <div className="py-2">
                                <div className="d-flex justify-content-end mb-4">
                                  <input
                                    type="radio"
                                    checked={
                                      getPaymentCard === details?.id
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      setSelectCardIDForPay(details?.id)
                                    }
                                  />
                                </div>
                                {/* <div className="bgE933 p-4 borderRadius8"> */}
                                {/* {details?.cardIssuedBy} */}
                                <img
                                  className="height35px"
                                  src={
                                    images[details?.cardIssuedBy] ||
                                    images.defaultCard
                                  }
                                  alt="images"
                                />
                                {/* </div> */}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="px-2 fontAntarctica fontSize13 ">
                          No cards available Please add a card for Payment.
                        </div>
                      )}
                      <div>
                        {/* Display bookingReferenceError error message if there is an error */}
                        {/* {bookingReferenceError && (
                          <div className="alert alert-danger text-danger mx-5 mt-4">
                            <strong>Error:</strong> {bookingReferenceError}
                          </div>
                        )} */}
                      </div>
                    </div>
                  )}
                </div>
              ) : disClaimer ? (
                <div className="fontColor5BD fontAntarctica fontSize14 p-3 text-center">
                  Just hit 'Yes' and we'll have someone show you the ropes and
                  help out with any questions you might have.
                </div>
              ) : (
                <div className="fontColor5BD fontAntarctica fontSize14  mt-0 p-3">
                  The booking will be confirmed only after the required
                  documents have been provided and verified. Please visit My
                  Trips section to upload the required documents under the
                  specific booking.
                  <div className="mt-2">
                    All Vehicles are Pre-loaded with E-ZPass. Do not bring a
                    personal E-ZPass.
                  </div>
                </div>
              )}
            </div>
          </DesktopCustomModal>
        </div>
        <DesktopCustomModal
              isModalOpen={activeModal.includes("AddNewCards")}
              closeModal={() => {
                closeModal("AddNewCards")
                openModal("ProceedBooking")
                localStorage.setItem("isRentalCoverageModal", true)
              }}
              closeOnOutsideClick={false}
              modalId="AddNewCards"
              modalTitle={<div className="fontColorCE1 p-3">Add New Card</div>}
              isCloseIconRequired={true}
              modalButton=""
              modalCenter={true}
              modalBackgroudColor="transparent"
              modalClassHeight="modalHeightAddNewCardStripe"
            >
              <Checkout
                closeModal={() => {
                  closeModal("AddNewCards");
                  getSavedCards();
                  openModal("ProceedBooking")
                  if(window?.innerWidth < 992){
                  dispatch(toggleShowModalBookingDetails(true))
                  }
                  localStorage.setItem("isRentalCoverageModal", true)
                }}
              />
            </DesktopCustomModal>
      </div>
      <DesktopCustomModal
        isModalOpen={activeModal.includes("UpdateUserDetails")}
        closeModal={() => closeModal("UpdateUserDetails")}
        closeOnOutsideClick={true}
        modalId="UpdateUserDetails"
        modalTitle={<div className="fontColorCE1 p-3">Update User Details</div>}
        modalButton=""
        modalCenter={true}
        modalBackgroudColor="bg23"
        modalClassHeight="updateUserDetailsModalHeight"
      >
        <div className="px-2 input-group w-100">
          <div className="w-100">
            {socialFlName && (
              <div className="mb-3">
                <input
                  id="firstName"
                  placeholder="First Name"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  onChange={handleChangeUpdateUserDetails}
                  onBlur={(e) => {
                    setTouched((prevState) => ({
                      ...prevState,
                      firstName: true,
                    }));
                    const error = validateField(e.target.id, e.target.value);
                    setUserErrors((prevErrors) => ({
                      ...prevErrors,
                      firstName: error,
                    }));
                  }}
                  value={editUserDetails?.firstName}
                />
                {userErrors.firstName && (
                  <div className="text-danger">{userErrors.firstName}</div>
                )}
              </div>
            )}

            {socialFlName && (
              <div className="mb-3">
                <input
                  id="lastName"
                  placeholder="Last Name"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  onChange={handleChangeUpdateUserDetails}
                  onBlur={(e) => {
                    setTouched((prevState) => ({
                      ...prevState,
                      lastName: true,
                    }));
                    const error = validateField(e.target.id, e.target.value);
                    setUserErrors((prevErrors) => ({
                      ...prevErrors,
                      lastName: error,
                    }));
                  }}
                  value={editUserDetails?.lastName}
                />
                {userErrors.lastName && (
                  <div className="text-danger">{userErrors.lastName}</div>
                )}
              </div>
            )}
            {socialDob && (
              <div className="mb-3">

                   <div>
                    <DateOfBirthPicker
                      maxDate={
                        new Date(
                          2006,
                          new Date().getMonth(),
                          Math.min(new Date().getDate(), 31)
                        )
                      }
                      handleBlur={(e) => {
                        setTouched((prevState) => ({ ...prevState, dob: true }));
                        const error = validateField(e.target.id, e.target.value);
                        setUserErrors((prevErrors) => ({
                          ...prevErrors,
                          dob: error,
                        }));
                      }}
                      value={editUserDetails?.dob}
                      placeholder="Date Of Birth mm/dd/yyyy"
                      onDateSelect={handleDobUpdate}
                    />
                  </div>


                {/* <div>
                  <CommonCalendar
                    maxDate={
                      new Date(
                        2006,
                        new Date().getMonth(),
                        Math.min(new Date().getDate(), 31)
                      )
                    }
                    handleBlur={(e) => {
                      setTouched((prevState) => ({ ...prevState, dob: true }));
                      const error = validateField(e.target.id, e.target.value);
                      setUserErrors((prevErrors) => ({
                        ...prevErrors,
                        dob: error,
                      }));
                    }}
                    value={editUserDetails?.dob}
                    placeholder="Date Of Birth"
                    onDateSelect={handleDateSelect}
                  />
                </div> */}
              </div>
            )}
            {socialPhone && (
              <div className="mb-3 borderRadius8 p-1">
                <PhoneInput
                  value={editUserDetails?.primaryContact}
                  country={"us"}
                  placeholder="Phone Number"
                  onChange={handlePhoneChange}
                />
                <div className="fontColor4031">
                  {" "}
                  {userErrors?.primaryContact && userErrors?.primaryContact}
                </div>
              </div>
            )}
            {socialEmail && (
              <div className="mb-3">
                <input
                  id="email"
                  placeholder="Email"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  onChange={handleChangeUpdateUserDetails}
                  onBlur={(e) => {
                    setTouched((prevState) => ({ ...prevState, email: true }));
                    const error = validateField(e.target.id, e.target.value);
                    setUserErrors((prevErrors) => ({
                      ...prevErrors,
                      lastName: error,
                    }));
                  }}
                  value={editUserDetails?.email}
                  disabled
                />
              </div>
            )}
            <div className="d-flex justify-content-between">
              <button
                onClick={handleSubmitUpdateUserDetails}
                className={`${
                  userErrors?.primaryContact ||
                  userErrors?.firstName ||
                  userErrors?.lastName ||
                  userErrors?.email ||
                  userErrors?.dob ||
                  editUserDetails?.email?.length === 0 ||
                  editUserDetails?.firstName?.length === 0 ||
                  editUserDetails?.lastName?.length === 0 ||
                  editUserDetails?.dob?.length === 0 ||
                  editUserDetails?.dob === undefined ||
                  editUserDetails?.primaryContact?.length === 0
                    ? "disabled-class"
                    : ""
                } w-100 mt-2 mx-1 px-3 py-2 text-white bgCE1 borderRadius8 border-0 fontSize13`}
              >
                {updateUserLoading ? <SpinnerLoader /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </DesktopCustomModal>
    </div>
  );
};

export default ExtrasAndRentalDetails;

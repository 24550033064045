import { useSelector } from "react-redux";
import { useRef, useEffect, useState } from "react";
import garageDoorIcon from "../../assets/svg/garageDoorIcon.svg";
import locationIcon from "../../assets/svg/locationIcon.svg";
import SpinnerLoader from "../SpinnerLoader";
const LocationsList = ({
  show,
  filterOptions,
  optionValue,
  handleChangeLocation,
  highlightedIndex,
  autocompleteSuggestions,
  suggestionLoader,
  handlePlaceSelect,
  mainTitle,//with this mainTitle we are disabling the drop locations
  garageServiceCount,
  restrictDropOffLocations,
}) => {
  //console.log(mainTitle);
  
  // console.log(restrictDropOffLocations);
  const getDisableDropLocation = localStorage.getItem("disableDropLocation");
  //console.log(getDisableDropLocation);
  
  const pickAndDropLocation = useSelector((store) => store?.currentLocations);

  // console.log('getDisableDropLocation', getDisableDropLocation);
  // console.log('restrictDropOffLocations', restrictDropOffLocations);

  // console.log(getDisableDropLocation === "true");

  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const listRefs = useRef([]);

  useEffect(() => {
    if (listRefs?.current[highlightedIndex]) {
      listRefs?.current[highlightedIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [highlightedIndex]);

  return (
    <div
      className={`${
        isDarkMode ? "darkMode" : "lightMode"
      } fontAntarctica p-2  d-flex flex-column ${show ? "box" : ""}`}
    >
      <div>
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-start">
          {autocompleteSuggestions?.length > 0 ? (
            <div>
              <div className="w-100 py-2 text-center fontSize17 fontAntarctica">
                Deliver to my location
              </div>
              <div className="customizedScrollBar ">
                <div className="garageList">
                  {autocompleteSuggestions?.length > 0 ? (
                    autocompleteSuggestions?.map((location, index) => {
                      const isHighlighted =
                        location?.placeId?.toLowerCase() ===
                          optionValue?.toLowerCase() ||
                        highlightedIndex === index;

                      return (
                        <ul
                          key={index}
                          ref={(el) => (listRefs.current[index] = el)}
                          className={`unorderList ${
                            isHighlighted
                              ? isDarkMode
                                ? "bg191916"
                                : "bgFEFDFD"
                              : ""
                          }`}
                        >
                          <li
                            className={`${
                              isDarkMode ? "listInDarkMode" : "listInLightMode"
                            } list d-flex align-items-center`}
                            onClick={() => {
                              handlePlaceSelect(location.placeId);
                            }}
                          >
                            <div className="p-2">
                              <img src={locationIcon} alt="locationIcon" />
                            </div>
                            <div>
                              <p className="mb-0 fontSize13 textAlignLeft">
                                {location?.description}
                              </p>
                            </div>
                          </li>
                        </ul>
                      );
                    })
                  ) : (
                    <p className="text-center fontColorFE9  opacity-75  fontSize13">
                      Sorry, currently we are not servicing at {optionValue} Try
                      a different location for results.
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="locationListBox">
            <div className="px-2 fontColorFE9 fontSize13 text-center opacity-75 mt-2">
              {garageServiceCount === ""
                ? "Search for your address or you can select a available garage"
                : garageServiceCount > 1 || garageServiceCount === 0
                ? "We do not deliver to your area at this time"
                : "Search for your address or you can select a available garage"}
            </div>
            </div>
          )}
          <div
            className={`${
              isDarkMode ? "darkMode" : "lightMode"
            } p-1 d-none d-lg-flex flex-column justify-content-between align-items-center`}
          >
            <div className="OR_Border"></div>
            <div className="p-1">OR</div>
            <div className="OR_Border"></div>
          </div>
          <div>
            <div className="w-100 py-2 text-center fontSize17 fontAntarctica">
              {/* {mainTitle}  */}
              Our Locations
            </div>
            <div className="customizedScrollBar  ">
              {!suggestionLoader ? (
                <div className="garageList">
                  {filterOptions?.length > 0 ? (
                    filterOptions?.map((location, index) => {
                      const isHighlighted =
                        location.displayAddress?.toLowerCase() ===
                          optionValue?.toLowerCase() ||
                        pickAndDropLocation?.pickup?.toLowerCase() ===
                          location.displayAddress?.toLowerCase() ||
                        highlightedIndex === index;

                      return (
                        <ul
                          key={index}
                          ref={(el) => (listRefs.current[index] = el)}
                          className={`unorderList ${
                            isHighlighted
                              ? isDarkMode
                                ? "bg191916"
                                : "bgFEFDFD"
                              : mainTitle === "Garage Drop Off" && getDisableDropLocation === "true"
                              ? "disabled-class"
                              : ""
                          } ${
                            location?.garageServiceable
                              ? ""
                              : "disableLocations"
                          }`}
                        >
                          <li
                            className={`${
                              isDarkMode ? "listInDarkMode" : "listInLightMode"
                            } list d-flex align-items-center`}
                            onClick={() => {
                              handleChangeLocation(
                                location.displayAddress,
                                location.id,
                                null, // location.latitude,
                                null, // location.longitude,
                                true
                              );
                            }}
                          >
                            <div className="p-2">
                              <img src={garageDoorIcon} alt="locationIcon" />
                            </div>
                            <div>
                              <p className="mb-0 fontSize13 textAlignLeft">
                                {location.displayAddress.split(" - ")[0]}{" "}
                                {location?.distanceInMiles !== 0 &&
                                  location?.distanceInMiles && (
                                    <span className="mx-1 fontColor757575">
                                      {location?.distanceInMiles} mi away
                                    </span>
                                  )}
                              </p>
                              <p className="mb-0 fontSize13 textAlignLeft">
                                {location.displayAddress.split(" - ")[1]}
                              </p>
                            </div>
                          </li>
                        </ul>
                      );
                    })
                  ) : (
                    <p className="text-center fontColorFE9  opacity-75 fontSize13">
                      Sorry, currently we are not servicing at {optionValue} Try
                      a different location for results.
                    </p>
                  )}
                </div>
              ) : (
                <div className="garageList text-center">
                  <SpinnerLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsList;

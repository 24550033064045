import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../Store/pageSlice";
import support_image from "../assets/Images/support_image.jpg";
import emailWhiteIcon from "../assets/svg/emailWhiteIcon.svg";
import phoneWhiteIcon from "../assets/svg/phoneWhiteIcon.svg";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SpinnerLoader from "../Components/SpinnerLoader";
import { BASE_URL } from "../api";
import Recaptcha from "../Components/Recaptcha";

const Support = () => {
  const dispatch = useDispatch();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [faqActive, setFaqActive] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [faqID, setFaqID] = useState("");
  const location = useLocation();
  const [loadingFaqs, setLoadingFaqs] = useState(false);

  const [supportPayload, setSupportPayload] = useState({
    adminReply: "",
    companyName: "",
    email: "",
    firstName: "",
    id: 0,
    lastName: "",
    message: "",
    reachOn: 0,
    repliedOn: 0,
    subject: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [showContactForm, setShowContactForm] = useState(false);

  // Email regex for @gmail.com
  const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;

  const handleBlur = (e) => {
    const { id, value } = e.target;
    let errorMsg = "";

    switch (id) {
      case "firstName":
        if (value.length < 2) {
          errorMsg = "First name should have at least 2 characters.";
        }
        break;

      case "lastName":
        if (value.length < 2) {
          errorMsg = "Last name should have at least 2 characters.";
        }
        break;

      case "email":
        if (!emailRegex.test(value)) {
          errorMsg = "Please enter a valid email address";
        }
        break;

      case "subject":
        if (value === "Selected") {
          errorMsg = "Please select a valid subject.";
        }
        break;

      case "message":
        if (value.length < 50) {
          errorMsg = "Message should be at least 50 characters.";
        }
        break;

      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMsg,
    }));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setSupportPayload((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll behavior
    });
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("support"));
  }, [dispatch]);

  // useEffect(() => {
  //   const fetchFaqs = async () => {
  //     setLoadingFaqs(true);
  //     try {
  //       const response = await axios.get(`${BASE_URL}faq/open`);
  //       setFaqData(response?.data?.data);
  //       setLoadingFaqs(false);
  //     } catch (error) {
  //       toast.error("Error fetching FAQs:", error);
  //       setLoadingFaqs(false);
  //       // Optionally, you could set an error state to show an error message to the user
  //     }
  //   };

  //   fetchFaqs();
  // }, []);

  useEffect(() => {
    if (location?.state?.FAQs) {
      setFaqActive(true);
    }
  }, [location?.state]);
  const decodeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const handleSumbitDetails = async () => {
    try {
      setLoadingFaqs(true);
      const response = await axios.post(
        `${BASE_URL}contactUs/open`,
        supportPayload
      );
      // console.log("Response:", response);
      setShowContactForm(true);
      setLoadingFaqs(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setShowContactForm(false);
      setLoadingFaqs(false);
    }
  };

    // Handle captcha response
    const handleCaptchaChange = (value) => {
      if (value) {
        setCaptchaVerified(true);
      } else {
        setCaptchaVerified(false);
      }
    };

  return (
    <div>
      {loadingFaqs && (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      <div className="bg191916">
        <img
          className="support-image-container"
          src={support_image}
          alt="supportBackgroundImage"
        />
        <div className="support_content_container col-10">
          <div className="supportPageHeading fontAtacama">Support</div>
          <div className="fontAntarctica supportPageDescriptive">
            <div>
              Have a question or some feedback? We'd love to hear from you!
            </div>
            <div>
              Fill out form below and we'll get back to you as soon as possible
            </div>
          </div>
        </div>
      </div>
      <div className="bg262523 supportFormContainer">
        <div className="d-flex justify-content-center pt-5">
          <div className="text-white d-flex justify-content-center align-items-center getInTouchContainer">
            <div
              onClick={() => setFaqActive(false)}
              className={`m-1 ${
                faqActive ? "" : "border-bottom-line"
              } cursorPointer`}
            >
              Get In Touch
            </div>
            {/* <div
              onClick={() => setFaqActive(true)}
              className={`m-1 ${
                faqActive ? "border-bottom-line" : ""
              } cursorPointer`}
            >
              FAQ
            </div> */}
          </div>
        </div>
        <div className="d-flex justify-content-center pt-4">
          {!faqActive ? (
            showContactForm ? (
              <div className="bg191916 text-white  p-2 borderRadius8">
                Thank you for reaching us. Our team will consider your
                issue/feedback in next 24 hours.
                <span
                  onClick={() => {
                    setShowContactForm(false);
                    setSupportPayload({
                      adminReply: "",
                      companyName: "",
                      email: "",
                      firstName: "",
                      id: 0,
                      lastName: "",
                      message: "",
                      reachOn: 0,
                      repliedOn: 0,
                      subject: "",
                    });
                  }}
                  className="fontColorCE1 text-decoration-underline cursorPointer"
                >
                  Click here
                </span>{" "}
                to submit new request.
              </div>
            ) : (
              <div className="input-group d-flex flex-column">
                <div className="d-flex flex-row">
                  <input
                    placeholder="First Name"
                    type="text"
                    aria-label="First name"
                    className="form-control supportFormFocus"
                    style={{ marginRight: "6px" }}
                    id="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={supportPayload?.firstName}
                  />
                  {/* {errors.firstName && <span className="fontColor4031">{errors.firstName}</span>} */}

                  <input
                    placeholder="Last Name"
                    type="text"
                    aria-label="Last name"
                    className="form-control supportFormFocus"
                    style={{ marginLeft: "6px" }}
                    id="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={supportPayload?.lastName}
                  />
                  {/* {errors.lastName && <span className="fontColor4031">{errors.lastName}</span>} */}
                </div>
                <div>
                  {errors.firstName && (
                    <span className="fontColor4031">{errors.firstName}</span>
                  )}
                </div>
                <div>
                  {errors.lastName && (
                    <span className="fontColor4031">{errors.lastName}</span>
                  )}
                </div>
                <div>
                  <input
                    placeholder="Email"
                    type="text"
                    aria-label="Last name"
                    className="form-control supportFormFocus my-2"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={supportPayload?.email}
                  />
                  {errors.email && (
                    <span className="fontColor4031">{errors.email}</span>
                  )}

                  <div className="form-floating">
                    <select
                      className="form-select supportFormFocus opacity fontAntarctica"
                      id="subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={supportPayload?.subject}
                      aria-label="Floating label select example"
                    >
                      <option value="" disabled className="opacity">Subject</option>
                      <option value="Customer Service">Customer Service</option>
                      <option value="Partnerships">Partnerships</option>
                      <option value="Product Testing">Product Testing</option>
                      <option value="Careers">Careers</option>
                      <option value="Others">Others</option>
                    </select>
                    {errors.subject && (
                      <span className="fontColor4031">{errors.subject}</span>
                    )}
                  </div>
                  <div className="form-floating my-2">
                    <textarea
                      className="form-control supportFormFocus textareaHeight"
                      placeholder="Leave a comment here"
                      id="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={supportPayload?.message}
                    ></textarea>
                    {errors.message && (
                      <span className="fontColor4031">{errors.message}</span>
                    )}
                    {!supportPayload?.message && <label htmlFor="message" className="opacity">Message</label>}
                  </div>
                  <div className="py-2">
                   <Recaptcha onCaptchaChange={handleCaptchaChange} />
                  </div>
                </div>
                <div>
                  <button
                    onClick={handleSumbitDetails}
                    className={`w-100 my-2 p-2 bgCE1 borderRadius12 text-white outlineNone borderE1 accordion
                      ${
                        !captchaVerified ||
                        supportPayload?.firstName === "" ||
                        supportPayload?.lastName === "" ||
                        supportPayload?.email === "" ||
                        supportPayload?.message === "" ||
                        supportPayload?.subject === "" ||
                        errors?.email !== "" ||
                        errors?.firstName !== "" ||
                        errors?.lastName !== "" ||
                        errors?.message !== "" ||
                        errors?.subject !== ""
                          ? "disabled-class"
                          : ""
                      }
                      `}
                  >
                    Get In Touch
                  </button>
                </div>
              </div>
            )
          ) : (
            <div className="text-white">
              {/* <div className="d-flex flex-column justify-content-center align-items-center">
                {faqData?.map((faq, index) => (
                  <div
                    className="text-center fontAntarctica d-flex flex-column justify-content-center align-items-center width100vw"
                    key={faq?.id}
                  >
                    <div
                      onClick={() => {
                        if (index === faqID) {
                          setFaqID("");
                        } else {
                          setFaqID(index);
                        }
                      }}
                      className="fontSize17 my-1 cursorPointer w-75"
                    >
                      {faq?.question}
                    </div>
                    {index === faqID && (
                      // <div className="fontSize13 w-75">
                      //   {faq?.answer}</div>
                      <p
                        className="fontSize13 w-75"
                        dangerouslySetInnerHTML={{
                          __html: decodeHTML(faq?.answer),
                        }}
                      ></p>
                    )}
                    {faqData?.length - 1 === index ? (
                      <div className="mb-2"></div>
                    ) : (
                      <hr className="w-75" />
                    )}
                  </div>
                ))}
              </div> */}
            </div>
          )}
        </div>
        <div className="my-2 text-center fontAntarctica">
          <div className="text-white fontSize17 my-3">Contact Us</div>
          <div className="fontSize16 my-2 d-flex justify-content-center p-2 borderRadius12">
            <div className="bg191916 border3B3936  supportPageWidth2 p-2 borderRadius12 d-flex justify-content-start">
              <div className="support_Image_div">
                <img src={emailWhiteIcon} alt="EmailIcon" />
              </div>
              <a
                href="mailto:support@drivejoulez.com"
                className="text-white m-1 mx-2 cursorPointer opacity text-decoration-none"
              >
                support@drivejoulez.com
              </a>
            </div>
          </div>
          <div className="fontSize16 my-2 d-flex mx-2 justify-content-center p-2 borderRadius12">
            <div className="bg191916 border3B3936 supportPageWidth2 p-2 borderRadius12 d-flex justify-content-start">
              <div className="support_Image_div">
                <img src={phoneWhiteIcon} alt="EmailIcon" />
              </div>
              <a
                href="tel:+14452568539"
                className="text-white opacity m-1 mx-2 text-decoration-none"
              >
                +1 (445) 2JOULEZ
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
